import React, { useEffect, useState } from 'react'
import Spinner from 'react-spinkit'
import { Button } from 'antd'
import { auth } from "../../../firebaseConfig"

function RecoverEmail(props) {
  const {history} = props
  const [error, setError] = useState('')
  const [restoredEmail, setRestoredEmail] = useState('')
  const [resetSent, setResetSent] = useState(false)
  const [validCode, setValidCode] = useState(null)
  const [verifiedCode, setVerifiedCode] = useState(false)

  useEffect(() => {
    // Confirm the action code is valid.
    auth
      .checkActionCode(props.actionCode)
      .then(info => {
        // Get the restored email address.
        // Revert to the old email.
        auth
          .applyActionCode(props.actionCode)
          .then(() => {
            // Account email reverted to restoredEmail
            setRestoredEmail(info['data']['email'])
            setValidCode(true)
            setVerifiedCode(true)
          })
      }, error => {
        // Invalid code.
        setError(error.message)
        setValidCode(false)
        setVerifiedCode(true)
      })
  }, [])

  const sendReset = () => {
    // You might also want to give the user the option to reset their password
    // in case the account was compromised:
    auth
      .sendPasswordResetEmail(this.state.restoredEmail)
      .then(() => {
        // Password reset confirmation sent. Ask user to check their email.
        setResetSent(true)
      })
  }

  if (!verifiedCode) {
    return <Spinner spinnerName="three-bounce" />
  } else if (resetSent) {
    return (
      <div className="fly-form auth-form v1-form t-center">
        <h2>Check your email</h2>
        <p>Follow the instructions sent to <span>{restoredEmail}</span> to recover your password.</p>
      </div>
    )
  } else if (verifiedCode && validCode) {
    return (
      <div className="fly-form auth-form v1-form t-center">
        <h2>Updated email address</h2>
        <p>Your sign-in email address has been changed back to <span>{restoredEmail}</span></p>
        <Button type="primary" className="fly-btn mt-4" onClick={() => history.push('/auth/login')}>
          Continue
        </Button>
      </div>
    )
  } else if (verifiedCode && !validCode) {
    return (
      <div className="fly-form auth-form v1-form t-center">
        <h2>Unable to update your email address</h2>
        <p>There was a problem changing your sign-in email back.</p>
        <p className="error">{error}</p>
        <Button type="primary" className="fly-btn mt-4" onClick={() => history.push('/auth/login')}>
          Continue
        </Button>
      </div>
    )
  }
}

export default RecoverEmail
