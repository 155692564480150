import React, { useState } from 'react'
import { Form, Input, Button, Row, Col, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { InfoCircleOutlined } from '@ant-design/icons'
import NumberFormat from 'react-number-format'
import FlyActions from '../../../redux/common'
import * as utils from '../../../utils'
import { auth, firestore } from "../../../firebaseConfig"
import { ReactSVG } from 'react-svg'
import ChangePassword from './ChangePassword';
import ChangePasswordStatus from './ChangePasswordStatus';
import ApiManager from '../../../service';

function Profile(props) {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const {history, currentUser} = props
    const [avatar, setAvatar] = useState(currentUser.photoURL)
    const [loading, setLoading] = useState(false)
    const [step, setStep] = useState('')

    const handleSignOut = () => {
        const user = null
        dispatch({ type: 'USER_LOADED', user })
        auth.signOut()
        // history.push('/client/auth/login')
    }

    const onSubmitHandler = async (values) => {
        setLoading(true)
        Object.keys(values).forEach(key => {
            if (values[key] === undefined) values[key] = ''
        })

        try {
            if (values.phone) {
                const phoneNumber = values.phone.replace(/[\s()-]/g, '');
                
                await ApiManager.changePhone({ uid: currentUser.uid, phoneNumber });

                await firestore.doc(`users/${currentUser.uid}`).update({
                    phoneNumber: phoneNumber.includes('+') ? phoneNumber : `+1${phoneNumber}`
                });
            }
            
            await firestore.doc(`users/${currentUser.uid}`).update(values);
            
            if (avatar) {
                await currentUser.updateProfile({ photoURL: avatar });
            }

            if (values.email !== '' && values.email !== currentUser.email) {
                // This is for default firebase email change
                // auth.signInWithEmailAndPassword(currentUser.email, currentUser.profile.password)
                // .then((res) => {res.user.updateEmail(values.email)})
                // .catch(error => {})
                await ApiManager.changeEmail({ uid: currentUser.uid, email: values.email, oldEmail: currentUser.email })
                  .then(async () => {
                      await auth.signInWithEmailAndPassword(values.email, currentUser.profile.password)
                  })
            } else {
                dispatch(FlyActions.onLoadUser(currentUser));
            }

            utils.openNotification('Saved successfully!');
            setLoading(false);
        } catch (e) {
            utils.openNotification(e.message, false)
            setLoading(false);
        }
    }

    const params = {
        ...props,
        step,
        setStep
    }

    switch (step) {
        case 'change-password':
            return <ChangePassword {...params} />
        case 'change-password-status_success':
            return <ChangePasswordStatus {...params} error={false}/>
        case 'change-password-status_error':
            return <ChangePasswordStatus {...params} error={true}/>
        default:
            break;
    }

    return (
        <div className="content auth-page auth-page-mobile">
            <div className="client-header b-gray">
                <div className="header-item">
                   <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={() => history.goBack()}/>
                    <h2 className="mb-0" style={{color: "#0A0702"}}>Profile</h2>
                    <div className="step-marker">
                    </div>
                </div>
            </div>
            <div className="client-body b-gray profile height-100-percent">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                    initialValues={currentUser.profile}
                >
                    <p className="section-label mb-4">Personal Information</p>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="First name" name="firstname"
                                rules={[{ validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'First name', true) }]}
                            >
                                <Input type="text" className="fly-input" placeholder="First name" suffix={
                                    <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                }/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Last name" name="lastname"
                                rules={[{ validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Last name', true) }]}
                            >
                                <Input type="text" className="fly-input" placeholder="Last name" suffix={
                                    <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                }/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Email" name="email"
                                rules={[{
                                    required: true,
                                    validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true)
                                }]}>
                                <Input type="email" className="fly-input" placeholder="Email" suffix={
                                    <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                }/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Phone Number" name="phone">
                                <NumberFormat className="fly-input" format="(###) ###-####" mask="_" placeholder="(000) 000-0000" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Nationality" name="country" rules={[{ required: true, message: 'This field is required' }]}>
                                <Select
                                    placeholder="Pick fom the list"
                                    className="fly-select"
                                    showSearch={true}
                                    options={utils.resources.allCountries}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Approximate Weight" name="approximateWeight" rules={[{ required: true, message: 'This field is required' }]}>
                                <Select
                                    placeholder="Pick fom the list"
                                    className="fly-select"
                                    showSearch={true}
                                    options={utils.resources.personWeightRange2}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Button className="btn-container" onClick={() => setStep('change-password')}>
                        Change password
                        <ReactSVG src="/images/arrow.svg" className="arrow-icon" />
                    </Button>
                    <Form.Item shouldUpdate className="mt-5 t-right">
                        {() => (
                            <Button type="primary" htmlType="submit" className="fly-btn w-full"
                                loading={loading}
                                disabled={
                                    form.getFieldsError().filter(({ errors }) => errors.length)
                                      .length > 0
                                  }
                            >Save</Button>
                        )}
                    </Form.Item>
                    <Form.Item className="mt-2 t-right">
                        <Button type="primary" className="fly-btn w-full"
                            loading={loading}
                            onClick={() => handleSignOut()}
                        >Log out</Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Profile
