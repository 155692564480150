import React from 'react'
import { Link } from "react-router-dom"
import { Form, Row,  Col,  Input, Button } from 'antd'
import { FlySideBar } from '../../components/'
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { ReactSVG } from 'react-svg'
import * as utils from '../../utils'

function Welcome(props) {
    const [form] = Form.useForm()
    const {user, setUser, history} = props

    const onSubmitHandler = (values) => {
        setUser(values)
        history.push(`/auth/register?email=${values.email}`)
    }

    return (
        <>
            <div className="auth-page desktop-view auth-page-auth">
                <FlySideBar />
                <div className="content">
                    <div className="fly-form auth-form v1-form w-expand">
                        <h2 className="d-flex welcome-subtitle">Welcome to <ReactSVG src="/images/logo_black.svg" className="logo-img" />!</h2>
                        <p className="fly-info mb-4">We need to make sure you're real, please check your junk folder for our authentication email to continue creating your account. </p>
                        <Form
                            name="form"
                            id="form"
                            layout='vertical'
                            form={form}
                            onFinish={onSubmitHandler}
                            requiredMark={false}
                            initialValues={user}
                        >
                            <Row>
                                <Col className='fly-input-wrapper'>
                                    <Form.Item label="Email" name="email"
                                        rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                                    >
                                        <Input type="email" className="fly-input" placeholder="Enter your email" suffix={
                                            <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                        }/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item shouldUpdate className="mt-4 fly-next-btn">
                                        {() => (
                                            <Button type="primary" htmlType="submit" className="fly-btn"
                                            disabled={
                                                form.getFieldsError().filter(({ errors }) => errors.length)
                                                  .length > 0 || !form.isFieldsTouched(true)
                                              }
                                            >NEXT<ArrowRightOutlined /></Button>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/*<div className="d-flex">
                                <Link to="/">Verify with your phone number</Link>
                            </div>*/}
                        </Form>
                    </div>
                    <h4 className="signIn-btn">Already have an account? <Link to="/auth/login" className="ml-1">Sign in</Link></h4>
                </div>
            </div>
        </>
    )
}

export default Welcome
