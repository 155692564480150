import React, { useEffect, useState } from 'react'
import * as utils from '../../../utils'
import './index.scss'
import Requested from './Requested'
import MyFlights from './MyFlights'
import { firestore } from '../../../firebaseConfig'
import resources from '../../../utils/resources';


function Flights(props) {
    const {history, currentUser, listScrollHeight, setListScrollHeight, config} = props
    const [activeNav, setActiveNav] = useState('upcoming');

    let intervalId;
    let savedFlightId;
    let savedRequestId;

    const sendGAEvent = async (flightId, requestId) => {
        const flightSnapshot = await firestore.collection('flights').doc(flightId).get();
        const requestSnapshot = await firestore.collection('flights')
            .doc(flightId).collection('requests').doc(requestId).get();
        if (flightSnapshot.exists && requestSnapshot.exists) {
            const flight = flightSnapshot.data();
            const request = requestSnapshot.data();
            if (request.payment) {
                const paymentSnapshot = await firestore.collection('transactions').doc(request.payment).get();
                if (paymentSnapshot.exists) {
                    clearInterval(intervalId);

                    const payment = paymentSnapshot.data();

                    utils.pushDataLayer({ ecommerce: null });
                    utils.pushDataLayer({
                        event: "purchase",
                        ecommerce: {
                            transaction_id: payment.transactionId,
                            value: payment.amount,
                            currency: payment.currency,
                            items: [
                                {
                                    item_id: payment.flightNumber,
                                    item_name: `${resources.airports.index[flight?.departureAirport]?.code || flight?.pickupInstruction || `${flight?.departureLocation?.latitude},${flight?.departureLocation?.longitude}` || ''} - ${resources.airports.index[flight?.arrivalAirport]?.code || `${flight?.arrivalLocation?.latitude},${flight?.arrivalLocation?.longitude}` || ''}`,
                                    item_category: "Request a Flight",
                                    price: payment.amount,
                                    quantity: request.passengers.length
                                }
                            ]
                        }
                    })
                }
            }
        }
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search)
        const ftype = urlParams.get('ft')
        if (ftype) {
            setActiveNav(ftype.toLowerCase())
        }

        const flightId = urlParams.get('fd');
        const requestId = urlParams.get('rd');

        if (flightId && requestId) {
            savedFlightId = flightId;
            savedRequestId = requestId;

            urlParams.delete('fd');
            urlParams.delete('rd');
            window.history.replaceState(null, '', `${history.location.pathname}?${urlParams.toString()}`);
        }

        if (savedFlightId && savedRequestId) {
            intervalId = setInterval(() => {
                sendGAEvent(savedFlightId, savedRequestId);
            }, 5000);

            setTimeout(() => {
                clearInterval(intervalId);
            }, 60000);
        }

        return () => {
            if (savedFlightId && savedRequestId) clearInterval(intervalId);
        };
    }, [history.location.search])

    useEffect(() => {
        setListScrollHeight(0);
    }, [activeNav])

    const params = {
        activeNav, setActiveNav, history, currentUser, setListScrollHeight, config
    }

    let renderPage = ''

    switch (activeNav) {
        case 'requested':
            renderPage = <Requested {...params} />
            break
        default:
            renderPage = <MyFlights {...params} />
    }

    const onNavSwitchHandler = (nav) => {
        setActiveNav(nav)
    }

    return (
        <div className={`content flight-list ${activeNav.toLowerCase()}${listScrollHeight > 100 ? ' resizePage': ''}`}>
            { renderPage }
        </div>
    )
}

export default Flights
