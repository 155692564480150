import React, { useEffect, useState } from 'react'
import PasswordRecoveryRequest from "./PasswordRecoveryRequest"
import PasswordRecoveryVerify from "./PasswordRecoveryVerify"
import PasswordRecoveryReset from "./PasswordRecoveryReset"
import PasswordRecoverySent from "./PasswordRecoverySent"

function PasswordRecovery(props) {
    const {user, setUser, history} = props
    const [step, setStep] = useState('request')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])

    let params = {
        user,
        setUser,
        step,
        setStep,
        history
    }

    let renderPage = ''

    switch (step) {
        case 'verify':
            return <PasswordRecoveryVerify {...params} />
        case 'reset':
            return <PasswordRecoveryReset {...params} />
        case 'sent':
            return <PasswordRecoverySent {...params} />
        default:
            return <PasswordRecoveryRequest {...params} />
    }
}

export default PasswordRecovery
