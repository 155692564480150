import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import AddFboModal from "../../Auth/AccountCompany/AddFboModal"
import RenderCountryState from '../../Auth/AccountCompany/RenderCountryState';
import _ from "lodash"

function FixedBasedOperationsPanel (props) {
    const [fixedCountry, setFixedCountry] = useState()
    const [additionalFBOs, setAdditionalFBOs] = useState(0)
    const [usedIslands, setUsedIslands] = useState({})

    useEffect(() => {
        if (props.profile.fixedBasedOperations) {
            setAdditionalFBOs(Object.keys(props.profile.fixedBasedOperations).length)
            setUsedIslands(props.mainform.getFieldValue('fixedBasedOperations') || {})
        }
    }, [props.profile])

    const onChangeFixedCountry = (selectedCountry) => {
        setFixedCountry(selectedCountry)
    }

    const onDeleteFBO = (idx) => {
        const FBOdata = props.mainform.getFieldValue('fixedBasedOperations');
        delete FBOdata[idx];
        const newFboData = {};
        Object.values(FBOdata).map((value, index) => newFboData[index] = value);
        props.mainform.setFieldsValue({ 'fixedBasedOperations': newFboData });
        setAdditionalFBOs(additionalFBOs - 1);
    }

    return (
        <>
            {_.times(additionalFBOs, (idx) => (
                <RenderCountryState
                    key={idx}
                    idx={idx}
                    additionalFBOs={additionalFBOs}
                    onDeleteFBO={onDeleteFBO}
                    usedIslands={usedIslands}
                    setUsedIslands={setUsedIslands}
                    form={props.mainform}
                />
            ))}
            <Row className="mt-2">
                <Col span={24} className="t-center">
                    <AddFboModal
                        {...props}
                        additionalFBOs={additionalFBOs}
                        setAdditionalFBOs={setAdditionalFBOs}
                        fixedCountry={fixedCountry}
                        mainForm={props.mainform}
                        usedIslands={usedIslands}
                        setUsedIslands={setUsedIslands}
                    />
                </Col>
            </Row>
        </>
    )
}

export default FixedBasedOperationsPanel;
