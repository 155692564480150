import React, { useState } from 'react'
import { Row, Col, Form, Button, Statistic } from 'antd'
import { ReactSVG } from 'react-svg'
import * as utils from '../../../../utils'
import { ApiManager } from "../../../../service"
import {
    FIXED_STRIPE_COMMISSION,
    FLYMINGO_COMMISSION,
    KANOO_COMMISSION,
    KANOO_PAYMENT_COUNTRY,
    MIN_STRIPE_COMMISSION
} from '../../../../constants';

function RequestBidInfo(props) {
    const [form] = Form.useForm()
    const {step, setStep, activeBid, flight, currentUser, config} = props
    const [loading, setLoading] = useState()

    const onSubmitHandler = async (values) => {
        setLoading(true);

        await ApiManager.acceptBid({ uid: currentUser.uid, requestId: flight.id, bidId: activeBid.id })
          .then(async (res) => {
              if (flight.passengers) {
                  await ApiManager.scheduleRequest({fid: res.data.body.fid, rid: res.data.body.rid});
              }

              res.data.body.emailsToSend.map((content) => {
                  ApiManager.sendEmail(content);
              })
          })

        setLoading(false);
        setStep('Done');
    }

    const personCnt = flight.passengers ? Object.keys(flight.passengers).length : 0
    let bagCnt = 0
    flight.passengers && Object.keys(flight.passengers).map(pkey => {
        let passenger = flight.passengers[pkey]
        const passengerBagCnt = passenger.numberOfBags || 0
        bagCnt += passengerBagCnt > 1 ? passengerBagCnt - 1 : 0
    })
    
    let subTotalPrice = (parseInt(activeBid.cost) * ((flight.public && flight.createdBy !== currentUser.uid) ? personCnt : 1)
      + parseInt(activeBid.costPerAdditionalBag) * bagCnt).toFixed(2);
    let serviceFee = (+subTotalPrice * (config.FLYMINGO_COMMISSION || FLYMINGO_COMMISSION)).toFixed(2);

    // const IS_KANOO_PAY = activeBid.companyCountry === KANOO_PAYMENT_COUNTRY;
    const IS_KANOO_PAY = false;

    const commissionPercentage = IS_KANOO_PAY ? KANOO_COMMISSION : MIN_STRIPE_COMMISSION;

    const totalTaxes = (
      ((+subTotalPrice + +serviceFee) *
        (commissionPercentage)) / (100 - commissionPercentage)
      + (IS_KANOO_PAY ? 0 : FIXED_STRIPE_COMMISSION)
    ).toFixed(2);

    const totalPrice = (+subTotalPrice + +serviceFee + +totalTaxes).toFixed(2);

    return (
        <div className="bid-info-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <div className="flight-item">
                    <div className="flight-item-body">
                        <Row className="row-wrapper">
                            <Col span={24}>
                                <div className="d-flex d-space">
                                    {flight?.departureAirport
                                        ? (
                                            <>
                                                <p className="fly-notes">Airport</p>
                                                <p className="ml-3" style={{ textAlign: 'right' }}>
                                                    {flight?.departureAirport || ''}<br />
                                                    {utils.resources.airports.index[flight?.departureAirport]?.title || ''}
                                                </p>
                                            </>
                                        )
                                        : (
                                            <>
                                                <p className="fly-notes">Departure Location</p>
                                                <div>
                                                    <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                                        {flight.departureLocation?.latitude}
                                                    </p>
                                                    <p style={{
                                                        fontSize: '12px',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <p style={{
                                                            opacity: 0.4,
                                                            marginRight: '3px',
                                                            fontSize: '12px',
                                                            whiteSpace: 'nowrap'
                                                        }}>Lon:</p>
                                                        {flight.departureLocation?.longitude}
                                                    </p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                {flight?.pickupInstruction && (
                                    <div className="d-flex d-space">
                                        <p className="fly-notes">Pickup Instructions</p>
                                        <p style={{ textAlign: "end" }}>
                                            {flight.pickupInstruction}
                                        </p>
                                    </div>
                                )}
                                <div className="d-flex d-space">
                                    <p className="fly-notes">Departure Time</p>
                                    <p>{activeBid.departureTime} - {flight.departureDate}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="row-wrapper mt-2">
                            <Col span={24}>
                                <div className="d-flex d-space">
                                    {flight?.arrivalAirport
                                        ? (
                                            <>
                                                <p className="fly-notes">Airport</p>
                                                <p style={{ textAlign: 'right' }}>
                                                    {flight.arrivalAirport}<br />
                                                    {utils.resources.airports.index[flight?.arrivalAirport]?.title || ''}
                                                </p>
                                            </>
                                        )
                                        : (
                                            <>
                                                <p className="fly-notes">Arrival Location</p>
                                                <div>
                                                    <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                                        {flight.arrivalLocation?.latitude}
                                                    </p>
                                                    <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                                        {flight.arrivalLocation?.longitude}
                                                    </p>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                {flight.arrivalTime &&
                                    <div className="d-flex d-space">
                                        <p className="fly-notes">Departure Time</p>
                                        <p>{flight.arrivalTime} - {flight.arrivalDate}</p>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row className="row-wrapper mt-2">
                            <Col span={24}>
                                <div className="d-flex d-space">
                                    <p className="section-desc">Status</p>
                                    {activeBid.pilotDetails.verified === "done"?
                                        <div className="d-flex">
                                            <ReactSVG src="/images/verified-star.svg" className="mr-1 planetype-icon" />
                                            <p>Verified</p>
                                        </div>
                                        :
                                        <p>unverified</p>
                                    }
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Pilot</p>
                                    <div className="d-flex">
                                        <img alt='' src={activeBid.pilotDetails.photo || "/images/user-placeholder.png"} className="pilot-profile mr-1" />
                                        <p>{activeBid.pilotDetails.firstname} {activeBid.pilotDetails.lastname}</p>
                                    </div>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Aircraft Make</p>
                                    <p>{activeBid.planeDetails.planeMake}</p>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Year</p>
                                    <p>{activeBid.planeDetails.year}</p>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Plane Type</p>
                                    <div className="d-flex">
                                        <ReactSVG src={`images/${utils.getPlaneType(activeBid.planeDetails.planeType)}.svg`} className="planetype-icon mr-1" />
                                        <p>{activeBid.planeDetails.planeType}</p>
                                    </div>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Flight Type</p>
                                    <p>{flight.public? "Public": "Private"}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col span={24}>
                                <h3>Bid Price</h3>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Per person X {personCnt}</p>
                                    <Statistic prefix="$" value={(flight.public && flight.createdBy !== currentUser.uid) ? activeBid.cost : (activeBid.cost / personCnt)} precision={2} />
                                </div>
                                {bagCnt >= 1 &&
                                    <div className="d-flex d-space mt-1">
                                        <p className="section-desc">Per additional bag X {bagCnt}</p>
                                        <Statistic prefix="$" value={activeBid.costPerAdditionalBag} precision={2} />
                                    </div>
                                }
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Subtotal</p>
                                    <Statistic prefix="$" value={subTotalPrice} precision={2} />
                                </div>
                                <hr />
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Service Fee</p>
                                    <Statistic prefix="$" value={serviceFee} precision={2} />
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Taxes</p>
                                    <Statistic prefix="$" value={totalTaxes} precision={2} />
                                </div>
                                <hr />
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Total</p>
                                    <Statistic prefix="$" value={totalPrice} precision={2} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full" loading={loading}>
                                Accept Bid
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default RequestBidInfo
