import React, { useState } from 'react'
import { Row, Col, Form, Button, Input, Radio, Switch, Modal, Tooltip } from 'antd'
import moment from 'moment';
import { ReactSVG } from 'react-svg'
import FlyTimePicker from '../../../components/FlyTimePicker';
import * as utils from "../../../utils";

function TimePopup(props) {
	const [timeForm] = Form.useForm()
	const {form, idx, field, selectedDate} = props
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [enabled, setEnabled] = useState(false)
	const diffBetweenDays = moment().startOf("day").diff(selectedDate, "days");

    const onSetTimeHandler = (values) => {
        let time = values.flexibleTime
        if (values.stEnabled && values.specificTime)
            time = values.specificTime.format('hh:mm a')
			
				if (!time) return;
			
        form.setFieldsValue({
            'list': {
                [idx]: {[field]: time}
            }
        })

        handleOk()
    }

    const onChangeSwitchHandler = (checked) => {
		timeForm.setFieldsValue({
			flexibleTime: checked ? '' : 'Sunrise'
		})
    	setEnabled(checked)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

	return (
      	<>
            <Form.Item
								label=""
								name={['list', idx, field]}
                rules={[{ required: true, message: 'This field is required' }]}
						>
                <Input
										placeholder="HH:MM A"
										className="time-input"
										disabled={!selectedDate}
                		onClick={() => setIsModalVisible(true)}
                		suffix={<ReactSVG src="/images/time-white.svg" className="time-icon"/>}
								/>
            </Form.Item>
            <Modal
								title="Departure Time Preference"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width='23rem'
                className="time-picker-modal"
                footer={[]}
                destroyOnClose={true}
                closable={false}
                centered
						>
						<Form
		            name="time-form"
		            id="time-form"
		            layout='vertical'
		            className="select-time-picker"
		            form={timeForm}
		            onFinish={onSetTimeHandler}
		            requiredMark={false}
		        >
						<Row>
								<Col span={24}>
										<div className="d-flex mb-1">
												<p className="mb-0 mr-1">Flexible Time</p>
												<Tooltip className="fly-tooltip" title={<p>A pilot will include in their bid a departure time within the morning or afternoon range for you to accept - pick the one that best suits you!</p>} color="#90C4C2" placement="top">
														<ReactSVG src="/images/info.svg" className="label-icon" />
												</Tooltip>
										</div>
										<Form.Item
												label=""
												name="flexibleTime"
												rules={[{ required: false, validator: (rule, value, callback) => utils.validations.validateFlexible(rule, value, callback, 'Flexible Time', selectedDate, enabled)} ]}
										>
												<Radio.Group
														options={[
																{label: 'Sunrise', value: 'Sunrise'},
																{label: 'Sunset', value: 'Sunset'},
														]}
														size='large'
														optionType="button"
													 	// defaultValue={'Sunrise'}
														className="flexible-time-radio-grp"
													 	disabled={enabled}
												/>
										</Form.Item>
								</Col>
						</Row>
						<hr className="mh-1" />
		            <Row>
		                <Col span={24}>
		                    <div className="d-flex d-space mb-1">
														<p className="mb-0">Specific Time</p>
														<Form.Item label="" name="stEnabled" valuePropName="checked">
																<Switch onChange={onChangeSwitchHandler}/>
														</Form.Item>
		                    </div>
		                    {enabled &&
														<Form.Item
																label=""
																name="specificTime"
																rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateTime(rule, value, callback, 'Specific Time', true, selectedDate)} ]}
														>
																<FlyTimePicker
																		format="hh:mm a"
																		placeholder="HH:MM A"
																		className="fly-date-picker"
																		minuteStep={30}
																/>
														</Form.Item>
		                		}
		                </Col>
		            </Row>
		            <Row className="mt-2">
		                <Col span={24} className="t-right">
		                    <Button className="fly-btn" htmlType="submit">Apply</Button>
		                </Col>
		            </Row>
		        </Form>
            </Modal>
        </>
    )
}

export default TimePopup
