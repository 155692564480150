import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Form, Input, Button, Select, Radio } from 'antd'
import Upload from 'rc-upload'
import { ArrowRightOutlined, InfoCircleOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import NumberFormat from 'react-number-format'
import { firestore, serverTimestamp, firestorage } from "../../../firebaseConfig"
import Loader from "react-loader-spinner"
import { ApiManager } from "../../../service"
import prepareNumericalValue from '../../../utils/prepareNumericalValue';
import isMobile from "../../../utils/isMobile";

const requiredFields = ['tailNumber', 'year', 'planeMake', 'seatCapacity', 'planeType']

function AccountCompanyFleet(props) {
    const [form] = Form.useForm()
    const {user, setUser, history, setStep, currentUser} = props
    const [uploadedFilesAOC, setUploadedFilesAOC] = useState({files: [], uploading: false})
    const [uploadedFilesInspection, setUploadedFilesInspection] = useState({files: [], uploading: false})
    const [uploadedFilesPhotos, setUploadedFilesPhotos] = useState({files: [], uploading: false})
    const [fileToUploadAOC, setFileToUploadAOC] = useState({});
    const [fileToUploadInspection, setFileToUploadInspection] = useState({});
    const [fileToUploadPhotos, setFileToUploadPhotos] = useState({});
    const [loading, setLoading] = useState(false)
    const [photoError, setPhotoError] = useState(utils.resources.planePhotos.reduce((acc, photo) => {
        acc[photo.value] = false;
        return acc;
    }, {}))
    const isPhotoError = Object.values(photoError).some(value => !!value);
    
    window.mobileCheck = isMobile;

    const onSubmitHandler = (values) => {
        addFleetInfo(values, true)
    }

    const changePage = () => {
        const urlParams = new URLSearchParams(history.location.search)
        urlParams.set('pt', 'pilots')
        history.push(`${history.location.pathname}?${urlParams.toString()}`)
    }

    const onNextHandler = () => {
        let isAllPlanePhotosAdded = true;
        
        for (const side of utils.resources.planePhotos.map(photo => photo.value)) {
            const photoExists = uploadedFilesPhotos.files.some(photo => photo.side === side);
            
            if (!photoExists) {
                setPhotoError(prevState => ({
                    ...prevState,
                    [side]: true
                }));
                isAllPlanePhotosAdded = false;
            }
        }
        
        form.validateFields().then(values => {
            if (values.errorFields) return;
            addFleetInfo(values, false, isAllPlanePhotosAdded);
        })
    }

    const addFleetInfo = async (values, addAnother, isAllPlanePhotosAdded) => {
        setLoading(true)
        // get user info from users collection and check if fleets exist.
        const uSnapshot = await firestore.collection('users').doc(currentUser.uid).get()
        if (uSnapshot.exists) { // user exists
            const userData = uSnapshot.data()
            let fleets = userData.fleets || []
            let cData = Object.assign({}, values)
            let fSnapshot = await firestore.collection('fleets').where("tailNumber", "==", values.tailNumber).get()
            if (!fSnapshot.empty) {
                utils.openNotification('Tail Number should be unique', false)
                setLoading(false)
                return
            }
            cData.uid = currentUser.uid
            cData.createdAt = serverTimestamp
            // replace undefinded values with '' to save data into firestore
            Object.keys(values).forEach(key => {
                if (cData[key] === undefined) cData[key] = ''
            })
            cData.AOCCertificate = uploadedFilesAOC.files || []
            cData.inspectionCertificate = uploadedFilesInspection.files || []
            
            if (!isAllPlanePhotosAdded) {
                setLoading(false);
                return;
            }
            
            cData.photos = uploadedFilesPhotos.files || []
            // company info not exists, add new company document to fleets collection and add company doc id to user doc.
            const cSnapshot = await firestore.collection('fleets').add(cData)
            currentUser.profile.fleets.push(cSnapshot.id)
            await firestore.collection('users').doc(currentUser.uid).update({fleets: currentUser.profile.fleets})
                .then(() => {
                    utils.pushDataLayer({ event: 'plane_added' });
                    ApiManager.sendSlackAlert({
                        text: `🔔 plane added \nTail number: ${cData?.tailNumber || ''} \nSeat capacity ${cData?.seatCapacity || ''}`
                    });
                })
            ApiManager.sendEmail({type: "fleetRequest", id: cSnapshot.id, uid: currentUser.uid, collection: "fleets",
                verified: "pending", reason: ""})
            utils.openNotification('Added a fleet successfully!', true)
        }
        else {
            utils.openNotification('User information does not exist', false)
        }
        setUser({...user, fleet: undefined })
        setLoading(false)
        if (addAnother) {
            form.resetFields()
            setUploadedFilesAOC({ files: [], uploading: false })
            setUploadedFilesInspection({ files: [], uploading: false })
        }
        else
            changePage()
    }

    useEffect(() => {
        if (Object.keys(fileToUploadAOC).length !== 0) {
            setUploadedFilesAOC({
                ...uploadedFilesAOC,
                files: [...uploadedFilesAOC.files, fileToUploadAOC],
                uploading: false
            })
        }
    }, [fileToUploadAOC])

    const customAOCUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;

        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }

        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFilesAOC({...uploadedFilesAOC, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`fleets/${currentUser.uid}/aoc-certificate-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUploadAOC({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFilesAOC({...uploadedFilesAOC, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }

    useEffect(() => {
        if (Object.keys(fileToUploadInspection).length !== 0) {
            setUploadedFilesInspection({
                ...uploadedFilesInspection,
                files: [...uploadedFilesInspection.files, fileToUploadInspection],
                uploading: false
            })
        }
    }, [fileToUploadInspection])

    const customInspectionUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFilesInspection({...uploadedFilesInspection, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`fleets/${currentUser.uid}/inspection-certificate-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUploadInspection({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFilesInspection({...uploadedFilesInspection, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }
    
    useEffect(() => {
        if (Object.keys(fileToUploadPhotos).length !== 0) {
            setUploadedFilesPhotos({
                ...uploadedFilesPhotos,
                files: [...uploadedFilesPhotos.files, fileToUploadPhotos],
                uploading: false
            })
        }
    }, [fileToUploadPhotos])
    
    const onRemoveFilePhoto = (side) => {
        setPhotoError({ ...photoError, [side]: false });

        setUploadedFilesPhotos({
            ...uploadedFilesPhotos,
            files: uploadedFilesPhotos.files.filter(file => file.side !== side)
        })
    }
    
    const customFilePhotosUploadHandler = async ({ onError, onSuccess, file, side }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        setPhotoError({ ...photoError, [side]: false });
        
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            setUploadedFilesPhotos({...uploadedFilesPhotos, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`fleets/${currentUser.uid}/photo-${utils.generateRandomKeys()}.png`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                if (uploadedFilesPhotos.files.find(file => file.side === side)) {
                    onRemoveFilePhoto(side);
                }
                setFileToUploadPhotos({ path: filePath, name: file.name, side })
            } catch(e) {
                setUploadedFilesPhotos({...uploadedFilesPhotos, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }
    
    const onChangeSeatCapacity = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        form.setFieldValue('seatCapacity', newVal);
    }

    return (
        <div className="content">
            <div className="fly-form fly-form-mob">
                <Form
                    className=""
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                    initialValues={user.fleet}
                >
                    <div className="form-content">
                        <div className="form-header">
                            <h2>Add your fleet</h2>
                            <p className="fly-info mb-4">Verify all of your scheduled flights and start to recieve requests for flights by adding your fleet with active certificates.</p>
                        </div>
                        <div className="form-body">
                            <Row className="d-flex">
                                <Col xl={12} xs={24} className="pr-2">
                                    <Form.Item label="Tail Number" name='tailNumber'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateTailNumber(rule, value, callback, 'Tail number', true) }]
                                        }>
                                        <Input type="text" className="fly-input" placeholder="Add tail number" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                    </Form.Item>
                                </Col>
                                <Col xl={12} xs={24} className="pl-2">
                                    <Form.Item label="Nickname" name='nickname'
                                        rules={[{
                                            validator: (rule, value, callback) => utils.validations.validateTextAndNumber(rule, value, callback, 'Nickname', false)
                                        }]}>
                                        <Input type="text" className="fly-input" placeholder="Name your plane" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/>}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={6} xs={24} className="pr-2">
                                    <Form.Item label="Year" name='year'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateYear(rule, value, callback, 'Year', true) }]
                                        }>
                                        <Input type="text" className="fly-input" placeholder="Year" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                    </Form.Item>
                                </Col>
                                <Col xl={6} xs={24} className="px-2">
                                    <Form.Item label="Plane Make" name='planeMake'
                                        rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Type to search"
                                            className="fly-select"
                                            options={utils.resources.planeManufacturers}
                                            showSearch={true}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xl={6} xs={24} className="px-2">
                                    <Form.Item label="Seat Capacity" name='seatCapacity'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Seat capacity', true, 0, 19) }]
                                        }>
                                        <Input type="number" className="fly-input" min="1" max="19" placeholder="Seat" onChange={onChangeSeatCapacity}/>
                                    </Form.Item>
                                </Col>
                                <Col xl={6} xs={24} className="pl-2">
                                    <Form.Item label="Max Weight Capacity" name='maxWeightCapacity' className="max-weight-capacity">
                                        <NumberFormat className="fly-input" thousandSeparator={true} placeholder="Weight" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl={12} xs={24} className="pr-2">
                                    <Form.Item label="AOC Certificate">
                                        {uploadedFilesAOC.files.length !== 0 && (
                                            <div className="ant-upload-select uploaded">
                                                <CheckOutlined style={{ color: '#ffffff'}}/>
                                                <p>Uploaded!</p>
                                                <EditOutlined
                                                    className="p-right"
                                                    onClick={() => setUploadedFilesAOC({
                                                        ...uploadedFilesAOC,
                                                        files: []
                                                    })}
                                                />
                                            </div>
                                        )}
                                        {uploadedFilesAOC.uploading && (
                                            <div className="ant-upload-select uploading">
                                                <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                            </div>
                                        )}
                                        <div style={{
                                            position: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 'initial' : 'absolute',
                                            zIndex: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 1 : -1,
                                            opacity: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 1 : 0,
                                            pointerEvents: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 'initial' : 'none',
                                            touchAction: uploadedFilesAOC.files.length === 0 && !uploadedFilesAOC.uploading ? 'initial' : 'none'
                                        }}>
                                            <Upload
                                                className="ant-upload ant-upload-select"
                                                customRequest={customAOCUploadHandler}
                                                showUploadList={false}
                                                multiple={true}
                                                accept=".png,.jpg,.jpeg,.pdf"
                                            >
                                                <p style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                                                    {window?.mobileCheck()
                                                        ? 'Add your AOC certificate here'
                                                        : 'Drag and drop your AOC certificate or browse from computer'}
                                                </p>
                                            </Upload>
                                        </div>
                                    </Form.Item>
                                </Col>
                                <Col xl={12} xs={24} className="pl-2">
                                    <Form.Item label="Inspection certificate">
                                        {uploadedFilesInspection.files.length !== 0 && (
                                            <div className="ant-upload-select uploaded">
                                                <CheckOutlined style={{ color: '#ffffff'}}/>
                                                <p>Uploaded!</p>
                                                <EditOutlined
                                                    className="p-right"
                                                    onClick={() => setUploadedFilesInspection({
                                                        ...uploadedFilesInspection,
                                                        file: []
                                                    })}
                                                />
                                            </div>
                                        )}
                                        {uploadedFilesInspection.uploading && (
                                            <div className="ant-upload-select uploading">
                                                <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                            </div>
                                        )}
                                        <div style={{
                                            position: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 'initial' : 'absolute',
                                            zIndex: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 1 : -1,
                                            opacity: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 1 : 0,
                                            pointerEvents: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 'initial' : 'none',
                                            touchAction: uploadedFilesInspection.files.length === 0 && !uploadedFilesInspection.uploading ? 'initial' : 'none'
                                        }}>
                                            <Upload
                                                className="ant-upload ant-upload-select"
                                                customRequest={customInspectionUploadHandler}
                                                showUploadList={false}
                                                multiple={true}
                                                accept=".png,.jpg,.jpeg,.pdf"
                                            >
                                                <p style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
                                                    {window?.mobileCheck()
                                                        ? 'Add your Inspection certificate here'
                                                        : 'Drag and drop your Inspection certificate or browse from computer'}
                                                </p>
                                            </Upload>
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="d-flex">
                                <Col xl={24} xs={24}>
                                    <Form.Item label="Choose plane type" name='planeType'
                                        rules={[{ required: true, message: 'This field is required' }]}>
                                        <Radio.Group size="large" className="plane-type-radio-grp plane-type-radio-grp-mob">
                                            {
                                                utils.resources.planeTypes.map((plane, idx) =>
                                                    <Radio.Button value={plane.label} key={plane.label}>
                                                        <div className="d-block">
                                                            <ReactSVG src={`images/${plane.value}.svg`} />
                                                            <p>{plane.label}</p>
                                                        </div>
                                                    </Radio.Button>
                                                )
                                            }
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <div className={`uploadPhotoTitle${isPhotoError ? ' uploadPhotoTitleError' : ''}`}>
                                Upload Plane Photo
                            </div>
                            <div className='add-plane-wrapper add-plane-wrapper-big'>
                                {utils.resources.planePhotos.map((side, idx) => {
                                    return (
                                        uploadedFilesPhotos.files.find(file => file.side === side.value)
                                            ? (
                                                <div key={idx} className='add-plane-container add-plane-container-big'>
                                                    <div
                                                        className='delete-plane-btn-wrapper'
                                                        onClick={() => onRemoveFilePhoto(side.value)}
                                                    >
                                                        <ReactSVG src={`images/deleteSmall.svg`}/>
                                                    </div>
                                                    <img
                                                        style={{width: '100%', height: '100%', objectFit: 'cover'}}
                                                        src={uploadedFilesPhotos.files.find(file => file.side === side.value).path}
                                                        alt={side.label}
                                                    />
                                                </div>
                                            ) : (
                                                <Upload
                                                    customRequest={(props) => customFilePhotosUploadHandler({
                                                        ...props,
                                                        side: side.value
                                                    })}
                                                    showUploadList={false}
                                                    multiple={true}
                                                    accept=".png,.jpg,.jpeg"
                                                    key={idx}
                                                    style={{ width: '100%' }}
                                                >
                                                    <div
                                                        className='add-plane-container add-plane-container-big'
                                                        style={{
                                                            borderColor: photoError[side.value] ? '#e64c25' : '#458796',
                                                            backgroundColor: photoError[side.value] ? '#E24D591A' : '#fff',
                                                        }}
                                                    >
                                                        <ReactSVG src={`images/addPhoto.svg`}/>
                                                        <p>{side.label}</p>
                                                    </div>
                                                </Upload>
                                            )
                                    )
                                })}
                            </div>
                            {isPhotoError && <div className='ant-form-item-explain-error'>This field is required</div>}
                        </div>
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Form.Item shouldUpdate>
                                {() => {
                                    let invalid = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || (user.fleet === undefined && !form.isFieldsTouched(requiredFields, true)) || isPhotoError
                                    return (
                                        <div className={invalid? 'w-full': 'w-right'}>
                                            {
                                                invalid?
                                                <Link to="?pt=pilots" className="extra-txt" onClick={changePage}>Add later</Link>
                                                :
                                                <Button type="primary" htmlType="submit" className="fly-btn bordered" loading={loading}>SAVE & ADD ANOTHER</Button>
                                            }
                                            <Button type="primary" className="fly-btn ml-2" onClick={onNextHandler} disabled={currentUser.profile.fleets? false: invalid} loading={loading}>
                                                NEXT<ArrowRightOutlined />
                                            </Button>
                                        </div>
                                    )
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default AccountCompanyFleet
