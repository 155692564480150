import React from 'react'
import { useLocation, useHistory } from "react-router-dom"
import { ReactSVG } from 'react-svg'
import './index.scss'
import { FLYMINGO_APP_URL } from "../../constants";

function FlySideBar(props) {
    const location = useLocation()
    const history = useHistory()
    const showDetails = location.pathname.indexOf('/welcome') > -1 || location.pathname.indexOf('/register') > -1
    const isWelcome = location.pathname.indexOf('/welcome') > -1
    const isComplete = location.pathname.indexOf('/complete') > -1
    let clsName = "sidebar"
    if (!showDetails)
        clsName = "sidebar v1"
    if (isWelcome || isComplete)
        clsName = "sidebar v2"

    if (props.isMobile) {
        return (
            <div className={clsName}>
                <ReactSVG src="/images/logo_white.svg" className="logo-img" />
                <img alt='' src="/images/cloud-3.png" className="cloud-img" style={{ left: "1.25rem", bottom: 0}} />
                <img alt='' src="/images/cloud-2.png" className="cloud-img cloud-one" style={{ right: 0, bottom: 0}} />
                <img alt='' src="/images/cloud-1.png" className="cloud-img" style={{ top: 0, left: "32%"}} />
                <img alt='' src="/images/bird.png" className="cloud-img cloud-bird" style={{ right: "20%", bottom: "1rem"}} />
            </div>
        )
    }

    return (
        <div className={clsName}>
            <a href={FLYMINGO_APP_URL} target='_blank' style={{ zIndex: 1000 }}>
                <ReactSVG
                    src="/images/logo_white.svg"
                    className="logo-img"
                />
            </a>
            { showDetails && <>
                <ul>
                    <li className="schedule-flight">
                        <p>Upload your flights and fill those empty seats.</p>
                    </li>
                    <li className="start-req">
                        <p>Receive requests for flights based on your FBO & fleet.</p>
                    </li>
                    <li className="missed-revenue">
                        <p>Receive a percentage of fare when flyers cancel last minute!</p>
                    </li>
                </ul>
            </>}
            {/*<ul>
                <li className="schedule-flight">
                    <p>Upload your scheduled and recurring flights and fill your empty seats</p>
                </li>
                <li className="start-req">
                    <p>Start to receive requests for charter flights based on your FBO & fleet of planes</p>
                </li>
                <li className="missed-revenue">
                    <p>No more missed revenue on missed cancellations - receive a percentage of fare when flyers cancel last minute!</p>
                </li>
            </ul>*/}
        </div>
    )
}

export default FlySideBar
