import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Form, Button, Tabs, Statistic, Table, Space, Select, DatePicker, Tooltip, Spin } from 'antd'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import SetupPayoutMethod from './SetupPayoutMethod'
import RequestPayoutModal from './RequestPayoutModal'
import { firestorage } from "../../../firebaseConfig"
import './index.scss'
import { ApiManager } from "../../../service"
import { FLYMINGO_SERVICES } from "../../../constants";

const { TabPane } = Tabs

function Wallet(props) {
    const [form] = Form.useForm()
    const {history, currentUser, config} = props
    const [activeTabKey, setActiveTabKey] = useState('wallet')
    const [setupPayoutMethod, setSetupPayoutMethod] = useState(false)
    const [payoutList, setPayoutList] = useState([])
    const [tripList, setTripList] = useState([])
    const [totalEarnings, setTotalEarnings] = useState(0)
    const [availableBalance, setAvailableBalance] = useState(0)
    const [loading, setLoading] = useState(false)
    const [filters, setFilters] = useState({})
    const [isRequestPayoutModalVisible, setIsRequestPayoutModalVisible] = useState(false);

    useEffect(() => {
        loadData()
    }, [currentUser])

    const loadData = async () => {
        setLoading(true)
        ApiManager.getPilotWallet({uid: currentUser.uid}).then(async res => {
            const trips = await Promise.all(res.data.body.trips.map(async (trip) => {
                if (trip?.manifest) {
                    const storageRef = await firestorage.ref();
                    const pdfFile = storageRef.child(trip.manifest);
                    const url = await pdfFile.getDownloadURL();
                    return {...trip, manifest: url};
                } else {
                    return trip;
                }
            }));

            setTripList(trips)
            setTotalEarnings(res.data.body.totalEarnings)
            setPayoutList(res.data.body.payouts)
            setAvailableBalance(res.data.body.availableBalance)
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    useEffect(() => {
        if (history.location.state !== undefined)
            setSetupPayoutMethod(history.location.state.setupPayoutMethod)
    }, [history.location])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [setupPayoutMethod])

    const onSubmitHandler = (values) => {
        console.log(values)
        setFilters(values)
    }

    const handleChangeTab = (key) => {
        setActiveTabKey(key)
    }

    const earningColumns = [
        {
            title: 'Flight number',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => <Space>
                <ReactSVG src="/images/fleet.svg" className="label-icon" />
                <p>{text}</p>
            </Space>,
        }, {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text, record) => <Space>
                <ReactSVG src="/images/time.svg" className="label-icon" /><p className="mr-1">{record.departureDate} {record.departureTime}</p>
            </Space>
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => <Space>
                <ReactSVG src="/images/price.svg" className="label-icon" /><p className="mr-1">${text}</p>
            </Space>
        }, {
            title: 'Departure Airport',
            dataIndex: 'departureAirport',
            key: 'departureAirport',
            render: (text, record) => <Space>
                <ReactSVG src="/images/location.svg" className="label-icon" />
                {text
                    ? <p>{text}</p>
                    : (
                        record?.pickupInstruction
                            ? (
                                <p>
                                    {record.pickupInstruction}
                                </p>
                            )
                            : (
                                <div>
                                    <p style={{
                                        fontSize: '12px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '2px'
                                    }}>
                                        <p style={{
                                            opacity: 0.4,
                                            marginRight: '3px',
                                            fontSize: '12px',
                                            whiteSpace: 'nowrap'
                                        }}>Lat:</p>
                                        {record.departureLocation?.latitude}
                                    </p>
                                    <p style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>
                                        <p style={{
                                            opacity: 0.4,
                                            marginRight: '3px',
                                            fontSize: '12px',
                                            whiteSpace: 'nowrap'
                                        }}>Lon:</p>
                                        {record.departureLocation?.longitude}
                                    </p>
                                </div>
                            )
                    )}
            </Space>,
        }, {
            title: 'Arrival Airport',
            dataIndex: 'arrivalAirport',
            key: 'arrivalAirport',
            render: (text, record) => <Space>
                <ReactSVG src="/images/location.svg" className="label-icon"/>
                {text
                    ? <p>{text}</p>
                    : (
                        <div>
                            <p style={{fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px'}}>
                                <p style={{
                                    opacity: 0.4,
                                    marginRight: '3px',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap'
                                }}>Lat:</p>
                                {record.arrivalLocation?.latitude}
                            </p>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                {record.arrivalLocation?.longitude}
                            </p>
                        </div>
                    )
                }
            </Space>,
        }, {
            title: 'Pilot',
            dataIndex: 'pilotName',
            key: 'pilotName',
            render: (text, record) => <Space>
                <ReactSVG src="/images/captain.svg" className="label-icon" />
                <p>{text || <div className="blank-icon"></div>}</p>
            </Space>
        }, {
        //     title: 'Document',
        //     dataIndex: 'document',
        //     key: 'document',
        //     render: (text, record) => <Button className="file-btn">{text}</Button>
        // }, {
            title: 'Manifest',
            dataIndex: 'manifest',
            key: 'manifest',
            render: (text, record) => {
                if (record?.manifest) {
                    return <a href={record.manifest} target='_blank'><Button className="file-btn">manifest.pdf</Button></a>
                } else {
                    return <Button className="file-btn file-btn-disabled">manifest.pdf</Button>
                }
            }
        }
    ]

    const tripsColumns = [
        {
            title: 'Route',
            dataIndex: 'route',
            key: 'route',
            render: (text, record) => <Space>
                {record.departureAirport
                    ? record.departureAirport.split('-')[0]
                    : record?.pickupInstruction
                        ? (
                            <p>
                                {record.pickupInstruction}
                            </p>
                        ) : (
                            (
                                <div>
                                    <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                        {record.departureLocation?.latitude}
                                    </p>
                                    <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                        {record.departureLocation?.longitude}
                                    </p>
                                </div>
                            )
                        )
                }
                -
                {record.arrivalAirport
                    ? record.arrivalAirport.split('-')[0]
                    : (
                        <div>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                {record.arrivalLocation?.latitude}
                            </p>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                {record.arrivalLocation?.longitude}
                            </p>
                        </div>
                    )
                }
            </Space>
        }, {
            title: 'Manifest',
            dataIndex: 'manifest',
            key: 'manifest',
            render: (text, record) => {
                if (record?.manifest) {
                    return <a href={record.manifest} target='_blank'><Button className="file-btn">manifest.pdf</Button></a>
                } else {
                    return <Button className="file-btn file-btn-disabled">manifest.pdf</Button>
                }
            }
        }
    ]

    const payoutColumns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'date',
            render: (text, record) => <Space>
                <ReactSVG src="/images/time.svg" className="label-icon" /><p className="mr-1">{utils.formatDateTime(text)}</p>
            </Space>
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => <Space>
                <ReactSVG src="/images/price.svg" className="label-icon" /><p className="mr-1">${text.toFixed(2)}</p>
            </Space>
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        //     key: 'type',
        //     render: (text, record) => <Space>
        //         Requested
        //     </Space>
        // },
        {
            title: 'Method',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => <Space>
                <ReactSVG src={`/images/credit-card.svg`} className="label-icon" /><p className="mr-1">{utils.getPaymentTypeLabel(text)}</p>
            </Space>
        },
        // {
        //     title: 'Receipt',
        //     dataIndex: 'receipt',
        //     key: 'receipt',
        //     render: (text, record) => <Button className="file-btn">{text}</Button>
        // }
    ]

    const pagination = {
        showSizeChanger: false,
        defaultPageSize: 15,
        showTotal: (total, range) => `Showing ${range[1]-range[0]+1} of ${total}`,
        itemRender: utils.itemRender
    }

    const filterPanel =
        <Form name="earningFilterForm" id="earningFilterForm" form={form} >
            <Row className="mt-2 mr-2">
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item name='date'>
                        <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" className="fly-date-picker" defaultPickerValue={utils.getToday()} />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item name='amount'>
                        <Select
                            placeholder="All"
                            className="fly-select"
                            showSearch={true}
                            options={[]}
                            allowClear
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>

    if (setupPayoutMethod)
        return <SetupPayoutMethod setSetupPayoutMethod={setSetupPayoutMethod}
                currentUser={currentUser} />

    return (
        <div className="content fly-board wallet-board">
            <Spin spinning={loading}>
                <div className="content-header">
                    <h2 className="mb-0">Wallet</h2>
                    { activeTabKey !== 'wallet' && <Button type="primary" className="fly-btn" onClick={() => setSetupPayoutMethod(true)}>SETUP PAYOUT METHOD</Button>}
                </div>
                <RequestPayoutModal isModalVisible={isRequestPayoutModalVisible}
                    setIsModalVisible={setIsRequestPayoutModalVisible}
                    currentUser={currentUser} />
                <div className="content-body">
                    {/* activeTabKey === 'payouts' &&
                        <Form
                            name="form"
                            id="form"
                            layout='vertical'
                            form={form}
                            onFinish={onSubmitHandler}
                            requiredMark={true}
                        >
                            <div className="form-filters">
                                <Row>
                                    <Col md={5} xs={24} className="pr-2">
                                        <Form.Item label="Date" name='date'>
                                            <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" className="fly-date-picker" />
                                        </Form.Item>
                                    </Col>
                                    <Col md={5} xs={24} className="pr-2">
                                        <Form.Item label="Amount" name='amount'>
                                            <Select placeholder="All" className="fly-select" showSearch={true} options={[]} allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col md={5} xs={24} className="pr-2">
                                        <Form.Item label="Type" name='type'>
                                            <Select  placeholder="All" className="fly-select"  showSearch={true} options={[
                                                {label: 'Requested', value: 'requested'},
                                                {label: 'Scheduled', value: 'scheduled'},
                                            ]} allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col md={5} xs={24} className="pr-2">
                                        <Form.Item label="Payout Method" name='payoutMethod'>
                                            <Select placeholder="All" className="fly-select" showSearch={true} options={utils.resources.payoutType} allowClear/>
                                        </Form.Item>
                                    </Col>
                                    <Col md={4} xs={24}>
                                        <Form.Item label="&nbsp;">
                                            <Button type="primary" htmlType="submit" className="fly-btn w-full px-0" style={{ float: 'right'}}>FILTER</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    */}
                    <Tabs defaultActiveKey="1" className="form-tabs" onChange={handleChangeTab}
                        tabBarExtraContent={activeTabKey === 'trips!' && filterPanel}
                        activeKey={activeTabKey}
                    >
                        <TabPane tab="Wallet" key="wallet" >
                            <div className="home-board">
                                <div className="categories">
                                    <div className="category-item">
                                        <div className="d-block">
                                            <Statistic title="" prefix="$" value={totalEarnings} precision={2} />
                                            <p>Total earnings</p>
                                        </div>
                                        <div className="d-block">
                                            <p className="highlight">Platform fee <b>{100 - (100 * (config.FLYMINGO_SERVICES || FLYMINGO_SERVICES))}%</b></p>
                                            <div className="d-flex mt-4">
                                                <Tooltip className="fly-tooltip" title={<p>Your total earnings for all flights accomplished, calculated based on your accomplished flights minus our service fee of {100 - (100 * (config.FLYMINGO_SERVICES || FLYMINGO_SERVICES))}%.</p>} color="#90C4C2" placement="top">
                                                    <Link className="extra-txt m-auto" to="#">Details</Link>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="category-item">
                                        <div className="d-block">
                                            <Statistic title="" prefix="$" value={availableBalance} precision={2} />
                                            <p>Available balance</p>
                                            <p className="description mt-2">Available balance you can currently withdraw to your account</p>
                                        </div>
                                        <div className="d-block">
                                            <p className="highlight mt-2">Next scheduled payout : <b>{utils.getScheduledDate()}</b></p>
                                            {/* currentUser.profile.requestPayout?
                                                <a className="extra-txt mt-4" style={{color: '#909090'}} to="#">Payout Requested</a>
                                                : <a className="extra-txt mt-4" to="#" onClick={() => setIsRequestPayoutModalVisible(true)}>Request A Payout</a>
                                            */}
                                        </div>
                                    </div>
                                    <div className="category-item">
                                        <div className="d-block">
                                            <Statistic title="" prefix="" value={tripList.length} />
                                            <p>Total trips</p>
                                            <p className="description mt-2">Represents the total number of paid reservations you have completed</p>
                                        </div>
                                        <Link className="extra-txt mt-4" to="/dashboard/flights">Manage</Link>
                                    </div>
                                </div>
                                <Row className="trips-payouts">
                                    <Col span={8} className="p-0" style={{ borderRight: "1px solid #E8E8E8"}}>
                                        <div className="tp-header">
                                            <div className="d-flex">
                                                <ReactSVG src="/images/flights.svg" className="category-icon" />
                                                <h3>Most recent trips</h3>
                                            </div>
                                        </div>
                                        <Table columns={tripsColumns} dataSource={tripList.slice(0, 5)}
                                            pagination={false} />
                                    </Col>
                                    <Col span={16} className="p-0">
                                        <div className="tp-header">
                                            <div className="d-flex">
                                                <ReactSVG src="/images/payouts.svg" className="category-icon" />
                                                <h3>Payouts</h3>
                                            </div>
                                            <a className="extra-txt" onClick={() => setSetupPayoutMethod(true)}>Setup Payout Method</a>
                                        </div>
                                        <Table columns={payoutColumns} dataSource={payoutList.slice(0, 5)}
                                            pagination={false} />
                                    </Col>
                                </Row>
                            </div>
                        </TabPane>
                        <TabPane tab="Trips" key="trips">
                            <Table columns={earningColumns} dataSource={tripList}
                                pagination={pagination} />
                        </TabPane>
                        <TabPane tab="Payouts" key="payouts">
                            <Table columns={payoutColumns} dataSource={payoutList}
                                pagination={pagination} />
                        </TabPane>
                    </Tabs>
                </div>
            </Spin>
        </div>
    )
}

export default Wallet
