import React, { useState } from 'react'
import { Form, Input, Button, Row, Col } from 'antd'
import { Link } from "react-router-dom"
import { InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../../utils'
import { ApiManager } from "../../../../service"

function Step1(props) {
    const [form] = Form.useForm()
    const {newUser, setNewUser, history, setStep} = props
    const [loading, setLoading] = useState(false)

    const onSubmitHandler = async (values) => {
        setLoading(true)
        try {
            ApiManager.sendEmail({type: 'registrationRequest', uid: values.email})
            setNewUser(values)
            setStep('SentEmail')
        }
        catch (error) {
            utils.openNotification(error.message, false)
        }
        setLoading(false)
    }

    return (
        <div className="client-body height-100-percent">
            <Form
                className=""
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={newUser}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                >
                    <Input type="email" className="fly-input" placeholder="Email" suffix={
                        <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                    }/>
                </Form.Item>
                <Form.Item shouldUpdate className="mt-5 t-right">
                    {() => (
                        <Button type="primary" htmlType="submit" className="fly-btn w-full"
                            loading={loading}
                            disabled={
                                form.getFieldsError().filter(({ errors }) => errors.length)
                                  .length > 0 || !form.isFieldsTouched(true)
                              }
                        >Continue</Button>
                    )}
                </Form.Item>
            </Form>
            <Row>
                <Col span={24}>
                    <p className="t-center mb-3">By creating an account, you agree to our <br />
                        <Link to="#">Terms of Use</Link> and <Link to="#">Privacy Policy</Link>
                    </p>
                    <p className="t-center">Already have an account? <Link to="/client/auth/login">Sign In!</Link></p>
                </Col>
            </Row>
        </div>
    )
}

export default Step1
