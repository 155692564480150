import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router-dom"
import { Progress } from 'antd'
import { ReactSVG } from 'react-svg'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import SentEmail from './SentEmail'
import VerifiedEmail from './VerifiedEmail'
import SetPassword from './SetPassword'
import { firestore } from '../../../../firebaseConfig'


function Register(props) {
    const {history, currentUser, newUser, setNewUser} = props
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(true)

    const params = {
        history, currentUser,
        newUser, setNewUser,
        setStep
    }

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        let params = new URLSearchParams(history.location.search)
        let userId = params.get('uid')
        let requestId = params.get('rid')
        let email = ''
        let firstname = ''
        let lastname = ''
        let approximateWeight = ''
        if (userId && requestId) {
            const userSnapshot = await firestore.collection('users').doc(userId).get()
            if (userSnapshot.exists) {
                let uData = userSnapshot.data()
                email = uData.email
                firstname = uData.firstname
                lastname = uData.lastname
                approximateWeight = uData.weight
            }
            setStep('VerifiedEmail')
            setNewUser({...newUser, email, firstname, lastname, approximateWeight, userId, requestId})
        }
        setLoading(false);
    }

    const prevStepHandler = () => {
        if (step > 1)
            setStep(step-1)
        if (step === 'SentEmail')
            setStep(1)
        if (step === 'VerifiedEmail')
            setStep(1)
        if (step === 'SetPassword')
            setStep(2)
        if (step === 1)
            history.push('/client/boarding')
    }

    let renderPage = ''

    if (currentUser && step === 1) {
        if (!currentUser?.profile?.isClientCompleteRegistration) {
            return setStep(3);
        } else {
            return <Redirect to="/client/departures" />
        }
    }

    switch (step) {
        case 1:
            renderPage = <Step1 {...params} />
            break
        case 2:
            renderPage = <Step2 {...params} />
            break
        case 3:
            renderPage = <Step3 {...params} />
            break
        case 'SentEmail':
            renderPage = <SentEmail {...params} />
            break
        case 'VerifiedEmail':
            renderPage = <VerifiedEmail {...params} />
            break
        case 'SetPassword':
            renderPage = <SetPassword {...params} />
            break
        default:
            renderPage = <Step1 {...params} />
    }

    return (
        <div className="content auth-page auth-page-mobile">
            {!loading && (
                <>
                    <div className="client-header b-gray">
                        {typeof(step) == 'number' && <div className="header-item">
                            {(step === 2 || step === 3)
                                ? <div style={{ width: '40px' }}/>
                                : <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>}
                            <p>Creating Account</p>
                            <div className="step-marker">
                                <Progress
                                    type="circle"
                                    trailColor="#ffffff1a"
                                    strokeColor="#33CDCD"
                                    percent={step/3*100} width={36}
                                    format={percent => `${step}/3`}
                                />
                            </div>
                        </div>}
                    </div>
                    { renderPage }
                </>
            )}
        </div>
    )
}

export default Register
