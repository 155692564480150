import React from 'react'
import { Checkbox, Tooltip } from 'antd'
import './index.scss'

function FlyCheckbox(props) {
    const {
        label,
        value,
        showIcon,
        tipText,
    } = props

    return (
        <Checkbox value={value} className="fly-checkbox">{label}
            {showIcon && <Tooltip title={tipText} color="#124698">
                <div className="i-mark">i</div>
            </Tooltip>}
        </Checkbox>
    )
}

export default FlyCheckbox
