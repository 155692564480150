import React from 'react'
import { Row, Col, Form, Button } from 'antd'
import { ReactSVG } from 'react-svg'
import './index.scss'

function Request(props) {
    const [form] = Form.useForm()
    const {history, step, setStep, flight, setFlight} = props

    const onSubmitHandler = (values) => {
        history.push('/client/flight-requests?ft=requested')
    }

    const cancelFlightHandler = () => {
        setFlight({})
        setStep(1)
    }

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <div className="form-content">
                    <div className="form-body">
                        <Row className="d-flex">
                            <Col span={24} className="t-center">
                                <ReactSVG src="/images/done.svg" />
                                <h3 className="mt-3">Your Request <br />was successfully Sent</h3>
                                <p className="fly-notes mt-2 t-center">You will be notified via email each time a pilot has bid on your requested flight.</p>
                            </Col>
                        </Row>
                        {/*<Row className="row-wrapper mt-5">
                            <Col span={20}>
                                <p className="fly-notes">Recommended</p>
                                <p>Turn on Notifications</p>
                            </Col>
                            <Col span={4} className="t-right d-flex">
                                <Form.Item label="" name='notification' valuePropName="checked" className="m-auto">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>*/}
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                Go to My Flights
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default Request
