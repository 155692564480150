import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Collapse, Space, Table, Spin } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { firestorage } from "../../../firebaseConfig"
import Categories from './Categories'
import './index.scss'
import { ReactSVG } from 'react-svg'
import { ApiManager } from "../../../service"

const { Panel } = Collapse

function Home(props) {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const {history, currentUser} = props
    const [uploadedFiles, setUploadedFiles] = useState({files: [], uploading: false})
    const [tripList, setTripList] = useState([])
    const [payoutList, setPayoutList] = useState([])
    const [earningList, setEarningList] = useState([])
    const [totalEarnings, setTotalEarnings] = useState([])

    useEffect(() => {
        loadData()
    }, [currentUser])


    const loadData = async () => {
        setLoading(true)
        if (currentUser) {
            setUploadedFiles({
                files: (!currentUser.profile.businessLicense || typeof (currentUser.profile.businessLicense) === 'string')
                    ? []
                    : currentUser.profile.businessLicense,
                uploading: false
            })
        }
        ApiManager.getPilotOverview({uid: currentUser.uid}).then(async res => {
            const trips = await Promise.all(res.data.body.trips.map(async (trip) => {
                if (trip?.manifest) {
                    const storageRef = await firestorage.ref();
                    const pdfFile = storageRef.child(trip.manifest);
                    const url = await pdfFile.getDownloadURL();
                    return {...trip, manifest: url};
                } else {
                    return trip;
                }
            }));

            setTripList(trips)
            setTotalEarnings(res.data.body.totalEarnings)
            setPayoutList(res.data.body.payouts)
            currentUser.profile.requests = res.data.body.requests || []
            currentUser.profile.upcomingFlights = res.data.body.upFlights || []
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    const onSubmitHandler = (values) => {
        console.log(values)
    }

    const setSetupPayoutMethod = () => {
        history.push({
          pathname: '/dashboard/wallet',
          state: {
            setupPayoutMethod: true,
          },
        })
    }

    const resendEmailVerification = () => {
        ApiManager.verifyEmailRequest({user: {
            email: currentUser.email,
            firstname: currentUser.profile?.firstname
        }}).then(() => {
            utils.openNotification("The email verification request has been sent successfully!", true)
        })
    }

    const tripsColumns = [
        {
            title: 'Route',
            dataIndex: 'route',
            key: 'route',
            render: (text, record) => <Space>
                {record.departureAirport
                    ? record.departureAirport.split('-')[0]
                    : record?.pickupInstruction
                        ? (
                            <p>
                                {record.pickupInstruction}
                            </p>
                        ) : (
                            (
                                <div>
                                    <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                        {record.departureLocation?.latitude}
                                    </p>
                                    <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                        {record.departureLocation?.longitude}
                                    </p>
                                </div>
                            )
                        )
                }
                -
                {record.arrivalAirport
                    ? record.arrivalAirport.split('-')[0]
                    : (
                        <div>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lat:</p>
                                {record.arrivalLocation?.latitude}
                            </p>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                {record.arrivalLocation?.longitude}
                            </p>
                        </div>
                    )
                }
            </Space>
        }, {
            title: 'Manifest',
            dataIndex: 'manifest',
            key: 'manifest',
            render: (text, record) => {
                if (record?.manifest) {
                    return <a href={record.manifest} target='_blank'><Button className="file-btn">manifest.pdf</Button></a>
                } else {
                    return <Button className="file-btn file-btn-disabled">manifest.pdf</Button>
                }
            }
        }
    ]

    const payoutColumns = [
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'date',
            render: (text, record) => <Space>
                <ReactSVG src="/images/time.svg" className="label-icon" /><p className="mr-1">{utils.formatDateTime(text)}</p>
            </Space>
        }, {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (text, record) => <Space>
                <ReactSVG src="/images/price.svg" className="label-icon" /><p className="mr-1">${text.toFixed(2)}</p>
            </Space>
        },
        // {
        //     title: 'Type',
        //     dataIndex: 'type',
        //     key: 'type',
        //     render: (text, record) => <Space>
        //         Requested
        //     </Space>
        // },
        {
            title: 'Method',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => <Space>
                <ReactSVG src={`/images/${text}.svg`} className="label-icon" /><p className="mr-1">{utils.getPaymentTypeLabel(text)}</p>
            </Space>
        },
        // {
        //     title: 'Receipt',
        //     dataIndex: 'receipt',
        //     key: 'receipt',
        //     render: (text, record) => <Button className="file-btn">{text}</Button>
        // }
    ]

    const pagination = {
        showSizeChanger: false,
        defaultPageSize: 8,
        showTotal: (total, range) => `Showing ${range[1]-range[0]+1} of ${total}`,
        itemRender: utils.itemRender
    }

    const isAccountActive = currentUser && currentUser.emailVerified
        && uploadedFiles.files.length !== 0 && currentUser.profile.fleets.length > 0
        && currentUser.profile.flights.length > 0

    return (
        <div className="content home-container">
            <Spin spinning={loading}>
                <div className="fly-form">
                    <Form
                        className=""
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={true}
                    >
                        {isAccountActive?
                            <div className="form-content">
                                <div className="form-header">
                                    <h2>Hello {currentUser.profile && currentUser.profile.companyName}</h2>
                                    {currentUser.profile.verified === "done" && <div className="d-flex">
                                        <p className="fly-info mr-1">Your account is now active</p>
                                        <ReactSVG src="/images/check.svg" className="account-active-icon active" />
                                    </div>}
                                    {currentUser.profile.verified === "failed" && <div className="d-flex">
                                        <p className="fly-info mr-1">Your account is suspended, please contact <a href="mailto:support@flymingo.io" className="extra-txt">support@flymingo.io</a></p>
                                        <ReactSVG src="/images/check.svg" className="account-active-icon" />
                                    </div>}
                                    {(!currentUser.profile.verified || currentUser.profile.verified === "pending") && <div className="d-flex">
                                        <p className="fly-info mr-1">Your account is pending verification.</p>
                                        <ReactSVG src="/images/check.svg" className="account-active-icon inactive" />
                                    </div>}
                                </div>
                            </div>
                            :
                            <div className="form-content">
                                <div className="form-header">
                                    <h2>You’re almost there!</h2>
                                    <p className="fly-info">You have some few outstanding items before you can start to use <b>Flymingo.</b></p>
                                </div>
                                <div className="form-body">
                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={[]}
                                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : -90} />}
                                        className="complete-collapse"
                                    >
                                        <Panel header={<p>Verify your account by confirming your email address (check your junkmail).</p>} key="1" className={currentUser && currentUser.emailVerified? "completed": ''}>
                                            <p className={currentUser && currentUser.emailVerified ? "extra-txt verification-disabled" : "extra-txt"} style={{cursor: "pointer"}} onClick={() => resendEmailVerification()}>Resend verification email</p>
                                        </Panel>
                                        <Panel header={<p>Upload documents for verification.</p>} key="2" className={uploadedFiles.files.length !== 0 ? 'completed' : ''}>
                                            <a className="extra-txt" onClick={() => history.push({
                                                pathname: '/dashboard/settings', state: { isCompanyInformationOpen: true }
                                            })}>Add Company Business License</a>
                                        </Panel>
                                        <Panel header={<p>Add your fleet to receive and bid on requests from flyers.</p>} key="3" className={currentUser && currentUser.profile.fleets.length > 0? "completed": ''}>
                                            <a className="extra-txt" onClick={() => history.push({
                                                pathname: '/dashboard/fleets', state: { addFleetVisible: true }
                                            })}>Add Plane</a>
                                        </Panel>
                                        <Panel header={<p>Upload your first flight.</p>} key="4" className={currentUser && currentUser.profile.flights.length > 0? "completed": ''}>
                                            <a className="extra-txt" onClick={() => history.push({
                                                pathname: '/dashboard/flights', state: { addFlightVisible: true }
                                            })}>Add Flight</a>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        }
                    </Form>
                </div>
                <div className="home-board">
                    <Categories currentUser={currentUser} totalEarnings={totalEarnings} />
                    <Row className="trips-payouts">
                        <Col span={8} className="p-0" style={{ borderRight: "1px solid #E8E8E8"}}>
                            <div className="tp-header">
                                <div className="d-flex">
                                    <ReactSVG src="/images/flights.svg" className="category-icon" />
                                    <h3>Most recent trips</h3>
                                </div>
                            </div>
                            <Table columns={tripsColumns} dataSource={tripList}
                                pagination={false} />
                        </Col>
                        <Col span={16} className="p-0">
                            <div className="tp-header">
                                <div className="d-flex">
                                    <ReactSVG src="/images/payouts.svg" className="category-icon" />
                                    <h3>Payouts</h3>
                                </div>
                                <a className="extra-txt" onClick={() => setSetupPayoutMethod()}>Setup Payout Method</a>
                            </div>
                            <Table columns={payoutColumns} dataSource={payoutList}
                                pagination={false} />
                        </Col>
                    </Row>
                </div>
            </Spin>
        </div>
    )
}

export default Home
