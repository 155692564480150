import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import './index.scss'
import * as utils from '../../../utils'
import { firestore } from '../../../firebaseConfig'
import AddPassenger from '../RequestFlight/AddPassenger'
import Flight from '../RequestFlight/Flight'
import { ApiManager } from '../../../service'

function FlightDetails(props) {
    const {history, currentUser, setActiveRecord, loadDeparturesData, config} = props
    const [loading, setLoading] = useState()
    const [step, setStep] = useState('Details')
    const [flight, setFlight] = useState()
    const [activePassenger, setActivePassenger] = useState()
    const [companies, setCompanies] = useState([])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        setFlight(props.flight)
        const uSnapshot = await firestore.collection('users').get()
        if (!uSnapshot.empty)
            uSnapshot.forEach(udoc => {
                companies.push(udoc.id)
            })
        setCompanies(companies)
    }

    const postRequest = async (values) => {
        setLoading(true);

        if (flight.passengers) {
            const res = await ApiManager.sendRequestToBook({
                uid: currentUser.uid,
                id: flight.id,
                departureAt: flight.departureAt,
                passengers: flight.passengers,
                hasPet: values.hasPet || '',
                numberOfPets: values.numberOfPets || '',
                weightPerPet: values.weightPerPet || '',
            });

            if (res?.data?.message) {
                return utils.openNotification(res.data.message, false);
            } else {
                const requestId = res.data.body.requestId;

                ApiManager.sendEmail({type: "requestToBookScheduledFlight", id: requestId, uid: flight.uid,
                    collection: "flights", verified: "pending", reason: "", flightRequest: {
                        requestId,
                        flightId: flight.id,
                        departureTime: flight.departureTime,
                        departureAirport: flight.departureAirport,
                        departureLocation: flight.departureLocation || '',
                        pickupInstruction: flight?.pickupInstruction || '',
                        arrivalAirport: flight.arrivalAirport,
                        arrivalLocation: flight.arrivalLocation || '',
                        passengers: flight.passengers,
                        number: flight.number,
                        hasPet: values.hasPet || '',
                        numberOfPets: values.numberOfPets || '',
                        weightPerPet: values.weightPerPet || '',
                    }})

                utils.openNotification('Posted successfully!', true);
            }

            setActiveRecord();
            history.push('/client/flight-requests?ft=requested');
        } else {
            utils.openNotification('Add one passenger at least!', false);
        }

        await loadDeparturesData();
        setLoading(false);
    }

    const prevStepHandler = () => {
        if (step === "Details")
            setActiveRecord()
        if (step === "AddPassenger")
            setStep('Details')
    }

    const setFlightValues = (values) => {
        let temp = flight;
        Object.keys(values).forEach(key => {temp[key] = values[key]})
        setFlight(temp)
    }

    const params = {
        history, step, setStep,
        flight, setFlight, setFlightValues,
        activePassenger, setActivePassenger,
        postRequest, loading, currentUser,
        config
    }

    let renderPage = ''
    let pageTitle = 'Flight Details'

    switch (step) {
        case 'AddPassenger':
            renderPage = <AddPassenger {...params} external={true} />
            pageTitle = 'Passenger'
            break
        default:
            renderPage = <Flight {...params} />
            break
    }

    if (!flight)
        return ''

    return (
        <div className="content flight-details flight-details-mobile">
            <div className="client-header">
                <div className="header-item">
                    {pageTitle && <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>}
                    <p>{pageTitle}</p>
                    <div className="step-marker">
                    </div>
                </div>
                <div className="header-item mt-3">
                    <div className="t-left">
                        {flight?.departureAirport
                            ? (
                                <>
                                    <p className="gray">{utils.resources.airports.index[flight?.departureAirport]?.place || ''}</p>
                                    <h1 className="mb-0">{flight?.departureAirport ? flight.departureAirport.split('-')[0] : ''}</h1>
                                    <p className="gray mb-3">{utils.resources.airports.index[flight?.departureAirport]?.title || ''}</p>
                                </>
                            )
                            : (
                                flight?.pickupInstruction
                                    ? (
                                        <p>
                                            {flight.pickupInstruction}
                                        </p>
                                    )
                                    : (
                                        <div className="mb-3">
                                            <p style={{fontSize: '12px'}}>
                                                {flight?.departureLocation?.latitude}
                                                <p style={{
                                                    opacity: 0.4,
                                                    marginRight: '3px',
                                                    fontSize: '12px',
                                                    whiteSpace: 'nowrap'
                                                }}>Latitude</p>
                                            </p>
                                            <p style={{fontSize: '12px'}}>
                                                {flight?.departureLocation?.longitude}
                                                <p style={{
                                                    opacity: 0.4,
                                                    marginRight: '3px',
                                                    fontSize: '12px',
                                                    whiteSpace: 'nowrap'
                                                }}>Longitude</p>
                                            </p>
                                        </div>
                                    )
                            )
                        }
                    </div>
                    <div className="t-right">
                        {flight?.arrivalAirport
                            ? (
                                <>
                                    <p className="gray">{utils.resources.airports.index[flight?.arrivalAirport]?.place || ''}</p>
                                    <h1 className="mb-0">{flight?.arrivalAirport ? flight.arrivalAirport.split('-')[0] : ''}</h1>
                                    <p className="gray mb-3">{utils.resources.airports.index[flight?.arrivalAirport]?.title || ''}</p>
                                </>
                            )
                            : (
                                <div className="mb-3">
                                    <p style={{ fontSize: '12px' }}>
                                        {flight?.arrivalLocation?.latitude}
                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Latitude</p>
                                    </p>
                                    <p style={{ fontSize: '12px' }}>
                                        {flight?.arrivalLocation?.longitude}
                                        <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Longitude</p>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="header-item">
                    <ReactSVG src="/images/arrow-circle.svg" className="b-icon" />
                    <div className="dashed"></div>
                    <ReactSVG src="/images/bird1.svg" className="b-icon" />
                    <div className="dashed"></div>
                    <ReactSVG src="/images/dot-circle.svg" className="b-icon" />
                </div>
                <div className="header-item mt-3">
                    <div className="d-flex">
                        <ReactSVG src="/images/flights1.svg" />
                        <p className="ml-1">{flight.departureTime} • {flight.departureDate}</p>
                    </div>
                    <p className="gray">{flight.number}</p>
                </div>
                <div className="header-item mt-3 mb-2">
                    <p className="price">${(+flight.pricePerPerson).toFixed(2)}/person</p>
                    <p className="time-left">{flight.leftTime} left to book</p>
                </div>
            </div>
            <div className="client-body">
                { renderPage }
            </div>
        </div>
    )
}

export default FlightDetails
