import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Button, Drawer, Form, Space} from 'antd'
import { ReactSVG } from 'react-svg'
import { firestore } from '../../firebaseConfig'
import * as utils from '../../utils'

function NotificationDrawer(props) {
    const [form] = Form.useForm()
    const [todayNotis, setTodayNotis] = useState([])
    const [yesterdayNotis, setYesterdayNotis] = useState([])
    const [otherNotis, setOtherNotis] = useState([])
    const [unreadNotis, setUnreadNotis] = useState([])
    const [loading, setLoading] = useState()

    const {notificationVisible, openNotificationHandler, closeNotificationHandler,
        currentUser, history} = props

    const onSubmitHandler = (values) => {
    }

    const markAllAsReadHandler = async () => {
        if (unreadNotis.length > 0) {
            let batch = firestore.batch();
            unreadNotis.map(noti => {
                batch.update(firestore.collection('notifications').doc(noti.id), {
                    read: true
                });
            })
            await batch.commit();
            loadData()
        }
    }

    const onClickHandler = async (noti) => {
        await firestore.collection('notifications').doc(noti.id).update({
            read: true
        });

        if (noti.type === 'flightReviewPilot') {
            window.open(noti.link, '_blank');
        } else {
            closeNotificationHandler()
            history.push(noti.link);
            loadData()
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    const loadData = async () => {
        setLoading(true)
        var date = new Date();
        var last = new Date(date.getTime() - (21 * 24 * 60 * 60 * 1000));
        const nSnapshot = await firestore.collection('notifications')
            .where('uid', '==', currentUser.uid)
            .where('createdAt', '>', last)
            .orderBy("createdAt", "desc")
            .get();
        let today = utils.getToday().format("MM/DD/YYYY")
        let yesterday = utils.getYesterday().format("MM/DD/YYYY")
        let tList = []
        let yList = []
        let oList = []
        let uList = []
        if (!nSnapshot.empty) {
            nSnapshot.forEach(doc => {
                let nItem = doc.data()
                nItem.id = doc.id
                let date = utils.formatDate2(nItem.createdAt)
                if (date === today)
                    tList.push(nItem)
                else if (date === yesterday)
                    yList.push(nItem)
                else {
                    let diff = Math.abs(utils.getDaysDiff(nItem.createdAt.toDate()))
                    if (diff < 7)
                        nItem.diff = `${diff} day${diff>1? 's': ''} ago`
                    else {
                        diff = Math.floor(Math.abs(utils.getDaysDiff(nItem.createdAt.toDate())) / 7)
                        nItem.diff = `${diff} week${diff>1? 's': ''} ago`
                    }
                    oList.push(nItem)
                }
                if (!nItem.read)
                    uList.push(nItem)
            })
        }
        setTodayNotis(tList)
        setYesterdayNotis(yList)
        setOtherNotis(oList)
        setUnreadNotis(uList)
        setLoading(false)
    }

    return (<>
        <ReactSVG src="/images/notification.svg" className={`notification-icon mr-4 ${unreadNotis.length > 0 && 'unread'}`} onClick={openNotificationHandler} />
        <Drawer
            title='Notifications'
            placement="right"
            onClose={closeNotificationHandler}
            visible={notificationVisible}
            width='32.31rem'
            extra={
              <Space>
                <Button onClick={closeNotificationHandler}>Cancel</Button>
                <Button type="primary" onClick={closeNotificationHandler}>
                  OK
                </Button>
              </Space>
            }
            destroyOnClose={true}
        >
            <Form
                className="content notification-drawer"
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                    preserve={false}
                >
                <div className="form-content">
                    <Row className="noti-header d-flex d-space">
                       <Col><p>Today</p></Col>
                       <Col><Link to="#" onClick={markAllAsReadHandler}>Mark all as read</Link></Col>
                    </Row>
                    {todayNotis.map(noti =>
                        <Row className="noti-item" key={noti.id} onClick={() => onClickHandler(noti)}>
                            <Col span={3}><ReactSVG src={`/images/${noti.icon}.svg`} className={`noti-icon ${!noti.read && 'unread'}`} /></Col>
                            <Col span={21}><p>{noti.content}</p></Col>
                        </Row>
                    )}
                    <Row className="noti-header d-flex d-space">
                        <Col><p>Yesterday</p></Col>
                    </Row>
                    {yesterdayNotis.map(noti =>
                        <Row className="noti-item" key={noti.id} onClick={() => onClickHandler(noti)}>
                            <Col span={3}><ReactSVG src='/images/plane.svg' className={`noti-icon ${!noti.read && 'unread'}`} /></Col>
                            <Col span={21}><p>{noti.content}</p></Col>
                        </Row>
                    )}
                    <Row className="noti-header d-flex d-space">
                        <Col><p>Earlier</p></Col>
                    </Row>
                    {otherNotis.map(noti =>
                        <Row className="noti-item" key={noti.id} onClick={() => onClickHandler(noti)}>
                            <Col span={3}><ReactSVG src='/images/plane.svg' className={`noti-icon ${!noti.read && 'unread'}`} /></Col>
                            <Col span={21}>
                                <p>{noti.content}</p>
                                <p className="t-diff">{noti.diff}</p>
                            </Col>
                        </Row>
                    )}
                </div>
            </Form>
        </Drawer>
    </>)
}

export default NotificationDrawer
