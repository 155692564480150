import React from 'react'
import { useHistory } from "react-router-dom"
import { Row, Col} from 'antd'
import { ReactSVG } from 'react-svg'
import './index.scss'

function FlyFooter(props) {
    const {active} = props
    const history = useHistory()

    return (
        <div className="client-footer">
            <Row>
                <Col span={8} className={`client-footer-item ${active === 'departures'? 'active': ''}`}
                    onClick={() => history.push('/client/departures')}
                >
                    <ReactSVG src="/images/departure.svg" className="planetype-icon" />
                    <p>Departures</p>
                </Col>
                <Col span={8} className={`client-footer-item ${active === 'flights'? 'active': ''}`}
                    onClick={() => history.push('/client/flight-requests')}
                >
                    <ReactSVG src="/images/my-flights.svg" className="planetype-icon" />
                    <p>My Flights</p>
                </Col>
                <Col span={8} className={`client-footer-item ${active === 'profile'? 'active': ''}`}
                    onClick={() => history.push('/client/profile')}
                >
                    <ReactSVG src="/images/user.svg" className="planetype-icon" />
                    <p>Profile</p>
                </Col>
            </Row>
        </div>
    )

}

export default FlyFooter
