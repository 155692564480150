import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './index.scss';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';
import NotFound from '../404';
import ApiManager from '../../service';
import { CLIENT_DOMAIN , PILOT_DOMAIN } from '../../constants';

const domain = window.location.host;

// const stripePromise = loadStripe("pk_test_51NXhvZDLHniyJMMzdvc1JbdZOaXfbbWJjof6VbtPKzkT6WdhDkLDkv0watUdiaSpSc3p6JWgv2Eobhf6AvVv381r003ldy2mTw");
const stripePromise = loadStripe(
  (domain === CLIENT_DOMAIN || domain === PILOT_DOMAIN)
    ? "pk_live_51HCQNLA64qd1D4Izhr9FX3Rluqx7DbOQos3Vd2TOKYtOva5TiW8ZrOFeDFSrZemQPYCXL1p7OjrScqxQciCXdRVL00IwSA9wAj"
    : "pk_test_51HCQNLA64qd1D4Iz0gFvCoUa1IoUJq9jvgcHpsa8qTRYejUQYHE2G995ishEsGwxBNSrQtG8BiKdZOdgqbexVm3V00ik2HSJvo"
);

const StripePayment = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);
  const [clientSecret, setClientSecret] = useState("");
  const [price, setPrice] = useState("");

  const uid = urlParams.get('uid');
  const fid = urlParams.get('fid');
  const rid = urlParams.get('rid');
  const pid = urlParams.get('pid');
  const coupon = urlParams.get('coupon');
  const redirect = urlParams.get('redirect');
  const paymentIntent = urlParams.get('payment_intent');
  const paymentIntentClientSecret = urlParams.get('payment_intent_client_secret');
  const redirectStatus = urlParams.get('redirect_status');

  useEffect(() => {
    if (paymentIntentClientSecret) {
      setClientSecret(paymentIntentClientSecret);
    }

    if (uid && fid && rid && (!paymentIntent || !paymentIntentClientSecret || !redirectStatus)) {
      ApiManager.createPaymentIntent({ uid, fid, rid, pid, coupon })
        .then((res) => {
          setClientSecret(res.data.clientSecret);
          setPrice(res.data.price);
        });
    }
  }, []);

  if (!uid || !fid || !rid) {
    if (!(paymentIntent && paymentIntentClientSecret && redirectStatus)) {
      return <NotFound/>
    }
  }

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className='payment-wrapper'>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm price={price} redirect={redirect} fid={fid} rid={rid}/>
        </Elements>
      )}
    </div>
  )
}

export default StripePayment;
