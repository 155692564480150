import React, { useState } from 'react'
import {  Form, Button, Radio } from 'antd'
import { FlySideBar } from '../../components/'
import { ArrowRightOutlined } from '@ant-design/icons'

function Login(props) {
    const [form] = Form.useForm()
    const {user, setUser, history} = props
    const [acctType, setAcctType] = useState()

    const onSubmitHandler = (values) => {
        setUser(values)
        console.log(values)
        history.push('/auth/account-company')
    }

    const onChange = (e) => {
        setAcctType(e.target.value)
    }

    return (
        <div className="auth-page">
            <FlySideBar />
            <div className="content">
                <div className="fly-form auth-form v1-form w-expand">
                    <h2>What best describes your business model?</h2>
                    <p className="fly-info mb-4">In order to receive tailored booking requests, we need to understand <br />how you work.</p>
                    <Form
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        initialValues={user}
                        style={{ width: '65%'}}
                    >
                        <Form.Item name="accountType" rules={[{ required: true }]}>
                            <Radio.Group size="large" onChange={onChange}>
                                <Radio.Button value="company" className="account-company">
                                    <div className="d-flex">
                                        <div className="d-block">
                                            <h3>Company</h3>
                                            <p className="fly-info">I am a registered business having or managing multiple pilots and planes.</p>
                                        </div>
                                    </div>
                                </Radio.Button>
                                <Radio.Button value="pilot" className="pilot-company mt-4">
                                    <div className="d-flex">
                                        <div className="d-block">
                                            <h3>Pilot</h3>
                                            <p className="fly-info">I am a licensed pilot having a plane(s) that I own or use.</p>
                                        </div>
                                    </div>
                                </Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item shouldUpdate className="mt-3 t-right">
                            {() => {
                                let invalid = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched(true) || acctType === 'pilot'
                                return (
                                    <Button type="primary" htmlType="submit" className="fly-btn"
                                        disabled={invalid}>NEXT <ArrowRightOutlined />
                                    </Button>
                                )
                            }}
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default Login
