const getMarkerRotation = (point1, point2) => {
  const { latitude: lat1, longitude: lon1 } = point1;
  const { latitude: lat2, longitude: lon2 } = point2;

  const dLon = lon2 - lon1;
  const dLat = lat2 - lat1;

  const angleRadians = Math.atan2(dLon, dLat);

  const angleDegrees = (angleRadians * 180) / Math.PI;

  const iconDefaultRotation = angleDegrees + 90;

  return Math.floor(iconDefaultRotation / 15) * 15;
};

export default getMarkerRotation;
