import React, { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import './index.scss';
import { useHistory } from 'react-router-dom';
import ApiManager from '../../service';
import { Spin } from 'antd';
import NotFound from '../404';

const Receipt = () => {
  const history = useHistory();
  const [isError, setError] = useState(false);

  useEffect(() => {
    setError(false);
    if (history.location.pathname === '/receipt') {
      const urlParams = new URLSearchParams(history.location.search)
      const uid = urlParams.get('uid');
      const tid = urlParams.get('tid');

      if (uid && tid) {
        ApiManager.getReceipt({ uid, tid })
          .then(res => {
            const url = res?.data?.body?.url;
            if (url) {
              window.location.href = url;
            }
          })
          .catch(() => {
            setError(true);
          })
      }
    }
  }, []);

  if (isError) return <NotFound/>;

  return (
    <div className='receipt'>
      <div className='cloud'><ReactSVG src={'/images/cloud.svg'} /></div>
      <div className='container'>
        <Spin/>
        <div className='flamingo'><ReactSVG src={'/images/flamingo.svg'} /></div>
      </div>
    </div>
  )
}

export default Receipt;
