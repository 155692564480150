import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Select, DatePicker, Input } from 'antd'
import Upload from 'rc-upload'
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { firestorage } from "../../../firebaseConfig"
import Loader from "react-loader-spinner"

function CompanyInformationPanel (props) {
    const {currentUser} = props
    const {uploadedFiles, setUploadedFiles, form} = props
    const [fileToUpload, setFileToUpload] = useState({})

    useEffect(() => {
        if (currentUser.profile)
            setUploadedFiles({
                files: (!currentUser.profile.businessLicense || typeof (currentUser.profile.businessLicense) === 'string'
                    ? []
                    : currentUser.profile.businessLicense),
                uploading: false
            })
    }, [currentUser])

    useEffect(() => {
        if (Object.keys(fileToUpload).length !== 0) {
            const newData = {
                ...uploadedFiles,
                files: [...uploadedFiles.files, fileToUpload],
                uploading: false
            };
            setUploadedFiles(newData)
        }
    }, [fileToUpload]);

    const customUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFiles({...uploadedFiles, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`companies/${currentUser.uid}/business-license-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUpload({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFiles({...uploadedFiles, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }

    return (
        <>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Company Name" name="companyName"
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Company Name', true) }]
                        }>
                        <Input type="text" className="fly-input" placeholder="Company name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item label="Company Business License">
                        {uploadedFiles.files.length !== 0 && (
                            <div
                                className="license-field d-flex d-space"
                                style={{
                                    height: 'auto',
                                    minHeight: '3.5rem',
                                    paddingTop: uploadedFiles.files.length > 1 ? '10px' : 0,
                                    paddingBottom: uploadedFiles.files.length > 1 ? '10px' : 0,
                                }}
                            >
                                <div style={{
                                    display: 'flex',
                                    gap: 10,
                                    flexWrap: 'wrap'
                                }}>
                                    {uploadedFiles.files.map((file) => {
                                        return <a className="file-link mr-2" target="blank" href={file.path}>{file.name}</a>
                                    })}
                                </div>
                                <EditOutlined
                                    onClick={() => {
                                        setUploadedFiles({...uploadedFiles, files: []});
                                        form.setFieldValue('licenseExpirationDate', '');
                                    }}
                                />
                            </div>
                        )}
                        {uploadedFiles.uploading && (
                          <div className="ant-upload-select uploading">
                              <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                          </div>
                        )}
                        <div style={{
                            position: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 'initial' : 'absolute',
                            zIndex: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 1 : -1,
                            opacity: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 1 : 0,
                            pointerEvents: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 'initial' : 'none',
                            touchAction: uploadedFiles.files.length === 0 && !uploadedFiles.uploading ? 'initial' : 'none'
                        }}>
                            <Upload
                                className="ant-upload ant-upload-select"
                                customRequest={customUploadHandler}
                                showUploadList={false}
                                multiple={true}
                                accept=".png,.jpg,.jpeg,.pdf"
                            >
                                <p>Drag-n-drop your business here or browse from your computer</p>
                            </Upload>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item label="Company Description" name="description">
                        <Input.TextArea rows="4" className="fly-input" placeholder="Start typing..." />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={8} xs={24} className="pr-2">
                    <Form.Item
                        label="License expiration date"
                        name="licenseExpirationDate"
                        rules={[{ required: uploadedFiles.files.length !== 0, message: 'This field is required' }]}
                    >
                        <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" className="fly-date-picker" defaultPickerValue={utils.getDefaultExpirationDate} disabledDate={utils.disabledDate}/>
                    </Form.Item>
                </Col>
                <Col md={8} xs={24} className="pr-2 pl-2">
                    <Form.Item label="Number of pilots" name="pilotsNumber">
                        <Select
                            placeholder="Pick one"
                            className="fly-select"
                            options={utils.resources.availableNumbers}
                        />
                    </Form.Item>
                </Col>
                <Col md={8} xs={24} className="pl-2">
                    <Form.Item label="Number of aircraft" name="aircraftNumber">
                        <Select
                            placeholder="Pick one"
                            className="fly-select"
                            options={utils.resources.availableNumbers}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default CompanyInformationPanel
