// Comment out following polyfills if you don't need IE11 support
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import 'regenerator-runtime/runtime'
import './index.css'
import App from './App'
import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'
import { configureStore } from './redux'
import { addGTMNoScript } from './utils';

const store = configureStore()

ReactDOM.render(<Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
)

addGTMNoScript()
registerServiceWorker()
