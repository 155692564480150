import React, { useState } from 'react'
import { Row, Col, Form, Button, Modal } from 'antd'
import Upload from 'rc-upload'
import * as utils from '../../../utils'
import Loader from "react-loader-spinner"
import { firestorage } from "../../../firebaseConfig"
import { ArrowRightOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'

function UploadAvatarModal (props) {
    const {currentUser, avatar, setAvatar} = props
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm()
    const [uploadedFile, setUploadedFile] = useState({})

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleOk = async () => {
        setAvatar(uploadedFile.file)
        setIsModalVisible(false)
    }

    const onSubmitHandler = (values) => {
        console.log(values)
        handleOk()
    }

    const customUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            setUploadedFile({...uploadedFile, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`companies/${currentUser.uid}/logo-${utils.generateRandomKeys()}.png`)
            try {
                const image = await imgFile.put(file, {contentType: 'image/jpeg'})
                onSuccess(null, image)
                setUploadedFile({...uploadedFile, file: await image.ref.getDownloadURL()})
            } catch(e) {
                setUploadedFile({...uploadedFile, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }

    return (
        <>
            <div className="avatar-item">
                <img alt='' src={avatar? avatar : "/images/user-placeholder.png"} className="avatar-icon" />
                <p onClick={() => setIsModalVisible(true)}>Update <br />avatar</p>
            </div>
            <Modal title=""
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width='520px'
                className="fly-modal content"
                footer={[]}
                destroyOnClose={true}
            >
                <div className="fly-form auth-form">
                    <Form
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        preserve={false}
                    >
                        <h2>Upload your Logo</h2>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Your logo" name="avatar">
                                    {
                                        uploadedFile.file ?
                                            <div className="ant-upload-select uploaded">
                                                <CheckOutlined style={{ color: '#ffffff'}}/>
                                                <p>Uploaded!</p>
                                                <EditOutlined className="p-right" onClick={() => setUploadedFile({...uploadedFile, file: null})}/>
                                            </div>
                                        :
                                        uploadedFile.uploading?
                                            <div className="ant-upload-select uploading">
                                                <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                            </div>
                                            :
                                            <Upload
                                                className="ant-upload ant-upload-select"
                                                customRequest={customUploadHandler}
                                                showUploadList={false}
                                                multiple={false}
                                                accept=".png,.jpg,.jpeg"
                                            >
                                                <p>Upload</p>
                                            </Upload>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item shouldUpdate className="mt-3 t-right">
                            {() => (
                                <Button type="primary" htmlType="submit" className="fly-btn"
                                    disabled={!uploadedFile.file}
                                >UPLOAD<ArrowRightOutlined /></Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default UploadAvatarModal
