import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Spin } from 'antd'
import * as utils from '../../../../utils'
import { ReactSVG } from 'react-svg'
import { FlyFooter, FlyFlightsNavBar } from '../../../../components/'
import SmartCheckIn from './SmartCheckIn'
import FlightDetails from './FlightDetails'
import { ApiManager } from "../../../../service"
import { countBags } from '../../../../utils';

function MyFlights(props) {
    const {history, currentUser, activeNav, setActiveNav, setListScrollHeight, config} = props
    const [flights, setFlights] = useState([])
    const [activeRecord, setActiveRecord] = useState(null)
    const [loading, setLoading] = useState()
    const [isSmartCheckIn, setIsSmartCheckIn] = useState(false)

    useEffect(() => {
        updateData()
    }, [history.location.search])

    useEffect(() => {
        if (activeRecord === undefined) {
            updateData()
        }
    }, [activeRecord])

    const updateData = () => {
        const urlParams = new URLSearchParams(history.location.search)
        const ftype = urlParams.get('ft')
        const fid = urlParams.get('fid')
        const rid = urlParams.get('rid')
        if (ftype) {
            setActiveNav(ftype.toLowerCase())
        }
        if (fid && rid) {
            loadFlight(fid, rid)
        }
        else {
            loadData(ftype)
        }
    }

    const onNavSwitchHandler = (nav) => {
        setActiveNav(nav)
    }

    const goBack = () => {
        setActiveRecord()
        setIsSmartCheckIn(false)
        const urlParams = new URLSearchParams(history.location.search)
        const ftype = urlParams.get('ft')
        let url = '/client/flight-requests'
        if (ftype)
            url += `?ft=${ftype}`
        history.push(url)
    }

    const loadFlight = async (flightId, requestId) => {
        setLoading(true)
        ApiManager.getFlyerFlightDetails({uid: currentUser.uid, fid: flightId, rid: requestId}).then(res => {
            setActiveRecord(res.data.body.flight)
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    const loadData = async (ftype) => {
        setLoading(true)
        ApiManager.getFlyerFlights({uid: currentUser.uid, ftype: ftype}).then(res => {
            setFlights(ftype === 'upcoming' ? res.data.body.flights?.filter((flight) => parseInt(flight?.leftTime) >= 0) : res.data.body.flights)
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    const handleScroll = (e) => {
        setListScrollHeight(e?.currentTarget?.scrollTop || 0)
    }

    if (activeRecord) {
        setListScrollHeight(0)
        let action = new URLSearchParams(history.location.search).get('action')
        if (action === 'smart-check-in' || isSmartCheckIn)
            return <SmartCheckIn
                    flight={activeRecord}
                    setActiveRecord={setActiveRecord}
                    history={history}
                    currentUser={currentUser}
                    activeNav={activeNav}
                    goBack={goBack}
                    parentLoading={loading}
                />
        else
            return <FlightDetails
                    flight={activeRecord}
                    setActiveRecord={setActiveRecord}
                    history={history}
                    currentUser={currentUser}
                    activeNav={activeNav}
                    goBack={goBack}
                    updateData={loadData}
                    config={config}
                />
    }

    return (
        <>
            <div className="client-header">
                <div className="client-header-vectors">
                    <div className="vector-flamingo"><ReactSVG src={'/images/flamingo.svg'} /></div>
                    <div className="vector-cloud top"><ReactSVG src={'/images/cloud.svg'} /></div>
                </div>
                <h2>My Flights</h2>
                <FlyFlightsNavBar active={activeNav} onNavSwitchHandler={onNavSwitchHandler}/>
                <div className="mt-4">
                    <p className="flights-count">{flights.length} Flights</p>
                </div>
            </div>
            <div className="client-body list list-mobile" onScroll={handleScroll}>
                {loading ?
                    <Row className="m-auto">
                        <Col span={24} className="t-center">
                            <Spin />
                        </Col>
                    </Row>
                    : flights.length > 0 ? flights.map(flight =>
                        <Row className="flight-item flight-item-mobile" key={flight.request.id} onClick={flight.request.status !== 'expired'? () => setActiveRecord(flight): null}>
                            <Col span={24}>
                                {activeNav !== 'past' && flight.request.status !== 'expired' &&
                                    <div className="d-flex mb-1">
                                        <p className={`type-label ${flight.request.status === 'verified'? 'cancelled' : flight.request.status} text-white`}>
                                            {flight.request.status !== 'cancelled' && `${flight.leftTime} until`} {utils.resources.flightStatusBadge[flight.request.status]}
                                        </p>
                                    </div>
                                }
                                <div className="flight-item-header">
                                    <div className="d-flex">
                                        <ReactSVG src="/images/flights1.svg" />
                                        <p className="ml-1">{flight.departureTime} • {flight.departureDate}</p>
                                    </div>
                                    <p className="gray">{flight.number}</p>
                                </div>
                                <div className="flight-item-body">
                                    <div className="d-flex d-space">
                                        <div style={{ width: '50%' }}>
                                            <h3>{flight.departureAirport ? flight.departureAirport.split('-')[0] : ''}</h3>
                                            {flight?.departureAirport
                                                ? <p className="gray">{utils.resources.airports.index[flight?.departureAirport]?.place || ''}</p>
                                                : (
                                                    flight?.pickupInstruction
                                                        ? (
                                                            <p>
                                                                {flight.pickupInstruction}
                                                            </p>
                                                        )
                                                        : (
                                                            <div>
                                                                <p style={{fontSize: '12px'}}>
                                                                    {flight.departureLocation?.latitude}
                                                                    <p style={{
                                                                        opacity: 0.4,
                                                                        marginRight: '3px',
                                                                        fontSize: '12px',
                                                                        whiteSpace: 'nowrap'
                                                                    }}>Latitude</p>
                                                                </p>
                                                                <p style={{fontSize: '12px'}}>
                                                                    {flight.departureLocation?.longitude}
                                                                    <p style={{
                                                                        opacity: 0.4,
                                                                        marginRight: '3px',
                                                                        fontSize: '12px',
                                                                        whiteSpace: 'nowrap'
                                                                    }}>Longitude</p>
                                                                </p>
                                                            </div>
                                                        )
                                                )
                                            }
                                        </div>
                                        <ReactSVG src="/images/bird1.svg" className="b-icon"/>
                                        <div className="t-right" style={{width: '50%'}}>
                                            <h3>{flight.arrivalAirport ? flight.arrivalAirport.split('-')[0] : ''}</h3>
                                            {flight?.arrivalAirport
                                                ?
                                                <p className="gray">{utils.resources.airports.index[flight?.arrivalAirport]?.place || ''}</p>
                                                : (
                                                    <div>
                                                        <p style={{fontSize: '12px'}}>
                                                        {flight.arrivalLocation?.latitude}
                                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Latitude</p>
                                                        </p>
                                                        <p style={{ fontSize: '12px' }}>
                                                            {flight.arrivalLocation?.longitude}
                                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Longitude</p>
                                                        </p>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    {activeNav !== 'past' && (
                                        <>
                                            <hr className="mh-1" />
                                            <div className="d-flex d-space">
                                                <div>
                                                    <p className="gray">Passengers</p>
                                                    <p>{flight.request.passengersCnt}</p>
                                                </div>
                                                <div className="t-center">
                                                    <p className="gray">Number of Bags</p>
                                                    <p>{countBags(flight.request.passengers)}</p>
                                                </div>
                                                <div>
                                                    <p className="gray">Plane Type</p>
                                                    <p>{flight.planeDetails.planeType}</p>
                                                </div>
                                            </div>
                                            <hr className="mh-1" />
                                            <div className="d-flex d-space">
                                                <div>
                                                    <p className="gray">Pilot</p>
                                                    <p>{flight.pilotName}</p>
                                                </div>
                                                <div>
                                                    <p className="gray">Pilot Type</p>
                                                    <p>Company</p>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                                {activeNav !== 'past' && (
                                    <div className="flight-item-footer d-block">
                                        <Button type="primary" className={`fly-btn w-full check-in ${flight.request.status}`}
                                                onClick={flight.request.status === 'checkin'? () => {
                                                    setIsSmartCheckIn(true)
                                                }: null}
                                        >
                                            {utils.resources.flightStatus[flight.request.status]}
                                        </Button>
                                        {/*flight.request.status === 'on-time' &&
                                    <Button type="primary" className={`fly-btn w-full check-in download-ticket mt-1`}>
                                        Download Tickets to Wallet
                                    </Button>*/}
                                    </div>
                                )}
                            </Col>
                        </Row>
                    ) : <div className='center-block'>
                        <p className='block-title'>No Pending Flights Yet</p>
                        <Button
                            type="primary"
                            className="fly-btn centered-small wrapper"
                            onClick={() => history.push('/client/request-a-flight')}
                        >
                            Request a Flight
                        </Button>
                        <p className='block-text'>or visit <span onClick={() => history.push('/client/departures')}>departures</span>.</p>
                    </div>
            }
                {!loading && flights.length !== 0 && activeNav === 'past' && <div className='empty-block'/>}
                {flights.length !== 0 && activeNav === 'past' && (
                    <Button type="primary" className="fly-btn w-full btn-position-4" onClick={() => history.push('/client/request-a-flight')}>
                        Request a Flight
                    </Button>
                )}
            </div>
            <FlyFooter active="flights" />
        </>
    )
}

export default MyFlights
