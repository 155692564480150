import React from 'react'
import { Form, Input, Button } from 'antd'
import { FlySideBar } from '../../../components/'
import { ArrowRightOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'

function PasswordRecoveryReset(props) {
    const [form] = Form.useForm()
    const {user, setUser, setStep, history} = props

    const onSubmitHandler = (values) => {
        setUser(values)
        console.log(values)
        history.push('/auth/login')
        utils.openNotification('Password has been reset successfully!.')
    }

    return (
        <div className="auth-page auth-page-auth">
            <FlySideBar />
            <div className="content">
                <div className="fly-form auth-form v1-form">
                    <h2>Password recovery</h2>
                    <Form
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        initialValues={user}
                    >
                        <Form.Item label="New Password" name="password"
                            rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword(rule, value, callback, 'Password', true) }]}
                        >
                            <Input.Password className="fly-input fly-input-password" placeholder="At least 8 charactors" />
                        </Form.Item>
                        <Form.Item label="Confirm Password" name="recovery password"
                            rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword(rule, value, callback, 'Password', true) }]}
                        >
                            <Input.Password className="fly-input fly-input-password" placeholder="At least 8 charactors" />
                        </Form.Item>
                        <Form.Item shouldUpdate className="mt-5 t-right">
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="fly-btn"
                                    disabled={
                                        form.getFieldsError().filter(({ errors }) => errors.length)
                                            .length > 0 || !form.isFieldsTouched(true)
                                    }
                                >
                                    NEXT<ArrowRightOutlined/>
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default PasswordRecoveryReset
