import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Input, Button, Radio, Space, Drawer, DatePicker } from 'antd'
import Upload from 'rc-upload'
import { InfoCircleOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import NumberFormat from 'react-number-format'
import { firestore, serverTimestamp, firestorage } from "../../../firebaseConfig"
import { ApiManager } from "../../../service"
import Loader from "react-loader-spinner"
import prepareNumericalValue from '../../../utils/prepareNumericalValue';


const requiredFields = ['firstname', 'lastname', 'email', 'phone', 'ifrRating', 'vfrRating']

function AddPilotDrawer(props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState()
    const {drawerVisible, closeDrawerHandler, activeRecord, currentUser, loadData} = props
    const [uploadedFilesLicense, setUploadedFilesLicense] = useState({files: [], uploading: false})
    const [fileToUploadLicense, setFileToUploadLicense] = useState({});
    const [uploadedFilePhoto, setUploadedFilePhoto] = useState({})

    useEffect(() => {
        if (activeRecord) {
            setUploadedFilesLicense({
                files: (!activeRecord.license || typeof (activeRecord.license) === 'string')
                  ? []
                  : activeRecord.license,
                uploading: false
            })
            setUploadedFilePhoto({file: activeRecord.photo})
        }
    }, [])

    const onSubmitHandler = async (values) => {
        setLoading(true)
        let pData = Object.assign({}, values)
        pData.uid = currentUser.uid
        Object.keys(values).forEach(key => {
            if (pData[key] === undefined) pData[key] = ''
        })
        if (pData.licenseExpirationDate && pData.licenseExpirationDate !== '')
            pData.licenseExpirationDate = pData.licenseExpirationDate.format('MM/DD/YYYY')
        pData.license = uploadedFilesLicense.files || []
        pData.photo = uploadedFilePhoto.file || ''
        if (!pData.license) pData.licenseExpirationDate = ''
        if (activeRecord) {
            pData.verified = "pending"
            await firestore.collection('pilots').doc(activeRecord.id).update(pData)
            if (activeRecord.license) {
                const sSnapshot = await firestore.collection('schedules')
                    .where('uid', '==', currentUser.uid)
                    .where('pid', '==', activeRecord.id)
                    .where('licenseType', '==', 'pilotLicense').get();
                if (!sSnapshot.empty) {
                    sSnapshot.forEach(doc => {
                        firestore.collection('schedules').doc(doc.id).delete();
                    })
                }
            }
            if (pData.license && pData.licenseExpirationDate) {
                ApiManager.scheduleLicenseExpiration({ uid: currentUser.uid, pid: activeRecord.id, type: 'pilotLicense', date: pData.licenseExpirationDate });
            }
            ApiManager.sendSlackAlert({
                text: `🔔 pilot updated \nEmail: ${pData?.email || ''} \nHours flown: ${pData?.hoursFlown || ''}`
            })
        }
        else {
            pData.createdAt = serverTimestamp
            const pSnapshot = await firestore.collection('pilots').add(pData)
            if (pData.license && pData.licenseExpirationDate) {
                ApiManager.scheduleLicenseExpiration({ uid: currentUser.uid, pid: pSnapshot.id, type: 'pilotLicense', date: pData.licenseExpirationDate });
            }
            currentUser.profile.pilots.push(pSnapshot.id)
            await firestore.collection('users').doc(currentUser.uid).update({pilots: currentUser.profile.pilots})
                .then(() => {
                    utils.pushDataLayer({ event: 'pilot_added' });
                    ApiManager.sendSlackAlert({
                        text: `🔔 pilot added \nEmail: ${pData?.email || ''} \nHours flown: ${pData?.hoursFlown || ''}`
                    });
                })
            ApiManager.sendEmail({type: "pilotRequest", id: pSnapshot.id, uid: currentUser.uid, collection: "pilots",
                verified: "pending", reason: "", pilotName: `${pData.firstname} ${pData.lastname}`})
        }
        utils.openNotification(`${activeRecord? 'Saved': 'Added'} successfully!`, true)
        setLoading(false)
        loadData()
        closeDrawerHandler(false)
    }

    useEffect(() => {
        if (Object.keys(fileToUploadLicense).length !== 0) {
            setUploadedFilesLicense({
                ...uploadedFilesLicense,
                files: [...uploadedFilesLicense.files, fileToUploadLicense],
                uploading: false
            })
        }
    }, [fileToUploadLicense]);

    const customFileLicenseUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFilesLicense({...uploadedFilesLicense, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`pilots/${currentUser.uid}/license-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUploadLicense({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFilesLicense({...uploadedFilesLicense, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }

    const customFilePhotoUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            setUploadedFilePhoto({...uploadedFilePhoto, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`pilots/${currentUser.uid}/photo-${utils.generateRandomKeys()}.png`)
            try {
                const image = await imgFile.put(file, {contentType: 'image/jpeg'})
                onSuccess(null, image)
                setUploadedFilePhoto({...uploadedFilePhoto, file: await image.ref.getDownloadURL()})
            } catch(e) {
                setUploadedFilePhoto({...uploadedFilePhoto, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }
    
    const onChangeHoursFlown = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        form.setFieldValue('hoursFlown', newVal);
    }

    const initialValues = activeRecord ? activeRecord : {}

    return (
        <Drawer
            title={`${activeRecord? 'Edit': 'Add a'} pilot`}
            placement="right"
            onClose={closeDrawerHandler}
            visible={drawerVisible}
            width='32.31rem'
            extra={
              <Space>
                <Button onClick={closeDrawerHandler}>Cancel</Button>
                <Button type="primary" onClick={closeDrawerHandler}>
                  OK
                </Button>
              </Space>
            }
            destroyOnClose={true}
        >
            <Form
                className="content"
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={true}
                preserve={false}
                initialValues={initialValues}
            >
                <div className="form-content">
                    <div className="form-body">
                        <Row className="d-flex">
                            <Col md={24} xs={24}>
                                <Form.Item label="First Name" name='firstname'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'First name', true) }]
                                    }>
                                    <Input type="text" className="fly-input" placeholder="First Name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item label="Last Name" name='lastname'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Last name', true) }]
                                    }>
                                    <Input type="text" className="fly-input" placeholder="Last Name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item label="Phone" name='phone'
                                    rules={[{ required: true, message: "This field is required"}]}>
                                    <NumberFormat className="fly-input" format="(###) ###-####" mask="_" placeholder="(000) 000-0000" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="d-flex">
                            <Col md={24} xs={24}>
                               <Form.Item label="Email" name="email"
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                                >
                                    <Input type="email" className="fly-input" placeholder="Email" suffix={
                                        <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                    }/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Hours Flown" name='hoursFlown'>
                                    <Input type="number" className="fly-input has-custom-suffix-icon" placeholder="Enter amount" suffix={<p>hours</p>} onChange={onChangeHoursFlown}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="d-flex">
                            <Col span={12} className="pr-2">
                                <Form.Item label="IFR Rating" name='ifrRating'
                                    rules={[{ required: true, message: 'This field is required' }]}>
                                    <Radio.Group options={utils.resources.yesOrNo} size='large' className="rating-radio-grp"></Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={12} className="pl-2">
                                <Form.Item label="VFR Rating" name='vfrRating'
                                    rules={[{ required: true, message: 'This field is required' }]}>
                                    <Radio.Group options={utils.resources.yesOrNo} size='large' className="rating-radio-grp"></Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Pilot's Photo">
                                    {
                                        uploadedFilePhoto.file?
                                            <div className="ant-upload-select uploaded">
                                                <CheckOutlined style={{ color: '#ffffff'}}/>
                                                <p>Uploaded!</p>
                                                <EditOutlined className="p-right" onClick={() => setUploadedFilePhoto({...uploadedFilePhoto, file: null})}/>
                                            </div>
                                        :
                                        uploadedFilePhoto.uploading?
                                            <div className="ant-upload-select uploading">
                                                <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                            </div>
                                            :
                                            <Upload
                                                className="ant-upload ant-upload-select"
                                                customRequest={customFilePhotoUploadHandler}
                                                showUploadList={false}
                                                multiple={false}
                                                accept=".png,.jpg,.jpeg"
                                            >
                                                <p>Upload</p>
                                            </Upload>
                                    }
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Pilot's License">
                                    {uploadedFilesLicense.files.length !== 0 && (
                                      <div className="ant-upload-select uploaded">
                                          <CheckOutlined style={{ color: '#ffffff'}}/>
                                          <p>Uploaded!</p>
                                          <EditOutlined
                                            className="p-right"
                                            onClick={() => setUploadedFilesLicense({
                                                ...uploadedFilesLicense,
                                                files: []
                                            })}
                                          />
                                      </div>
                                    )}
                                    {uploadedFilesLicense.uploading && (
                                      <div className="ant-upload-select uploading">
                                          <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                      </div>
                                    )}
                                    <div style={{
                                        position: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 'initial' : 'absolute',
                                        zIndex: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 1 : -1,
                                        opacity: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 1 : 0,
                                        pointerEvents: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 'initial' : 'none',
                                        touchAction: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 'initial' : 'none'
                                    }}>
                                        <Upload
                                          className="ant-upload ant-upload-select"
                                          customRequest={customFileLicenseUploadHandler}
                                          showUploadList={false}
                                          multiple={true}
                                          accept=".png,.jpg,.jpeg,.pdf"
                                        >
                                            <p>Upload</p>
                                        </Upload>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col span={12}>
                                <Row className="d-flex switch-item">
                                    <Col span={8}>
                                        <p>Verified</p>
                                    </Col>
                                    <Col span={16} className="t-right">
                                        <Form.Item label="" name='verified' valuePropName="checked">
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>*/}
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item
                                    label="License expiration date"
                                    name='licenseExpirationDate'
                                    rules={[{ required: uploadedFilesLicense.files.length !== 0, message: 'This field is required' }]}
                                >
                                    <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" className="fly-date-picker" defaultPickerValue={utils.getDefaultExpirationDate} disabledDate={utils.disabledDate} showToday={false}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
                <Row className="form-footer mt-5">
                    <Col span={24}>
                        <Form.Item shouldUpdate>
                            {() => {
                                let invalid = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || (!activeRecord && !form.isFieldsTouched(requiredFields, true))
                                return (
                                    <Button type="primary"  htmlType="submit" className="fly-btn w-full" disabled={invalid}
                                        loading={loading}
                                    >
                                        {`${activeRecord? 'SAVE': 'ADD'} PILOT`}
                                    </Button>
                                )
                            }}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default AddPilotDrawer
