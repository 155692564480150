import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { Form, Input, Button, Modal, Select } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import * as utils from '../../utils'
import ApiManager from '../../service';

function GetNotifiedModal (props) {
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm()
    const {user, setUser} = props

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const onSubmitHandler = async (values) => {
        setIsLoading(true);
        setUser(values)
        console.log(values)
        await ApiManager.saveUserEmailForCountryExpandNotification({ email: values.email, country: values.country });
        handleOk()
        utils.openNotification('Submitted successfully!.')
        setIsLoading(false);
    }

    return (
        <>
            <Link to="#" onClick={() => setIsModalVisible(true)}>notified</Link>
            <Modal title=""
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width='540px'
                className="fly-modal content"
                footer={[]}
                destroyOnClose={true}
            >
                <div className="fly-form auth-form">
                    <Form
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        initialValues={user}
                    >
                        <h2>Get notified when we expand</h2>
                        <Form.Item label="Email" name="email"
                            rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                        >
                            <Input type="email" className="fly-input" />
                        </Form.Item>
                        <Form.Item label="Country" name="country"
                            rules={[{ required: true, message: 'Invalid' }]}
                        >
                            <Select
                                placeholder="Enter your country"
                                className="fly-select"
                                showSearch={true}
                                options={utils.resources.allCountries}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item shouldUpdate className="mt-5 t-right">
                            {() => (
                                <Button type="primary" htmlType="submit" className="fly-btn"
                                    disabled={
                                        form.getFieldsError().filter(({ errors }) => errors.length)
                                            .length > 0 || !form.isFieldsTouched(['country'], true)
                                    }
                                    loading={isLoading}
                                >SUBMIT<ArrowRightOutlined /></Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default GetNotifiedModal
