import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button } from 'antd'
import './index.scss'

function TermsAndConditions(props) {
    const timeout = 1000;
    const [countdownValue, setCountdownValue] = useState(5);
    const [form] = Form.useForm()
    const {step, setStep} = props

    useEffect(() => {
        if (!countdownValue) return;
        const timer = setTimeout(() => {
            setCountdownValue(countdownValue - 1);
        }, timeout);

        return () => clearTimeout(timer)
    }, [countdownValue])

    const onSubmitHandler = (values) => {
        setStep("Review")
    }

    return (
        <div className="flight-board terms-page">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label-small">1. You must be at least 18 years of age</p>
                        <p className="section-label-small">2. Your request will expire 24 hours before your departure time</p>
                        <p className="section-label-small">3. A bid will be available for you to accept 24 hours prior to your departure</p>
                        <p className="section-label-small">4. After you have selected your bid, you will have 15 min to pay before your flight is automatically cancelled</p>
                        <p className="section-label-small">5. 24 Hours prior to departure, you must complete Smart-Check In™. If the weight does not match , you will be requested to manually check in with the pilot upon arrival at the airport.</p>
                        <p className="section-label-small">6. Passenger’s luggage weight is limited for safety reasons and varies according to aircraft type. Items determined by the crew to be of excessive weight or size will not be permitted on the flight and your flight maybe cancelled with no refund. </p>
                        <p className="section-label-small">7. If you cancel your flight, the following charges shall apply;</p>
                        <p className="section-label-small mt-1 ml-2">7.1. 100% of the Total Cost if cancelled within the period 24 hours prior to the first date of departure.</p>
                        <p className="section-label-small mt-1 ml-2">7.2. 50% of the Total Cost if cancelled within the period 48 hours prior to the first date of departure.</p>
                    </div>
                    <Row className="form-footer mt-1">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full" disabled={!!countdownValue}>
                                {countdownValue ? countdownValue : 'I Agree'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default TermsAndConditions
