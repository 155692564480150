import React from 'react'
import { Row, Col, Button} from 'antd'

function SetPassword(props) {
    const {history, newUser, setStep} = props

    return (
        <div className="client-body b-gray height-100-percent">
            <Row className="t-center mt-5">
                <Col span={24}>
                    <img alt='' src={`/images/success.png`}/>
                    <h3 className="mt-3"> Password was <br />New password was <br /> successfully set!</h3>
                    <p className="fly-notes mt-2 t-center">Now you can use it to sing in!</p>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col span={24}>
                    <Button type="primary fly-btn" className="fly-btn w-full" onClick={() => history.push('/client/auth/login')}>
                        Sign in
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default SetPassword
