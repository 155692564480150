import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { Form, Button, Modal, Select } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { STRIPE_PAYMENT_COUNTRY } from '../../../constants';

function AddFboModal (props) {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm()
    let {fixedCountry, additionalFBOs, setAdditionalFBOs, mainForm, usedIslands, setUsedIslands} = props
    const [country, setCountry] = useState()

    const onChangeCountry = (selectedCountry) => {
        setCountry(selectedCountry)
        form.setFieldsValue({'state': undefined})
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const onSubmitHandler = (values) => {
        setAdditionalFBOs(additionalFBOs + 1)
        mainForm.setFieldsValue({
            'fixedBasedOperations': {
                [additionalFBOs]: values
            }
        })
        setUsedIslands({ ...usedIslands, [additionalFBOs]: values })
        handleOk()
        utils.openNotification('Submitted successfully!.')
    }

    useEffect(() => {
        if (!fixedCountry)
            fixedCountry = 'Bahamas'
        setCountry(fixedCountry)
        form.setFieldsValue({'country': fixedCountry})
    }, [props])

    return (
        <>
            <Link to="#" className="extra-txt" onClick={() => setIsModalVisible(true)}>Add another FBO</Link>
            <Modal title=""
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                width='637px'
                className="fly-modal content"
                footer={[]}
                destroyOnClose={true}
            >
                <div className="fly-form">
                    <Form
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        preserve={false}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        initialValues={{
                            country: country
                        }}
                    >
                        <h2>Add another Fixed based operations company address</h2>
                        <Form.Item label="Country" name="country"
                            rules={[{ required: true, message: 'Invalid' }]}
                        >
                            <Select
                                placeholder="Enter your country"
                                className="fly-select"
                                showSearch={true}
                                options={utils.resources.defaultCountries}
                                onChange={onChangeCountry}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item label={utils.resources.addressLabel[country] || 'Island'} name="state"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select
                                placeholder="Pick one"
                                className="fly-select"
                                showSearch={true}
                                options={
                                    utils.resources.address[country]?.filter(island => !Object.values(usedIslands).map(value => value.state).includes(island.value)) || []
                                }
                                allowClear
                                disabled={!country}
                            />
                        </Form.Item>
                        <Form.Item shouldUpdate className="mt-5 t-right">
                            {() => (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="fly-btn"
                                    disabled={
                                        form.getFieldsError().filter(({ errors }) => errors.length)
                                            .length > 0 || !form.isFieldsTouched(['state'], true)
                                    }
                                >
                                    SUBMIT<ArrowRightOutlined/>
                                </Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
        </>
    )
}

export default AddFboModal
