import React from 'react'
import { Row, Col, Form, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'

function PaymentMethodPanel (props) {
    return (
        <>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Beneficiary Account Number" name={['payment', 'beneficiaryAccountNumber']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Beneficiary Account Number', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Enter your bank account number" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item label="SWIFT or IBN" name={['payment', 'swiftOrIBN']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateTextAndNumber(rule, value, callback, 'SWIFT or IBN', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Enter the SWIFT code" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Bank Name" name={['payment', 'bankName']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Bank Name', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Enter the bank name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item label="Street Address" name={['payment', 'address']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateAddress(rule, value, callback, 'Street Address', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Enter street address" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Apt, Suite" name={['payment', 'aptSuite']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateTextAndNumber(rule, value, callback, 'Apt, Suite', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Ex. #123" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item label="City" name={['payment', 'city']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'City', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Enter your city/province" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="State/Island" name={['payment', 'state']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'State/Island', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Enter your country/state" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item label="Zip/Post Code*" name={['payment', 'zipcode']}
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateUSZipCode(rule, value, callback, 'Zip/Post Code*', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Enter zip/post code" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default PaymentMethodPanel
