import React from 'react'
import { Tabs } from 'antd'
import './index.scss'
import OneWayFlight from './OneWayFlight'
import RoundFlight from './RoundFlight'
import MultipleFlight from './MultipleFlight'

const {TabPane} = Tabs;

function Step1(props) {
    const {flight, setFlight} = props

    const defaultActiveKey = flight.fTab? flight.fTab: "One Way"

    const handleTabChangeHandler = (tab) => {
        setFlight({...flight, fTab: tab})
    }

    return (
        <Tabs defaultActiveKey={defaultActiveKey} className="form-tabs" onChange={handleTabChangeHandler}>
            <TabPane tab="One Way" key="One Way">
                <OneWayFlight {...props} />
            </TabPane>
            <TabPane tab="Round Trip" key="Round Trip">
                <RoundFlight {...props} />
            </TabPane>
            <TabPane tab="Multi Island" key="Multi Island">
                <MultipleFlight {...props} />
            </TabPane>
        </Tabs>
    )
}

export default Step1
