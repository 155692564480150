import React from 'react'
import { Row, Col } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { ReactSVG } from 'react-svg'
import * as utils from '../../../../utils'

function PaymentTypes(props) {
    const {step, setStep} = props

    return (
        <div className="payment-board">
            {utils.resources.paymentType.map(payment => {
                return (
                    <Row className="payment-item" key={payment.value} onClick={() => setStep('Payment Card')}>
                        <Col span={24}>
                            <div className="d-flex d-space mt">
                                <div className="d-flex d-center">
                                    <ReactSVG src={`images/${payment.value}.svg`} className="payment-icon" />
                                    <p className="ml-2">{payment.label}</p>
                                </div>
                                <RightOutlined />
                            </div>
                        </Col>
                    </Row>
                )
            })}
        </div>
    )
}

export default PaymentTypes
