import React from 'react'
import { Link } from "react-router-dom"
import { Statistic } from 'antd'
import { ReactSVG } from 'react-svg'

function Categories(props) {
    const { currentUser, totalEarnings } = props

    return (
        <div className="categories d-flex">
            <div className="category-item">
                <div className="d-block">
                    <Statistic title="" value={currentUser.profile.requests?.length} />
                    <p>Requests</p>
                </div>
                <Link className="extra-txt" to="/dashboard/requests">See Requests</Link>
                <ReactSVG src="/images/category-requests.svg" className="category-icon"/>
            </div>
            <div className="category-item">
                <div className="d-block">
                    <Statistic title="" value={currentUser.profile.upcomingFlights?.length} />
                    <p>Scheduled flights</p>
                </div>
                <Link className="extra-txt" to="/dashboard/flights">Manage Flights</Link>
                <ReactSVG src="/images/category-flights.svg" className="category-icon"/>
            </div>
            <div className="category-item">
                <div className="d-block">
                    <Statistic title="" value={currentUser.profile.fleets?.length} />
                    <p>Registered planes</p>
                </div>
                <Link className="extra-txt" to="/dashboard/fleets">Add Plane</Link>
                <ReactSVG src="/images/category-plane.svg" className="category-icon"/>
            </div>
            <div className="category-item">
                <div className="d-block">
                    <Statistic title="" prefix="$" value={totalEarnings} precision={2} />
                    <p>Total earnings</p>
                </div>
                <Link className="extra-txt" to="/dashboard/wallet">Go to Wallet</Link>
                <ReactSVG src="/images/category-wallet.svg" className="category-icon"/>
            </div>
        </div>
    )
}

export default Categories
