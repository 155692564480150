import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Redirect } from "react-router-dom"
import { useSelector } from 'react-redux'
import './index.scss'
import { Layout } from 'antd'
import { ReactSVG } from 'react-svg'
import RequestFlight from "./RequestFlight"
import Departures from "./Departures"
import Boarding from './Auth/Boarding'
import Auth from "./Auth"
import Profile from "./Profile"
import Flights from "./Flights"
import isMobile from '../../utils/isMobile';
import { firestore } from '../../firebaseConfig';

const { Header, Content, Footer } = Layout

const ScreenForDesktop = () => {
    return (
        <div className='screen-for-desktop'>
            <div className='cloud'><ReactSVG src={'/images/cloud.svg'} /></div>
            <div className='container'>
                <p className='title'>
                    For a better experience, please view this site on your mobile device
                </p>
                <div className='flamingo'><ReactSVG src={'/images/flamingo.svg'} /></div>
            </div>
        </div>
    )
}

function Client (props) {
    const history = useHistory()
    const { currentUser } = useSelector((state) => state.common)
    const { path } = useParams()
    const [listScrollHeight, setListScrollHeight] = useState(0)
    const [config, setConfig] = useState({});

    let renderPage = ''
    
    useEffect(() => {
        firestore.collection('main').doc('config').get().then(doc => {
           if (doc.exists) setConfig(doc.data());
        });
    }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0)
        setListScrollHeight(0);
    }, [path])

    let params = {
        history,
        currentUser,
        listScrollHeight,
        setListScrollHeight,
        config
    }

    window.mobileCheck = isMobile;

    if (!window.mobileCheck()) return <ScreenForDesktop/>

    if (!currentUser && ['boarding', 'auth'].indexOf(path) === -1)
        return <Redirect to="/client/auth/login" />

    switch (path) {
        case 'boarding':
            renderPage = <Boarding {...params} />
            break
        case 'flight-requests':
            renderPage = <Flights {...params} />
            break
        case 'request-a-flight':
            renderPage = <RequestFlight {...params} />
            break
        case 'profile':
            renderPage = <Profile {...params} />
            break
        case 'auth':
            renderPage = <Auth {...params} />
            break
        default:
            renderPage = <Departures {...params} />
    }

    if (currentUser && !currentUser?.profile?.isClientCompleteRegistration) {
        renderPage = <Auth {...params} />
    }

    let clsName = "client-layout " + path

    return (
        <Layout className={clsName}>
            <Content>{ renderPage }</Content>
        </Layout>
    )
}

export default Client
