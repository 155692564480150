import React from 'react'
import { Row, Col, Form, Button, Switch } from 'antd'
import './index.scss'

function Step4(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, setFlight, setFlightValues} = props

    const onSubmitHandler = (values) => {
        setFlightValues(values)
        setStep("TermsAndConditions")
    }

    const removePassengerHandler = (idx) => {
        let passengers = flight.passengers.filter((item, i) => i !== idx)
        setFlight({...flight, passengers })
    }

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label">Flight Privacy</p>
                        <Row className="d-flex">
                            <Col span={20}>
                                <p>Public</p>
                            </Col>
                            <Col span={4} className="t-right">
                                <Form.Item label="" name='public' valuePropName="checked">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-wrapper">
                            <Col span={24}>
                                <p className="section-label mb-1">Share Your Ride and Pay Less</p>
                                <p className="section-desc">
                                    By making your flight public, we share this to other users on the app to book too. Initially we charge you for the entire flight, but as more seats are booked, you pay less - get sharing!
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                Review
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default Step4
