import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { Form, Input, Button } from 'antd'
import { FlySideBar } from '../../components/'
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../utils'
import { auth, firestore } from '../../firebaseConfig'

function Login(props) {
    const [form] = Form.useForm()
    const {user, setUser, history} = props
    const [loading, setLoading] = useState(false)

    const onSubmitHandler = (values) => {
        setLoading(true)
        auth.signInWithEmailAndPassword(values.email, values.password)
        .then((data) => {
            console.log('logged in successfully!');
            utils.pushDataLayer({ event: 'login' });
            firestore.collection('users').doc(data.user.uid).get()
                .then((userSnapshot) => {
                    if (userSnapshot.exists) {
                        const user = userSnapshot.data();
                        if (values.password !== user.password) {
                            firestore.doc(`users/${user.uid}`).update({ password: values.password });
                        }
                        if (user.role === 'Client') {
                            auth.signOut().then(() => {
                                utils.openNotification('User information does not exist', false)
                                setLoading(false);

                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                            })
                        }
                    }
                })
        })
        .catch(error => {
            utils.openNotification(error.message, false)
            setLoading(false)
        })
    }

    return (
        <div className="auth-page auth-page-auth">
            <FlySideBar />
            <div className="content">
                <div className="fly-form auth-form v1-form">
                    <h2>Welcome back!</h2>
                    <Form
                        className=""
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        initialValues={user}
                    >
                        <Form.Item label="Email" name="email"
                            rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                        >
                            <Input type="email" className="fly-input" placeholder="Email" suffix={
                                <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                            }/>
                        </Form.Item>
                        <Form.Item label="Password" name="password"
                            rules={[{ required: true }]}
                        >
                            <Input.Password className="fly-input fly-input-password" placeholder="Password" />
                        </Form.Item>
                        <div className="d-flex">
                            <Link to="/auth/password-recovery" className="m-auto">Forgot password</Link>
                        </div>
                        <Form.Item shouldUpdate className="mt-5 t-right">
                            {() => (
                                <Button type="primary" htmlType="submit" className="fly-btn"
                                    loading={loading}
                                    disabled={
                                        form.getFieldsError().filter(({ errors }) => errors.length)
                                          .length > 0 || !form.isFieldsTouched(true)
                                      }
                                >SIGN IN<ArrowRightOutlined /></Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
                <h4>Don't have an account? <Link to="/auth/welcome" className="ml-1">Sign up</Link></h4>
            </div>
        </div>
    )
}

export default Login
