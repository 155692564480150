import React from 'react'
import { Button } from 'antd'
import { FlySideBar } from '../../components/'
import { ArrowRightOutlined } from '@ant-design/icons'

function Complete(props) {
    const {user, setUser, history} = props

    const handleNext = () => {
        // window.location.href = '/auth/account-type'
        window.location.href = '/auth/account-company'
    }

    return (
        <div className="auth-page auth-page-auth">
            <FlySideBar />
            <div className="content">
                <div className="fly-form auth-form v1-form ">
                    <div className="complete-image"></div>
                    <h2>Verification Email Sent</h2>
                    <p className="fly-info mb-4">To finalize creating your account, please verify your email by clicking the link sent to {user.email}.</p>
                    <Button type="primary" className="fly-btn complete-continue" onClick={handleNext}>
                        CONTINUE<ArrowRightOutlined />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Complete
