import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Row } from 'antd';
import { GoogleApiWrapper, Map, Marker, Polyline } from 'google-maps-react';
import { MAP_STYLES } from '../../../../utils/mapStyles';
import getMarkerRotation from '../../../../utils/getMarkerRotation';
import getInitRegion from '../../../../utils/getInitRegion';
import calculateZoomLevel from '../../../../utils/calculateZoomLevel';
import { resources } from '../../../../utils';
import { useMediaQuery } from '../../../../hooks/useMediaQuery.';
import { firestore } from '../../../../firebaseConfig';
import * as utils from '../../../../utils';
import ApiManager from '../../../../service';
import { setRequestDetailsOpen } from '../../../../redux/common/actions';

function RequestInfoForm (props) {
  const { setStep, activeRecord, currentUser, loadData } = props
  const [form] = Form.useForm()
  const isMobile = useMediaQuery('(max-width: 768px)');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const departureLat = activeRecord?.departureLocation
    ? activeRecord?.departureLocation?.latitude
    : resources.airports.index[activeRecord?.departureAirport]?.latitude;
  const departureLng = activeRecord?.departureLocation
    ? activeRecord.departureLocation?.longitude
    : resources.airports.index[activeRecord?.departureAirport]?.longitude;
  
  const arrivalLat = activeRecord?.arrivalLocation
    ? activeRecord?.arrivalLocation?.latitude
    : resources.airports.index[activeRecord?.arrivalAirport]?.latitude;
  const arrivalLng = activeRecord?.arrivalLocation
    ? activeRecord?.arrivalLocation?.longitude
    : resources.airports.index[activeRecord?.arrivalAirport]?.longitude;
  
  const path = [
    {
      lat: departureLat,
      lng: departureLng,
    },
    {
      lat: arrivalLat,
      lng: arrivalLng,
    }
  ];
  
  const point1 =
    activeRecord.departureLocation ||
    {
      latitude: resources.airports.index[activeRecord?.departureAirport]?.latitude,
      longitude: resources.airports.index[activeRecord?.departureAirport]?.longitude
    };
  const point2 =
    activeRecord.arrivalLocation ||
    {
      latitude: resources.airports.index[activeRecord?.arrivalAirport]?.latitude,
      longitude: resources.airports.index[activeRecord?.arrivalAirport]?.longitude
    };
  
  const onSubmitHandler = (values) => {
    setStep('bid')
  }
  
  const onCancelBid = async () => {
    try {
      setLoading(true);

      await firestore.collection('users').doc(currentUser.uid).collection('requests').doc(activeRecord.rid).update({
        status: 'pending'
      });

      const bSnapshot = await firestore.collection('bids')
          .where('rid', '==', activeRecord.rid)
          .where('uid', '==', currentUser.uid)
          .where('status', '==', 'pending')
          .get();

      if (!bSnapshot.empty) {
        const batch = firestore.batch();

        bSnapshot.docs.forEach(bid => {
          const bidRef = firestore.collection('bids').doc(bid.id);
          batch.update(bidRef, { status: 'canceled' });
        });

        await batch.commit();
      }

      ApiManager.sendEmail({ type: 'canceledBid', uid: activeRecord.uid, requestNumber: activeRecord.number });
      ApiManager.sendSlackAlert({
        text: `❌ bid for request ${activeRecord.number} canceled by pilot`,
        thread_ts: activeRecord.thread_ts
      });
      
      loadData();
      dispatch(setRequestDetailsOpen(false));
      setLoading(false);
    } catch (e) {
      utils.openNotification('Something went wrong. Contact with the administrator', false);
      setLoading(false);
    }
  }
  
  return (
    <Form
      name="form"
      id="form"
      layout='vertical'
      form={form}
      onFinish={onSubmitHandler}
      requiredMark={false}
    >
      <h2 className="t-center">Request Details - {activeRecord.number}</h2>
      <h3 className="mt-5">Flight</h3>
      <div className="card-bordered">
        <Row style={{ justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row' }}>
          {activeRecord.departureAirport && (
            <Col span={isMobile ? 24 : 12}>
              <p className="label label--mobileLabel">Departure Airport</p>
              <p>{activeRecord.departureAirport}</p>
            </Col>
          )}
          {activeRecord.departureLocation && (
            <Col span={isMobile ? 24 : 12} style={{ flex: '0 0 48%' }}>
              <p className="label label--mobileLabel">Departure Location</p>
              <span style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px' }}>
                <p style={{ opacity: 0.4 }}>Latitude</p>
                <p>{activeRecord.departureLocation?.latitude}</p>
              </span>
              <span style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px' }}>
                <p style={{ opacity: 0.4 }}>Longitude</p>
                <p>{activeRecord.departureLocation?.longitude}</p>
              </span>
            </Col>
          )}
          {activeRecord.arrivalAirport && (
            <Col span={isMobile ? 24 : 12}>
              <p className="label label--mobileLabel">Arrival Airport</p>
              <p>{activeRecord.arrivalAirport}</p>
            </Col>
          )}
          {activeRecord.arrivalLocation && (
            <Col span={isMobile ? 24 : 12} style={{ flex: '0 0 48%' }}>
              <p className="label label--mobileLabel">Arrival Location</p>
              <span style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px' }}>
                <p style={{ opacity: 0.4 }}>Latitude</p>
                <p>{activeRecord.arrivalLocation?.latitude}</p>
              </span>
              <span style={{ display: 'flex', justifyContent: 'space-between', marginRight: '30px' }}>
                <p style={{ opacity: 0.4 }}>Longitude</p>
                <p>{activeRecord.arrivalLocation?.longitude}</p>
              </span>
            </Col>
          )}
        </Row>
        <hr />
        {(activeRecord.departureLocation || activeRecord.arrivalLocation) && (
          <>
            <Row style={{
              height: '160px',
              justifyContent: 'space-between',
              flexDirection: isMobile ? 'column' : 'row'
            }}>
              <Col span={isMobile ? 24 : 12} style={{height: '135px', flex: '0 0 48%'}}>
                {activeRecord.departureLocation && (
                  <>
                    <p className="label label--mobileLabel">Departure Location</p>
                    <Map
                      google={props.google}
                      zoom={11}
                      initialCenter={{
                        lat: activeRecord.departureLocation?.latitude,
                        lng: activeRecord.departureLocation?.longitude
                      }}
                      styles={MAP_STYLES}
                    >
                      <Marker
                        name={'Departure location'}
                        icon={{
                          url: '/images/markers/web_marker.svg'
                        }}
                      />
                    </Map>
                  </>
                )}
              </Col>
              <Col span={isMobile ? 24 : 12} style={{height: '135px', flex: '0 0 48%'}}>
                {activeRecord.arrivalLocation && (
                  <>
                    <p className="label label--mobileLabel">Arrival Location</p>
                    <Map
                      google={props.google}
                      zoom={11}
                      initialCenter={{
                        lat: activeRecord.arrivalLocation?.latitude,
                        lng: activeRecord.arrivalLocation?.longitude
                      }}
                      styles={MAP_STYLES}
                    >
                      <Marker
                        name={'Arrival location'}
                        icon={{
                          url: '/images/markers/web_marker.svg'
                        }}
                      />
                    </Map>
                  </>
                )}
              </Col>
            </Row>
            <hr/>
            {activeRecord.pickupInstruction && (
              <>
                <Row style={{
                  flexDirection: isMobile ? 'column' : 'row'
                }}>
                  <Col>
                    <p className="label label--mobileLabel">Pickup Details</p>
                    <p>{activeRecord.pickupInstruction}</p>
                  </Col>
                </Row>
                <hr/>
              </>
            )}
          </>
        )}
        <Row style={{
          flexDirection: isMobile ? 'column' : 'row'
        }}>
          <Col span={isMobile ? 24 : 6}>
            <p className="label label--mobileLabel">Departure Date</p>
            <p>{activeRecord.departureDate}</p>
          </Col>
          <Col span={isMobile ? 24 : 6}>
            <p className="label label--mobileLabel">Departure Time</p>
            <p>{activeRecord.departureTime}</p>
          </Col>
        </Row>
      </div>
      <div className="card-bordered mt-2">
        <Row>
          <Col span={12}>
            <p className="label label--mobileLabel">Plane Type</p>
            <p>{activeRecord.planeType}</p>
          </Col>
        </Row>
      </div>
      {Object.keys(activeRecord.passengers).length > 0 &&
        <h3 className="mt-4">Passengers</h3>
      }
      {Object.keys(activeRecord.passengers).map(key => {
        let passenger = activeRecord.passengers[key]
        return (
          <div className="card-bordered mb-4" key={key}>
            <Row style={{flexDirection: isMobile ? 'column' : 'row'}}>
              <Col span={isMobile ? 24 : 7}>
                <p className="label label--mobileLabel">Passenger {parseInt(key)+1}</p>
                <p>{passenger.firstname} {passenger.lastname}</p>
              </Col>
              <Col span={isMobile ? 24 : 7}>
                <p className="label label--mobileLabel">Approximate Weight</p>
                <p>{passenger.weight}lbs</p>
              </Col>
            </Row>
            {!!passenger.numberOfBags && <>
              <hr />
              <Row style={{flexDirection: isMobile ? 'column' : 'row'}}>
                <Col span={isMobile ? 24 : 7}>
                  <p className="label label--mobileLabel">Number of Bags</p>
                  <p>{passenger.numberOfBags}</p>
                </Col>
                <Col span={isMobile ? 24 : 7}>
                  <p className="label label--mobileLabel">Approximate Weight</p>
                  <p>{resources.additionalWeightOptions[passenger.weightPerBag]}lbs (S)</p>
                </Col>
              </Row>
            </>}
          </div>
        )
      })}
      
      <h3 className="mt-5">Additional Details</h3>
      <div className="card-bordered">
        <Row style={{flexDirection: isMobile ? 'column' : 'row'}}>
          <Col span={isMobile ? 24 : 8}>
            <p className="label label--mobileLabel">Travelling with Pet</p>
            <p>{activeRecord.hasPet? "Yes": "No"}</p>
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <p className="label label--mobileLabel">Number of Pets</p>
            <p>{activeRecord.numberOfPets || 0}</p>
          </Col>
          <Col span={isMobile ? 24 : 8}>
            <p className="label label--mobileLabel">Approximate Weight per Pet</p>
            <p>{activeRecord.weightPerPet ? resources.additionalWeightOptions[activeRecord.weightPerPet] : 0}lbs</p>
          </Col>
        </Row>
      </div>
      <div className="card-bordered mt-2">
        <Row>
          <Col span={isMobile ? 24 : 7}>
            <p className="label label--mobileLabel">Flight Privacy</p>
            <p>{activeRecord.public? "Public": "Private"}</p>
          </Col>
        </Row>
      </div>
      <Row className="mt-4" style={{ height: '254px' }}>
        <Col span={isMobile ? 24 : 12}>
          <h3>Flight Path</h3>
          <Map
            google={props.google}
            zoom={calculateZoomLevel(path)}
            styles={MAP_STYLES}
            initialCenter={getInitRegion([point1, point2])}
            style={{ height: '204px' }}
          >
            <Marker
              name={'Departure location'}
              icon={{
                url: `/images/markers/departureMarker${getMarkerRotation(point1, point2)}.svg`,
                anchor: new props.google.maps.Point(11, 11),
              }}
              position={{
                lat: departureLat,
                lng: departureLng,
              }}
            />
            <Marker
              name={'Arrival location'}
              icon={{
                url: '/images/markers/arrivalMarker.svg',
                anchor: new props.google.maps.Point(11, 11),
              }}
              position={{
                lat: arrivalLat,
                lng: arrivalLng,
              }}
            />
            <Polyline
              path={path}
              strokeColor={'#000'}
              strokeWeight={1}
            />
          </Map>
        </Col>
        <Col span={12}>
          {/*<h3>Weather</h3>*/}
          {/*<p className="label">Current</p>*/}
          {/*<Row className="d-flex d-space current-weather-card">*/}
          {/*    <Col>*/}
          {/*        <Space>*/}
          {/*            <ReactSVG src="/images/sun-light.svg" />*/}
          {/*            <h3 className="mb-0">24&#176</h3>*/}
          {/*            <p className="label mb-0 w-type mt-1">Sunny</p>*/}
          {/*        </Space>*/}
          {/*    </Col>*/}
          {/*    <Col>*/}
          {/*        <Space>*/}
          {/*            <p className="label mb-0">Wind:</p>*/}
          {/*            <p>1.2km/h</p>*/}
          {/*        </Space>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
          {/*<hr style={{margin: '1rem auto'}}/>*/}
          {/*<Row className="d-flex d-space mt-2">*/}
          {/*    <Col span={8} className="pr-1">*/}
          {/*        <div className="weather-card">*/}
          {/*            <h4 className="mb-1">26&#176</h4>*/}
          {/*            <p className="label mb-2 w-type">Mostly Sunny</p>*/}
          {/*            <p className="label mb-0">Tomorrow</p>*/}
          {/*        </div>*/}
          {/*    </Col>*/}
          {/*    <Col span={8} className="px-1">*/}
          {/*        <div className="weather-card">*/}
          {/*            <h4 className="mb-1">18&#176</h4>*/}
          {/*            <p className="label mb-2 w-type">Cloudy</p>*/}
          {/*            <p className="label mb-0">Wednesday</p>*/}
          {/*        </div>*/}
          {/*    </Col>*/}
          {/*    <Col span={8} className="pl-1">*/}
          {/*        <div className="weather-card">*/}
          {/*            <h4 className="mb-1">22&#176</h4>*/}
          {/*            <p className="label mb-2 w-type">Mostly Sunny</p>*/}
          {/*            <p className="label mb-0">Thursday</p>*/}
          {/*        </div>*/}
          {/*    </Col>*/}
          {/*</Row>*/}
        </Col>
      </Row>
      <Form.Item shouldUpdate className={`mt-5`}>
        <div style={{
          display: 'flex',
          justifyContent: activeRecord.status === 'expired' || activeRecord.status === 'awaiting' ? 'space-between' : 'end',
        }}>
          {(activeRecord.status === 'expired' || activeRecord.status === 'awaiting') && (
              <Button
                  type="primary"
                  className="fly-btn red-btn"
                  disabled={loading}
                  onClick={onCancelBid}
              >
                Cancel
              </Button>
          )}
          <Button
              type="primary"
              htmlType="submit"
              className="fly-btn green-btn"
              disabled={currentUser.profile?.verified !== 'done'}
          >
            {(activeRecord.status === 'expired' || activeRecord.status === 'awaiting') ? 'EDIT BID' : 'BID NOW'}
          </Button>
        </div>
      </Form.Item>
    </Form>
  )
}

export default GoogleApiWrapper({
  apiKey: (process.env.REACT_APP_SECRET_CODE === "Prod" ? "AIzaSyCAFRPjw-QymLcMe-lVBLLzmpCtw0paTh4" : "AIzaSyA1YYDadk0xblsApCYiM8OUaGrnWipVSss")
})(RequestInfoForm)
