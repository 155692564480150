const generateInviteCode = () => {
  const numbers = Array.from({length: 3}, () =>
    Math.floor(Math.random() * 10)
  ).join("");
  const letters = Array.from({length: 2}, () =>
    String.fromCharCode(65 + Math.floor(Math.random() * 26))
  ).join("");
  const lastDigit = Math.floor(Math.random() * 10);
  return `${numbers}${letters}${lastDigit}`;
};

export default generateInviteCode;
