import React, { useEffect, useState } from 'react'
import Spinner from 'react-spinkit'
import { auth } from "../../../firebaseConfig"
import { useHistory } from "react-router-dom"
import { Button } from 'antd'

function VerifyEmail(props) {
  const history = useHistory()
  const [error, setError] = useState('')
  const [validCode, setValidCode] = useState(null)
  const [verifiedCode, setVerifiedCode] = useState(false)

  useEffect(() => {
    auth
      .applyActionCode(props.actionCode)
      .then(() => {
        setValidCode(true)
        setVerifiedCode(true)
      }, error => {
        setError(error.message)
        setValidCode(false)
        setVerifiedCode(true)
      })
  }, [])

  if (!verifiedCode) {
    return <Spinner spinnerName="three-bounce" />
  } else if (verifiedCode && validCode) {
    window.location.href = '/auth/login'
  } else if (verifiedCode && !validCode) {
    return (
      <div className="fly-form auth-form v1-form t-center">
        <p className="fly-info">{error}</p>
        <Button type="primary" className="fly-btn mt-4" onClick={() => history.push('/auth/login')}>
          Continue
        </Button>
      </div>
    )
  }
}

export default VerifyEmail
