import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Row, Col, Form, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import './index.scss'
import * as utils from '../../../utils'
import { resources } from '../../../utils';
import ApiManager from '../../../service';

function FlightItem(props) {
    const {flight, key, type} = props

    return (
        <Row className="row-wrapper mb-2" key={key}>
            <Col span={24}>
                <p className="fly-notes">Departure Location</p>
                {flight.departureAirport
                    ? <p>{flight.departureAirport}</p>
                    : (
                        <div>
                            <p style={{fontSize: '12px'}}>
                                <p style={{
                                    opacity: 0.4,
                                    marginRight: '3px',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap'
                                }}>Latitude</p>
                                {flight.departureLocation?.latitude}
                            </p>
                            <p style={{fontSize: '12px'}}>
                                <p style={{
                                    opacity: 0.4,
                                    marginRight: '3px',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap'
                                }}>Longitude</p>
                                {flight.departureLocation?.longitude}
                            </p>
                            {flight?.pickupInstruction && (
                                <p style={{fontSize: '12px'}}>
                                    <p style={{
                                        opacity: 0.4,
                                        marginRight: '3px',
                                        fontSize: '12px',
                                        whiteSpace: 'nowrap'
                                    }}>Pickup Instructions</p>
                                    {flight.pickupInstruction}
                                </p>
                            )}
                        </div>
                    )
                }
                
                <p className="fly-notes mt-2">Departure Date</p>
                <p>{type ? flight.departureDate : utils.formatDate(flight.departureDate)}</p>
                
                <p className="fly-notes mt-2">Departure Time</p>
                <p>{type ? flight.departureTime : utils.formatTime(flight.departureTime)}</p>
                
                <hr className="mh-2"/>

                <p className="fly-notes">Arrival Location</p>
                {flight.arrivalAirport
                    ? <p>{flight.arrivalAirport}</p>
                    : (
                        <div>
                            <p style={{ fontSize: '12px' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Latitude</p>
                                {flight.arrivalLocation?.latitude}
                            </p>
                            <p style={{ fontSize: '12px' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Longitude</p>
                                {flight.arrivalLocation?.longitude}
                            </p>
                        </div>
                    )
                }

                {flight.arrivalDate && <>
                    <p className="fly-notes mt-2">Return Date</p>
                    <p>{utils.formatDate(flight.arrivalDate)}</p>
                </>}

                {flight.arrivalTime && <>
                    <p className="fly-notes mt-2">Return Time</p>
                    <p>{utils.formatTime(flight.arrivalTime)}</p>
                </>}
            </Col>
        </Row>
    )
}

function Review(props) {
    const [form] = Form.useForm()
    const history = useHistory()
    const {step, setStep, flight, postRequest, external, noBids} = props
    const [loading, setLoading] = useState(false);

    const onSubmitHandler = async (values) => {
        setLoading(true);
        
        if (flight.status === 'approved') {
            await ApiManager.acceptPlace({ requestId: flight.id });
            history.push('/client/flight-requests?ft=upcoming');
        } else {
            if (external) {
                if (!noBids) setStep("Bids");
            } else {
                postRequest && await postRequest();
                setStep("Request");
            }
        }

        setLoading(false);
    }

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <div className="form-content">
                    <div className="form-body">
                        <Row className="d-flex">
                            <Col span={20}>
                                <p className="section-label">Step1</p>
                            </Col>
                            <Col span={4} className="t-right">
                                {!external && <EditOutlined onClick={() => setStep(1)} />}
                            </Col>
                        </Row>
                        {flight.list ? Object.keys(flight.list).map(key =>
                                <FlightItem flight={flight.list[key]} key={key} />
                            ) : <FlightItem flight={flight} type="origins" />
                        }
                        <Row className="d-flex mt-3">
                            <Col span={20}>
                                <p className="section-label">Step2</p>
                            </Col>
                            <Col span={4} className="t-right">
                                {!external && <EditOutlined onClick={() => setStep(2)} />}
                            </Col>
                        </Row>
                        <Row className="row-wrapper">
                            <Col span={24}>
                                <p className="fly-notes">Plane Type</p>
                                <p>{flight.planeType || "Any"}</p>
                            </Col>
                        </Row>

                        <Row className="d-flex mt-3">
                            <Col span={20}>
                                <p className="section-label">Step3</p>
                            </Col>
                            <Col span={4} className="t-right">
                                {!external && <EditOutlined onClick={() => setStep(3)} />}
                            </Col>
                        </Row>
                        {flight.passengers && flight.passengers.map((passenger, idx) =>
                            <Row className="row-wrapper mb-2" key={idx}>
                                <Col span={24}>
                                    <p className="fly-notes">Passenger {idx+1}</p>
                                    <p>{passenger.firstname} {passenger.lastname}</p>

                                    <p className="fly-notes mt-2">Approximate Weight</p>
                                    <p>{passenger.weight}lbs</p>

                                    {!!passenger.numberOfBags && <>
                                        <hr className="mh-2"/>

                                        <p className="fly-notes">Number of Bags</p>
                                        <p>{passenger.numberOfBags}</p>

                                        <p className="fly-notes mt-2">Approximate Weight</p>
                                        <p>{resources.additionalWeightOptions[passenger.weightPerBag]}lbs (S) per bag</p>
                                    </>}
                                </Col>
                            </Row>
                        )}
                        <Row className={`row-wrapper ${flight.passengers? "mt-2": ""}`}>
                            <Col span={24}>
                                <p className="fly-notes">Traveling with Pet</p>
                                <p>{flight.hasPet? "Yes": "No"}</p>

                                {flight.hasPet && <>
                                    <p className="fly-notes mt-2">Number of Pets</p>
                                    <p>{flight.numberOfPets}</p>

                                    <p className="fly-notes mt-2">Approximate Weight per Pet</p>
                                    <p>{resources.additionalWeightOptions[flight.weightPerPet]}lbs</p>
                                </>}
                            </Col>
                        </Row>
                        {/*{!flight.passengerCnt && <>*/}
                        {/*    <Row className="d-flex mt-3">*/}
                        {/*        <Col span={20}>*/}
                        {/*            <p className="section-label">Step4</p>*/}
                        {/*        </Col>*/}
                        {/*        <Col span={4} className="t-right">*/}
                        {/*            {!external && <EditOutlined onClick={() => setStep(4)} />}*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*    <Row className="row-wrapper">*/}
                        {/*        <Col span={24}>*/}
                        {/*            <p className="fly-notes">Flight Privacy</p>*/}
                        {/*            <p>{flight.public? "Public": "Private"}</p>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</>}*/}
                    </div>
                    {flight.status !== 'pending-pilot-status' && (
                        <Row className="form-footer mt-5">
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" className={`fly-btn w-full ${flight.status !== 'approved' && noBids && 'add-flight-btn'}`} loading={loading} disabled={loading}>
                                    {flight.status === 'approved' ? 'Accept & Pay' : external ? 'View All Bids': 'Request'}
                                </Button>
                            </Col>
                        </Row>
                    )}
                </div>
            </Form>
        </div>
    )
}

export default Review
