import React, { useState } from 'react'
import { Row, Col, Button} from 'antd'

function SetPassword(props) {
    const {history, newUser, setStep} = props
    const [showTooltip, setShowTooltip] = useState(false);

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    return (
        <div className="client-body b-gray height-100-percent">
            <Row className="t-center">
                <Col span={24}>
                    <img alt='' src={`/images/success.png`}/>
                    <h3 className="mt-3">Your account has<br /> been created!</h3>
                    <p className="fly-notes mt-2 t-center">Please complete your flight charter <br />request by selecting continue.</p>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Button type="primary fly-btn" className="fly-btn w-full" onClick={() => setStep(3)}>
                        Continue
                    </Button>
                </Col>
            </Row>

            {showTooltip && isSafari && (
                <div className="tooltip">
                    <div>You can install the web app on your phone by tapping the <strong>'share'</strong> button and then <strong>'Add to Home Screen'</strong></div>
                    <Button
                        type="primary"
                        htmlType="button"
                        className="fly-btn mt-2"
                        style={{ height: '3rem' }}
                        onClick={() => setShowTooltip(false)}
                    >
                        Close
                    </Button>
                </div>
            )}
        </div>
    )
}

export default SetPassword
