import React from 'react'
import { Form, Modal, Row, Col } from 'antd'

function ReviewModal (props) {
    const [form] = Form.useForm()
    const {isModalVisible, handleOk, handleCancel, currentUser, activeRecord } = props

    const onSubmitHandler = (values) => {
        console.log(values)
    }

    let width = '56.25rem'
    let closable = true

    return (
        <Modal title=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={width}
            className="fly-modal content request-details-modal"
            footer={[]}
            destroyOnClose={true}
            maskClosable={true}
            closable={closable}
        >
            <div className="fly-form auth-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                >
                    <h2 className="t-center">Review</h2>

                    <div className="card-bordered mb-4">
                        <h4 className="fly-info mb-1">Old vales:</h4>
                        <Row>
                            <Col span={7}>
                                <p className="label">Passenger</p>
                                <p>{activeRecord.request.prevValues[activeRecord.psgId + 1]?.firstname} {activeRecord.request.prevValues[activeRecord.psgId + 1]?.lastname}</p>
                            </Col>
                            <Col span={7}>
                                <p className="label">Weight</p>
                                <p>{activeRecord.request.prevValues[activeRecord.psgId + 1]?.weight}lbs</p>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={7}>
                                <p className="label">Number of Bags</p>
                                <p>{activeRecord.request.prevValues[activeRecord.psgId + 1]?.numberOfBags || 0}</p>
                            </Col>
                            <Col span={7}>
                                <p className="label">Weight Per Bag</p>
                                <p>{activeRecord.request.prevValues[activeRecord.psgId + 1]?.weightPerBag || 0}lbs (S)</p>
                            </Col>
                        </Row>
                        {activeRecord.psgId === 0 && (
                            <>
                                <hr />
                                <Row>
                                    <Col span={7}>
                                        <p className="label">Number of Pets</p>
                                        <p>{activeRecord.request.prevValues[0]?.numberOfPets || 0}</p>
                                    </Col>
                                    <Col span={7}>
                                        <p className="label">Weight per Pet</p>
                                        <p>{activeRecord.request.prevValues[0]?.weightPerPet || 0}lbs (S)</p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>

                    <div className="card-bordered mb-4">
                        <h4 className="fly-info mb-1">New vales:</h4>
                        <Row>
                            <Col span={7}>
                                <p className="label">Passenger</p>
                                <p>{activeRecord.request.newValues[activeRecord.psgId + 1]?.firstname || ''} {activeRecord.request.newValues[activeRecord.psgId + 1]?.lastname || ''}</p>
                            </Col>
                            <Col span={7}>
                                <p className="label">Weight</p>
                                <p>{activeRecord.request.newValues[activeRecord.psgId + 1]?.weight || ''}lbs</p>
                            </Col>
                        </Row>
                        <hr />
                        <Row>
                            <Col span={7}>
                                <p className="label">Number of Bags</p>
                                <p>{activeRecord.request.newValues[activeRecord.psgId + 1]?.numberOfBags || 0}</p>
                            </Col>
                            <Col span={7}>
                                <p className="label">Weight Per Bag</p>
                                <p>{activeRecord.request.newValues[activeRecord.psgId + 1]?.weightPerBag || 0}lbs (S)</p>
                            </Col>
                        </Row>
                        {activeRecord.psgId === 0 && (
                            <>
                                <hr />
                                <Row>
                                    <Col span={7}>
                                        <p className="label">Number of Pets</p>
                                        <p>{activeRecord.request.newValues[0]?.numberOfPets || 0}</p>
                                    </Col>
                                    <Col span={7}>
                                        <p className="label">Weight per Pet</p>
                                        <p>{activeRecord.request.newValues[0]?.weightPerPet || 0}lbs (S)</p>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </div>
                </Form>
            </div>
        </Modal>
    )
}

export default ReviewModal
