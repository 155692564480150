import React, { useEffect, useState } from 'react'
import { Form, Button, Table, Space, Tooltip, Modal } from 'antd'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import './index.scss'
import AddPlaneDrawer from './AddPlaneDrawer'
import NumberFormat from 'react-number-format'
import { firestore } from '../../../firebaseConfig'

function Fleet(props) {
    const [form] = Form.useForm()
    const {history, currentUser} = props
    const [addFleetVisible, setAddFleetVisible] = useState(false)
    const [editFleetVisible, setEditFleetVisible] = useState(false)
    const [activeRecord, setActiveRecord] = useState()
    const [fleets, setFleets] = useState([])
    const [loading, setLoading] = useState()

    useEffect(() => {
        if (history.location.state !== undefined)
            setAddFleetVisible(history.location.state.addFleetVisible)
    }, [history.location])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        const fSnapshot = await firestore.collection('fleets').where('uid', '==', currentUser.uid).get();
        let fList = []
        let currentTime = utils.getToday()
        if (!fSnapshot.empty) {
            fSnapshot.forEach(doc => {
                let fItem = doc.data()
                fItem.id = doc.id
                if (!fItem.deletedByUser) fList.push(fItem)
            })
            for(let i=0;i<fList.length;i++) {
                let fItem = fList[i];
                if (fItem.expiredDate && fItem.expiredDate !== '' && (new Date(fItem.expiredDate) < currentTime)) {
                    fItem.verified = 'pending'
                    await firestore.collection('fleets').doc(fItem.id).update({
                        verified: 'pending',
                        inspectionCertificate: '',
                        AOCCertificate: ''
                    });
                }
            }
        }
        setFleets(fList)
        setLoading(false)
    }

    const onSubmitHandler = (values) => {
        console.log(values)
    }

    const editRecordHandler = (record) => {
        setActiveRecord(record)
        setEditFleetVisible(true)
    }

    const pagination = {
        showSizeChanger: false,
        defaultPageSize: 8,
        showTotal: (total, range) => `Showing ${range[1]-range[0]+1} of ${total}`,
        itemRender: utils.itemRender
    }

    const removeModal = (record) => {
        Modal.confirm({
            title: '',
            maskClosable: true,
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div>
                <h3 className="mt-4">Remove the plane</h3>
                <p>Are you sure you want to remove the plane <b>{record.nickname}</b>?</p>
            </div>,
            okText: 'REMOVE PLANE',
            onOk() {
                onRemoveHandler(record)
            },
        })
    }

    const onRemoveHandler = async (record) => {
        await firestore.collection('fleets').doc(record.id).update({ deletedByUser: true })
        currentUser.profile.fleets = currentUser.profile.fleets.filter(item => item !== record.id)
        await firestore.collection('users').doc(currentUser.uid).update({fleets: currentUser.profile.fleets})
        utils.openNotification('Removed successfully!', true)
        loadData()
    }

    const switchStatusHandler = async (record) => {
        if (record.verified === 'done') {
            await firestore.collection('fleets').doc(record.id).update({status: !record.status})
            loadData()
        }
    }

    const openAddFlightHandler = () => {
        setAddFleetVisible(true)
    }

    const closeAddFlightHandler = () => {
        setAddFleetVisible(false)
    }

    const openEditFlightHandler = () => {
        setEditFleetVisible(true)
    }

    const closeEditFlightHandler = () => {
        setEditFleetVisible(false)
        setActiveRecord(null)
    }

    const columns = [
        {
            title: 'Tail number',
            dataIndex: 'tailNumber',
            key: 'tailNumber',
            render: (text, record) => <Space>
                <ReactSVG src="/images/plane.svg" className="label-icon" />
                <p>{text}</p>
            </Space>,
        }, {
            title: 'Nickname',
            dataIndex: 'nickname',
            key: 'nickname',
        }, {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            render: (text, record) => <Space>
                { record.AOCCertificate && record.inspectionCertificate?
                    <Tooltip className="fly-tooltip" title={<p>{text === "done"? "Verified": text === "failed"? "Documents not verified, please upload active documents for verification": "Documents uploaded, pending verification"}</p>} color="#90C4C2" placement="left">
                        <ReactSVG src="/images/check.svg" className={`label-icon check-icon ${text === "done"? "active": text === "failed"? "inactive": ""}`} />
                    </Tooltip>
                    :
                    <Tooltip className="fly-tooltip" title={<p>Documents not uploaded, please upload active documents for verification</p>} color="#90C4C2" placement="left">
                        <div className="blank-icon"></div>
                    </Tooltip>
                }
            </Space>,
        // }, {
        //     title: 'IFR Rating',
        //     dataIndex: 'ifrRating',
        //     key: 'ifrRating',
        //     render: (text, record) => <div className="d-flex d-center">
        //         {text}
        //     </div>,
        // }, {
        //     title: 'VFR Rating',
        //     dataIndex: 'vfrRating',
        //     key: 'vfrRating',
        //     render: (text, record) => <div className="d-flex d-center">
        //         {text}
        //     </div>,
        }, {
            title: 'Plane Type',
            dataIndex: 'planeType',
            key: 'planeType',
            render: (text, record) => <Space>
                <ReactSVG src={`images/${utils.getPlaneType(text)}.svg`} className="label-icon plane-type-icon"/>
                <p>{text}</p>
            </Space>,
        }, {
            title: 'Plane Make',
            dataIndex: 'planeMake',
            key: 'planeMake',
        }, {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            render: (text, record) => <Space>
                <ReactSVG src="images/calendar.svg" className="label-icon"/>
                <p>{text}</p>
            </Space>,
        }, {
            title: 'Seat Capacity',
            dataIndex: 'seatCapacity',
            key: 'seatCapacity',
            render: (text, record) => <Space>
                <ReactSVG src="/images/seat.svg" className="label-icon" />
                <p>{text}</p>
            </Space>,
        }, {
            title: 'Weight Capacity(lbs)',
            dataIndex: 'maxWeightCapacity',
            key: 'maxWeightCapacity',
            render: (text, record) => <Space>
                <ReactSVG src="/images/weight.svg" className="label-icon" />
                <NumberFormat className="fly-text" thousandSeparator={true} value={text} readOnly/>
            </Space>,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                const verified = record.verified === 'done';
                const notDisabled = text && verified;

                return <Space>
                    <Tooltip className="fly-tooltip" title={<p>{notDisabled? 'Available': 'Not available'} for requests</p>} color="#90C4C2" placement="left">
                        <a className={`status-btn ${notDisabled ? '': 'disabled'}`} onClick={() => switchStatusHandler(record)}>{notDisabled? 'Active': 'Disabled'}</a>
                    </Tooltip>
                </Space>
            }
        },
        {
            title: '',
            render: (text, record) => <Space>
                <ReactSVG src="/images/edit.svg" className="action-icon" onClick={() => editRecordHandler(record)}/>
                <ReactSVG src="/images/delete.svg" className="action-icon" onClick={() => removeModal(record)} />
            </Space>
        },
    ]

    return (
        <div className="content fly-board fleet-board">
            <div className="content-header">
                <h2 className="mb-0">Fleet</h2>
                <Button type="primary" className="fly-btn" onClick={openAddFlightHandler}>ADD A PLANE</Button>
            </div>
            <div className="fly-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                >
                    <div className="form-content">
                        <Table columns={columns} dataSource={fleets}
                            pagination={pagination} loading={loading} />
                    </div>
                </Form>
            </div>
            {editFleetVisible &&
                <AddPlaneDrawer drawerVisible={editFleetVisible}
                    openDrawerHandler={openEditFlightHandler}
                    closeDrawerHandler={closeEditFlightHandler}
                    activeRecord={activeRecord}
                    currentUser={currentUser}
                    loadData={loadData}
                />
            }
            {addFleetVisible &&
                <AddPlaneDrawer drawerVisible={addFleetVisible}
                    openDrawerHandler={openAddFlightHandler}
                    closeDrawerHandler={closeAddFlightHandler}
                    currentUser={currentUser}
                    loadData={loadData}
                />
            }
        </div>
    )
}

export default Fleet
