import React, { useEffect, useState } from 'react'
import { Progress } from 'antd'
import _ from "lodash"
import { ReactSVG } from 'react-svg'
import { ApiManager } from "../../../../service"
import Passengers from './Passengers'
import Pets from './Pets'
import CheckInDone from './CheckInDone'
import CheckInFailed from './CheckInFailed'
import AddPassenger from '../../RequestFlight/AddPassenger'

function SmartCheckIn(props) {
    const {history, currentUser, setActiveRecord, activeNav, goBack, parentLoading} = props
    const [loading, setLoading] = useState()
    const [step, setStep] = useState(1)
    const [flight, setFlight] = useState()
    const [activePassenger, setActivePassenger] = useState()

    useEffect(() => {
        setFlight(JSON.parse(JSON.stringify(props.flight.request)))
    }, [props.flight])

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search)
        const stype = urlParams.get('status')
        if (stype)
            setStep(stype.toLowerCase())
    }, [history.location.search])

    let keyGroup = {
        'numberOfPets': 'Pets',
        'weightPerPet': 'Pets',
        'hasPet': 'Pets',
        'firstname': "Personal Info",
        'lastname': "Personal Info",
        "weight": "Personal Info",
        'numberOfBags': "Bags",
        'weightPerBag': "Bags",
    }

    const difference = (obj1, obj2) => {
        let keyFound = [];
        Object.keys(obj1).forEach(key => {
            if(['passengers', 'hasPet', 'numberOfPets', 'weightPerPet'].indexOf(key) > -1 && !_.isEqual(obj1[key], obj2[key])){
                if (key === 'passengers') {
                    if (obj1[key].length === obj2[key].length) {
                        obj1[key].map((psg, index) => {
                            Object.keys(psg).forEach(pkey => {
                                if (psg[pkey] !== obj2[key][index][pkey]) {
                                    if (keyFound.indexOf(keyGroup[key]) === -1)
                                        keyFound.push(keyGroup[pkey])
                                }
                            })
                        })
                    }
                    else {
                        keyFound.push("Number of Passengers")
                    }
                }
                else {
                    if (keyFound.indexOf(keyGroup[key]) === -1)
                        keyFound.push(keyGroup[key]);
                }
            }
       });
       return keyFound;
    };

    const postRequest = async () => {
        let nextPage;

        await ApiManager.smartCheckIn(
            {
                uid: currentUser.uid,
                flight,
                flightProps: {
                    request: props.flight.request,
                    leftSeats: props.flight.leftSeats,
                    id: props.flight.id,
                    uid: props.flight.uid,
                    number: props.flight.number,
                    arrivalAirport: props.flight.arrivalAirport
                }
            }
        )
            .then((res) => {
                res.data.body.emailsToSend.map((content) => {
                    ApiManager.sendEmail(content);
                })

                nextPage = res.data.body.nextPage;
            })

        return nextPage;
    }

    const prevStepHandler = () => {
        if (step === 1) {
            goBack()
        }
        if (step > 1) {
            setStep(step-1)
        }
        if (step === "Passenger")
            setStep(1)
        if (step === "Done")
            setStep('Payment Card')
        if (step === "Failed")
            setStep('Payment Card')
    }

    const setFlightValues = (values) => {
        let temp = flight;
        Object.keys(values).forEach(key => {temp[key] = values[key]})
        setFlight(temp)
    }

    const params = {
        history, step, setStep,
        flight, setFlight,
        setFlightValues,
        setActiveRecord,
        goBack,
        activePassenger, setActivePassenger,
        postRequest, loading, currentUser,
    }

    let renderPage = ''
    let pageTitle = 'Smart Check-In'
    let headerStyle = "client-header"
    let bodyStyle = 'client-body white'

    switch (step) {
        case 2:
            renderPage = <Pets {...params} />
            bodyStyle = 'client-body white addPets'
            break
        case 'Passenger':
            renderPage = <AddPassenger {...params} checkin={true}/>
            pageTitle = 'Passenger'
            bodyStyle = 'client-body white addPassenger height-100-percent'
            break
        case 'Done':
            renderPage = <CheckInDone {...params} />
            pageTitle = ''
            headerStyle ="client-header gray"
            bodyStyle = "client-body"
            break
        case 'Failed':
            renderPage = <CheckInFailed {...params} />
            pageTitle = ''
            headerStyle ="client-header gray"
            bodyStyle = "client-body"
            break
        default:
            renderPage = <Passengers {...params} />
            break
    }

    if (parentLoading || !flight)
        return ''

    return (
        <div className="content flight-details smart-check-in">
            <div className={headerStyle}>
                <div className="header-item">
                    {pageTitle && <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>}
                    <p>{pageTitle}</p>
                    <div className="step-marker">
                        {typeof(step) == 'number' && <Progress type="circle"
                            trailColor="#ffffff1a"
                            strokeColor="#33CDCD"
                            percent={step/2*100} width={36}
                            format={percent => `${step}/2`}
                        />}
                    </div>
                </div>
            </div>
            <div className={bodyStyle}>
                { renderPage }
            </div>
        </div>
    )
}

export default SmartCheckIn
