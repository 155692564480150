import React from 'react'
import { Form, DatePicker } from 'antd'
import './index.scss'

function FlyDatePicker(props) {
    const {
        label,
        field,
        required,
        style,
        format,
        placeholder,
    } = props

    const name = field? field.split('.'): 'default'

    return (
        <Form.Item
            label={label}
            name={name}
            colon={false}
            rules={[{ required: {required}, message: '**Required Field' }]}
            className="fly-date-picker"
        >
            <DatePicker format={format} placeholder={placeholder} />
        </Form.Item>
    )
}

export default FlyDatePicker
