import React from 'react'

const ICONS = [
    'facebook', 'twitter', 'youtube', 'instagram', 'linkedin'
]

function FlyAuthFooter(props) {
    return (
        <div className="auth-footer">
            <hr />
            <img alt='' src="/images/logo-black.png" className="logo-img" />
            <ul>
                {ICONS.map((icon, idx) =>
                    <li key={idx}><div className="social-icon"><img alt='' src={`/images/${icon}.png`} /></div></li>
                )}
            </ul>
            <p className="mt-3 mb-5"><a href="https://Flymingo.com">Flymingo.com</a>, Copyright © 2021 All rights reserved</p>
            <p>195 Montague Street, Unit 1410, Brooklyn, NY, 11201</p>
            <p>Want to change how you receive these emails? <br />
                You can <a href="#">update your preferences</a> or <a href="#">unsubscribe from this list</a>.</p>
        </div>
    )
}

export default FlyAuthFooter
