import React from 'react'
import { Row, Col, Form, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import NumberFormat from 'react-number-format'
import { ReactSVG } from 'react-svg'

function CredentialsPanel (props) {
    let {currentUser} = props

    return (
        <>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item
                        label="First name"
                        name="firstname"
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'First name', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="First name" suffix={
                            <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                        }/>
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item
                        label="Last name"
                        name="lastname"
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Last name', true)
                        }]}
                    >
                        <Input type="text" className="fly-input" placeholder="Last name" suffix={
                            <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                        }/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Company Phone Number" name="phone"
                        rules={[{ required: true, message: "This field is required"}]}>
                        <NumberFormat className="fly-input" format="(###) ###-####" mask="_" placeholder="(000) 000-0000" />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item label="Company Email" name="companyEmail"
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true)
                        }]}>
                        <Input type="email" className="fly-input" placeholder="Email" suffix={
                            <>
                                <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                            </>
                        }/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Password" name="password"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword(rule, value, callback, 'Password', true) }]}
                    >
                        <Input.Password className="fly-input fly-input-password" placeholder="At least 8 charactors" />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item label="Email" name="email"
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true)
                        }]}>
                        <Input type="email" className="fly-input" placeholder="Email" suffix={
                            <>
                                <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                {currentUser.emailVerified && <ReactSVG src="/images/check.svg" className="account-active-icon active" />}
                            </>
                        }/>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default CredentialsPanel
