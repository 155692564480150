import React from 'react'
import { Row, Col, Form, Button, Dropdown, Menu, Modal } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { ReactSVG } from 'react-svg'

function PassengerItem(props) {
    const {passenger, passengers, removePassengerHandler, editPassengerHandler, idx, edit} = props

    const menu = (
        <Menu className="passenger-menu">
            <Menu.Item key="edit" onClick={() => editPassengerHandler(passenger, idx)}>
                <p>Edit</p>
            </Menu.Item>
            {passengers.length > 1 && (
                <Menu.Item key="remove" onClick={() => removePassengerHandler(passenger, idx)}>
                    <p>Remove</p>
                </Menu.Item>
            )}
        </Menu>
    )

    return (
        <div className="passenger-item row-wrapper mb-2">
            <Row>
                {passenger.checkin && <Col span={3} className="d-flex d-center">
                    <ReactSVG src="/images/verified-star.svg" className="mr-1 planetype-icon" />
                </Col>}
                <Col span={passenger.checkin? 19: 22}>
                    <p>{passenger.firstname} {passenger.lastname}, <span className="fly-notes">{passenger.weight}lbs</span></p>
                    {passenger.numberOfBags && <p>{passenger.numberOfBags} Bag{passenger.numberOfBags>1 && "s"}, <span className="fly-notes">{passenger.weightPerBag}lbs (S)</span></p>}
                </Col>
                <Col span={2} className="t-right">
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                        <EllipsisOutlined />
                    </Dropdown>
                </Col>
            </Row>
            {!passenger.checkin && !edit &&
                <Row className="mt-2">
                    <Col span={24}>
                        <Button type="primary" className="fly-btn w-full check-in" onClick={() => editPassengerHandler(passenger, idx)}>
                            Check-In
                        </Button>
                    </Col>
                </Row>
            }
        </div>
    )
}

function Passengers(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, setFlight, setFlightValues, setActivePassenger, edit, seatCapacity} = props
    const onSubmitHandler = (values) => {
        setFlightValues(values)
        setStep(2)
    }

    const AddPassengerHandler = () => {
        setActivePassenger()
        setStep("Passenger")
    }

    const removePassengerHandler = (passenger, idx) => {
        Modal.confirm({
            title: '',
            maskClosable: true,
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div>
                <h3 className="mt-4">Remove the passenger</h3>
                <p>Are you sure you want to remove the passenger <b>{passenger.firstname} {passenger.lastname}</b>?</p>
            </div>,
            okText: 'REMOVE',
            onOk() {
                let passengers = flight.passengers.filter((item, i) => i !== idx)
                setFlight({...flight, passengers: passengers})
            },
        })
    }

    const editPassengerHandler = (passenger, idx) => {
        setActivePassenger({
            passenger: passenger,
            idx: idx
        })
        setStep("Passenger")
    }

    let checkedInList = flight.passengers?.map(passenger => passenger?.checkin === true)

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label">Passengers</p>
                        {flight.passengers?.map((passenger, idx) =>
                            <PassengerItem key={idx}
                               passenger={passenger}
                               passengers={flight.passengers}
                               idx={idx}
                               editPassengerHandler={editPassengerHandler}
                               removePassengerHandler={removePassengerHandler}
                               edit={edit}
                            />
                        )}
                    </div>
                    {edit && seatCapacity > flight.passengers.length && (
                        <Row className="form-footer mt-5">
                            <Col span={24}>
                                <Button type="primary" className="ant-btn ant-btn-primary fly-btn w-full add-btn mb-2" onClick={AddPassengerHandler}>
                                    Add a Passenger
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {(!checkedInList.includes(false) || edit) &&
                        <Row className={`form-footer ${edit ? '' : 'mt-5'}`}>
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                    Continue
                                </Button>
                            </Col>
                        </Row>
                    }
                </div>
            </Form>
        </div>
    )
}

export default Passengers
