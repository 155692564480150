import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Button, Col, DatePicker, Form, Modal, Row, Select, Space, Table, Tabs, Tooltip } from 'antd'
import { DownloadOutlined } from '@ant-design/icons';
import writeXlsxFile from 'write-excel-file'
import moment from 'moment';
import * as utils from '../../../utils'
import { sendExpoNotificationToUser } from '../../../utils'
import { ReactSVG } from 'react-svg'
import './index.scss'
import AddFlightDrawer from './AddFlightDrawer'
import Requests from './Requests'
import { firestore, serverTimestamp } from '../../../firebaseConfig'
import PassengerInfoModal from './PassengerInfoModal'
import { ApiManager } from '../../../service'
import saveByteArray from '../../../utils/saveByteArray';
import generateFlightData from '../../../utils/generateFlightData';
import { useSelector, useDispatch } from 'react-redux';
import { setAddFlightOpen, setEditFlightOpen } from '../../../redux/common/actions';

function DeleteEditForm (props) {
    const { handleOk, type, setEditAllOne, handleEditFlightOpen } = props
    const [form] = Form.useForm()

    return (
        <Form
            name="form"
            id="form"
            layout='vertical'
            form={form}
            onFinish={handleOk}
            requiredMark={false}
        >
            <ReactSVG src="/images/warning.svg" className="t-center"/>
            <h2 className="t-center mt-4">Do you want to {type} just this flight or all related recurring flights?</h2>
            <Row className="mt-5 d-center">
                <Col>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="fly-btn mr-2"
                        onClick={() => { setEditAllOne('all'); handleEditFlightOpen()
                         }}
                    >
                        ALL
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className="fly-btn green-btn"
                        onClick={() => { setEditAllOne('one'); handleEditFlightOpen()
                         }}
                    >
                        THIS ONE
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

const { TabPane } = Tabs

function Flights(props) {
    const dispatch = useDispatch();
    const [form] = Form.useForm()
    const [formFilters] = Form.useForm()
    const {history, currentUser, config} = props
    const [addFlightVisible, setAddFlightVisible] = useState(false)
    const [editFlightVisible, setEditFlightVisible] = useState(false)
    const [activeRecord, setActiveRecord] = useState()
    const [activeFlight, setActiveFlight] = useState()
    const [upFlights, setUpFlights] = useState([])
    const [prevFlights, setPrevFlights] = useState([])
    const [fleets, setFleets] = useState([])
    const [pilots, setPilots] = useState([])
    const [loading, setLoading] = useState()
    const [filters, setFilters] = useState({});
    const [isPassengerInfoVisible, setIsPassengerInfoVisible] = useState(false)
    const [modalVisible, setModalVisible] = useState(false);
    const [editAllOne, setEditAllOne] = useState('');
    const [departureAirport, setDepartureAirport] = useState('')
    const [arrivalAirport, setArrivalAirport] = useState('')
    const [departureCountry, setDepartureCountry] = useState('')
    const [arrivalCountry, setArrivalCountry] = useState('')
    const [activeTab, setActiveTab] = useState('1');
    const {addFlightOpen, editFlightOpen} = useSelector((state) => state.common);


    useEffect(() => {
        if (history.location.state !== undefined) {
            // setAddFlightVisible(history.location.state.addFlightVisible)
            dispatch(setAddFlightOpen(history.location.state.addFlightVisible))
        }
        if (history.location.hash === '#add-flight') {
            openAddFlightHandler();
            window.history.replaceState(null, null, '/dashboard/flights');
        }
        let params = new URLSearchParams(history.location.search)
        if (params.get('fid') && params.get('rid')) {
            approveRequest(params.get('fid'), params.get('rid'))
        }
    }, [history.location])


    const approveRequest = async (fid, rid) => {
        await firestore.collection('flights').doc(fid).collection('requests').doc(rid).update({
            status: 'verified'
        })
        history.push('/dashboard/flights')
    }

    useLayoutEffect(() => {
        loadData()
        loadAssets()
    }, [activeFlight])

    const loadData = async () => {
        setLoading(true)
        ApiManager.pilotFlights({uid: currentUser.uid}).then(res => {
            setUpFlights(res.data.body.upFlights)
            setPrevFlights(res.data.body.prevFlights)
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }

    const loadAssets = async () => {
        const fSnapshot = await firestore.collection('fleets')
            .where('uid', '==', currentUser.uid).where('status', '==', true).get()
        let fList = []
        if (!fSnapshot.empty) {
            fSnapshot.forEach(doc => {
                let fItem = doc.data()
                fItem.id = doc.id
                fItem.label = fItem.tailNumber
                fItem.value = doc.id
                if (fItem.verified === 'done' && !fItem.deletedByUser)
                    fList.push(fItem)
            })
            setFleets(fList)
        }
        const pSnapshot = await firestore.collection('pilots')
            .where('uid', '==', currentUser.uid).get()
        let pList = []
        if (!pSnapshot.empty) {
            pSnapshot.forEach(doc => {
                let pItem = doc.data()
                if (pItem.licenseExpirationDate !== '')
                    pItem.licenseExpirationDate = utils.getDate(pItem.licenseExpirationDate)
                pItem.id = doc.id
                pItem.label = `${pItem.firstname} ${pItem.lastname}`
                pItem.value = doc.id
                if (pItem.verified === 'done' && !pItem.deletedByUser)
                    pList.push(pItem)
            })
            setPilots(pList)
        }
    }

    const onSubmitHandler = (values) => {
        console.log(values)
        setFilters(values)
    }

    const pagination = {
        showSizeChanger: false,
        defaultPageSize: 15,
        showTotal: (total, range) => `Showing ${range[1]-range[0]+1} of ${total}`,
        itemRender: utils.itemRender
    }

    const onChangeCancelledReasonHandler = (event, record) => {
        record.cancelledReason = event
    };

    const removeModal = (record) => {
        const recurringFlight = record &&
            record.recurringRoute !== '' &&
            record.repeatOn &&
            record.repeatOn.length !== 0 &&
            record.endsDate &&
            record.endsDate !== ''

        Modal.confirm({
            title: '',
            maskClosable: true,
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div className="content">
                <h3 className="mt-4">Remove the flight{recurringFlight ? '(s)' : ''}</h3>
                <p>{recurringFlight
                    ? "Do you want to cancel all related recurring flights or just flight "
                    : "Are you sure you want to cancel your flight "}<b>{record.number}</b>?</p>
                <Form.Item>
                    {recurringFlight && (
                        <Select
                            className="fly-select t-left mt-2"
                            showSearch={true}
                            defaultValue={{label: `Flight ${record.number}`, value: 'one'}}
                            options={[{label: `Flight ${record.number}`, value: 'one'}, {label: 'All', value: 'all'}]}
                            onChange={(event) => record.removeFlights = event}
                        />
                    )}
                    <p className="mh-2">Reason for cancellation</p>
                    <Select
                        placeholder="Pick one"
                        className="fly-select t-left"
                        showSearch={true}
                        options={utils.resources.cancelReason}
                        allowClear
                        onChange={(event) => onChangeCancelledReasonHandler(event, record)}
                    />
                </Form.Item>
            </div>,
            okText: 'REMOVE FLIGHT',
            onOk() {
                onRemoveHandler(record, recurringFlight)
            },
        })
    }
    
    const cancelFlight = async (flightId, cancelledReason, currentUser) => {
        await firestore.collection('flights').doc(flightId).update({
            cancelled: cancelledReason || 'other',
            cancelledAt: serverTimestamp
        });
        
        currentUser.profile.flights = currentUser.profile.flights.filter(item => item !== flightId);
        
        await firestore.collection('users').doc(currentUser.uid).update({ flights: currentUser.profile.flights });
    }
    
    const handleCancellation = async (record, currentUser) => {
        const requestSnapshot = await firestore.collection('flights').doc(record.id).collection('requests').get();
        
        await Promise.all(requestSnapshot.docs.map(async doc => {
            let rItem = doc.data();
            rItem.id = doc.id;
            
            if (rItem?.rid) {
                await firestore.collection('requests').doc(rItem.rid).update({ status: 'denied' });
            }
            
            await firestore.collection('flights').doc(record.id).collection('requests').doc(rItem.id).update({ status: 'denied' });
            
            if (rItem.payment && rItem.status !== 'cancelled') {
                ApiManager.sendEmail({ type: 'flightCancelledByPilotAfterPayment', uid: rItem.uid, flightNumber: record.number });
                
                const paymentSnapshot = await firestore.collection('transactions').doc(rItem.payment).get();
                
                if (paymentSnapshot.exists) {
                    const payment = paymentSnapshot.data();

                    ApiManager.sendSlackAlert({
                        text: `❌ flight cancelled \nCancelled by: pilot \nFlight #: ${record.number} \n# of passengers: ${rItem.passengers.length} \nReason: ${record.cancelledReason || 'other'} \nPrice: ${payment.currency} ${payment.amount}`
                    });
                }
                
                const title = 'Your Scheduled Charter Has Been Cancelled';
                const content = 'Your Scheduled Charter Has Been Cancelled, you should receive a full refund shortly.';
                
                ApiManager.addNotification({
                    uid: rItem.uid,
                    type: 'flightCancelled',
                    title,
                    content,
                    icon: 'alert',
                    showInApp: true
                });
                
                sendExpoNotificationToUser({
                    uid: rItem.uid,
                    title,
                    content,
                    data: { type: 'flightCancelled' }
                });
            }
        }));
    }
    
    const onRemoveHandler = async (record, recurringFlight = false) => {
        if (recurringFlight && record.removeFlights === 'all') {
            const fSnapshot = await firestore.collection('flights').where('recurringId', '==', record.recurringId).get()
            const flightsId = fSnapshot.docs
                .filter(udoc => !udoc.data().cancelled)
                .map(doc => doc.id);
            
            await Promise.all(flightsId.map(async (fId, index) => {
                await cancelFlight(fId, record.cancelledReason, currentUser);
                await handleCancellation(record, currentUser);
            }));
        } else {
            await cancelFlight(record.id, record.cancelledReason, currentUser);
            await handleCancellation(record, currentUser);
        }
        
        utils.openNotification('Removed successfully!', true);

        loadData();
    }

    const editRecordHandler = (record) => {
        const recurringFlight = record &&
            record.recurringRoute !== '' &&
            record.repeatOn &&
            record.repeatOn.length !== 0 &&
            record.endsDate &&
            record.endsDate !== ''
        setActiveRecord(record)
        setModalVisible(true)
        if (!recurringFlight) dispatch(setEditFlightOpen(true))
    }

    const openAddFlightHandler = () => {
        // setAddFlightVisible(true);
        dispatch(setAddFlightOpen(true));
    }

    const closeAddFlightHandler = () => {
        // setAddFlightVisible(false);
        dispatch(setAddFlightOpen(false));

    }

    const openEditFlightHandler = () => {
        dispatch(setEditFlightOpen(true))
    }

    const closeEditFlightHandler = () => {
        dispatch(setEditFlightOpen(false))
        setActiveRecord(null)
        setEditAllOne('')
    }

    const showPassengerHandler = (record) => {
        setActiveRecord(record)
        setIsPassengerInfoVisible(true)
    }

    const handleOk = () => {
        setActiveRecord()
        setIsPassengerInfoVisible(false)
    }

    const onModalClose = () => {
        // setEditFlightVisible(false);
        dispatch(setEditFlightOpen(false))
        setModalVisible(false);
        setActiveRecord(null);
        setEditAllOne('');
    }

    const handleOkModal = () => {
        setModalVisible(false);
    }

    const flightColumns = [
        {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            render: (text, record) => <Space>
                <Tooltip
                    className="fly-tooltip"
                    title={<p>{text === "done"? "Verified": "Please add a verified plane & pilot for a verified flight"}</p>}
                    color="#90C4C2"
                    placement="left"
                >
                    <ReactSVG src="/images/check.svg" className={`label-icon check-icon ${text === "done"? "active": "inactive"}`} />
                </Tooltip>
            </Space>,
        }, {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (text, record) => <Space>
                <Tooltip className="fly-tooltip" title={<p>{text? 'Public': 'Private'}</p>} color="#90C4C2" placement="left">
                    <ReactSVG src={`/images/${text? 'eye': 'closed-eye'}.svg`} className="label-icon" />
                </Tooltip>
            </Space>,
        }, {
            title: 'Flight Number',
            dataIndex: 'number',
            key: 'number',
            render: (text, record) => <Space>
                <ReactSVG src="/images/fleet.svg" className="label-icon" />
                <p>{text}</p>
            </Space>,
            width: '12%'
        }, {
            title: 'Available Seats',
            dataIndex: 'leftSeats',
            key: 'leftSeats',
            render: (text, record) => <Space>
                <ReactSVG src="/images/seat.svg" className="label-icon" />
                <p>{text}</p>
            </Space>,
        }, {
            title: 'Departure Date/Time',
            dataIndex: 'departure',
            key: 'departure',
            render: (text, record) =><Space>
                <ReactSVG src="/images/time.svg" className="label-icon" />
                <p className="mr-1">{record.departureDate} <span style={{ whiteSpace: 'nowrap' }}>{record.departureTime}</span></p>
            </Space>
        ,
        }, {
            title: 'Departure Airport',
            dataIndex: 'departureAirport',
            key: 'departureAirport',
            render: (text, record) => <Space>
                <ReactSVG src="/images/location.svg" className="label-icon" />
                    {text
                        ? <p>{text}</p>
                        : (
                            record?.pickupInstruction
                                ? (
                                    <p>
                                        {record.pickupInstruction}
                                    </p>
                                )
                                : (
                                    <div>
                                        <p style={{
                                            fontSize: '12px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginBottom: '2px'
                                        }}>
                                            <p style={{
                                                opacity: 0.4,
                                                marginRight: '3px',
                                                fontSize: '12px',
                                                whiteSpace: 'nowrap'
                                            }}>Lat:</p>
                                            {record.departureLocation?.latitude}
                                        </p>
                                        <p style={{fontSize: '12px', display: 'flex', alignItems: 'center'}}>
                                            <p style={{
                                                opacity: 0.4,
                                                marginRight: '3px',
                                                fontSize: '12px',
                                                whiteSpace: 'nowrap'
                                            }}>Lon:</p>
                                            {record.departureLocation?.longitude}
                                        </p>
                                    </div>
                                )
                        )}
            </Space>,
        }, {
            title: 'Arrival Airport',
            dataIndex: 'arrivalAirport',
            key: 'arrivalAirport',
            render: (text, record) => <Space>
                <ReactSVG src="/images/location.svg" className="label-icon"/>
                {text
                    ? <p>{text}</p>
                    : (
                        <div>
                            <p style={{fontSize: '12px', display: 'flex', alignItems: 'center', marginBottom: '2px'}}>
                                <p style={{
                                    opacity: 0.4,
                                    marginRight: '3px',
                                    fontSize: '12px',
                                    whiteSpace: 'nowrap'
                                }}>Lat:</p>
                                {record.arrivalLocation?.latitude}
                            </p>
                            <p style={{ fontSize: '12px', display: 'flex', alignItems: 'center' }}>
                                <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Lon:</p>
                                {record.arrivalLocation?.longitude}
                            </p>
                        </div>
                  )
                }
            </Space>,
        }, {
            title: 'Tail number',
            dataIndex: 'tailNumber',
            key: 'tailNumber',
            render: (text, record) => <Space>
                <ReactSVG src="/images/plane.svg" className="label-icon" />
                <p>{text || <div className="blank-icon"></div>}</p>
            </Space>,
        }, {
            title: 'Pilot',
            dataIndex: 'pilotName',
            key: 'pilotName',
            render: (text, record) => <Space>
                <ReactSVG src="/images/captain.svg" className="label-icon" />
                <p>{text || <div className="blank-icon"></div>}</p>
            </Space>
        }
    ]

    let columns = Object.assign([], flightColumns)
    columns.push({
        title: 'Flight status',
        dataIndex: 'status',
        key: 'flightStatus',
        width: '6%',
        render: (text, record) => <Space>
            { text === 'accepted' && <a className="status-btn success">Accepted</a> }
            { text === 'waiting' && <a className="status-btn warning">Waiting</a> }
            { text === 'declined' && <a className="status-btn danger">Declined</a> }
            <Tooltip className="fly-tooltip" title={<p>{text}</p>} color="#90C4C2" placement="left">
                <ReactSVG src={`/images/${text.toLowerCase()}.svg`} className={`status-icon ${text !== 'Open'? "inactive": ""}`} />
            </Tooltip>
        </Space>
    })
    columns.push({
        title: 'Passenger status',
        dataIndex: 'status',
        key: 'passengerStatus',
        width: '9%',
        render: (text, record) => <Space>
            { text === 'Closed'?
                    record.rTitle === "Check-In"?
                        <a className="extra-txt ml-1" onClick={() => setActiveFlight(record)}>
                            {record.checkinRequests.length} {record.rTitle}{record.checkinRequests.length > 1 && 's'}
                        </a>
                    :
                        <a className="extra-txt ml-1" onClick={() => setActiveFlight(record)}>
                            {record.rTitle}
                        </a>
                :
                <a className="extra-txt ml-1" onClick={() => setActiveFlight(record)}>
                    {record.requests.length} {record.rTitle || 'Request'}{record.requests.length > 1 && 's'}
                </a>
            }
        </Space>
    })
    columns.push({
        title: '',
        render: (text, record) => <Space>
            <ReactSVG src="/images/edit.svg" className="action-icon" onClick={() => editRecordHandler(record)}/>
            <ReactSVG src="/images/delete.svg" className="action-icon" onClick={() => removeModal(record)} />
        </Space>
    })

    let previousColumns = flightColumns.map(col => {
        if (col.title === 'Flight Number') {
            return {
                title: 'Flight Number',
                dataIndex: 'number',
                key: 'number',
                render: (text, record) => <Space>
                    <ReactSVG src="/images/fleet.svg" className="label-icon" />
                    <a className="extra-txt" onClick={() => showPassengerHandler(record)}>{text}</a>
                </Space>,
                width: '12%'
            }
        }
        else
            return col
    })
    previousColumns.push({
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => <Space>
            { text === 'Completed'? <a className="status-btn success">Complete</a>
                :
                <a className="status-btn danger">Cancelled</a>
            }
        </Space>
    })

    if (activeFlight)
        return <Requests activeFlight={activeFlight}
            setActiveFlight={setActiveFlight}
            flightColumns={flightColumns}
            loadData={loadData}
            fleets={fleets}
            currentUser={currentUser}
        />

    let departureAirportList =
        utils.resources.airports[utils.resources.countryContraction[departureCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== arrivalAirport)
    let arrivalAirportList =
        utils.resources.airports[utils.resources.countryContraction[arrivalCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== departureAirport)

    const onSubmitFilters = (values) => {
        setLoading(true)
        let filtersData = Object.assign({}, values)
        Object.keys(values).forEach(key => {
            if (filtersData[key] === undefined) filtersData[key] = ''
        })
        if (filtersData.departureDate && filtersData.departureDate !== '')
            filtersData.departureDate = filtersData.departureDate.format('MM/DD/YYYY')

        ApiManager.pilotFlights({ uid: currentUser.uid, filtersData }).then(res => {
            setUpFlights(res.data.body.upFlights)
            setPrevFlights(res.data.body.prevFlights)
            setLoading(false)
        }).catch(err => {
            utils.openNotification('Something went wrong. Contact with the administrator', false)
            setLoading(false)
        })
    }
    
    const saveExcelFile = async (name) => {
        const data = generateFlightData(activeTab === '1' ? upFlights : prevFlights);
        const buffer = await writeXlsxFile(data, {
            columns: [
                { width: 15 },
                { width: 15 },
                { width: 15 },
                { width: 40 },
                { width: 40 },
                { width: 15 },
                { width: 20 },
                { width: 20 },
                { width: 20 },
                { width: 10 },
                { width: 15 },
                { width: 10 },
                { width: 15 },
            ],
            buffer: true
        })
        
        saveByteArray(`${name}.xlsx`, buffer, 'application/xlsx');
    }

    return (
        <div className="content flights-board">
            <div className="content-header">
                <h2 className="mb-0">Upcoming Departures</h2>
                <Button type="primary" className="fly-btn" onClick={openAddFlightHandler}>ADD FLIGHT</Button>
            </div>
            <div className="fly-form">
                <Form
                    name='formFilters'
                    id='formFilters'
                    layout='vertical'
                    form={formFilters}
                    onFinish={onSubmitFilters}
                    requiredMark={true}
                >
                    <div className='form-content' style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
                        <Row className="form-tabs pt-3">
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Departure Country" name='departureCountry'>
                                    <Select
                                        placeholder="Pick one"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.defaultCountries}
                                        allowClear
                                        onChange={(value) => {
                                            setDepartureCountry(value);
                                            formFilters.setFieldValue('departureAirport', undefined);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Departure Airport" name='departureAirport'>
                                    <Select
                                        placeholder="Pick one"
                                        className="fly-select"
                                        showSearch={true}
                                        options={departureAirportList}
                                        allowClear
                                        onChange={(value) => setDepartureAirport(value)}
                                        disabled={!departureCountry}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Arrival Country" name='arrivalCountry'>
                                    <Select
                                        placeholder="Pick one"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.defaultCountries}
                                        allowClear
                                        onChange={(value) => {
                                            setArrivalCountry(value);
                                            formFilters.setFieldValue('arrivalAirport', undefined);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Arrival Airport" name='arrivalAirport'>
                                    <Select
                                        placeholder="Pick one"
                                        className="fly-select"
                                        showSearch={true}
                                        options={arrivalAirportList}
                                        allowClear
                                        onChange={(value) => setArrivalAirport(value)}
                                        disabled={!arrivalCountry}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form-tabs">
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Date" name='departureDate'>
                                    <DatePicker
                                        format="MM/DD/YYYY"
                                        placeholder="MM/DD/YYYY"
                                        className="fly-date-picker"
                                        defaultPickerValue={utils.getToday()}
                                        showToday={true}
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Planes" name='planes'>
                                    <Select
                                        placeholder="All"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.filterPlaneTypes}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Number of Available Seats" name='availableSeats'>
                                    <Select
                                        placeholder="Pick one"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.filterAvailableNumbers}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Captain Name" name='captainName'>
                                    <Select
                                        placeholder="Pick one"
                                        className="fly-select"
                                        showSearch={true}
                                        options={pilots}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="form-tabs">
                            <Col style={{ width: '25%' }} className='pr-2'>
                                <Form.Item label="Status" name='status'>
                                    <Select
                                        placeholder="All"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.flightFilterStatuses}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ justifyContent: 'end', paddingBottom: '20px' }} className='form-tabs mr-1'>
                            <Button type="primary" htmlType='submit' className="fly-btn">FILTER</Button>
                        </Row>
                    </div>
                </Form>
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                >
                    <div className="form-content" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                        <Tooltip className="fly-tooltip" title={<p>Download flights list in .xlsx</p>} color="#90C4C2" placement="left">
                            <DownloadOutlined
                                onClick={() => {
                                    saveExcelFile(
                                        `${activeTab === '1' ? 'Upcoming' : 'Previous'} flights ${moment().toDate()}`
                                    );
                                }}
                                className='downloadButton'
                            />
                        </Tooltip>
                        <Tabs defaultActiveKey="1" className="form-tabs" onChange={(value) => setActiveTab(value)}>
                            <TabPane tab="Upcoming flights" key="1">
                                <Table
                                    columns={columns}
                                    dataSource={upFlights}
                                    key="upcoming"
                                    pagination={pagination}
                                    loading={loading}
                                />
                            </TabPane>
                            <TabPane tab="Previous flights" key="2">
                                <Table
                                    columns={previousColumns}
                                    dataSource={prevFlights}
                                    key="previous"
                                    pagination={pagination}
                                    loading={loading}
                                />
                                {isPassengerInfoVisible &&
                                    <PassengerInfoModal
                                        isModalVisible={isPassengerInfoVisible}
                                        handleCancel={handleOk}
                                        handleOk={handleOk}
                                        activeRecord={activeRecord}
                                        currentUser={currentUser}
                                    />
                                }
                            </TabPane>
                        </Tabs>
                    </div>
                </Form>
            </div>
            {editFlightOpen && <AddFlightDrawer
                drawerVisible={editFlightOpen}
                openDrawerHandler={openEditFlightHandler}
                closeDrawerHandler={closeEditFlightHandler}
                currentUser={currentUser}
                loadData={loadData}
                activeRecord={activeRecord}
                editAllOne={editAllOne}
                fleets={fleets}
                pilots={pilots}
                config={config}
            />}
            {addFlightOpen && <AddFlightDrawer
                drawerVisible={addFlightOpen}
                openDrawerHandler={openAddFlightHandler}
                closeDrawerHandler={closeAddFlightHandler}
                currentUser={currentUser}
                loadData={loadData}
                fleets={fleets}
                pilots={pilots}
                config={config}
            />}
            {modalVisible &&
                activeRecord &&
                (activeRecord.recurringRoute !== '' &&
                    activeRecord.repeatOn &&
                    activeRecord.repeatOn.length !== 0 &&
                    activeRecord.endsDate &&
                    activeRecord.endsDate !== '') && (
                <Modal
                    title=""
                    visible={true}
                    onOk={handleOkModal}
                    onCancel={onModalClose}
                    width='56.25rem'
                    className="fly-modal content request-details-modal"
                    footer={[]}
                    destroyOnClose={true}
                    maskClosable={true}
                    closable={true}
                >
                    <div className="fly-form auth-form">
                        <DeleteEditForm
                            type={'edit'}
                            handleOk={handleOkModal}
                            setEditAllOne={setEditAllOne}
                            handleEditFlightOpen={() => dispatch(setEditFlightOpen(true))}
                        />
                    </div>
                </Modal>
            )}
        </div>
    )
}

export default Flights
