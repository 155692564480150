import React from 'react'
import { Row, Col, Button} from 'antd'

function VerifiedEmail(props) {
    const {history, newUser, setStep, currentUser} = props

    if (currentUser) {
        setStep(3);
    }

    return (
        <div className="client-body b-gray height-100-percent">
            <Row className="t-center">
                <Col span={24}>
                    <img alt='' src={`/images/success.png`}/>
                    <h3 className="mt-3">Your account was verified successfully!</h3>
                    <p className="fly-notes mt-2 t-center">Please continue creating your account.</p>
                </Col>
            </Row>

            <Row>
                <Col span={24}>
                    <Button type="primary fly-btn" className="fly-btn w-full" onClick={() => setStep(2)}>
                        Continue
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default VerifiedEmail
