import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router-dom"
import { ReactSVG } from 'react-svg'
import Step1 from './Step1'
import Step3 from './Step3'
import SetPassword from './SetPassword'
import SentEmail from './SentEmail'

function PasswordRecovery(props) {
    const {history, currentUser, newUser, setNewUser} = props
    const [step, setStep] = useState(1)

    const params = {
        history, currentUser,
        newUser, setNewUser,
        setStep
    }

    useEffect(() => {
        let params = new URLSearchParams(history.location.search)
        let actionCode = params.get('actionCode')
        if (actionCode) {
            setStep('ChangePassword')
        }
    }, [])

    const prevStepHandler = () => {
        if (step > 1)
            setStep(step-1)
        if (step === 1)
            history.push('/client/auth/login')
    }

    let renderPage = ''
    let headerTitle = 'Change your password'

    if (currentUser && step === 1)
        return <Redirect to="/client/departures" />

    switch (step) {
        case 1:
            renderPage = <Step1 {...params} />
            break
        case 2:
            renderPage = <SentEmail {...params} />
            break
        case 'ChangePassword':
            renderPage = <Step3 {...params} />
            break
        case 'SetPassword':
            renderPage = <SetPassword {...params} />
            headerTitle = ''
            break
        default:
            renderPage = <Step1 {...params} />
    }

    return (
        <div className="content auth-page auth-page-mobile">
            <div className="client-header b-gray">
                <div className="header-item">
                    {typeof(step) == 'number'
                        ? <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>
                        : <div className="step-marker"></div>}
                    <p>{headerTitle}</p>
                    <div className="step-marker">
                    </div>
                </div>
            </div>
            { renderPage }
        </div>
    )
}

export default PasswordRecovery
