import React from 'react'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import { notification } from "antd"
import _ from "lodash"
import resources from "./resources"
import firebase from "firebase/app"
import ApiManager from '../service';
import { firestore } from '../firebaseConfig';

export const devPilotDomain = 'https://dev.pilot.flymingo.io'
export const devClientDomain = 'https://dev.app.flymingo.io'
export const prodPilotDomain = 'https://pilot.flymingo.io'
export const prodClientDomain = 'https://app.flymingo.io'
export const openNotification = (message, status=true) => {
    if (status) notification.success({ message: message, placement: 'topRight'})
    else notification.error({ message: message, placement: 'topRight'})
}
export const defaultDate = moment(new Date('01/01/1990'))
export const getDate = value => moment(new Date(value))
export const getToday = value => moment(new Date())
export const getTomorrow = value => moment(new Date()).add(1, 'days')
export const getSpecificDate = value => moment(new Date()).add(value, 'days')
export const getYesterday = value => moment(new Date()).add(-1, 'days')
export const getDefaultExpirationDate = moment(new Date()).add(1, 'years')
export const getNumbers = value => _.times(value, (i) => { return {label: i+1, value: i+1} })
export const disabledDate = (current) => {return current && current < moment(new Date())}
export const disabledDateWithoutToday = (current) => {return current && current <= new Date().setHours(0, 0, 0, 0)}
export const disabledTomorrow = (current) => {return current && current < moment(new Date()).add(1, 'days')}
export const getScheduledDate = () => {
    let today = new Date()
    let date = `${today.getMonth() + 2}/01/${today.getFullYear()}`
    if (today.getDate() < 15)
        date = `${today.getMonth() + 1}/15/${today.getFullYear()}`
    return moment(new Date(date)).format("MM/DD/YY")
}
export const formatDate = value => value? value.format('L'): ''
export const formatTime = (value) => {
    if (typeof(value) === 'string')
        return value
    return value? value.format('LT'): ''
}
export const formatDate2 = (datetime) => {
    if (datetime === '' || datetime == null)
      return ''
    else
      return moment(datetime.seconds * 1000).format("MM/DD/YYYY");
}
export const formatDateTime = (datetime) => {
    let dsec = datetime.seconds || datetime._seconds
    if (datetime === '' || datetime == null)
      return ''
    else
      return moment(dsec * 1000).format("MM/DD/YYYY hh:mm");
}
export const delay = (delayInms) => {
    return new Promise(resolve => {setTimeout(() => { resolve(1) }, delayInms)})
}
export const getInitialValue = value => (value != null ? value : undefined)
export const generateRandomKeys = () => Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
export const getPlaneType = (plane) => {
    if (!plane)
        return ''
    let planeTypes = resources.planeTypes.filter(item => item.label.toLowerCase() === plane.toLowerCase())
    if (planeTypes.length > 0) return planeTypes[0].value
    else return 'any'
}
export const getPlaneTypeLabel = (plane) => {
    if (!plane)
        return ''
    let planeTypes = resources.planeTypes.filter(item => item.value.toLowerCase() === plane.toLowerCase())
    if (planeTypes.length > 0) return planeTypes[0].label
    else return ''
}
export const itemRender = (current, type, originalElement) => {
    if (type === 'prev') return <a>Previous</a>
    if (type === 'next') return <a>Next</a>
    return originalElement
}
export const generateFlightNumber = (idx) => {
    var zeros = Math.max(0, 4 - Math.floor(idx).toString().length )
    var zeroString = Math.pow(10, zeros).toString().substr(1)
    return 'WN'+zeroString+idx;
}
export const generateRequestNumber = (idx) => {
    var n = Math.abs(idx);
    var zeros = Math.max(0, 4 - Math.floor(n).toString().length )
    var zeroString = Math.pow(10, zeros).toString().substr(1)
    return 'R'+zeroString+n;
}
export const generateFlightRequestNumber = (idx) => {
    const zeros = Math.max(0, 4 - Math.floor(idx).toString().length );
    const zeroString = Math.pow(10, zeros).toString().substr(1);
    return "PR" + zeroString+idx;
};
export const getFirebaseTimeStamp = (datetime) => {
    return firebase.firestore.Timestamp.fromDate(new Date(datetime))
}
export const changeDateFormat = (value) => {
    if (value.length === 1) {
        return `0${value}`;
    }
    return value;
}
export const changeTimeFormat = (value) => {
    if (value.includes('am') || value.includes('pm')) {
        const [time, modifier] = value.split(" ");

        let [hours, minutes] = time.split(":");

        if (hours === "12") {
            hours = "00";
        }

        if (modifier === "pm") {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}`;
    }
    return value;
}
export const convertToEuropeLondonTimeZone = (date, time) => {
    const destructedDepartureDate = date.split('/');
    return momentTZ.tz(
      `${destructedDepartureDate[2]}-${changeDateFormat(destructedDepartureDate[0])}-${changeDateFormat(destructedDepartureDate[1])} ${changeTimeFormat(time)}`,
      "Europe/London"
    )
}
export const convertToBahTimeZone = (date, time, sunset = false) => {
    const destructedDepartureDate = date.split('/');
    return momentTZ.tz(
      `${destructedDepartureDate[2]}-${changeDateFormat(destructedDepartureDate[0])}-${changeDateFormat(destructedDepartureDate[1])} ${changeTimeFormat(time)}`,
      "America/Nassau"
    );
}
export const convertToBahamasTimeZone = (date, time, sunset = false) => {
    const destructedDepartureDate = date.split('/');
    return momentTZ.tz(
        `${destructedDepartureDate[2]}-${changeDateFormat(destructedDepartureDate[0])}-${changeDateFormat(destructedDepartureDate[1])} ${changeTimeFormat(time)}`,
        "America/Nassau"
    ).add(sunset ? 12 : 0, 'hours').toDate();
}
export const getDaysDiff = (datetime) => {
    const currentTime = new Date()
    const diffDays = Math.ceil((datetime - currentTime)/(1000 * 60 * 60 * 24))
    return diffDays
}
export const getHoursDiff = (datetime, flag=true) => {
    const currentTime = new Date()
    let diffHours = Math.ceil((datetime - currentTime)/(1000 * 60 * 60))
    if (!flag)
        diffHours = Math.ceil((currentTime - datetime)/(1000 * 60 * 60))
    return diffHours
}
export const getMinsDiff = (datetime, flag=true) => {
    const currentTime = new Date()
    let diffMins = Math.ceil((datetime - currentTime)/(1000 * 60))
    if (!flag)
        diffMins = Math.ceil((currentTime - datetime)/(1000 * 60))
    return diffMins
}
export const getDaysHoursDiff = (datetime, offset=false) => {
    let currentTime = new Date()
    if (offset)
        currentTime.setDate(currentTime.getDate() + offset);
    const diffTimes = Math.ceil((datetime - currentTime)/(1000 * 60 * 60))
    const diffDays = Math.floor(diffTimes / 24)
    const diffHours = diffTimes % 24
    return `${diffDays}d ${diffHours}h`
}
export const getMaxValue = (array) => {
    let ret = array[0]
    array.map(item => {
        if(item > ret) ret = item
    })
    return parseInt(ret) + 1
}
export const getPaymentTypeLabel = (plane) => {
    if (!plane)
        return ''
    let paymentType = resources.paymentType.filter(item => item.value.toLowerCase() === plane.toLowerCase())
    if (paymentType.length > 0) return paymentType[0].label
    else return ''
}

export const pushDataLayer = (data) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
};

export { default as resources } from "./resources"
export { default as validations } from "./validations"

export const addGTMNoScript = () => {
    if (process.env.REACT_APP_SECRET_CODE === 'Prod') {
        const GTMNoscript = document.createElement('noscript');
        GTMNoscript.innerHTML = '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WHH9NWD" height="0" width="0" style="display:none;visibility:hidden"></iframe>';
        document.body.prepend(GTMNoscript);
    }
}

export const countBags = (passengers) => {
    if (!passengers) return 0;
    return passengers.reduce((res, {numberOfBags = 0}) => res + numberOfBags, 0);
}

export const prepareDays = (days) => {
    let text = '';

    if (days.length === 1) {
        text = resources.repeatOnConvert[days[0]];
    } else {
        days.map((day, index) => {
            if (index === 0) {
                text = resources.repeatOnConvert[day];
            } else {
                text += `, ${resources.repeatOnConvert[day]}`
            }
        })
    }

    return text;
}

export const sendExpoNotificationToUser = async ({ uid, title, content }) => {
    const uPayload = await firestore
      .collection('users')
      .doc(uid).get();
    if (uPayload.exists) {
        const uData = uPayload.data();
        if (uData.pushToken) {
            await ApiManager.sendExpoAppNotification({
                to: uData.pushToken,
                title,
                body: content
            })
        }
    }
}
