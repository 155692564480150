import React, { useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Form, Input, Button, DatePicker, Checkbox, Select, Drawer, Space } from 'antd'
import * as utils from '../../../utils'
import { firestore, serverTimestamp, database } from "../../../firebaseConfig"
import { ApiManager } from "../../../service"
import FlyTimePicker from '../../../components/FlyTimePicker';
import moment from 'moment/moment';
import { prepareDays, resources, sendExpoNotificationToUser } from '../../../utils';
import { FLYMINGO_SERVICES, KANOO_PAYMENT_COUNTRY } from '../../../constants';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { MAP_STYLES } from '../../../utils/mapStyles';
import prepareNumericalValue from '../../../utils/prepareNumericalValue';
import './index.scss'
import { useMediaQuery } from '../../../hooks/useMediaQuery.'
import generateInviteCode from '../../../utils/generateInviteCode';

const MOCK_HOTELS = [
    {
        label: 'Small hope',
        value: 1,
        coords: {
            latitude:24.745960859106333, longitude: -77.80677937479935
        },
    },
    {
        label: 'Cottage',
        value: 2,
        coords: {
            latitude:25.174564226339943, longitude: -78.04805143370176
        },
    }
];

const INIT_WATER_COORDS = {
    'United States': {
        label: '',
        value: null,
        coords: {
            latitude: 27.764498585241228, longitude:-80.25419715668264
        }
    },
    'Bahamas': {
        label: '',
        value: null,
        coords: {
            latitude: 24.4556528, longitude: -78.098765
        }
    }
}


function AddFlightDrawer(props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState()
    const [tempVal, setTempVal] = useState({
        recurringRoute: [],
        waterTakeoff:[],
        waterLanding:[],
        availableSeats: 0,
        pricePerPerson: 0
    })
    const [selectedDate, setSelectedDate] = useState()
    const [endDate, setEndDate] = useState()
    const [repeatOn, setRepeatOn] = useState([])
    const [pilot, setPilot] = useState()
    const [plane, setPlane] = useState()
    const [departureAirport, setDepartureAirport] = useState('')
    const [arrivalAirport, setArrivalAirport] = useState('')
    const [departureCountry, setDepartureCountry] = useState('')
    const [arrivalCountry, setArrivalCountry] = useState('')
    const [requiredFields, setRequiredFields] = useState([
        'departureDate', 'departureTime', 'availableSeats', 'pricePerPerson', 'pricePerBag'
    ]);
    const [departureLocation, setDepartureLocation] = useState(null);
    const [arrivalLocation, setArrivalLocation] = useState(null);
    const {drawerVisible, closeDrawerHandler, activeRecord,
        currentUser, loadData, fleets, pilots, editAllOne, config} = props;
    const isMobile = useMediaQuery('(max-width: 768px)');
    
    const filteredFleets = useMemo(() => {
        if(tempVal?.waterTakeoff?.length > 0 || tempVal?.waterLanding?.length > 0) {
            return fleets.filter(fleet => fleet.planeType === 'Seaplane');
        } else {
            return fleets
        }
    }, [tempVal.waterTakeoff, tempVal.waterLanding, fleets]);
    
    useEffect(() => {
        if (selectedDate && form.getFieldValue('departureTime')) form.validateFields(['departureTime'])
    }, [selectedDate])
    
    useEffect(() => {
        if (activeRecord) {
            setDepartureCountry(activeRecord.departureCountry || KANOO_PAYMENT_COUNTRY);
            setArrivalCountry(activeRecord.arrivalCountry || KANOO_PAYMENT_COUNTRY);
        }
    }, [activeRecord])
    
    
    useEffect(() => {
        if(tempVal?.waterTakeoff?.length > 0) {
            form.validateFields(['latitude', 'longitude']);
        }
        if(tempVal?.waterLanding?.length > 0) {
            form.validateFields(['arrivalLatitude', 'arrivalLongitude']);
        }
    }, [tempVal.waterTakeoff, tempVal.waterLanding])
    
    const difference = (obj1, obj2) => {
        console.log({ obj1, obj2 });
        let prevChanges = [];
        let newChanges = [];
        
        Object.keys(obj1).forEach(key => {
            if (['recurringRoute', 'createdAt', 'potentialEarnings', 'departureAt'].indexOf(key) === -1 && obj1[key] !== obj2[key]) {
                let newVal = obj1[key];
                let prevVal = obj2[key];
                
                if (key === 'plane' && obj2[key]) {
                    let plane = fleets.find(fleet => fleet.id === obj1[key]);
                    newVal = plane?.tailNumber || '';
                    plane = fleets.find(fleet => fleet.id === obj2[key]);
                    prevVal = plane?.tailNumber || '';
                }
                
                if (key === 'pilot') {
                    let newPilot = pilots.find(pilot => pilot.id === obj1[key]);
                    newVal = `${newPilot?.firstname || ''} ${newPilot?.lastname || ''}`.trim() || 'Unknown Pilot';
                    
                    let prevPilot = pilots.find(pilot => pilot.id === obj2[key]);
                    prevVal = obj2[key]
                        ? `${prevPilot?.firstname || ''} ${prevPilot?.lastname || ''}`.trim() || 'Unknown Pilot'
                        : 'No Pilot Assigned'; // Default for null/undefined pilot
                }
                
                newChanges.push({ [key]: newVal });
                prevChanges.push({ [key]: prevVal });
            }
        });
        
        console.log({ prevChanges, newChanges });
        return { prevChanges, newChanges };
    };
    
    const onSubmitHandler = async (values) => {
        setLoading(true);
        const {latitude, longitude, arrivalLatitude, arrivalLongitude, ...correctValues} = values;
        
        let fData = Object.assign({}, correctValues);
        
        fData.uid = currentUser.uid
        fData.createdAt = serverTimestamp
        Object.keys(correctValues).forEach(key => {
            if (fData[key] === undefined) fData[key] = ''
        })
        fData.recurringRoute = !!(fData.recurringRoute !== '' && fData.repeatOn &&
            fData.repeatOn.length !== 0 && fData.endsDate && fData.endsDate !== '');
        if (fData.endsDate && fData.endsDate !== '')
            fData.endsDate = fData.endsDate.format('MM/DD/YYYY')
        if (fData.departureTime && fData.departureTime !== '')
            fData.departureTime = fData.departureTime.format('hh:mm a')
        
        let flightsSnapshot = await database.ref('/counter/flights').once('value')
        
        if (fData.recurringRoute && editAllOne !== 'one') {
            if (activeRecord) {
                // edit recurring flight
                if (editAllOne === 'all') {
                    const fSnapshot = await firestore.collection('flights')
                        .where('recurringId', '==', activeRecord.recurringId).get()
                    const flightsData = [];
                    if (!fSnapshot.empty) {
                        fSnapshot.forEach(doc => {
                            const snapshotData = doc.data();
                            if (!snapshotData.cancelled) flightsData.push({ ...snapshotData, id: doc.id })
                        })
                    }
                    
                    if (fData.departureDate && fData.departureDate !== '')
                        fData.departureDate = fData.departureDate.format('MM/DD/YYYY')
                    
                    const departureDates = [];
                    const usedDepartureDates = flightsData.map((data) => data.departureDate);

                    await Promise.all(flightsData.map(async (flight, index) => {
                        let { prevChanges, newChanges } = difference(fData, { ...flight, departureTime: flight?.departureTime?.toLowerCase() })
                        
                        const newFData = {}
                        
                        newChanges.map(val => newFData[Object.keys(val)[0]] = Object.values(val)[0])
                        delete newFData.departureDate
                        
                        newFData.potentialEarnings = Math.round(tempVal.pricePerPerson * tempVal.availableSeats * (config.FLYMINGO_SERVICES || FLYMINGO_SERVICES))
                        
                        if ((activeRecord.pilot == null || activeRecord.plane == null) && fData.pilot && fData.plane) {
                            newFData.verified = 'pending'
                            newFData.reason = ''
                            newFData.expiredDate = ''
                        }
                        
                        newFData.pilot = fData.pilot
                        newFData.plane = fData.plane

                        newFData.departureLocation = fData?.waterTakeoff?.length > 0 ? {
                            longitude,
                            latitude
                        } : '';
                        
                        newFData.arrivalLocation = fData?.waterLanding?.length > 0 ? {
                            longitude: arrivalLongitude,
                            latitude: arrivalLatitude
                        } : '';

                        if (newFData.departureTime) {
                            newFData.departureAt = utils.convertToBahamasTimeZone(flight.departureDate, newFData.departureTime, false)
                        }
                        
                        const firstFlightConvertedDay = moment(flight.departureDate);
                        
                        if (newFData.endsDate || (JSON.stringify(newFData.repeatOn) !== flight.repeatOn)) {
                            if (!newFData.repeatOn.includes(firstFlightConvertedDay.format('dddd'))) {
                                const dayFormat = firstFlightConvertedDay.format('MM/DD/YYYY');
                                console.log({ num: 1, departureDates, dayFormat, usedDepartureDates})
                                !departureDates.includes(dayFormat) && !usedDepartureDates.includes(dayFormat) && departureDates.push(dayFormat);
                            }
                            
                            for (let i = 0; i <= (Math.ceil(moment(newFData.endsDate || flight.endsDate).diff(moment(flight.departureDate), 'hours') / 24)); i++) {
                                const convertedDate = moment(flight.departureDate).add(i, 'days');
                                
                                if (newFData.repeatOn.includes(convertedDate.format('dddd'))) {
                                    const dayFormat = convertedDate.format('MM/DD/YYYY');
                                    console.log({ num: 2, departureDates, dayFormat, usedDepartureDates })
                                    !departureDates.includes(dayFormat) && !usedDepartureDates.includes(dayFormat) && departureDates.push(dayFormat)
                                }
                            }
                        }
                        
                        const rSnapshot = await firestore.collection('flights').doc(flight.id).collection('requests').get();
                        
                        let requestsData = []
                        if (!rSnapshot.empty) {
                            rSnapshot.forEach(doc => {
                                requestsData.push({ ...doc.data(), id: doc.id })
                            })
                        }
                        
                        await Promise.all(
                            requestsData.map(async request => {
                                const dataToUpdate = {
                                    arrivalAirport: fData.arrivalAirport,
                                    arrivalCountry: fData.arrivalCountry,
                                    departureAirport: fData.departureAirport,
                                    departureCountry: fData.departureCountry,
                                    pickupInstruction: fData?.pickupInstruction ? fData.pickupInstruction.trim() : '',
                                    departureDate: fData.departureDate,
                                    departureTime: fData.departureTime,
                                    planeType: fleets.find(fleet => fleet.id === plane)?.planeType || ''
                                }
                                
                                if (newFData.departureAt) {
                                    dataToUpdate.departureAt = newFData.departureAt;
                                }
                                
                                if (fData?.waterTakeoff?.length > 0) {
                                    dataToUpdate.departureLocation = newFData.departureLocation;
                                }
                                
                                if (fData?.waterLanding?.length > 0) {
                                    dataToUpdate.arrivalLocation = newFData.arrivalLocation;
                                }
                                
                                await firestore.collection('requests').doc(request.rid).update(dataToUpdate);
                                
                                if (request.payment && request.status !== 'cancelled') {
                                    const emailPromise = ApiManager.sendEmail({
                                        type: "flightChanged",
                                        uid: request.uid,
                                        changedFlight: {
                                            flightNumber: flight.number,
                                            arrivalAirport: flight.arrivalAirport,
                                            prevChanges: prevChanges,
                                            newChanges: newChanges
                                        }
                                    });
                                    
                                    const title = 'Your Scheduled Charter Has An Update';
                                    const content = 'Your Scheduled Charter Has An Update, please view your flight details by clicking this notification.';
                                    
                                    const notificationPromise = ApiManager.addNotification({
                                        uid: request.uid,
                                        type: 'flightChanged',
                                        title,
                                        content,
                                        icon: 'alert',
                                        fid: flight.id,
                                        showInApp: true
                                    });
                                    
                                    const expoNotificationPromise = sendExpoNotificationToUser({
                                        uid: request.uid, title, content, data: { type: 'flightChanged', fid: flight.id }
                                    });
                                    
                                    await Promise.all([emailPromise, notificationPromise, expoNotificationPromise]);
                                }
                            })
                        );
                        
                        if (!newFData.repeatOn.includes(firstFlightConvertedDay.format('dddd')) ||
                            (+new Date(newFData.endsDate) < +new Date(flight.departureDate) && newFData.endsDate !== flight.departureDate)) {
                            
                            await firestore.collection('flights').doc(flight.id).update({
                                recurringId: null,
                                recurringRoute: false,
                                repeatOn: [],
                                endsDate: fData.departureDate
                            });
                        } else {
                            await firestore.collection('flights').doc(flight.id).update(newFData)
                                .then(async () => {
                                    utils.pushDataLayer({event: 'edit_flight'});
                                    
                                    if (newFData.departureTime !== activeRecord.departureTime) {
                                        const rSnapshot = await firestore.collection('flights')
                                            .doc(flight.id).collection('requests').get();
                                        
                                        let requestsData = []
                                        if (!rSnapshot.empty) {
                                            rSnapshot.forEach(doc => {
                                                requestsData.push({ ...doc.data(), id: doc.id })
                                            })
                                        }
                                        
                                        requestsData.map(async (rData) => {
                                            const sSnapshot = await firestore.collection('schedules')
                                                .where('fid', '==', flight.id)
                                                .where('rid', '==', rData.id).get();
                                            
                                            if (rData?.payment) {
                                                await firestore.collection('transactions').doc(rData.payment).update({
                                                    departureAt: newFData.departureAt
                                                })
                                            }
                                            
                                            if (!sSnapshot.empty) {
                                                sSnapshot.forEach(doc => {
                                                    firestore.collection('schedules').doc(doc.id).delete();
                                                })
                                                
                                                ApiManager.scheduleRequest({fid: flight.id, rid: rData.id})
                                            }
                                        })
                                    }
                                })
                        }
                    }))

                    await Promise.all(departureDates.map(async (date, index) => {
                        const newFData = {...fData};
                        newFData.departureDate = date
                        newFData.departureAt = utils.convertToBahamasTimeZone(date, fData.departureTime, false)
                        let nextCounter = flightsSnapshot.val() + index + 1
                        await database.ref('counter/flights').set(nextCounter)
                        newFData.number = utils.generateFlightNumber(nextCounter)
                        if (newFData.pilot && newFData.plane) {
                            newFData.verified = 'pending'
                            newFData.reason = ''
                            newFData.expiredDate = ''
                        }
                        newFData.recurringId = activeRecord.recurringId
                        newFData.public = true
                        newFData.bookedSeats = 0
                        newFData.publicInviteCode = generateInviteCode();
                        newFData.createdByPilot = true;
                        const pSnapshot = await firestore.collection('flights').add(newFData)
                        currentUser.profile.flights.push(pSnapshot.id)
                        currentUser.profile.upcomingFlights.push(pSnapshot.id)
                        utils.pushDataLayer({event: 'add_flight'})
                        ApiManager.sendSlackAlert({
                            text: `🛫 flight added \nnumber: ${newFData?.number || ''} \n${resources.airports.index[newFData?.departureAirport]?.code || `${newFData?.departureLocation?.latitude},${newFData?.departureLocation?.longitude}`} - ${resources.airports.index[newFData?.arrivalAirport]?.code || `${newFData?.arrivalLocation?.latitude},${newFData?.arrivalLocation?.longitude}`} \nDeparture date: ${newFData?.departureDate || ''} \nDeparture time: ${newFData?.departureTime || ''}`
                        });
                    }))
                    
                    if ((activeRecord.pilot == null || activeRecord.plane == null) && fData.pilot && fData.plane) {
                        ApiManager.sendEmail({ type: "multipleScheduledFlightConfirmed", uid: currentUser.uid })
                    }
                } else {
                    fData.recurringId = utils.generateRandomKeys()
                    
                    const departureDates = [];
                    
                    const firstFlightConvertedDay = moment(fData.departureDate);
                    if (!fData.repeatOn.includes(firstFlightConvertedDay.format('dddd'))) {
                        departureDates.push(firstFlightConvertedDay.format('MM/DD/YYYY'))
                    }
                    
                    for (let i = 0; i <= (Math.ceil(moment(fData.endsDate).diff(moment(fData.departureDate), 'hours') / 24)); i++) {
                        const convertedDate = moment(fData.departureDate).add(i, 'days');
                        
                        if (fData.repeatOn.includes(convertedDate.format('dddd'))) {
                            departureDates.push(convertedDate.format('MM/DD/YYYY'))
                        }
                    }
                    
                    await Promise.all(departureDates.map(async (date, index) => {
                        const newFData = {...fData};
                        newFData.departureDate = date
                        newFData.departureAt = utils.convertToBahamasTimeZone(date, fData.departureTime, false)
                        let nextCounter = flightsSnapshot.val() + index + 1
                        await database.ref('counter/flights').set(nextCounter)
                        newFData.number = utils.generateFlightNumber(nextCounter)
                        if (newFData.pilot && newFData.plane) {
                            newFData.verified = 'pending'
                            newFData.reason = ''
                            newFData.expiredDate = ''
                        }
                        newFData.public = true
                        newFData.bookedSeats = 0
                        
                        if (date === firstFlightConvertedDay.format('MM/DD/YYYY')) {
                            await firestore.collection('flights').doc(activeRecord.id).update(newFData);
                        } else {
                            newFData.publicInviteCode = generateInviteCode();
                            newFData.createdByPilot = true;
                            const pSnapshot = await firestore.collection('flights').add(newFData)
                            currentUser.profile.flights.push(pSnapshot.id)
                            currentUser.profile.upcomingFlights.push(pSnapshot.id)
                            utils.pushDataLayer({event: 'add_flight'});
                            ApiManager.sendSlackAlert({
                                text: `🛫 flight added \nnumber: ${newFData?.number || ''} \n${resources.airports.index[newFData?.departureAirport]?.code || `${newFData?.departureLocation?.latitude},${newFData?.departureLocation?.longitude}`} - ${resources.airports.index[newFData?.arrivalAirport]?.code || `${newFData?.arrivalLocation?.latitude},${newFData?.arrivalLocation?.longitude}`} \nDeparture date: ${newFData?.departureDate || ''} \nDeparture time: ${newFData?.departureTime || ''}`
                            });
                        }
                    }))
                    
                    let usersData = [];
                    const uSnapshot = await firestore.collection('users')
                        .where('role', '==', 'Client')
                        .get();
                    
                    if (!uSnapshot.empty) {
                        uSnapshot.forEach(doc => {
                            let uItem = doc.data();
                            if (uItem.preferedIslands &&
                                uItem.homeBase &&
                                uItem.preferedIslands.find(island =>
                                    island.label === utils.resources.airports.index[fData?.arrivalAirport]?.place) &&
                                uItem.homeBase.id === fData?.departureAirport) {
                                uItem.id = doc.id;
                                usersData.push(uItem);
                            }
                        })
                    }
                    
                    usersData.map((user) => {
                        if (user?.scheduledFlightsNotifications) {
                            const title = 'New Scheduled Flight Added';
                            const content = `New Scheduled Flight Added to ${utils.resources.airports.index[fData?.arrivalAirport]?.place} is now available: ${prepareDays(fData.repeatOn)} departing at ${fData.departureTime.toUpperCase()}.`;
                            
                            ApiManager.addNotification({
                                uid: user.id,
                                type: 'scheduledFlightAdded',
                                title,
                                content,
                                icon: 'calendar',
                                showInApp: true,
                            })
                            
                            sendExpoNotificationToUser({
                                uid: user.id, title, content, data: { type: 'scheduledFlightAdded' }
                            });
                        }
                    })
                    
                    if (fData.pilot && fData.plane) {
                        ApiManager.sendEmail({ type: "multipleScheduledFlightConfirmed", uid: currentUser.uid })
                    }
                }
            } else {
                // add new recurring flight
                fData.recurringId = utils.generateRandomKeys()
                
                const departureDates = [];
                
                const firstFlightConvertedDay = moment(fData.departureDate);
                if (!fData.repeatOn.includes(firstFlightConvertedDay.format('dddd'))) {
                    departureDates.push(firstFlightConvertedDay.format('MM/DD/YYYY'))
                }
                
                for (let i = 0; i <= (Math.ceil(moment(fData.endsDate).diff(moment(fData.departureDate), 'hours') / 24)); i++) {
                    const convertedDate = moment(fData.departureDate).add(i, 'days');
                    
                    if (fData.repeatOn.includes(convertedDate.format('dddd'))) {
                        departureDates.push(convertedDate.format('MM/DD/YYYY'))
                    }
                }
                
                await Promise.all(departureDates.map(async (date, index) => {
                    const newFData = {...fData};
                    if (newFData?.waterTakeoff?.length > 0) {
                        newFData.departureAirport = '';
                        newFData.departureLocation = {
                            longitude: arrivalLongitude,
                            latitude: arrivalLatitude
                        }
                    }
                    if(newFData?.waterLanding?.length > 0) {
                        newFData.arrivalAirport = '';
                        newFData.arrivalLocation = {
                            longitude: arrivalLongitude,
                            latitude: arrivalLatitude
                        }
                    }
                    newFData.departureDate = date
                    newFData.departureAt = utils.convertToBahamasTimeZone(date, fData.departureTime, false)
                    let nextCounter = flightsSnapshot.val() + index + 1
                    await database.ref('counter/flights').set(nextCounter)
                    newFData.number = utils.generateFlightNumber(nextCounter)
                    if (newFData.pilot && newFData.plane) {
                        newFData.verified = 'pending'
                        newFData.reason = ''
                        newFData.expiredDate = ''
                    }
                    newFData.public = true
                    newFData.bookedSeats = 0
                    newFData.publicInviteCode = generateInviteCode();
                    newFData.createdByPilot = true;
                    const pSnapshot = await firestore.collection('flights').add(newFData)
                    currentUser.profile.flights.push(pSnapshot.id)
                    currentUser.profile.upcomingFlights.push(pSnapshot.id)
                    utils.pushDataLayer({event: 'add_flight'});
                    ApiManager.sendSlackAlert({
                        text: `🛫 flight added \nnumber: ${newFData?.number || ''} \n${resources.airports.index[newFData?.departureAirport]?.code || `${newFData?.departureLocation?.latitude},${newFData?.departureLocation?.longitude}`} - ${resources.airports.index[newFData?.arrivalAirport]?.code || `${newFData?.arrivalLocation?.latitude},${newFData?.arrivalLocation?.longitude}`} \nDeparture date: ${newFData?.departureDate || ''} \nDeparture time: ${newFData?.departureTime || ''}`
                    });
                }))
                
                let usersData = [];
                const uSnapshot = await firestore.collection('users')
                    .where('role', '==', 'Client')
                    .get();
                
                if (!uSnapshot.empty) {
                    uSnapshot.forEach(doc => {
                        let uItem = doc.data();
                        if (uItem.preferedIslands &&
                            uItem.homeBase &&
                            uItem.preferedIslands.find(island =>
                                island.label === utils.resources.airports.index[fData?.arrivalAirport]?.place) &&
                            uItem.homeBase.id === fData?.departureAirport) {
                            uItem.id = doc.id;
                            usersData.push(uItem);
                        }
                    })
                }
                
                usersData.map((user) => {
                    if (user?.scheduledFlightsNotifications) {
                        const title = 'New Scheduled Flight Added';
                        const content = `New Scheduled Flight Added to ${utils.resources.airports.index[fData?.arrivalAirport]?.place} is now available: ${prepareDays(fData.repeatOn)} departing at ${fData.departureTime.toUpperCase()}.`;
                        
                        ApiManager.addNotification({
                            uid: user.id,
                            type: 'scheduledFlightAdded',
                            title,
                            content,
                            icon: 'calendar',
                            showInApp: true,
                        })
                        
                        sendExpoNotificationToUser({
                            uid: user.id, title, content, data: { type: 'scheduledFlightAdded' }
                        });
                    }
                })
                
                if (fData.pilot && fData.plane) {
                    ApiManager.sendEmail({ type: "multipleScheduledFlightConfirmed", uid: currentUser.uid })
                }
            }
        } else {
            if (fData.departureDate && fData.departureDate !== '')
                fData.departureDate = fData.departureDate.format('MM/DD/YYYY')
            fData.potentialEarnings = Math.round(tempVal.pricePerPerson * tempVal.availableSeats * (config.FLYMINGO_SERVICES || FLYMINGO_SERVICES))
            fData.departureAt = utils.convertToBahamasTimeZone(fData.departureDate, fData.departureTime, false)
            if (activeRecord) {
                // edit flight
                
                const {waterTakeoff, waterLanding, departureLocation, arrivalLocation,...dataToCompare} = fData;
                const {waterTakeoff:activeWatertakeoff, waterLanding:activeWaterlanding, departureLocation: activeDepartureLocation, arrivalLocation: activeArrivalLocation,...activeRecordToCompare} = activeRecord;
                
                let {prevChanges, newChanges} = difference(dataToCompare, { ...activeRecordToCompare, departureTime: activeRecord?.departureTime?.toLowerCase() });
                
                if (editAllOne === 'one') {
                    fData.recurringId = null
                    fData.recurringRoute = false
                    fData.repeatOn = []
                    fData.endsDate = fData.departureDate
                }
                
                await Promise.all(
                    activeRecord.requests.map(async request => {
                        await firestore.collection('requests').doc(request.rid).update({
                            arrivalAirport: waterLanding?.length > 0 ? '' : fData.arrivalAirport,
                            departureAirport: waterTakeoff?.length > 0 ? '' : fData.departureAirport,
                            departureLocation: waterTakeoff?.length > 0 ? {
                                longitude,
                                latitude
                            } : '',
                            arrivalLocation: waterLanding?.length > 0 ? {
                                longitude: arrivalLongitude,
                                latitude: arrivalLatitude
                            } : '',
                            arrivalCountry: fData.arrivalCountry,
                            departureCountry: fData.departureCountry,
                            pickupInstruction: fData?.pickupInstruction ? fData.pickupInstruction.trim() : '',
                            departureAt: fData.departureAt,
                            departureDate: fData.departureDate,
                            departureTime: fData.departureTime,
                            planeType: fleets.find(fleet => fleet.id === plane)?.planeType || ''
                        }).then(()=>console.log('upd')).catch((error)=> console.log('upd error', error));
                        
                        if (request.payment && request.status !== 'cancelled') {
                            const emailPromise = ApiManager.sendEmail({
                                type: "flightChanged",
                                uid: request.uid,
                                changedFlight: {
                                    flightNumber: activeRecord.number,
                                    arrivalAirport: activeRecord.arrivalAirport,
                                    prevChanges: prevChanges,
                                    newChanges: newChanges
                                }
                            });
                            
                            const title = 'Your Scheduled Charter Has An Update';
                            const content = 'Your Scheduled Charter Has An Update, please view your flight details by clicking this notification.';
                            
                            const notificationPromise = ApiManager.addNotification({
                                uid: request.uid,
                                type: 'flightChanged',
                                title,
                                content,
                                icon: 'alert',
                                fid: activeRecord.id,
                                showInApp: true
                            });
                            
                            const expoNotificationPromise = sendExpoNotificationToUser({
                                uid: request.uid,
                                title,
                                content,
                                data: { type: 'flightChanged', fid: activeRecord.id }
                            });
                            
                            await Promise.all([emailPromise, notificationPromise, expoNotificationPromise]);
                        }
                    })
                );
                
                if (waterTakeoff?.length > 0) {
                    fData.departureAirport = '';
                    fData.departureLocation = {
                        longitude,
                        latitude
                    };
                }

                if (waterLanding?.length > 0) {
                    fData.arrivalAirport = '';
                    fData.arrivalLocation = {
                        longitude: arrivalLongitude,
                        latitude: arrivalLatitude
                    }
                }
                
                await firestore.collection('flights').doc(activeRecord.id).update(fData)
                    .then(async () => {
                        utils.pushDataLayer({ event: 'edit_flight' });
                        
                        if ((fData.departureDate !== activeRecord.departureDate) || (fData.departureTime !== activeRecord.departureTime)) {
                            const rSnapshot = await firestore.collection('flights')
                                .doc(activeRecord.id).collection('requests').get();
                            
                            let requestsData = []
                            if (!rSnapshot.empty) {
                                rSnapshot.forEach(doc => {
                                    requestsData.push({ ...doc.data(), id: doc.id })
                                })
                            }
                            
                            requestsData.map(async (rData) => {
                                const sSnapshot = await firestore.collection('schedules')
                                    .where('fid', '==', activeRecord.id)
                                    .where('rid', '==', rData.id).get();
                                
                                if (rData?.payment) {
                                    await firestore.collection('transactions').doc(rData.payment).update({
                                        departureAt: fData.departureAt
                                    })
                                }
                                
                                if (!sSnapshot.empty) {
                                    sSnapshot.forEach(doc => {
                                        firestore.collection('schedules').doc(doc.id).delete();
                                    })
                                    
                                    ApiManager.scheduleRequest({fid: activeRecord.id, rid: rData.id})
                                }
                            })
                        }
                    })

                if ((activeRecord.pilot == null || activeRecord.plane == null) && fData.pilot && fData.plane) {
                    ApiManager.sendEmail({type: "scheduledFlightConfirmed", id: activeRecord.id, uid: currentUser.uid,
                        collection: "flights", verified: "pending", flightNumber: activeRecord.number, reason: ""})
                }
            } else {
                // add new flight
                let nextCounter = flightsSnapshot.val()+1
                database.ref('counter/flights').set(nextCounter)
                
                fData.number = utils.generateFlightNumber(nextCounter)
                fData.public = true
                fData.bookedSeats = 0;
                if (fData?.waterTakeoff && fData.waterTakeoff?.length > 0) {
                    fData.departureAirport = '';
                    fData.departureLocation = {
                        longitude,
                        latitude
                    };
                }
                if(fData?.waterLanding && fData.waterLanding?.length > 0) {
                    fData.arrivalAirport = '';
                    fData.arrivalLocation = {
                        longitude: arrivalLongitude,
                        latitude: arrivalLatitude
                    }
                }
                fData.publicInviteCode = generateInviteCode();
                fData.createdByPilot = true;
                const pSnapshot = await firestore.collection('flights').add(fData)
                utils.pushDataLayer({ event: 'add_flight' })
                ApiManager.sendSlackAlert({
                    text: `🛫 flight added \nnumber: ${fData?.number || ''} \n${resources.airports.index[fData?.departureAirport]?.code || `${fData?.departureLocation?.latitude},${fData?.departureLocation?.longitude}`} - ${resources.airports.index[fData?.arrivalAirport]?.code || `${fData?.arrivalLocation?.latitude},${fData?.arrivalLocation?.longitude}`} \nDeparture date: ${fData?.departureDate || ''} \nDeparture time: ${fData?.departureTime || ''}`
                });
                currentUser.profile.flights.push(pSnapshot.id)
                currentUser.profile.upcomingFlights.push(pSnapshot.id)
                if (fData.pilot && fData.plane)
                    ApiManager.sendEmail({type: "scheduledFlightConfirmed", id: pSnapshot.id, uid: currentUser.uid,
                        collection: "flights", verified: "pending", flightNumber: fData.number, reason: ""})
                
                let usersData = [];
                const uSnapshot = await firestore.collection('users')
                    .where('role', '==', 'Client')
                    .get();
                
                if (!uSnapshot.empty) {
                    uSnapshot.forEach(doc => {
                        let uItem = doc.data();
                        if (uItem.preferedIslands &&
                            uItem.homeBase &&
                            uItem.preferedIslands.find(island =>
                                island.label === utils.resources.airports.index[fData?.arrivalAirport]?.place) &&
                            uItem.homeBase.id === fData?.departureAirport) {
                            uItem.id = doc.id;
                            usersData.push(uItem);
                        }
                    })
                }
                
                usersData.map((user) => {
                    if (user.emptySeatsNotifications) {
                        const title = 'Empty Seat Available';
                        const content = `Empty Seat Available to ${utils.resources.airports.index[fData?.arrivalAirport]?.place} - book now before it is taken!`;
                        
                        ApiManager.addNotification({
                            uid: user.id,
                            type: 'emptySeatsAvailable',
                            title,
                            content,
                            icon: 'bell',
                            fid: pSnapshot.id,
                            showInApp: true,
                        })
                        
                        sendExpoNotificationToUser({
                            uid: user.id, title, content, data: { type: 'emptySeatsAvailable', fid: pSnapshot.id }
                        });
                    }
                })
            }
        }
        utils.openNotification(`${activeRecord? 'Saved': 'Added'} successfully!`, true)
        loadData()
        setLoading(false)
        closeDrawerHandler(false)
    }
    
    const onChangeCheckBox = (checkedValues) => {
        setTempVal({...tempVal, recurringRoute: checkedValues})
    }
    
    const onWaterTakeoffChange = (checkedValues) => {
        setTempVal({...tempVal, waterTakeoff: checkedValues});
        form.setFieldValue('departureAirport', undefined);
        if(checkedValues.length > 0) {
            setDepartureLocation(INIT_WATER_COORDS.Bahamas)
        } else {
            setDepartureLocation(null);
        }
    }
    
    const onWaterLandingChange = (checkedValues) => {
        setTempVal({...tempVal, waterLanding: checkedValues});
        form.setFieldValue('arrivalAirport', undefined);
        if(checkedValues.length > 0) {
            setArrivalLocation(INIT_WATER_COORDS.Bahamas)
        } else {
            setArrivalLocation(null);
        }
    }
    
    const onChangeAvailableSeats = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        setTempVal({...tempVal, availableSeats: newVal});
        form.setFieldValue('availableSeats', newVal);
    }
    
    const onChangePricePerPerson = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        setTempVal({...tempVal, pricePerPerson: newVal});
        form.setFieldValue('pricePerPerson', newVal);
    }
    
    const onChangePricePerBag = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        setTempVal({...tempVal, pricePerBag: newVal});
        form.setFieldValue('pricePerBag', newVal);
    }
    
    // useEffect(() => {
    //     form.setFieldsValue({'potentialEarnings': tempVal.pricePerPerson * tempVal.availableSeats})
    // }, [tempVal])
    
    const pilotExist = pilots.find(pilot => pilot?.id === activeRecord?.pilot);
    const planeExist = fleets.find(fleet => fleet?.id === activeRecord?.plane)
    
    useEffect(() => {
        if (activeRecord) {
            let newRequiredFields = [];
            setSelectedDate(utils.convertToBahTimeZone(activeRecord.departureDate, '12:00 am'))
            setTempVal({
                ...(activeRecord?.departureLocation && {waterTakeoff: ['Water takeoff']}),
                ...(activeRecord?.arrivalLocation && {waterLanding: ['Water landing']}),
                recurringRoute: activeRecord.recurringRoute,
                availableSeats: activeRecord.availableSeats,
                pricePerPerson: activeRecord.pricePerPerson
            })
            setPilot(pilotExist ? activeRecord.pilot : null)
            setPlane(planeExist ? activeRecord.plane : null)
            setDepartureAirport(activeRecord.departureAirport)
            activeRecord.departureAirport && newRequiredFields.push('departureAirport')
            setArrivalAirport(activeRecord.arrivalAirport)
            activeRecord.arrivalAirport && newRequiredFields.push('arrivalAirport')
            setRepeatOn(activeRecord.repeatOn || [])
            setEndDate(activeRecord.endsDate ? utils.convertToBahTimeZone(activeRecord.endsDate, '12:00 am') : utils.getToday())
            setRequiredFields([...requiredFields, ...newRequiredFields])
            if (activeRecord?.departureLocation) {
                setDepartureLocation(() => ({
                    ...departureLocation,
                    coords: {
                        latitude: activeRecord.departureLocation.latitude,
                        longitude: activeRecord.departureLocation.longitude
                    }
                }));
                form.setFieldsValue({
                    waterTakeoff:['Water takeoff'],
                    latitude: activeRecord.departureLocation.latitude,
                    longitude: activeRecord.departureLocation.longitude
                });
            }
            if (activeRecord?.arrivalLocation) {
                setArrivalLocation(() => ({
                    ...arrivalLocation,
                    coords: {
                        latitude: activeRecord.arrivalLocation.latitude,
                        longitude: activeRecord.arrivalLocation.longitude
                    }
                }));
                form.setFieldsValue({
                    waterLanding:['Water landing'],
                    arrivalLatitude: activeRecord.arrivalLocation.latitude,
                    arrivalLongitude: activeRecord.arrivalLocation.longitude
                });
            }
        }
    }, []);
    
    const { arrivalLocation: currentArrivalLocation, departureLocation: currentDepartureLocation, ...correctActiveRecord } = activeRecord || {};
    
    const initialValues = activeRecord ? {...correctActiveRecord,
        pilot: pilotExist ? activeRecord.pilot : null,
        plane: planeExist ? activeRecord.plane : null,
        departureDate: utils.convertToBahTimeZone(activeRecord.departureDate, '12:00 am'),
        departureTime: utils.convertToBahTimeZone(activeRecord.departureDate, activeRecord.departureTime),
        endsDate: activeRecord.endsDate ? utils.getDate(activeRecord.endsDate) : utils.getToday(),
        departureCountry: activeRecord.departureCountry || KANOO_PAYMENT_COUNTRY,
        pickupInstruction: activeRecord?.pickupInstruction ? activeRecord.pickupInstruction.trim() : '',
        arrivalCountry: activeRecord.arrivalCountry || KANOO_PAYMENT_COUNTRY,
        departureLocation: MOCK_HOTELS.find(hotel => JSON.stringify(hotel.coords) === JSON.stringify(currentDepartureLocation)),
        arrivalLocation: MOCK_HOTELS.find(hotel => JSON.stringify(hotel.coords) === JSON.stringify(currentArrivalLocation)),
    } : {recurrenceFrequency: 'Weekly'}
    
    let departureAirportList =
        utils.resources.airports[utils.resources.countryContraction[departureCountry || ''] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== arrivalAirport)
    let arrivalAirportList =
        utils.resources.airports[utils.resources.countryContraction[arrivalCountry || ''] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== departureAirport)
    const diffBetweenDays = moment().startOf("day").diff(selectedDate, "days");
    
    
    
    const handleBlurCoords = (type, value) => {
        if(typeof +value === 'number'){
            setDepartureLocation((prev) => ({...prev,
                coords: {
                    ...prev?.coords,
                    [type]: +value
                } }))
        }
    }
    
    const handleBlurArrivalCoords = (type, value) => {
        if(typeof +value === 'number') {
            setArrivalLocation((prev) => ({...prev,
                coords: {
                    ...prev?.coords,
                    [type]: +value
                } }))
        }
    }
    
    const handleClickMap = (mapProps, map, clickEvent) => {
        // if(!activeRecord) {
        setDepartureLocation(()=>({
            label:'',
            value:'',
            coords: {
                latitude: clickEvent.latLng.lat(),
                longitude: clickEvent.latLng.lng()
            }
        }))
        form.setFieldsValue({
            latitude: clickEvent.latLng.lat(),
            longitude: clickEvent.latLng.lng()
        })
        // }
    }
    
    const handleClickArrivalMap = (mapProps, map, clickEvent) => {
        // if(!activeRecord) {
        setArrivalLocation(()=>({
            label:'',
            value:'',
            coords: {
                latitude: clickEvent.latLng.lat(),
                longitude: clickEvent.latLng.lng()
            }
        }))
        form.setFieldsValue({
            arrivalLatitude: clickEvent.latLng.lat(),
            arrivalLongitude: clickEvent.latLng.lng()
        })
        // }
    }
    
    const handleDepartureMarkerDragEnd = (props, map, clickEvent) => {
        setDepartureLocation(() => ({
            label:'',
            value:'',
            coords: {
                latitude: clickEvent.latLng.lat(),
                longitude: clickEvent.latLng.lng()
            }
        }));
        form.setFieldsValue({
            latitude: clickEvent.latLng.lat(),
            longitude: clickEvent.latLng.lng()
        })
    }
    
    const handleArrivalMarkerDragEnd = (props, map, clickEvent) => {
        setArrivalLocation(() => ({
            label:'',
            value:'',
            coords: {
                latitude: clickEvent.latLng.lat(),
                longitude: clickEvent.latLng.lng()
            }
        }));
        form.setFieldsValue({
            arrivalLatitude: clickEvent.latLng.lat(),
            arrivalLongitude: clickEvent.latLng.lng()
        })
    }
    
    return (
        <Drawer
            className='add-flight-drawer'
            title={`${activeRecord? 'Edit': 'Add a'} flight`}
            placement="right"
            onClose={closeDrawerHandler}
            visible={drawerVisible}
            width='32.31rem'
            extra={
                <Space>
                    <Button onClick={closeDrawerHandler}>Cancel</Button>
                    <Button type="primary" onClick={closeDrawerHandler}>
                        OK
                    </Button>
                </Space>
            }
            destroyOnClose={true}
        >
            <Form
                className="content"
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={true}
                preserve={false}
                initialValues={initialValues}
            >
                <div className="form-content">
                    <div className="form-body">
                        <Row>
                            <Col span={12}>
                                <Form.Item name='waterTakeoff'>
                                    <Checkbox.Group
                                        className="fly-checkbox"
                                        options={[{label: 'Water takeoff', value: 'Water takeoff'}]}
                                        onChange={onWaterTakeoffChange} disabled={activeRecord}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item label="Departure Country" name='departureCountry' rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        placeholder="Choose departure country"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.defaultCountries}
                                        allowClear
                                        onChange={(value) => {
                                            setDepartureCountry(value || '');
                                            form.setFieldValue('departureAirport', undefined);
                                            if(tempVal.waterTakeoff.length) {
                                                setDepartureLocation(INIT_WATER_COORDS[value]);
                                                form.setFieldsValue({
                                                    latitude: INIT_WATER_COORDS[value]?.coords?.latitude || '',
                                                    longitude: INIT_WATER_COORDS[value]?.coords?.longitude || ''
                                                })
                                            }
                                        }}
                                        disabled={activeRecord && !departureAirport}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {!!tempVal?.waterTakeoff?.length && departureCountry && (
                            <Row>
                                <Col md={24} xs={24}>
                                    <Form.Item
                                        label="Pickup Details"
                                        name='pickupInstruction'
                                        rules={[{
                                            required: true,
                                            validator: (rule, value, callback) => utils.validations.validatePickupDetailsField(rule, value, callback, 'Pickup details', true) }]
                                        }
                                    >
                                        <Input className="fly-input" placeholder="Add pickup details here"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {/*{!!tempVal?.waterTakeoff?.length && departureCountry && (*/}
                        {/*    <Row>*/}
                        {/*        <Col md={24} xs={24}>*/}
                        {/*            <Form.Item label="Departure Location" name='departureLocation'>*/}
                        {/*                <Select*/}
                        {/*                    placeholder="Choose departure location"*/}
                        {/*                    className="fly-select"*/}
                        {/*                    showSearch={true}*/}
                        {/*                    options={MOCK_HOTELS}*/}
                        {/*                    allowClear*/}
                        {/*                    onChange={(value) => {*/}
                        {/*                        const location = MOCK_HOTELS.find((hotel)=> hotel.value === value);*/}
                        {/*                        if(location) {*/}
                        {/*                            setDepartureLocation(location);*/}
                        {/*                            form.setFieldsValue({*/}
                        {/*                                latitude: location.coords.latitude,*/}
                        {/*                                longitude: location.coords.longitude*/}
                        {/*                            })*/}
                        {/*                        }*/}
                        {/*                    }}*/}
                        {/*                    // disabled={activeRecord && !departureAirport}*/}
                        {/*                />*/}
                        {/*            </Form.Item>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*)}*/}
                        {(initialValues.departureLocation || !!tempVal?.waterTakeoff?.length) && (
                            <Row style={{ height: '160px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                <Col md={24} xs={24} span={12} style={{ height: '135px', flex: '0 0 100%' }}>
                                    <>
                                        <p style={{ fontSize: '16px', paddingBottom: '8px' }}>Departure Location</p>
                                        <Map
                                            onClick={handleClickMap}
                                            google={props.google}
                                            zoom={11}
                                            initialCenter={{
                                                lat: activeRecord?.departureLocation?.latitude || INIT_WATER_COORDS['Bahamas'].coords.latitude,
                                                lng: activeRecord?.departureLocation?.longitude || INIT_WATER_COORDS['Bahamas'].coords.longitude
                                            }}
                                            center={{
                                                lat: initialValues.departureLocation?.latitude || departureLocation?.coords?.latitude,
                                                lng: initialValues.departureLocation?.longitude || departureLocation?.coords?.longitude
                                            }}
                                            styles={MAP_STYLES}
                                        >
                                            <Marker
                                                onDragend={handleDepartureMarkerDragEnd}
                                                // draggable={!activeRecord}
                                                position={{
                                                    lat: initialValues.departureLocation?.latitude ||  departureLocation?.coords?.latitude,
                                                    lng: initialValues.departureLocation?.longitude || departureLocation?.coords?.longitude
                                                }}
                                                name={'Departure location'}
                                                icon={{
                                                    url: '/images/markers/web_marker.svg'
                                                }}
                                            />
                                        </Map>
                                    </>
                                </Col>
                            </Row>
                        )}
                        {departureCountry && !activeRecord?.departureLocation && !tempVal?.waterTakeoff?.length && (
                            <Row>
                                <Col md={24} xs={24}>
                                    <Form.Item label="Departure Airport" name='departureAirport' rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Add departure airport"
                                            className="fly-select"
                                            showSearch={true}
                                            options={departureAirportList}
                                            allowClear
                                            onChange={(value) => setDepartureAirport(value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {(initialValues.departureLocation || !!tempVal?.waterTakeoff?.length) &&
                            <Row>
                                <Col md={12} xs={24} className="pr-1">
                                    <Form.Item
                                        label="Latitude"
                                        name='latitude'
                                        rules={[{ required: true, message: 'This field is required' }]}
                                        dependencies={['waterTakeoff']}
                                    >
                                        <Input
                                            onChange={() => {}}
                                            // disabled={activeRecord}
                                            className="fly-input"
                                            min={activeRecord ? 0 : 1}
                                            max="19"
                                            placeholder="00.000000"
                                            onBlur={(e) => handleBlurCoords('latitude', e.target.value)}
                                            onKeyPress={(e) => {
                                                const regex = /^[0-9.-]+$/;
                                                if (!regex.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24} className="pr-1">
                                    <Form.Item
                                        label="Longitute"
                                        name='longitude'
                                        rules={[{ required: true, message: 'This field is required' }]}
                                        dependencies={['waterTakeoff']}
                                    >
                                        <Input
                                            onKeyPress={(e) => {
                                                const regex = /^[0-9.-]+$/;
                                                if (!regex.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            // disabled={activeRecord}
                                            className="fly-input"
                                            min={activeRecord ? 0 : 1}
                                            max="19"
                                            placeholder="00.000000"
                                            onBlur={(e) => handleBlurCoords('longitude', e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md={12} xs={24} className="pr-1">
                                <Form.Item label="Departure Date" name='departureDate'
                                           rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateDate(rule, value, callback, 'Departure date', true)}]}>
                                    <DatePicker
                                        format="MM/DD/YYYY"
                                        placeholder="MM/DD/YYYY"
                                        className="fly-date-picker"
                                        onChange={(date) => setSelectedDate(date)}
                                        defaultPickerValue={utils.getToday()}
                                        disabledDate={utils.disabledDateWithoutToday}
                                        showToday={false}
                                        disabled={editAllOne === 'all'}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24} className={`${isMobile ? "" : "pl-1"}`}>
                                <Form.Item
                                    label="Departure Time"
                                    name='departureTime'
                                    rules={[{ required: true, validator: (rule, value, callback) =>
                                        utils.validations.validateTime(rule, value, callback, 'Departure time', true, selectedDate)}]}
                                >
                                    <FlyTimePicker
                                        format="hh:mm a"
                                        placeholder="HH:MM PP"
                                        className="fly-date-picker"
                                        showNow={diffBetweenDays < -1}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item name='waterLanding'>
                                    <Checkbox.Group
                                        className="fly-checkbox"
                                        options={[{label: 'Water landing', value: 'Water landing'}]}
                                        onChange={onWaterLandingChange} disabled={activeRecord}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item label="Arrival Country" name='arrivalCountry' rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        placeholder="Choose arrival country"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.defaultCountries}
                                        allowClear
                                        onChange={(value) => {
                                            setArrivalCountry(value || '');
                                            form.setFieldValue('arrivalAirport', undefined);
                                            if(tempVal.waterLanding.length) {
                                                setArrivalLocation(INIT_WATER_COORDS[value]);
                                                form.setFieldsValue({
                                                    arrivalLatitude: INIT_WATER_COORDS[value]?.coords?.latitude || '',
                                                    arrivalLongitude: INIT_WATER_COORDS[value]?.coords?.longitude || ''
                                                })
                                            }
                                        }}
                                        disabled={activeRecord && !arrivalAirport}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*{!!tempVal?.waterLanding?.length && arrivalCountry && (*/}
                        {/*    <Row>*/}
                        {/*        <Col md={24} xs={24}>*/}
                        {/*            <Form.Item label="Arrival Location" name='arrivalLocation'>*/}
                        {/*                <Select*/}
                        {/*                    placeholder="Choose arrival location"*/}
                        {/*                    className="fly-select"*/}
                        {/*                    showSearch={true}*/}
                        {/*                    options={MOCK_HOTELS}*/}
                        {/*                    allowClear*/}
                        {/*                    onChange={(value) => {*/}
                        {/*                        const location = MOCK_HOTELS.find((hotel)=> hotel.value === value);*/}
                        {/*                        if(location) {*/}
                        {/*                            setArrivalLocation(location);*/}
                        {/*                            form.setFieldsValue({*/}
                        {/*                                arrivalLatitude: location.coords.latitude,*/}
                        {/*                                arrivalLongitude: location.coords.longitude*/}
                        {/*                            })*/}
                        {/*                        }*/}
                        {/*                    }}*/}
                        {/*                    // disabled={activeRecord && !departureAirport}*/}
                        {/*                />*/}
                        {/*            </Form.Item>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*)}*/}
                        {(initialValues.arrivalLocation || !!tempVal?.waterLanding?.length) && (
                            <Row style={{ height: '160px', justifyContent: 'space-between', marginBottom: '24px' }}>
                                <Col md={24} xs={24} span={12} style={{ height: '135px', flex: '0 0 100%' }}>
                                    <>
                                        <p style={{ fontSize: '16px', paddingBottom: '8px' }}>Arrival Location</p>
                                        <Map
                                            onClick={handleClickArrivalMap}
                                            google={props.google}
                                            zoom={11}
                                            initialCenter={{
                                                lat: activeRecord?.arrivalLocation?.latitude || INIT_WATER_COORDS['Bahamas'].coords.latitude,
                                                lng: activeRecord?.arrivalLocation?.longitude || INIT_WATER_COORDS['Bahamas'].coords.longitude
                                            }}
                                            center={{
                                                lat: initialValues.arrivalLocation?.latitude || arrivalLocation?.coords?.latitude,
                                                lng: initialValues.arrivalLocation?.longitude || arrivalLocation?.coords?.longitude
                                            }}
                                            styles={MAP_STYLES}
                                        >
                                            <Marker
                                                // draggable={!activeRecord}
                                                onDragend={handleArrivalMarkerDragEnd}
                                                position={{
                                                    lat: initialValues.arrivalLocation?.latitude || arrivalLocation?.coords?.latitude,
                                                    lng: initialValues.arrivalLocation?.longitude || arrivalLocation?.coords?.longitude
                                                }}
                                                name={'Departure location'}
                                                icon={{
                                                    url: '/images/markers/web_marker.svg'
                                                }}
                                            />
                                        </Map>
                                    </>
                                </Col>
                            </Row>
                        )}
                        {arrivalCountry && !activeRecord?.arrivalLocation && !tempVal?.waterLanding?.length && (
                            <Row>
                                <Col md={24} xs={24}>
                                    <Form.Item label="Arrival Airport" name='arrivalAirport' rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Add arrival airport"
                                            className="fly-select"
                                            showSearch={true}
                                            options={arrivalAirportList}
                                            allowClear
                                            onChange={(value) => setArrivalAirport(value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {(initialValues.arrivalLocation || !!tempVal?.waterLanding?.length) &&
                            <Row>
                                <Col md={12} xs={24} className="pr-1">
                                    <Form.Item
                                        label="Latitude"
                                        name='arrivalLatitude'
                                        rules={[{ required: true, message: 'This field is required' }]}
                                        dependencies={['waterLanding']}
                                    >
                                        <Input
                                            onKeyPress={(e) => {
                                                const regex = /^[0-9.-]+$/;
                                                if (!regex.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            // disabled={activeRecord}
                                            className="fly-input"
                                            min={activeRecord ? 0 : 1}
                                            max="19"
                                            placeholder="00.000000"
                                            onBlur={(e) => handleBlurArrivalCoords('latitude', e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={12} xs={24} className="pr-1">
                                    <Form.Item
                                        label="Longitute"
                                        name='arrivalLongitude'
                                        rules={[{ required: true, message: 'This field is required' }]}
                                        dependencies={['waterLanding']}
                                    >
                                        <Input
                                            onKeyPress={(e) => {
                                                const regex = /^[0-9.-]+$/;
                                                if (!regex.test(e.key)) {
                                                    e.preventDefault();
                                                }
                                            }}
                                            // disabled={activeRecord}
                                            className="fly-input"
                                            min={activeRecord ? 0 : 1}
                                            max="19"
                                            placeholder="00.000000"
                                            onBlur={(e) => handleBlurArrivalCoords('longitude', e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item
                                    label="Available Seats"
                                    name='availableSeats'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Available seats', true, activeRecord ? 0 : 1, 19, fleets.find(fleet => fleet.id === plane), activeRecord) }]
                                    }
                                    dependencies={['plane']}
                                >
                                    <Input type="number" className="fly-input" min={activeRecord ? 0 : 1} max="19" placeholder="# of Seats" onChange={onChangeAvailableSeats} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} xs={24} className="pr-1">
                                <Form.Item label="Price Per Person" name='pricePerPerson'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Price', true, 1)
                                    }]}
                                >
                                    <Input type="decimal" className="fly-input" min="1" placeholder="Price" prefix="$" onChange={onChangePricePerPerson}/>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24} className={`${isMobile ? "" : "pl-1"}`}>
                                <Form.Item label="Price Per Extra Bag" name='pricePerBag'
                                    rules={[{
                                        required: true,
                                        validator: (rule, value, callback) => utils.validations.validateNumber(rule, value, callback, 'Price', true, 0)
                                    }]}
                                >
                                    <Input type="number" className="fly-input" min="0" placeholder="Price" prefix="$" onChange={onChangePricePerBag}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={24} xs={24} className="pr-1">
                                <Form.Item
                                    label="Plane"
                                    name='plane'
                                    rules={[{
                                        required: activeRecord,
                                        message: 'Verified planes need to be added before flight is active for requests' }]
                                    }
                                    dependencies={['availableSeats']}
                                >
                                    <Select
                                        placeholder="Pick from list"
                                        className={`fly-select ${activeRecord && !plane && 'invalid'}`}
                                        onChange={(value) => setPlane(value)}
                                        options={filteredFleets}
                                        showSearch={true}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col md={12} xs={24} className="pr-1">
                                <Form.Item label="Tail Number" name='tailNumber'
                                    rules={[{
                                        validator: (rule, value, callback) => utils.validations.validateTailNumber(rule, value, callback, 'Tail number', false) }]
                                    }>
                                    <Input type="text" className="fly-input" placeholder="Add tail number" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={24} className="pl-1">
                                <Form.Item label="Max Weight Capacity" name='weightCapacity'  className="max-weight-capacity">
                                    <NumberFormat className="fly-input" thousandSeparator={true} placeholder="Add max weight" />
                                </Form.Item>
                            </Col>
                        </Row>*/}
                        <Row>
                            <Col md={24} xs={24}>
                                <Form.Item label="Pilot" name='pilot'
                                    rules={[{
                                        required: !!activeRecord,
                                        message: 'Verified pilot needs to be added before flight is active for requests' }]
                                    }
                                >
                                    <Select
                                        placeholder="Pick from list"
                                        className={`fly-select ${activeRecord && !pilot && 'invalid'}`}
                                        options={pilots}
                                        onChange={(value) => setPilot(value)}
                                        showSearch={true}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {(!activeRecord?.departureLocation && !activeRecord?.arrivalLocation) && (
                            <Row>
                                <Col span={12}>
                                    <Form.Item name='recurringRoute'>
                                        <Checkbox.Group
                                            className="fly-checkbox"
                                            options={[{label: 'Recurring route', value: 'Recurring route'}]}
                                            onChange={onChangeCheckBox} disabled={editAllOne}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                        {
                            tempVal && tempVal.recurringRoute && tempVal.recurringRoute.length > 0 && <>
                                <Row>
                                    <Col md={24} xs={24}>
                                        <Form.Item label="Recurrence Frequency" name='recurrenceFrequency'>
                                            <Select
                                                placeholder="Frequency"
                                                className="fly-select"
                                                showSearch={true}
                                                options={utils.resources.frequencies}
                                                disabled={editAllOne === 'one'}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} xs={24}>
                                        <Form.Item
                                            label="Repeat on"
                                            name='repeatOn'
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, callback) => utils.validations.validateRepeatOn(rule, value, callback, 'Repeat on', true)
                                            }]}
                                        >
                                            <Checkbox.Group
                                                className="repeat-on-checkbox-grp"
                                                options={utils.resources.repeatOn}
                                                onChange={(value) => setRepeatOn(value)}
                                                disabled={editAllOne === 'one'}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={24} xs={24}>
                                        <Form.Item
                                            label="Ends Date"
                                            name='endsDate'
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, callback) => utils.validations.validateDateCompare(rule, value, callback, 'Ends Date', true, selectedDate)
                                            }]}
                                        >
                                            <DatePicker
                                                format="MM/DD/YYYY"
                                                placeholder="MM/DD/YYYY"
                                                className="fly-date-picker"
                                                disabledDate={utils.disabledDate}
                                                showToday={false}
                                                onChange={(date) => setEndDate(date)}
                                                disabled={editAllOne === 'one'}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        }
                    </div>
                </div>
                <Row className="form-footer mt-5">
                    <Col span={24}>
                        <Form.Item shouldUpdate>
                            {() => {
                                let invalid = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
                                    (!activeRecord && !form.isFieldsTouched(requiredFields, true)) ||
                                    (tempVal && tempVal.recurringRoute && tempVal.recurringRoute.length > 0 && (!endDate || repeatOn.length === 0))
                                return (
                                    <Button type="primary" htmlType="submit" className="fly-btn w-full" disabled={invalid} loading={loading}>
                                        {`${activeRecord? 'SAVE': 'ADD'} FLIGHT`}
                                    </Button>
                                )
                            }}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    )
}

export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_SECRET_CODE === "Prod" ? "AIzaSyCAFRPjw-QymLcMe-lVBLLzmpCtw0paTh4" : "AIzaSyA1YYDadk0xblsApCYiM8OUaGrnWipVSss")
})(AddFlightDrawer)
