import React, { useEffect, useState } from 'react'
import { Form, Button, Table, Space, Tooltip, Modal } from 'antd'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import './index.scss'
import AddPilotDrawer from './AddPilotDrawer'
import { firestore } from '../../../firebaseConfig'

function Pilots(props) {
    const [form] = Form.useForm()
    const {history, currentUser} = props
    const [pilots, setPilots] = useState([])
    const [addPilotVisible, setAddPilotVisible] = useState(false)
    const [editPilotVisible, setEditPilotVisible] = useState(false)
    const [activeRecord, setActiveRecord] = useState()
    const [loading, setLoading] = useState()

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        const pSnapshot = await firestore.collection('pilots').where('uid', '==', currentUser.uid).get();
        let pList = []
        let currentTime = utils.getToday()
        if (!pSnapshot.empty) {
            pSnapshot.forEach(doc => {
                let pItem = doc.data()
                if (pItem.licenseExpirationDate !== '')
                    pItem.licenseExpirationDate = utils.getDate(pItem.licenseExpirationDate)
                pItem.id = doc.id
                if (!pItem.deletedByUser) pList.push(pItem)
            })
            for(let i=0;i<pList.length;i++) {
                let pItem = pList[i];
                if (pItem.expiredDate && pItem.expiredDate !== '' && (new Date(pItem.expiredDate) < currentTime)) {
                    pItem.verified = 'pending'
                    pItem.license = ''
                    await firestore.collection('pilots').doc(pItem.id).update({
                        verified: 'pending',
                        license: '',
                        expiredDate: ''
                    });
                }
            }
        }
        setPilots(pList)
        setLoading(false)
    }

    const pagination = {
        showSizeChanger: false,
        defaultPageSize: 6,
        showTotal: (total, range) => `Showing ${range[1]-range[0]+1} of ${total}`,
        itemRender: utils.itemRender
    }

    const removeModal = (record) => {
        Modal.confirm({
            title: '',
            maskClosable: true,
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div>
                <h3 className="mt-4">Remove the pilot</h3>
                <p>Are you sure you want to remove the pilot <b>{record.firstname} {record.lastname}</b>?</p>
            </div>,
            okText: 'REMOVE PILOT',
            onOk() {
                onRemoveHandler(record)
            },
        })
    }

    const onRemoveHandler = async (record) => {
        await firestore.collection('pilots').doc(record.id).update({ deletedByUser: true });
        currentUser.profile.pilots = currentUser.profile.pilots.filter(item => item !== record.id)
        await firestore.collection('users').doc(currentUser.uid).update({pilots: currentUser.profile.pilots})
        utils.openNotification('Removed successfully!', true)
        loadData()
    }

    const editRecordHandler = (record) => {
        setEditPilotVisible(true)
        setActiveRecord(record)
    }

    const openAddPilotHandler = () => {
        setAddPilotVisible(true)
    }

    const closeAddPilotHandler = () => {
        setAddPilotVisible(false)
    }

    const openEditPilotHandler = () => {
        setEditPilotVisible(true)
    }

    const closeEditPilotHandler = () => {
        setEditPilotVisible(false)
        setActiveRecord(null)
    }

    const columns = [
        {
            title: 'Photo',
            dataIndex: 'photo',
            key: 'photo',
            width: '10%',
            render: (text, record) => <Space>
                <img alt='' src={text? text : "/images/user-placeholder.png"} className="avatar-icon" />
            </Space>,
        }, {
            title: 'Name',
            dataIndex: 'firstname',
            key: 'firstname',
            render: (text, record) => <Space>
                <ReactSVG src="/images/captain.svg" className="label-icon" />
                <p>{record.firstname} {record.lastname}</p>
            </Space>,
        },  {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <Space>
                <ReactSVG src="/images/mail.svg" className="label-icon" />
                <p>{text}</p>
            </Space>,
        },  {
            title: 'Verified',
            dataIndex: 'verified',
            key: 'verified',
            render: (text, record) => <Space>
                {record.license?
                    <Tooltip className="fly-tooltip" title={<p>{text === "done"? "Verified": text === "failed"? "Documents not verified, please upload active documents for verification": "Documents uploaded, pending verification"}</p>} color="#90C4C2" placement="left">
                        <ReactSVG src="/images/check.svg" className={`label-icon check-icon ${text === "done"? "active": text === "failed"? "inactive": ""}`} />
                    </Tooltip>
                    :
                    <Tooltip className="fly-tooltip" title={<p>Documents not uploaded, please upload active documents for verification</p>} color="#90C4C2" placement="left">
                        <div className="blank-icon"></div>
                    </Tooltip>
                }
            </Space>,
        }, {
            title: '',
            render: (text, record) => <Space className="status-column">
                <ReactSVG src="/images/edit.svg" className="action-icon" onClick={() => editRecordHandler(record)}/>
                <ReactSVG src="/images/delete.svg" className="action-icon" onClick={() => removeModal(record)} />
            </Space>
        },
    ]

    return (
        <div className="content fly-board pilot-board">
            <div className="content-header">
                <h2 className="mb-0">Pilots</h2>
                <Button type="primary" className="fly-btn" onClick={openAddPilotHandler}>ADD PILOT</Button>
            </div>
            <div className="fly-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    requiredMark={true}
                >
                    <div className="form-content">
                        <Table columns={columns} dataSource={pilots}
                            pagination={pagination} loading={loading} />
                    </div>
                </Form>
            </div>
            {addPilotVisible && <AddPilotDrawer drawerVisible={addPilotVisible}
                openDrawerHandler={openAddPilotHandler}
                closeDrawerHandler={closeAddPilotHandler}
                currentUser={currentUser}
                loadData={loadData}
            />}
            {editPilotVisible && <AddPilotDrawer drawerVisible={editPilotVisible}
                openDrawerHandler={openEditPilotHandler}
                closeDrawerHandler={closeEditPilotHandler}
                activeRecord={activeRecord}
                currentUser={currentUser}
                loadData={loadData}
            />}
        </div>
    )
}

export default Pilots
