import React from 'react'
import { Row, Col, Button} from 'antd'

function SentEmail(props) {
    const {history, newUser, setStep} = props

    return (
        <div className="client-body b-gray height-100-percent">
            <Row className="t-center">
                <Col span={24}>
                    <img alt='' src={`/images/mail.png`}/>
                    <h3 className="mt-3">Please confirm your email address</h3>
                    <p className="fly-notes mt-2 t-center">For your security we are verifying your email address {newUser.email} <br /><br />
                    Click the link in your inbox to continue.</p>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col span={24}>
                    <Button type="primary fly-btn" className="fly-btn w-full" onClick={() => history.push('/client/auth/login')}>
                        Got it
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default SentEmail
