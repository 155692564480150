import React from 'react'
import { Button } from 'antd'
import { FlySideBar, FlyAuthFooter } from '../../../components/'

function PasswordRecoverySent(props) {
    const {user, setStep} = props

    return (
        <>
            <div className="auth-page desktop-view auth-page-auth">
                <FlySideBar />
                <div className="content">
                    <div className="fly-form auth-form auth-request">
                        <div className="complete-image"></div>
                        <h2>Email Sent</h2>
                        <p className="fly-info mb-4">We sent an email to {user.email} with a link to reset your password.</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordRecoverySent
