import React from 'react'
import RecoverEmail from './RecoverEmail'
import ResetPassword from './ResetPassword'
import VerifyEmail from './VerifyEmail'
import { Button } from 'antd'
import { FlySideBar } from '../../../components/'

function Action(props) {
  let {history} = props
  const params = new URLSearchParams(history.location.search)
  const mode = params.get('mode')
  const actionCode = params.get('oobCode')
  let continueUrl = params.get('continueUrl')
  if (continueUrl)
    continueUrl = continueUrl.split('.app')[1]
  else
    continueUrl = ''

  // Handle the user management action.
  let renderPage = ''

  switch (mode) {
    case 'recoverEmail':
      // Display reset password handler and UI.
      renderPage = <RecoverEmail actionCode={actionCode} {...props} />
      break
    case 'resetPassword':
      // Display email recovery handler and UI.
      renderPage = <ResetPassword actionCode={actionCode} continueUrl={continueUrl} {...props} />
      break
    case 'verifyEmail':
      // Display email verification handler and UI.
      renderPage = <VerifyEmail actionCode={actionCode} continueUrl={continueUrl} {...props} />
      break
    default:
      // Error: invalid mode.
      renderPage =
        <div className="fly-form auth-form v1-form t-center">
          <p className="fly-info">The selected page mode is invalid.</p>
          <Button type="primary" className="fly-btn mt-4" onClick={() => history.push('/auth/login')}>
            Continue
          </Button>
        </div>
  }

  return (
    <div className="auth-page">
        <FlySideBar />
        <div className="content">
          {renderPage}
        </div>
    </div>
  )
}

export default Action
