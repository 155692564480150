import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import "firebase/storage"
// import "firebase/messaging"

export const firebaseConfig = process.env.REACT_APP_SECRET_CODE === 'Prod' ? {
  apiKey: "AIzaSyBZJc9Qb_MBB_9wrF-xQJXEp1DcD2TDw0w",
  authDomain: "flymingo---prod.firebaseapp.com",
  projectId: "flymingo---prod",
  storageBucket: "flymingo---prod.appspot.com",
  messagingSenderId: "105467542370",
  appId: "1:105467542370:web:b197addfd54c143170ca3b",
  measurementId: "G-YG8CMXZSXY"
} : {
  apiKey: "AIzaSyC6OBngDUfAvwh9GMKg1KUR8cAuYhyi95Y",
  authDomain: "flymingo---dev.firebaseapp.com",
  projectId: "flymingo---dev",
  storageBucket: "flymingo---dev.appspot.com",
  messagingSenderId: "1089954877133",
  appId: "1:1089954877133:web:991fd05927aa89468b2384",
  measurementId: "G-M60REX7LQR"
};

firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const database = firebase.database()
export const firestorage = firebase.storage()
export const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
export const defaultTimestamp = firebase.firestore.Timestamp.fromDate(new Date('01/01/1970')).toDate()
// export const messaging = firebase.messaging()

export const generateUserDocument = async (user, additionalData) => {
  if (!user) return
  const userRef = firestore.doc(`users/${user.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    const { email, uid } = user
    try {
      await userRef.set({
        uid,
        email,
        ...additionalData
      })
    } catch (error) {
      console.error("Error creating user document", error)
    }
  }
  return getUserDocument(user.uid)
}

export const getUserDocument = async uid => {
  if (!uid) return null
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get()
    return {
      uid,
      ...userDocument.data()
    }
  } catch (error) {
    console.error("Error fetching user", error)
  }
}
