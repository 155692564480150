import { COMMON } from '../constants'

const initState = {
  currentUser: null,
  appLoaded: false,
  addFlightOpen: false,
  editFlightOpen: false,
  requestDetailsOpen: false
}

const FlyReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case COMMON.USER_LOADED:
      return {
        ...state,
        currentUser: action.user,
        appLoaded: true
      }

    case COMMON.PAGE_LOADED:
      return {
        ...state,
      }

    case COMMON.SET_ADD_FLIGHT_OPEN:
        return {
            ...state,
            addFlightOpen: action.payload
        }
    case COMMON.SET_EDIT_FLIGHT_OPEN:
        return {
             ...state,
             editFlightOpen: action.payload
        }
    case COMMON.SET_REQUEST_DETAILS_OPEN:
        return {
            ...state,
            requestDetailsOpen: action.payload
        }    

    default:
      return state
  }
}

export default FlyReducer
