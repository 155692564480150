import React from 'react';
import './index.scss';
import { Layout } from 'antd'
import { Link } from "react-router-dom";
import { ReactSVG } from 'react-svg';

const { Header} = Layout

function TpHeader(props) {
  return (
    <Header>
      <div className="fly-header fly-header-mob">
        <div>
          <Link to="/"><ReactSVG src="/images/logo_black.svg" className="logo-icon" /></Link>
        </div>
        <div>
          <div className="nav-list">
            <Link to="/" className="menu-item">Home</Link>
            <Link to="/" className="menu-item">Contact Us</Link>
          </div>
        </div>
        <div>
          <Link to="/auth/login" click="" className="fly-btn">Become a Pilot</Link>
        </div>
      </div>
    </Header>
  )
}

export default TpHeader;
