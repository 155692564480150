import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { FlySideBar } from '../../../components/'
import { ArrowRightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { auth } from "../../../firebaseConfig"

function PasswordRecoveryRequest(props) {
    const [form] = Form.useForm()
    const {user, setUser, setStep} = props
    const [loading, setLoading] = useState(false)

    const onSubmitHandler = (values) => {
        setLoading(true)
        setUser({ ...user, email: values.email })
        auth.sendPasswordResetEmail(values.email, {
            url: `${process.env.REACT_APP_SECRET_CODE === 'Prod' ? utils.prodPilotDomain : utils.devPilotDomain}/auth/login`,
            handleCodeInApp: false
        }).then(function(res) {
            setStep('sent')
        }).catch(function(error) {
            console.log(error)
            utils.openNotification(error.message, false)
            setLoading(false)
        })
    }

    return (
        <div className="auth-page auth-page-auth">
            <FlySideBar />
            <div className="content">
                <div className="fly-form auth-form v1-form auth-request">
                <h2>Password recovery</h2>
                <p className="fly-info mb-4">Forgot your password? No problem! <br />Provide your email during registration and we'll help you restore access.</p>
                <Form
                    className=""
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                    initialValues={user}
                >
                    <Form.Item label="Email" name="email"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                    >
                        <Input type="email" placeholder="email" className="fly-input" suffix={
                            <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                        }/>
                    </Form.Item>
                    <Form.Item shouldUpdate className="mt-5 t-right">
                        {() => (
                            <Button type="primary" htmlType="submit" className="fly-btn"
                                loading={loading}
                                disabled={
                                    form.getFieldsError().filter(({ errors }) => errors.length)
                                      .length > 0 || !form.isFieldsTouched(true)
                                }
                            >
                                REQUEST A RESET LINK<ArrowRightOutlined/>
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
            </div>
        </div>
    )
}

export default PasswordRecoveryRequest
