import moment from 'moment';
import * as utils from './index';

const isFormValid = (values, level, outs=[]) => {
  let avails = 0
  if (values)
    Object.keys(values).forEach(key => {
      if (values[key] && values[key].length !== 0 && outs.indexOf(key) === -1)
        avails += 1
    })
  return avails >= level;
}

const validateForm = (form) => {
  form.validateFields().then(values => {
    if (values.errorFields) {
      return values.errorFields.length === 0;
    }
  });
}

const validateEmail = (rule, value, callback, field, required = false) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value) === false) {
    callback(`${field} is invalid. Verify the format is correct.`)
    return
  }
  callback()
}

const validatePassword = (rule, value, callback, field, required = false) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) === false) {
    callback(`Password must be at least 8 characters long, contain one upper and lower case letter, one number and one special character.`)
    return
  }
  callback()
}

const validatePassword2 = (rule, value, callback, field, required = false) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value) === false) {
    callback(`Invalid`)
    return
  }
  callback()
}

const validateConfirmPassword = (rule, value, callback, field, required = false, password) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value !== password) {
    callback("Password doesn't match")
    return
  }
  callback()
}

const validateText = (rule, value, callback, field, required = true) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ' || /^[A-Za-z ]+$/.test(value) === false) {
    callback(`${field} is invalid. Valid characters include A-Z and single spaces`)
    return
  }
  callback()
}

const validateUsers = (rule, value, form, users, callback, field, required = true, checkin = false, activePassenger = false) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ' || /^[A-Za-z ]+$/.test(value) === false) {
    callback(`${field} is invalid. Valid characters include A-Z and single spaces`)
    return
  }
  if (activePassenger) return callback()
  const userExist = field === 'First name'
      ? users && !!users.find(passenger => passenger.firstname === value && passenger.lastname === form.getFieldValue('lastname'))
      : users && !!users.find(passenger => passenger.firstname === form.getFieldValue('firstname') && passenger.lastname === value);
  if (userExist && !checkin) {
    callback('User already added')
    return
  }
  callback()
}

const validateBirthDay = (rule, value, callback, field, required = false) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }

  let duration = moment.duration(moment(new Date().toLocaleString("en-US", { timeZone: "America/Nassau" })).diff(value));
  if (duration.asYears() < 18) {
    callback('')
    return
  }
  callback()
}

const validateDate = (rule, value, callback, field, required = false) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }

  let duration = moment.duration(value.diff(moment(new Date())));

  if (duration.asDays() < -1) {
    callback(`${field} is invalid.`)
    return
  }
  callback()
}

const validateDateCompare = (rule, value, callback, field, required = false, compareDate) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  let duration = moment.duration(value.diff(compareDate));
  if (duration.asDays() < 0) {
    callback(`${field} is invalid.`)
    return
  }
  callback()
}

const validateTime = (rule, value, callback, field, required = false, selectedDate=null) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }

  let activeTime = value;
  if (selectedDate) {
    activeTime = moment(new Date(`${selectedDate.format('L')} ${value.format('LTS')}`))
  }
  
  let duration = moment.duration(activeTime.diff(moment(new Date())));
  
  if (duration.asHours() < 2) {
    callback(`${field} should be greater than 2 hours.`)
    return
  }
  callback()
}

const validateTimeToday = (rule, value, callback, field, required = false, flexible, activeRecord) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }

  const selectedDate = utils.convertToBahTimeZone(activeRecord.departureDate, '12:00 am');
  const activeDateTime = moment(new Date(`${selectedDate.format('L')} ${value.format('LTS')}`));
  const timeDiff = moment.duration(activeDateTime.diff(moment(new Date())));

  let noon = new Date(`${activeDateTime.format('L')} 12:00:00 PM`)
  let duration = moment.duration(activeDateTime.diff(noon));

  if ((flexible === 'Sunrise' && duration.asHours() > 0) || (flexible === 'Sunset' && duration.asHours() < 0) || timeDiff.asHours() < 2)  {
    callback(`${field} is invalid.`)
    return
  }
  callback()
}

const validateSSN = (rule, value, callback, field) => {
  if (!value) {
    callback()
    return
  }
  if (/^\d{9}$/.test(value) === false) {
    callback(`${field} accepts only numbers (0-9) and must be exactly nine (9) digits.`)
    return
  }
  callback()
}

const validateNumber = (rule, value, callback, field, required = false, min=null, max=null, plane, activeRecord) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (field !== 'Price' && /^\d+$/.test(value) === false) {
    callback(`${field} accepts only numbers`)
    return
  }
  if (field === 'Price' && /^\d+(\.\d+)?$/.test(value) === false) {
    callback(`${field} accepts only decimal numbers`)
    return
  }
  if (min && value < min) {
    callback(`${field} can't be less than ${min}`)
    return
  }
  if (max && value > max) {
    callback(`${field} can't be more than ${max}`)
    return
  }

  if (plane && (+plane.seatCapacity < +value)) {
    callback(`${field} can't be more than seats in plane (${plane.seatCapacity})`)
    return
  }

  if (activeRecord && (activeRecord.bookedSeats > +value)) {
    callback(`${field} can't be less than booked seats (${activeRecord.bookedSeats})`)
    return
  }

  callback()
}

const validateSecretCode = (rule, value, callback, field, required) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (/(^\d{6}$)/.test(value) === false) {
    callback(`${field} is invalid. Verify the format is correct.`)
    return
  }
  callback()
}

const validateZipCode = (rule, value, callback, field, required) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ' || /^[a-zA-Z0-9 -]+$/.test(value) === false) {
    callback(`${field} is invalid. Only the following characters are valid for this field: A-Z, 0-9, hypen(-) and single spaces.`)
    return
  }
  callback()
}

const validateUSZipCode = (rule, value, callback, field, required) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ') {
    callback(`${field}  - leading spaces found in your entry`)
    return
  }
  if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) === false) {
    callback(`${field} is invalid. Verify the format is correct.`)
    return
  }
  callback()
}

const validateAddress = (rule, value, callback, field, required = true) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ' || /^[A-Za-z0-9#,. ]+$/.test(value) === false) {
    callback(`${field} is invalid. Valid characters include A-Z and single spaces`)
    return
  }
  callback()
}

const validateTextAndNumber = (rule, value, callback, field, required = true) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ' || /^[A-Za-z0-9 ]+$/.test(value) === false) {
    callback(`${field} is invalid. Valid characters include A-Z, 0-9, and single spaces.`)
    return
  }
  callback()
}

const validateTailNumber = (rule, value, callback, field, required = true) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ' || /^[A-Za-z0-9- ]+$/.test(value) === false) {
    callback(`${field} is invalid. Valid characters include A-Z, 0-9 and -.`)
    return
  }
  callback()
}

const validateYear = (rule, value, callback, field, required = true) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value[0] === ' ' || /^(19|20)\d{2}$/.test(value) === false) {
    callback(`${field} is invalid. Valid characters include 19XX or 20XX.`)
    return
  }
  callback()
}

const validateRepeatOn = (rule, value, callback, field, required = true) => {
  if (!value || (value && value.length === 0)) {
    if (required) {
      return callback('This field is required')
    } else {
      return callback()
    }
  }
  callback()
}

const validateNumberOfBags = (rule, value, form, callback, field) => {
  console.log('validateNumberOfBags', value, form.getFieldValue('weightPerBag'))
  if (form.getFieldValue('weightPerBag') && !value) {
    return callback('This field is required')
  }
  callback()
}

const validateWeightPerBag = (rule, value, form, callback, field) => {
  console.log('validateWeightPerBag', value, form.getFieldValue('numberOfBags'))
  if (form.getFieldValue('numberOfBags') && !value) {
    return callback('This field is required')
  }
  callback()
}

const validatePlaneAvailableSeats = (rule, value, callback, field, activeRecord, fleets) => {
  if (!value) {
    return callback('This field is required');
  } else {
    if (activeRecord.passengers.length > +fleets.find(fleet => fleet.id === value).seatCapacity) {
      return callback(`There are not enough seats on a plane`);
    }
    return callback();
  }
}

const validateFlexible = (rule, value, callback, field, selectedDate, enabled) => {
  if (enabled) return callback();

  if (!value) {
    return callback('This field is required');
  }

  const activeDateTime = moment(new Date(`${selectedDate.format('L')} 12:00:00 ${value === 'Sunrise' ? 'AM' : 'PM'}`));
  const timeDiff = moment.duration(activeDateTime.diff(moment(new Date())));
  
  if ((value === 'Sunrise' || value === 'Sunset') && timeDiff.asHours() + 10 < 0) {
    return callback(`${field} is invalid.`);
  }
  
  callback();
}

const validatePickupDetailsField = (rule, value, callback, field, required = false) => {
  if (!value) {
    if (required) {
      callback('This field is required')
    } else {
      callback()
    }
    return
  }
  if (value.length > 30) {
    callback(`${field} is too long`)
    return
  }
  
  callback()
}

const validations = {
  validateForm,
  validateEmail,
  validatePassword,
  validatePassword2,
  validateConfirmPassword,
  validateText,
  validateUsers,
  validateBirthDay,
  validateDate,
  validateTime,
  validateTimeToday,
  validateSSN,
  validateNumber,
  validateSecretCode,
  validateZipCode,
  validateUSZipCode,
  validateAddress,
  validateTextAndNumber,
  validateTailNumber,
  validateYear,
  validateDateCompare,
  validateRepeatOn,
  validateNumberOfBags,
  validateWeightPerBag,
  validatePlaneAvailableSeats,
  validateFlexible,
  validatePickupDetailsField
}

export default validations
