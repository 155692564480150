import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router-dom"
import Spinner from 'react-spinkit'
import { auth } from "../../../firebaseConfig"
import { Form, Input, Button } from 'antd'
import * as utils from '../../../utils'
import { ArrowRightOutlined } from '@ant-design/icons'

function ResetPassword(props) {
  const [form] = Form.useForm()
  const {history} = props
  const [email, setEmail] = useState(null)
  const [error, setError] = useState('')
  const [validCode, setValidCode] = useState(null)
  const [verifiedCode, setVerifiedCode] = useState(false)

  useEffect(() => {
    auth
      .verifyPasswordResetCode(props.actionCode)
      .then(email => {
        setEmail(email)
        setValidCode(true)
        setVerifiedCode(true)
      }, error => {
        setError(error.message)
        setValidCode(false)
        setVerifiedCode(true)
      })
  }, [])

  const onSubmitHandler = (values) => {
    if (values.password !== values.confirmPassword) {
      utils.openNotification('Password does not match.', false)
    }
    else {
      // Save the new password.
      auth
        .confirmPasswordReset(props.actionCode, values.password)
        .then(() => {
          history.push('/auth/login')
          utils.openNotification('Password has been reset successfully!', true)
        }, error => {
          utils.openNotification(error.message, false)
        })
    }
  }

  if (!verifiedCode) {
    return <Spinner spinnerName="three-bounce" />
  } else if (verifiedCode && validCode) {
    if (props.continueUrl.indexOf('client') > -1)
      return <Redirect to={`/client/auth/change-password?actionCode=${props.actionCode}`} />
    return (
      <div className="fly-form auth-form v1-form">
        <h2>Password recovery</h2>
        <Form
          name="form"
          id="form"
          layout='vertical'
          form={form}
          onFinish={onSubmitHandler}
          requiredMark={false}
        >
          <Form.Item label="New Password" name="password"
            rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword(rule, value, callback, 'Password', true) }]}
          >
            <Input.Password className="fly-input fly-input-password" placeholder="At least 8 charactors" />
          </Form.Item>
          <Form.Item label="Confirm Password" name="confirmPassword"
            rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword(rule, value, callback, 'Password', true) }]}
          >
            <Input.Password className="fly-input fly-input-password" placeholder="At least 8 charactors" />
          </Form.Item>
          <Form.Item shouldUpdate className="mt-5 t-right">
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                className="fly-btn"
                disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !form.isFieldsTouched(true)}
              >
                NEXT<ArrowRightOutlined/>
              </Button>
              )}
          </Form.Item>
        </Form>
    </div>
    )
  } else if (verifiedCode && !validCode) {
    return (
      <div className="fly-form auth-form v1-form t-center">
        <p className="fly-info">{error}</p>
        <Button type="primary" className="fly-btn mt-4" onClick={() => history.push('/auth/login')}>
          Continue
        </Button>
      </div>
    )
  }
}

export default ResetPassword
