import React from 'react';
import './index.scss';
import { Layout } from 'antd'
import TpHeader from './TpHeader';

const { Header, Content, Footer } = Layout

function Terms(props) {
  return (
    <Layout className="terms-and-privacy-page">
      <TpHeader />
      <Content>
        <div className="tp-content">
          <h1>
            TERMS OF SERVICE
          </h1>
          <p>This Terms of Service (“Terms”, “Terms of Service”, “Agreement”) govern your accessing and use of our web pages located at <a href="https://flymingo.app/" target="_blank">https://flymingo.app</a> (“Service”, “Website”, “we”, “our”, “us”) and our mobile application Flymingo  (“App”) collectively as “Platform” owned and operated by  Eleven Hospitality Group LTD (“Company”, “Flymingo”).</p>

          <p>Our Privacy Policy also governs your use of our Service and explains how we collect, safeguard and disclose information that results from your use of our web pages and mobile application. Please read it here <a href="https://flymingo.app/privacy">https://flymingo.app/privacy</a>.</p>

          <p>We provide the Services through our Website and App. By accessing the Services through the Website and/or App, immediately and from your very first access of the Services, you unconditionally and irrevocably agree to be bound by these Terms and Conditions, all applicable laws and/or regulations and you agree that you are responsible for this compliance.</p>

          <p>Your agreement with us includes these Terms and our Privacy Policy (“Agreements”). You acknowledge that you have read and understood Agreements, and agree to be bound by them.</p>

          <p>If you do not agree with (or cannot comply with) Agreements, then you may not use the Service. These Terms apply to all visitors, users and others who wish to access or use our Service.</p>

          <p>We reserve the right to change these Terms and Conditions. These changes are effective immediately upon posting. When changes come into effect, the revised Terms and Conditions shall supersede the previous version of the Terms and Conditions. You are responsible for regularly reviewing these Terms and Conditions.</p>

          <p>
            Thank you for being responsible.
          </p>
          <h2>1. DEFINITIONS</h2>
          <p>1.1. Unless the context otherwise requires, the following words and expressions shall have the meaning set forth below: </p>
          <p><strong>App:</strong> means mobile application “Flymingo” owned and operated by Eleven Hospitality Group Ltd. </p>
          <p><strong>Applicable Laws:</strong> means all applicable laws and regulations relating to the Charter Flights from/to Bahamas; </p>
          <p><strong>Act of Insolvency:</strong> means if the Operator becomes insolvent, makes an assignment for the benefit of its creditors, ceases to do business, or if any bankruptcy, reorganization, arrangement, insolvency, or liquidation proceeding or other proceeding under any bankruptcy or other law for the relief of debtors is instituted by or against the Operator and is not dismissed within 90 days. </p>
          <p><strong>Additional Services:</strong> means all additional services including (without limitation), non-standard catering, airport shuttles or ground transfers, helicopter transfers, SATCOM services, wi-fi, passenger telecommunication usage, access to VIP lounges and terminals, VIP meet and greet services and any other requested extra services not included in the Flight and Charter Price.   </p>
          <p><strong>Agreement:</strong> means this Terms and Conditions and any subsequent written amendments agreed between the parties. </p>
          <p><strong>Aircraft:</strong> means the aircraft, the type and model which shall perform the Flight(s) being the subject of this Agreement. </p>
          <p><strong>Account:</strong> means the account can be accessed by its holder via the App. </p>
          <p><strong>Base Date:</strong> means the date of booking the Charter Flight via Flymingo. </p>
          <p><strong>Booking Order:</strong> means a booking the Charter Flight by Customer via Flymingo. </p>
          <p><strong>Customer Terms and Conditions Agreement:</strong> means the Flight and any Additional Services quotation document issued by Flymingo setting out the Charter Price, Flight details and Taxes payable and any other applicable additional terms and conditions and these Terms and Conditions. The Booking Order also serves as a legally binding Agreement once signed by the Customer. </p>
          <p><strong>Charter Price:</strong> means the price set out in consideration for the Flight(s) to be performed under this Agreement, excluding any Taxes, Exceptional Charges and the costs of Additional Services (if any) which are payable. </p>
          <p><strong>Convention:</strong> means whichever of the following apply The Convention for the Unification of Certain Rules Relating to International Carriage by Air, signed at Warsaw, 12 October 1929 (Warsaw Convention). The Warsaw Convention as amended at The Hague on 28 September 1955. The Warsaw Convention as amended by Additional Protocol No.1 of Montreal (1975). The Warsaw Convention as amended at The Hague and by Additional Protocol No. 2 of Montreal (1975). The Warsaw Convention as amended at The Hague and by Additional Protocol No. 4 of Montreal (1975). The Guadalajara Supplementary Convention (1961). The Convention for the Unification of Certain Rules for International Carriage by Air, signed at Montreal, 28 May 1999 (referred to below as the Montreal Convention). </p>
          <p><strong>Customer(s):</strong> means the person named as such in this Agreement who purchased the Charter Flight via the App; </p>
          <p><strong>Data Protection Legislation:</strong> means all applicable data protection legislation for the time being in force in the Bahamas including the Data Protection Act and the General Data Protection Regulation (Regulation 2016/679) ("GDPR") and any other data protection and privacy laws elsewhere in the world. </p>
          <p><strong>Exceptional Charges:</strong> means charges which are in relation to the Flight for exceptional circumstances that include (without limitation) overflight permissions, war risk insurance,  insurance premium surcharges, de-icing, fuel surcharges, and additional crew costs. </p>
          <p><strong>Flight:</strong> means any charter flight booked for the Customer via Flymingo (solely in its capacity as agent) as set out in this Agreement. </p>
          <p><strong>Flymingo:</strong> means mobile application owned and operated by Eleven Hospitality Group Ltd.  </p>
          <p><strong>Force Majeure Event:</strong> means an event or circumstance beyond the reasonable control of Flymingo, the Operator or provider of Additional Services, including (without limitation) events caused by adverse weather conditions, mechanical breakdown, unexpected Flight safety shortcomings, security or health risks, epidemics and pandemics (including any significant changes to border entry requirements, vaccination records and status, quarantine obligation and other travel restrictions for passengers or crew), industrial action, air traffic control decisions, unexpected airspace or airport restrictions or closure, the unexpected exercise of authority by government or other competent authority, withdrawal of traffic rights, political instability, terrorism or war, or acts of god. </p>
          <p><strong>Luggage:</strong> means any articles, baggage, cargo or belongings that accompany the Customer or Passenger(s) on the Flight. </p>
          <p><strong>Non-standard catering:</strong> means catering requested by the customer which is not included in the Charter Price and for which additional charges with accrue. </p>
          <p><strong>Operator:</strong> means the third-party independent air carrier that operates the Flight(s). </p>
          <p><strong>Passenger:</strong> means those persons or animals named on any Flight Confirmation documentation issued by Flymingo or the Operator as entitled to travel on the Flight. </p>
          <p><strong>Permissions:</strong> means (without limitation) all customs and immigration permits, parking, operational restrictions, take-off and landing slots, and all other permits and requirements necessary for the Flight. </p>
          <p><strong>Repositioning Legs:</strong> (also known as empty legs or positioning sectors) means those legs that arise as a result of a Flight that are surplus to the Customer(s) requirements, requiring the aircraft to be positioned to the point of departure or repositioned from the Customer(s) destination. </p>
          <p><strong>Services:</strong> means booking a Charter Flight via App. </p>
          <p><strong>Standard catering:</strong> means catering included in the charter price and provided by the operator based on the flight time and duration. </p>
          <p><strong>Taxes:</strong> means all local and foreign taxes, passenger taxes, imposts, duties and excise taxes and other similar amounts (including associated interest and penalties) imposed by any authority of competent jurisdiction relating to the provision of the Services to the Customer. This does not include taxes imposed in any jurisdiction on Flymingo net income, net profits or net gains. </p>
          <p><strong>Total Cost:</strong> means the total cost of the Charter Price specified for the Flight, and any Additional Services as listed in the App, but excluding Taxes and Exceptional Charges unless otherwise stated. </p>
          <p><strong>Login Credentials:</strong> means the registeration of an Account as a customer or a operator, you may provide Flymingo with your choice of account password and username.</p>
          <h2>2.  ACCEPTANCE</h2>
          <p>
            2.1.   By accessing the Website and/or the App, you accept to be bound by these Terms in full and to comply with all applicable laws. Should you disagree with these Terms or any part thereof, you may not access the Website and/or the App. As such your continued use of the Web, App and/or Services shall be deemed as your acceptance of these Terms.
          </p>

          <h2>3.  ELIGIBILITY</h2>
          <p>3.1.   You are at least 18 years of age. </p>
          <p>3.2.   You use the Platform and Services according to these Terms and all applicable laws and regulations determined by the country of residence. </p>
          <p>3.3.   You agree and understand that we may, at any time, and without prior notice, revoke and/or cancel your access if you fail to meet these criteria or violate any portion of these Terms. </p>

          <h2>4.  INTERMEDIARY SERVICE</h2>
          <p>4.1.   Flymingo is a mobile application (“Platform”) for booking the Charter Flight from/to Bahamas. </p>
          <p>4.2.   Flymingo is an intermediary service that solely arranging the sale of available seats on behalf of the Operator, and there shall be no contractual relationship between Flymingo and the Customer regard to the appointed seat. </p>
          <p>4.3.   Flymingo has no responsibilities in respect of the appointed seat or for fulfilment of the agreement between the Operator and the Customer. </p>
          <p>4.4.   Flymingo is solely collecting the purchase price for the available seat on behalf of the Operator and is thus merely acting as a payment agent on behalf of the Operator. </p>
          <p>4.5.   Flymingo is exclusively arranging the contract between the Customer and the Operator, Flymingo has no responsibilities in respect of the Charter Flight or fulfilment of the contract between the Operator and Customer. </p>
          <p>4.6.   Flymingo is not liable or responsible for any failure to perform or and delay in the performance of any obligations relating to the Charter Flight. </p>
          <p>4.7.   Operator acknowledges and agrees that this Agreement does not constitute a contract of carriage and that nothing in this Agreement will be interpreted on that basis. Flymingo is not a contracting carrier for the purposes of any aviation Convention or otherwise. At all times, the operator of the Aircraft, shall be the Operator. </p>
          <h2>5.  USE OF APP</h2>
          <p>5.1.   You may not use the App to transmit, distribute, store or destroy any material or information (i) in violation of any applicable law or regulation; (ii) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of any third party or violate the privacy or other personal rights of third parties; or (iii)  that is defamatory, obscene, threatening, abusive or hateful.</p>
          <p>5.2.   When accessing the App, you shall be prohibited from:</p>
          <p>5.2.1.using any robot, spider, other automatic device or manual process to monitor or copy any part of the App;</p>
          <p>5.2.2.using any device, software or the like to interfere or attempt to interfere with the proper functioning of the App;</p>
          <p>5.2.3.taking any action that imposes an unreasonable or disproportionately large data load on the App infrastructure;</p>
          <p>5.2.4.copying, reproducing, altering, modifying, creating derivative works, or publicly displaying any content from the App without the our prior written consent;</p>
          <p>5.2.5.reverse assembling, reverse engineering or otherwise attempting to discover any source code relating to the App or any tool therein, except to the extent that such activity is expressly permitted by applicable law notwithstanding this limitation; and</p>
          <p>5.2.6.attempting to access any area of the App to which access is not authorised. </p>
          <p>5.3.   You may access Flymingo and the Services via our App.</p>
          <p>5.4.   In order to be able to use the App, you shall (i) download and install the App on a mobile device which is compatible with the App, as well as (ii) have an Internet connection.</p>
          <p>5.5.   You agree that you are solely responsible for meeting the aforementioned requirements, which may also (i) bear additional charges and fees, and/or (ii) require you to perform software updates and/or to comply with the terms and conditions of your agreement(s) with your mobile device and telecommunications provider.</p>

          <h2>6.  LIMITATION ON USE</h2>
          <p>6.1.   The Flymingo website is provided for personal and commercial use users worldwide. If you choose to access Flymingo platform from locations outside of Bahamas you are responsible for compliance with any other applicable local laws.</p>
          <p>6.2.   We seek to make the Flymingo platform accessible at all times but do not guarantee that access to Flymingo platform will be uninterrupted or error-free. You agree that you are solely responsible for the means by which you access the Flymingo platform and for any costs and expenses you may incur in relation to your use of the Flymingo platform.</p>
          <p>6.3.   We may prevent or suspend your access to or use of the Flymingo if you do not comply with any part of these Terms of Service, any other terms or policies applicable to use of the Flymingo  platform, or any applicable law.</p>

          <h2>7.  GENERAL UNDERTAKING</h2>
          <p>7.1.   When you use our Service you accept that you will not:</p>
          <p>7.1.1.breach any applicable laws or regulations that apply;</p>
          <p>7.1.2.breach any of the Terms;</p>
          <p>7.1.3.post or take any threatening, abusive, defamatory, obscene, inappropriate action or post any indecent material, or material or action that is invasive of another’s privacy (including but not limited to other User and our employees);</p>
          <p>7.1.4.share or otherwise upload or communicate any misleading or false information or messages of any kind;</p>
          <p>7.1.5.use our Service to intentionally deceive other Users;</p>
          <p>7.1.6.use our Service to stalk or otherwise harass another User;</p>
          <p>7.1.7.use our Service if we have suspended or banned you from using it.</p>
          <p>7.1.8.infringe the Intellectual Property Rights of any third-party;</p>
          <p>7.1.9.distribute any viruses or any other technologies that may harm our Service or its Users</p>
          <p>7.1.10.   interfere with the property working of our Service</p>
          <p>7.1.11.   copy modify, or distribute any other person’s Content without their consent use any robot, spider, scraper or other automated means to access our Service and/or collect Content or data for any purpose;</p>
          <p>7.1.12.   collect information about other Users, including email addresses, postal addresses, phone numbers, credit card or banking information or similar information without their explicit consent;</p>
          <p>7.1.13.   copy, modify or distribute rights or Content or collect data from our Service, applications or tools or otherwise infringe our or User’s Intellectual Property Rights;</p>
          <p>7.1.14.   bypass, disable or attempt to disable any security measures used in connection with our Service;</p>
          <p>7.1.15.   collect any data (including personal data) from our Service other than in accordance with these Terms and applicable laws;</p>
          <p>7.1.16.   sell any counterfeit Items or otherwise infringe the copyright, trademark or other rights of third parties;</p>
          <p>7.1.17.   write and post reviews that are anything other than true and accurate to the best of your knowledge; or</p>
          <p>7.1.18.   impersonate any person or misrepresent your affiliation with a person or entity.</p>

          <h2>8.  COMMUNICATIONS</h2>
          <p>8.1.   You agree to subscribe to newsletters, marketing or promotional materials and other information we may send.</p>
          <p>8.2.   You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or by emailing at <a href="mailto:support@flymingo.io">support@flymingo.io</a>.</p>
          <h2>9.  ACCESS AND USE</h2>
          <p>9.1.   You must only use the Service in accordance with these Terms and any applicable law.</p>
          <p>9.2.   You must not (or attempt to):</p>
          <p>9.2.1. Interfere with or disrupt the Service or the server or networks that host the Site;</p>
          <p>9.2.2. Use data mining, robots, screen scraping or similar data gathering and extraction tools on the Site; or</p>
          <p>9.2.3. Interfere with security-related or other features of the Service.</p>
          <p>9.3.   We do not warrant that the Service will be available at all times and without disruption and we provide no warranties in relation to the content of any other website linked to or from our Service.</p>
          <p>9.4.   You must not link to our Service or any part of our Service in a way that damages or takes advantage of our reputation, including but not limited to:</p>
          <p>9.4.1. In a way to suggest or imply that you have any kind of association and affiliation with us, or approval and endorsement from us when there is none; or</p>
          9.4.2. In a way that is illegal or unfair.
          <h2>10.  PROHIBITED USES</h2>
          <p>10.1.             You may use Service only for lawful purposes and in accordance with Terms.</p>
          <p>10.2.             You agree not to use Service:</p>
          <p>10.2.1.   In any way that violates any applicable national or international law or regulation.</p>
          <p>10.2.2.   For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</p>
          <p>10.2.3.   To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.</p>
          <p>10.2.4.   To impersonate or attempt to impersonate us, our employee, another user, or any other person or entity.</p>
          <p>10.2.5.   In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.</p>
          <p>10.2.6.   To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of Service, or which, as determined by us, may harm or offend the us or users of Service or expose them to liability.</p>
          <p>10.3.             Additionally, you agree not to:</p>
          <p>10.3.1.   Use Service in any manner that could disable, overburden, damage, or impair Service or interfere with any other party’s use of Service, including their ability to engage in real time activities through Service.</p>
          <p>10.3.2.   Use any robot, spider, or other automatic device, process, or means to access Service for any purpose, including monitoring or copying any of the material on Service.</p>
          <p>10.3.3.   Use any manual process to monitor or copy any of the material on Service or for any other unauthorized purpose without our prior written consent.</p>
          <p>10.3.4.   Use any device, software, or routine that interferes with the proper working of Service.</p>
          <p>10.3.5.   Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.</p>
          <p>10.3.6.   Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of Service, the server on which Service is stored, or any server, computer, or database connected to Service.</p>
          <p>10.3.7.   Attack Service via a denial-of-service attack or a distributed denial-of-service attack.</p>
          <p>10.3.8.   Take any action that may damage or falsify our rating.</p>
          <p>10.3.9.   Otherwise attempt to interfere with the proper working of Service.</p>
          <h2>11.  SAFETY   </h2>
          <p>11.1.             User will not (nor assist others to) violate any applicable law, contract, intellectual property, or other third-party right, and User is solely responsible for its conduct while using our Services.</p>
          <p>11.2.             User must not directly, indirectly, or through automated or other means:</p>
          <p>11.2.1.1.       engage in any harassing, threatening, intimidating, predatory, or stalking conduct; </p>
          <p>11.2.1.2.       impersonate or register on behalf of any person or entity or otherwise misrepresent your affiliation with a person or entity, perpetrate fraud, or publish falsehoods or misleading statements;</p>
          <p>11.2.1.3.       collect information of or about other users in any impermissible or unauthorized manner;</p>
          <p>11.2.1.4.       use our Services other than for their intended purpose or interfere with, disrupt, negatively affect, or inhibit other users;</p>
          <p>11.2.1.5.       damage, disable, overburden, or impair our Services;</p>
          <p>11.2.1.6.       send, distribute, or post spam, unsolicited electronic communications, chain letters, pyramid schemes, or illegal or impermissible communications;</p>
          <p>11.2.1.7.       post, upload, or share any content which is unlawful, libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory, fraudulent, or is in our sole judgment objectionable;</p>
          <p>11.2.1.8.       encourage or provide instructions for a criminal offense;</p>
          <p>11.2.1.9.       distribute any viruses, corrupted data, or other harmful, disruptive, or destructive files or content;</p>
          <p>11.2.1.10.    bypass, ignore, or circumvent instructions in our robots.txt file or any measures we employ to prevent or limit access to any part of our Services, including content-filtering techniques; or</p>
          <p>11.2.1.11.    expose us or others to any type of harm or liability.</p>

          <h2>12.  OPERATOR</h2>
          <p>12.1.             If a Operator wishes to provide Charter Flight Service via the Platform, the Operator shall sign-up on the Platform and provide Flymingo with the information requested by Flymingo (“Operator Information”), including but not limited to:</p>
          <p>12.1.1.   Business name and address</p>
          <p>12.1.2.   Registration number</p>
          <p>12.1.3.   Bank account information</p>
          <p>12.1.4.   Contact information</p>
          <p>12.2.             The Operator shall at all times keep the Charter Flight Information updated on the Platform.</p>
          <p>12.3.             By signing up on the Platform, the Operator authorizes for the duration of the Agreement, Flymingo to:</p>
          <p>12.3.1.   Offer the Available Charter Flights for sale to users on the Platform (“Users”);</p>
          <p>12.3.2.   Accept Booking Orders for Available Charter Flights placed by Users on the Platform and issue a Booking Order Confirmation.</p>
          <p>12.3.3.   Receive payment on behalf of the Operator for the Charter Flight.</p>
          <p>12.3.4.   Use the Operator’s trademarks and Aircraft Information on the Platform for the sole purpose of conducting Flymingo’s services as described herein.</p>
          <p>12.4.             By signing up on the Platform, the Operator accepts to follow the instructions from Flymingo necessary to conduct or improve the services provided hereunder.</p>

          <h2>13.  OPERATOR RESPONSIBILITY            </h2>
          <p>13.1.             The Operator remains responsible at all times for the operation of the Aircraft with the safe performance of the Flight.</p>
          <p>13.2.             The captain shall have absolute discretion in all the matters concerning the Aircraft.</p>
          <p>13.3.             The Customer shall be liable to bear any additional costs incurred by reason of any diversion of the Aircraft caused either by any Force Majeure, act or omission of the Passengers or other cause beyond the control of the Operator.</p>
          <p>13.4.              The Operator reserves the right to decide that additional crew are required to be transported onboard a Flight for safety reasons.</p>
          <p>13.5.             The Operator is solely and exclusively responsible for the maintenance, cleanliness and upkeep of the Aircraft. </p>
          <p>13.6.             The Operator has sole responsibility, liability and control of all aspects of the aircraft charter services provided, including without limitation, aircraft availability and pricing, the commencement and termination of scheduled Flights, the operation, regulation and safety of the Flight. Carriage by air of the Passengers and their Luggage on any Flight performed by the Operator shall at all times be subject to the conditions of carriage (or equivalent) of the Operator (if any) and the relevant statutory and/or other provisions relating to liability, including any applicable Convention.</p>

          <h2>14.  OPERATOR OBLIGATIONS</h2>
          <p>14.1.             The Operator shall pay the Commission and other payments due to the Flymingo.</p>
          <p>14.2.             The Operator shall promptly do all things (and provide such information) as reasonably required by Flymingo to comply with its obligations under these Terms and Conditions, including:</p>
          <p>14.2.1.   providing full, complete and accurate Aircraft information in the App in relation to each flight, including a full description and photographs of the Aircraft.</p>
          <p>14.2.2.   providing any other relevant information in relation to the Charter Flight.</p>
          <p>14.2.3.   complying with Flight Carriage requirements under Applicable Law.</p>
          <p>14.3.             In respect of all Booking Orders made by the Customers and processed by Flymingo, the Operator shall:</p>
          <p>14.3.1.   immediately accept or reject the order made by the Customer through the Website/App;</p>
          <p>14.3.2.   confirm time slot of Charter Flight for the Customer on accepting the Booking Order;</p>
          <p>14.3.3.   prepare the Aircraft in accordance with the Aircraft and Booking Order information provided by the Operator in the Website/App;</p>
          <p>14.3.4.   process Refunds in accordance with section “Sales, Returns and Refund” in this Agreement.</p>
          <p>14.3.5.   resolve issues relating to claims, returns with the Customer in accordance with the Customer Terms and Conditions.         </p>


          <h2>15.  SALES, RETURNS AND REFUNDS</h2>
          <p>15.1.              The Operator acknowledges and agrees that, in providing the Services:</p>
          <p>15.1.1.   Flymingo is providing a Platform for the Operator online sales of the Charter Flights only;</p>
          <p>15.1.2.   Flymingo is not a reseller of the Operator (and nothing in these Terms and Conditions is intended to or shall operate so as to appoint Flymingo as reseller); and</p>
          <p>15.1.3.   the contract for the Flight Carriage is between the Operator and the Customer (concluded on the Operator’s behalf by Flymingo pursuant).</p>
          <p>15.2.             The Operator acknowledges and agrees that this Agreement shall apply to all Booking Orders and be incorporated into all contracts between the Operator and the Customers.</p>
          <p>15.3.              The Operator shall fully comply with its obligations under this Agreement and shall do all things reasonably required by Flymingo to ensure such compliance.</p>
          <p>15.4.             The Operator shall accept and process all returns and refunds in accordance with the this Terms and Conditions and in accordance with Applicable Laws.</p>
          <p>15.5.             The Operator shall ensure that Returns are processed immediately after the returns and refunds are accepted by the Operator.</p>
          <p>15.6.             The Operator is responsible for any additional duties or charges made on return or refund of fees paid by the Customer, so that the Customer receives a full refund.  </p>


          <h2>16.  PAYMENT</h2>
          <p>16.1.             If the Booking Order is cancelled in accordance with these Terms, the Total Cost reserved, authorized or captured shall be cancelled or refunded, and no amounts are due for payment in the relation between the Operator and Flymingo.</p>
          <p>16.2.             The Operator hereby authorizes Flymingo to issue invoices or payment confirmation to Customers relating to the purchase the Charter Flights via the Platform and collect payment for the Charter Flights sold via the Platform on behalf of the Operator. The Operator may issue and provide the Customer with a receipt confirming the sale upon the Charter Flight.</p>
          <p>16.3.             Flymingo issues an invoice, subject to VAT if applicable, to the Operator in the amount of the fee earned by Flymingo relating to all purchases made by Customers from the Operator via the Platform in the preceding period in accordance with the prices set out in the Booking Order. Flymingo may include fees that Flymingo occurs from its payment providers when processing orders.</p>
          <p>16.4.             The Operator is responsible for its own legal obligations, including with respect to accounting, tax and VAT and Flymingo shall have no responsibility in this respect. If applicable, Flymingo’s service are subject to reserve charge and cross-border VAT.</p>
          <p>16.5.             Flymingo may amend, modify or restructure the payment procedure for its Customers, as it deems reasonable.</p>

          <h2>17.  REGISTRATION, LOGIN CREDENTIALS AND SECURITY  </h2>
          <p>17.1.             In order to be able to make use of and access the Services, Website and App, you shall register on the Website and/or App by providing Company with all required information which includes but is not limited to your full name, business name, address and contact information.</p>
          <p>17.2.             You agree to (i) provide true, accurate, current and complete information about yourself as prompted by the sign-up process; and (ii) maintain and promptly update the information provided during registration to keep it true, accurate, current, and complete.</p>
          <p>17.3.             Each account can be accessed by its/his/her holder via the Website (“Account”).</p>
          <p>17.4.             If you provide any information to Company that is untrue, inaccurate, outdated or incomplete, or if we have reasonable grounds to suspect that such information is untrue, inaccurate, outdated or incomplete, we may terminate your Account with us and refuse current or future use of any or all of the Services.</p>
          <p>17.5.             When registering an Account as a customer, you may provide Company with your choice of account password and username (“Login Credentials”).</p>
          <p>17.6.             You hereby:</p>
          <p>17.6.1.   acknowledge and agree that you are solely responsible for maintaining the confidentiality of your Login Credentials and for any and all activities that occur under your account;</p>
          <p>17.6.2.   agree to notify Company immediately of any unauthorized use of your account or of any other breach of security. Company shall not be liable for any loss that you may incur as a result of any third party using your Login Credentials or Account, either with or without your knowledge. However, you may be held liable for losses incurred by Company as a result of a third party using your Account or Login Credentials.</p>
          <p>17.6.3.   acknowledge and agree that you may not use anyone else's Account at any time, without the express permission of such account holder.       </p>


          <h2>18.  ACCOUNTS        </h2>
          <p>18.1.             When you create an account with us, you guarantee that you are above the age of 18, and that the information you provide us is accurate, complete, and current at all times.</p>
          <p>18.2.             Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on Service.</p>
          <p>18.3.             You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account.</p>
          <p>18.4.             You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Service or a third-party service.</p>
          <p>18.5.             You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>
          <p>18.6.             You may not use as a username the name of another person or entity or that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you, without appropriate authorization.</p>
          <p>18.7.             You may not use as a username any name that is offensive, vulgar or obscene.</p>
          <p>18.8.             We reserve the right to refuse service, terminate accounts, remove or edit content, or cancel orders in our sole discretion.</p>

          <h2>19.  PLATFORM</h2>
          <p>19.1.             Flymingo shall market the Operator on the Platform together with the Available Charter Flight provided by the Operator for sale to Users, provided that the Operator has Available Charter Flight for sale on the Platform.</p>
          <p>19.2.             Flymingo reserves the right to modify the method and style of representation options or to make adjustments in line with technical circumstances.</p>
          <p>19.3.             It is the obligation of the Operator to ensure that the Platform is updated with necessary information regarding the Operator, Available Charter Flight, at all times.</p>
          <p>19.4.             Flymingo shall be entitled to submit a Booking Order Confirmation based on the information made available by the Operator on the Platform.</p>
          <p>19.5.             Flymingo shall provide and maintain the Platform with all associated technical capacities. Further, Flymingo shall ensure that the Operator Information is properly reflected on the Platform.</p>
          <p>19.6.             Flymingo shall make best efforts to maintain uninterrupted availability of the Platforms, as far as this is possible. Temporary interruptions to the availability of the Platforms or occasional errors in their use may occur due to maintenance work, for security reasons or for reasons beyond the control of Flymingo (e.g. power failure or interruption of the public communication network.</p>
          <p>20.  Flymingo may conduct updates and maintenance on the Platform. Maintenance work is essential, for example, for improving the functionality of the Platforms and increasing efficiency in the ordering process to the benefit of both the Operators and Customers.</p>
          <h2>21.  ACCOUT TERMINATION         </h2>
          <p>21.1.             We may terminate or suspend your account and access to Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of Terms.</p>
          <p>21.2.             If you wish to terminate your account, you may simply discontinue using Service.</p>
          <p>21.3.             All provisions of Terms which by their nature should survive termination shall survive termination, including, without limitation, warranty disclaimers, indemnity and limitations of liability.</p>
          <p>21.4.             You may terminate this Agreement with Flymingo and close your Account at any time, following settlement of any pending transactions.</p>
          <p>21.5.             Notwithstanding any other provision of this Agreement, User acknowledges and agrees that Flymingo has the right to immediately terminate the User Account, and terminate access to the Service in the following cases:</p>
          <p>21.5.1.   there is any suspect or the fact of violation of this Agreement, Privacy Policy, or any applicable laws and regulations;</p>
          <p>21.5.2.   this User Account and activities of User became the subject to a criminal investigation or other pending administrative proceeding or litigation;</p>
          <p>21.5.3.   Flymingo detected unusual and suspicious activity in the User Account;</p>
          <p>21.5.4.   in case of unauthorized access to the User Account;</p>
          21.6.             Flymingo was required to do so by a court order or the order of regulatory/government authority.
          <h2>22.  CANCELLATION</h2>
          <p>22.1.             Flymingo or the Operator shall be entitled to cancel the Flight without notice to the Customer, and cancellation charges will be payable to Flymingo in accordance with this Agreement, in the event of:</p>
          <p>22.1.1.  cancellation of the Booking Order by the Customer;</p>
          <p>22.1.2.  a delay of any nature which in the opinion of the Operator or Flymingo is unreasonable; or</p>
          <p>22.1.3.  a failure of any Passenger to board the Flight</p>
          <p>22.1.4.  a failure to provide necessary Passenger documentation required for the Flight</p>

          <h2>23.  YOUR CONDUCT           </h2>
          <p>23.1.             By using the Flymingo, you agree not to upload, post, e-mail or otherwise send or transmit any material that contains viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment associated with the Flymingo.</p>
          <p>23.2.             You also agree not to interfere with the servers or networks connected to the Flymingo or to violate any of the procedures, policies or regulations of networks connected to the Flymingo, the terms of which are incorporated herein.</p>
          <p>23.3.             You also agree not to: (1) impersonate any other person while using the Flymingo; (2) conduct yourself in a vulgar, offensive, harassing or objectionable manner while using the Flymingo; or (3) use the Flymingo for any unlawful or improper purpose.</p>

          <h2>24.  INTELLECTUAL PROPERTY   </h2>
          <p>24.1.             Content found on or through this Service are the property of Flymingo or used with permission. You may not distribute, modify, transmit, reuse, download, repost, copy, or use said Content, whether in whole or in part, for commercial purposes or for personal gain, without express advance written permission from us.</p>
          <p>24.2.             Service and its original content (excluding Content provided by users), features and functionality are and will remain the exclusive property of Flymingo and its licensors.</p>
          <p>24.3.             Service is protected by copyright, trademark, and other laws of Bahamas.</p>
          <p>24.4.             Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Flymingo.</p>
          <p>24.5.             All code, text, software, scripts, graphics, files, photos, images, logos, and materials contained on this website, our applications, or within the services, are the sole property of  Flymingo.</p>
          <p>24.6.             Unauthorized use of any materials contained on this Website, our applications or within the Service may violate copyright laws, trademark laws, the laws of privacy and publicity, and/or other regulations and statutes. If you believe that any of the materials infringe on any third party's rights, please contact Flymingo immediately at: <a href="mailto:support@flymingo.io">support@flymingo.io</a>.</p>
          <p>24.7.             All content, trademarks, data, information or information contained in any materials, or documents used in relation to the Flymingo and the Services, including but not limited to any and all copyrighted works, software, databases, text, graphics, icons, designs, logos, graphics, hyperlinks, domain names, information and agreements (“Content”), are the exclusive property of or are licensed to the Flymingo.</p>
          <p>24.8.             You may not reproduce, distribute or copy the Content by any means, whether electronically or not, without the Flymingo’s prior written permission.</p>
          <p>24.9.             Any and all intellectual property rights in the Content, Services and the Flymingo or otherwise developed by or on behalf of the Flymingo, including all patents, rights in inventions, rights in designs, trademarks, trade and business names and all associated goodwill, rights to sue for passing off or for unlawful competition, copyright, moral rights and related rights, rights in databases, topography rights, domain names, rights in information (including know how and trade secrets) and all other similar or equivalent rights existing in the Content, Services and the Flymingo or otherwise developed by or on behalf of the Flymingo, now or in the future in any part of the world, in each case whether registered or unregistered and including all applications for, and renewals or extensions of, such rights for their full term (“Intellectual Property”), vests in the Flymingo, its licensors or suppliers, as the case may be, and all rights not expressly granted by the Flymingo to you are reserved by the Flymingo.</p>
          <p>24.10.          Save as expressly set out herein and in the Contracts, you shall not acquire any right, title or interest in the Flymingo’s Intellectual Property.</p>
          <p>24.11.          You may not use the Flymingo’s Intellectual Property in a manner which may (i) place the Flymingo’s Intellectual Property at risk of losing value, and (ii) cause reputational damage to the Flymingo.</p>

          <h2>25.  ERROR REPORTING AND FEEDBACK           </h2>
          <p>25.1.             You may provide us either directly at <a href="mailto:support@flymingo.io">support@flymingo.io</a> or via third party sites and tools with information and feedback concerning errors, suggestions for improvements, ideas, problems, complaints, and other matters related to our Service (“Feedback”).</p>
          <p>25.2.             You acknowledge and agree that: (i) you shall not retain, acquire or assert any intellectual property right or other right, title or interest in or to the Feedback; (ii) the Flymingo may have development ideas similar to the Feedback; (iii) Feedback does not contain confidential information or proprietary information from you or any third party; and (iv) the Flymingo is not under any obligation of confidentiality with respect to the Feedback.</p>
          <p>25.3.             In the event the transfer of the ownership to the Feedback is not possible due to applicable mandatory laws, you grant the Flymingo and its affiliates an exclusive, transferable, irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use (including copy, modify, create derivative works, publish, distribute and commercialize) Feedback in any manner and for any purpose.</p>
          <p>25.4.             We shall not be liable for any typo or any information being incorrect in this Agreement, in case you have discovered such type or mistake in wording of this Agreement you may inform us about such mistake directly at <a href="mailto:support@flymingo.io">support@flymingo.io</a>.</p>

          <h2>26.  LINKS TO OTHER WEB SITES</h2>
          <p>26.1.             Our Service may contain links to third party web sites or services that are not owned or controlled by Flymingo.</p>
          <p>26.2.             Flymingo has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party web sites or services. We do not warrant the offerings of any of these entities/individuals or their websites.</p>
          <p>26.3.             YOU ACKNOWLEDGE AND AGREE THAT WE SHALL NOT BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.</p>
          <p>26.4.             WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.</p>
          <h2>27.  DISCLAIMER OF WARRANTY</h2>
          <p>27.1.             THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.</p>
          <p>27.2.             FLYMINGO PROVIDES THE WEBSITE AND APP “AS IS” AND WITHOUT ANY WARRANTY OR CONDITION, WHETHER EXPRESS, IMPLIED OR STATUTORY. FLYMINGO SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF TITLE, MECHANTABILITITY, FITNESS FOR A PARTICULAR PURPOSR AND NON-INFRINGEMENT. FLYMINGO ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY ERRORS OR OMISSIONS IN THE WEBSITE;  ANY FAILURES, DELAYS OR INTERRUPTIONS IN THE WEBSITE; ANY LOSSES OR DAMAGES ARISING, FROM THE USE OF THE USE OF THE WEBSITE, INCLUDING, WITHOUT LIMITATION, ANY DAMAGE TO YOUR HOUSEHOLD/OFFICE/BUILDING, FURTNITURE ETC; OR ANY CONDUCT BY USERS OF THE WEBSITE. WE RESERCE THE RIGHT TO DELIVER, ALTER, SUSPEND OR RESTRICT THE WEBSITE IN OUR SOLE AND ABSOLUTE DISCRETION.</p>
          <p>27.3.             NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
          <p>27.4.             THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.</p>
          <p>27.5.             THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>

          <h2>28.  LIMITATION OF LIABILITY   </h2>
          <p>28.1.             EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF THE COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF THE COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES.</p>
          <p>28.2.             Operators have sole and exclusive operational control over all Aircraft. Flymingo has no discretion or responsibility regarding operational matters, including (without limitation) whether a Flight is carried out and the loading and unloading of the Aircraft. All safety, security and operational matters are at the absolute discretion of the pilot in command.</p>
          <p>28.3.             The Operator acknowledges that there is no partnership, agency, joint venture or any other similar relationship between Flymingo and the Operator.</p>
          <p>28.4.             To the fullest extent permitted by law, Flymingo’s total liability to the Operator for any claims, damages, losses and liabilities arising as a direct result of Flymingo’s performance or non-performance of this Agreement, including breach of contract or misrepresentation, shall not exceed the aggregate amount of the Total Cost set out in the Booking Order.</p>
          <p>28.5.             Flymingo is not excluding or restricting the liability owed under any applicable law in relation to the liability for death or personal injury caused by its fraudulent misrepresentation, negligence or any other liability that cannot otherwise be limited or excluded under law.</p>
          <p>28.6.             All warranties, conditions, representations whatsoever implied by statutory or common law are, to the fullest extent permitted by law, excluded from this Agreement.</p>

          <h2>29.  FORCE MAJEURE         </h2>
          <p>29.1.             A force majeure event means any event beyond a Party’s reasonable control that, by its nature, could not have been foreseen or, if it could have been foreseen, was unavoidable, including strikes, lock-outs or other industrial disputes (whether involving its own workforce or a third party’s), acts of God, war, riot, embargoes, acts of civil or military authorities, acts of terrorism or sabotage, shortage of supply or delay in delivery by our vendors, fire, flood, earthquake, accident, radiation, inability to secure transportation, failure of communications or energy sources, malicious damage, breakdown of plant or machinery, or default of suppliers or sub-contractors.</p>
          <p>29.2.             Neither Party is liable for delays or failures to perform any of its obligations under these Terms to the extent caused by a force majeure event.          </p>
          <p>29.3.             The Total Cost will remain payable to Flymingo if delay, cancellation or non or partial performance of any Flight or any Additional Service is due to the occurrence of a Force Majeure Event.</p>
          <p>29.4.              For the avoidance of doubt Flymingo shall use reasonable endeavors to ensure that the Operator completes all Flights as specified in the Booking order, however Flymingo shall be entitled to depart from the agreed flying schedule for any cause beyond its reasonable control such as a diversion or a Force Majeure Event, and the Customer shall reimburse Flymingo on demand for any additional expenses incurred as a result.</p>

          <h2>30.  GOVERNING LAW        </h2>
          <p>30.1.             These Terms shall be governed and construed in accordance with the laws Bahamas without regard to its conflict of law provisions.</p>
          <p>30.2.             Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights.</p>
          <p>30.3.             If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p>
          <p>30.4.             These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have had between us regarding Service.</p>
          <p>30.5.             Each party irrevocably agrees that the courts of Bahamas shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this agreement or its subject matter or formation (including non-contractual disputes or claims).</p>
          <p>30.6.             Notwithstanding the specified agreement on jurisdiction, you and the Company shall, if any dispute arises, attempt to settle it by mutual negotiations.</p>
          <h2>31.  CHANGES TO SERVICE           </h2>
          <p>31.1.             We reserve the right to withdraw or amend our Service, and any service or material we provide via Service, in our sole discretion without notice.</p>
          <p>31.2.             We will not be liable if for any reason all or any part of Service is unavailable at any time or for any period.</p>
          <p>31.3.             From time to time, we may restrict access to some parts of Service, or the entire Service, to users, including registered users.</p>
          <h2>32.  AMENDMENTS TO TERMS      </h2>
          <p>32.1.             We may amend Terms at any time by posting the amended terms on this site. It is your responsibility to review these Terms periodically.</p>
          <p>32.2.             Your continued use of the Platform following the posting of revised Terms means that you accept and agree to the changes.</p>
          <p>32.3.             You are expected to check this page frequently so you are aware of any changes, as they are binding on you.</p>
          <p>32.4.             By continuing to access or use our Service after any revisions become effective, you agree to be bound by the revised terms.</p>
          <p>32.5.             If you do not agree to the new terms, you are no longer authorized to use Service.</p>
          <h2>33.  INDEMNITY       </h2>
          <p>33.1.             To the fullest extent permitted by law, the Customer shall indemnify and hold Flymingo harmless from and against all claims, demands, liens, judgments, penalties, awards, remedies, liabilities, damages, actions, proceedings, costs and expenses, (including but not limited to legal costs) of whatsoever nature and howsoever and whensoever arising:</p>
          <p>33.1.1.   as a result of breach by the Customer of any undertakings, warranties or obligations under this Agreement;</p>
          <p>33.1.2.   in connection with any injury suffered by any person or damage caused to the Aircraft as a result of the actions of Customer or any Passenger;</p>
          <p>33.1.3.   when acting in accordance with the Customer’s instructions; or</p>
          <p>33.2.             when any Passenger is refused entry at any destination airport (including, but not limited to any arrangements made by Flymingo or the Operator to return such Passenger to the country from which they were originally carried).</p>
          <h2>34.  WAIVER AND SEVERABILITY</h2>
          <p>34.1.             No waiver by the Flymingo of any term or condition set forth in Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Flymingo to assert a right or provision under Terms shall not constitute a waiver of such right or provision.</p>
          <p>34.2.             If any provision of Terms is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of Terms will continue in full force and effect.</p>
          <h2>35.  ACKNOWLEDGEMENT            </h2>
          <p>35.1.             BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.</p>
          <h2>36.  CONTACT US     </h2>
          <p>36.1.             For more information about our Term and Conditions, if you have questions, or if you would like to make a complaint, please send your feedback, comments, requests for technical support: by email: <a href="mailto:support@flymingo.io">support@flymingo.io</a></p>
        </div>
      </Content>
  </Layout>
  )
}

export default Terms;
