import React from 'react';
import { Helmet } from 'react-helmet';
import { CLIENT_DOMAIN, PILOT_DOMAIN } from '../../constants';

const FlyHelmet = ({ chatra, analytics, staticBarColor }) => {
    const domain = window.location.host;

    return (
        <Helmet>
            {analytics && (
                  <script>
                      {`
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer','GTM-WHH9NWD');
                    `}
                  </script>
            )}
            {(domain === PILOT_DOMAIN || domain === CLIENT_DOMAIN) && chatra && (
                <script>
                    {`(function(d, w, c) { w.ChatraID = '${domain === PILOT_DOMAIN ? 'mjuTJ82ePZSa8GarK' : 'FsaW2WcqD7gLDzagX'}'; var s = d.createElement('script'); w[c] = w[c] || function() { (w[c].q = w[c].q || []).push(arguments); }; s.async = true; s.src = 'https://call.chatra.io/chatra.js'; if (d.head) d.head.appendChild(s); })(document, window, 'Chatra');`}
                </script>
            )}
            (staticBarColor && <meta name="theme-color" content="#277688"/>)
        </Helmet>
    )
}

export default FlyHelmet;
