const resources = {
	roles: [
		{label: 'Owner', value: 'Owner'},
		{label: 'Sales', value: 'Sales'},
		{label: 'Manager', value: 'Manager'},
		{label: 'Pilot', value: 'Pilot'},
		{label: 'Other', value: 'Other'},
	],
	defaultCountries: [
		{label: 'Bahamas', value: 'Bahamas'},
		{label: 'United States', value: 'United States'},
		{label: 'Turks & Caicos', value: 'Turks & Caicos'},
		{label: 'Cuba', value: 'Cuba'},
		{label: 'Jamaica', value: 'Jamaica'},
		{label: 'Cayman Islands', value: 'Cayman Islands'},
	],
	allCountries: [
		{label: 'Afghanistan', value: 'Afghanistan'},
		{label: 'Angola', value: 'Angola'},
		{label: 'Albania', value: 'Albania'},
		{label: 'Andorra', value: 'Andorra'},
		{label: 'Anguilla', value: 'Anguilla'},
		{label: 'United Arab Emirates', value: 'United Arab Emirates'},
		{label: 'Argentina', value: 'Argentina'},
		{label: 'Armenia', value: 'Armenia'},
		{label: 'Antigua And Barbuda', value: 'Antigua And Barbuda'},
		{label: 'Australia', value: 'Australia'},
		{label: 'Austria', value: 'Austria'},
		{label: 'Azerbaijan', value: 'Azerbaijan'},
		{label: 'Burundi', value: 'Burundi'},
		{label: 'Belgium', value: 'Belgium'},
		{label: 'Benin', value: 'Benin'},
		{label: 'Burkina Faso', value: 'Burkina Faso'},
		{label: 'Bangladesh', value: 'Bangladesh'},
		{label: 'Bulgaria', value: 'Bulgaria'},
		{label: 'Bahrain', value: 'Bahrain'},
		{label: 'Bahamas', value: 'Bahamas'},
		{label: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina'},
		{label: 'Belarus', value: 'Belarus'},
		{label: 'Belize', value: 'Belize'},
		{label: 'Bermuda', value: 'Bermuda'},
		{label: 'Bolivia', value: 'Bolivia'},
		{label: 'Brazil', value: 'Brazil'},
		{label: 'Barbados', value: 'Barbados'},
		{label: 'Brunei Darussalam', value: 'Brunei Darussalam'},
		{label: 'Bhutan', value: 'Bhutan'},
		{label: 'Botswana', value: 'Botswana'},
		{label: 'Central African Republic', value: 'Central African Republic'},
		{label: 'Canada', value: 'Canada'},
		{label: 'Switzerland', value: 'Switzerland'},
		{label: 'Chile', value: 'Chile'},
		{label: 'China', value: 'China'},
		{label: 'China (Hong Kong SAR)', value: 'China (Hong Kong SAR)'},
		{label: 'China (Macao SAR)', value: 'China (Macao SAR)'},
		{label: 'Ivory Coast', value: 'Ivory Coast'},
		{label: 'Cameroon', value: 'Cameroon'},
		{label: 'Democratic Rep. of Congo', value: 'Democratic Rep. of Congo'},
		{label: 'Republic of Congo', value: 'Republic of Congo'},
		{label: 'Colombia', value: 'Colombia'},
		{label: 'Comoros', value: 'Comoros'},
		{label: 'Cabo Verde', value: 'Cabo Verde'},
		{label: 'Costa Rica', value: 'Costa Rica'},
		{label: 'Cuba', value: 'Cuba'},
		{label: 'Cayman Islands', value: 'Cayman Islands'},
		{label: 'Cyprus', value: 'Cyprus'},
		{label: 'Czech Republic', value: 'Czech Republic'},
		{label: 'Germany, Federal Republic Of', value: 'Germany, Federal Republic Of'},
		{label: 'Djibouti', value: 'Djibouti'},
		{label: 'Dominica', value: 'Dominica'},
		{label: 'Denmark', value: 'Denmark'},
		{label: 'Dominican Republic', value: 'Dominican Republic'},
		{label: 'Algeria', value: 'Algeria'},
		{label: 'Ecuador', value: 'Ecuador'},
		{label: 'Egypt', value: 'Egypt'},
		{label: 'Eritrea', value: 'Eritrea'},
		{label: 'Spain', value: 'Spain'},
		{label: 'Estonia', value: 'Estonia'},
		{label: 'Ethiopia', value: 'Ethiopia'},
		{label: 'Finland', value: 'Finland'},
		{label: 'Fiji', value: 'Fiji'},
		{label: 'France', value: 'France'},
		{label: 'Federated States of Micronesia', value: 'Federated States of Micronesia'},
		{label: 'Gabon', value: 'Gabon'},
		{label: 'UK - Brit. overseas terr.', value: 'UK - Brit. overseas terr.'},
		{label: 'UK - Brit. Ntl. Overseas', value: 'UK - Brit. Ntl. Overseas'},
		{label: 'UK - Brit. overseas citizen', value: 'UK - Brit. overseas citizen'},
		{label: 'UK - Brit. protected person', value: 'UK - Brit. protected person'},
		{label: 'UK - British citizen', value: 'UK - British citizen'},
		{label: 'UK - British subject', value: 'UK - British subject'},
		{label: 'Georgia', value: 'Georgia'},
		{label: 'Ghana', value: 'Ghana'},
		{label: 'Guinea', value: 'Guinea'},
		{label: 'Gambia', value: 'Gambia'},
		{label: 'Guinea-Bissau', value: 'Guinea-Bissau'},
		{label: 'Equatorial Guinea', value: 'Equatorial Guinea'},
		{label: 'Greece', value: 'Greece'},
		{label: 'Grenada', value: 'Grenada'},
		{label: 'Guatemala', value: 'Guatemala'},
		{label: 'Guyana', value: 'Guyana'},
		{label: 'Honduras', value: 'Honduras'},
		{label: 'Croatia', value: 'Croatia'},
		{label: 'Haiti', value: 'Haiti'},
		{label: 'Hungary', value: 'Hungary'},
		{label: 'Indonesia', value: 'Indonesia'},
		{label: 'India', value: 'India'},
		{label: 'Ireland', value: 'Ireland'},
		{label: 'Iran', value: 'Iran'},
		{label: 'Iraq', value: 'Iraq'},
		{label: 'Iceland', value: 'Iceland'},
		{label: 'Israel', value: 'Israel'},
		{label: 'Italy', value: 'Italy'},
		{label: 'Jamaica', value: 'Jamaica'},
		{label: 'Jordan', value: 'Jordan'},
		{label: 'Japan', value: 'Japan'},
		{label: 'Kazakhstan', value: 'Kazakhstan'},
		{label: 'Kenya', value: 'Kenya'},
		{label: 'Kyrgyzstan', value: 'Kyrgyzstan'},
		{label: 'Cambodia', value: 'Cambodia'},
		{label: 'Kiribati', value: 'Kiribati'},
		{label: 'Saint Kitts and Nevis', value: 'Saint Kitts and Nevis'},
		{label: 'Korea, South', value: 'Korea, South'},
		{label: 'Kuwait', value: 'Kuwait'},
		{label: 'Laos', value: 'Laos'},
		{label: 'Lebanon', value: 'Lebanon'},
		{label: 'Liberia', value: 'Liberia'},
		{label: 'Libya', value: 'Libya'},
		{label: 'Saint Lucia', value: 'Saint Lucia'},
		{label: 'Liechtenstein', value: 'Liechtenstein'},
		{label: 'Sri Lanka', value: 'Sri Lanka'},
		{label: 'Lesotho', value: 'Lesotho'},
		{label: 'Lithuania', value: 'Lithuania'},
		{label: 'Luxembourg', value: 'Luxembourg'},
		{label: 'Latvia', value: 'Latvia'},
		{label: 'Morocco', value: 'Morocco'},
		{label: 'Monaco', value: 'Monaco'},
		{label: 'Moldova', value: 'Moldova'},
		{label: 'Madagascar', value: 'Madagascar'},
		{label: 'Maldives', value: 'Maldives'},
		{label: 'Mexico', value: 'Mexico'},
		{label: 'Marshall Islands', value: 'Marshall Islands'},
		{label: 'Macedonia', value: 'Macedonia'},
		{label: 'Mali', value: 'Mali'},
		{label: 'Malta', value: 'Malta'},
		{label: 'Burma (Myanmar)', value: 'Burma (Myanmar)'},
		{label: 'Montenegro', value: 'Montenegro'},
		{label: 'Mongolia', value: 'Mongolia'},
		{label: 'Mozambique', value: 'Mozambique'},
		{label: 'Mauritania', value: 'Mauritania'},
		{label: 'Montserrat', value: 'Montserrat'},
		{label: 'Mauritius', value: 'Mauritius'},
		{label: 'Malawi', value: 'Malawi'},
		{label: 'Malaysia', value: 'Malaysia'},
		{label: 'Namibia', value: 'Namibia'},
		{label: 'Niger', value: 'Niger'},
		{label: 'Nigeria', value: 'Nigeria'},
		{label: 'Nicaragua', value: 'Nicaragua'},
		{label: 'Netherlands, The', value: 'Netherlands, The'},
		{label: 'Norway', value: 'Norway'},
		{label: 'Nepal', value: 'Nepal'},
		{label: 'Nauru', value: 'Nauru'},
		{label: 'New Zealand', value: 'New Zealand'},
		{label: 'Oman', value: 'Oman'},
		{label: 'Pakistan', value: 'Pakistan'},
		{label: 'Panama', value: 'Panama'},
		{label: 'Peru', value: 'Peru'},
		{label: 'Philippines', value: 'Philippines'},
		{label: 'Republic of Palau', value: 'Republic of Palau'},
		{label: 'Papua New Guinea', value: 'Papua New Guinea'},
		{label: 'Poland', value: 'Poland'},
		{label: 'Korea, North (DPRK)', value: 'Korea, North (DPRK)'},
		{label: 'Portugal', value: 'Portugal'},
		{label: 'Paraguay', value: 'Paraguay'},
		{label: 'Palestinian Authority', value: 'Palestinian Authority'},
		{label: 'Qatar', value: 'Qatar'},
		{label: 'Kosovo', value: 'Kosovo'},
		{label: 'Romania', value: 'Romania'},
		{label: 'Russia', value: 'Russia'},
		{label: 'Rwanda', value: 'Rwanda'},
		{label: 'Saudi Arabia', value: 'Saudi Arabia'},
		{label: 'Sudan', value: 'Sudan'},
		{label: 'Senegal', value: 'Senegal'},
		{label: 'Singapore', value: 'Singapore'},
		{label: 'Saint Helena', value: 'Saint Helena'},
		{label: 'Solomon Islands', value: 'Solomon Islands'},
		{label: 'Sierra Leone', value: 'Sierra Leone'},
		{label: 'El Salvador', value: 'El Salvador'},
		{label: 'San Marino', value: 'San Marino'},
		{label: 'Somalia', value: 'Somalia'},
		{label: 'Serbia, Republic Of', value: 'Serbia, Republic Of'},
		{label: 'South Sudan', value: 'South Sudan'},
		{label: 'Sao Tome and Principe', value: 'Sao Tome and Principe'},
		{label: 'Suriname', value: 'Suriname'},
		{label: 'Slovakia', value: 'Slovakia'},
		{label: 'Slovenia', value: 'Slovenia'},
		{label: 'Sweden', value: 'Sweden'},
		{label: 'Swaziland', value: 'Swaziland'},
		{label: 'Seychelles', value: 'Seychelles'},
		{label: 'Syria', value: 'Syria'},
		{label: 'Turks and Caicos Islands', value: 'Turks and Caicos Islands'},
		{label: 'Chad', value: 'Chad'},
		{label: 'Togo', value: 'Togo'},
		{label: 'Thailand', value: 'Thailand'},
		{label: 'Tajikistan', value: 'Tajikistan'},
		{label: 'Turkmenistan', value: 'Turkmenistan'},
		{label: 'East Timor', value: 'East Timor'},
		{label: 'Tonga', value: 'Tonga'},
		{label: 'Trinidad and Tobago', value: 'Trinidad and Tobago'},
		{label: 'Tunisia', value: 'Tunisia'},
		{label: 'Turkey', value: 'Turkey'},
		{label: 'Tuvalu', value: 'Tuvalu'},
		{label: 'Taiwan', value: 'Taiwan'},
		{label: 'Tanzania', value: 'Tanzania'},
		{label: 'Uganda', value: 'Uganda'},
		{label: 'Ukraine', value: 'Ukraine'},
		{label: 'UN specialized agency', value: 'UN specialized agency'},
		{label: 'UN or official', value: 'UN or official'},
		{label: 'Uruguay', value: 'Uruguay'},
		{label: 'United States of America', value: 'United States of America'},
		{label: 'Uzbekistan', value: 'Uzbekistan'},
		{label: 'Vatican City State', value: 'Vatican City State'},
		{label: 'St. Vincent and the Grenadines', value: 'St. Vincent and the Grenadines'},
		{label: 'Venezuela', value: 'Venezuela'},
		{label: 'British Virgin Islands', value: 'British Virgin Islands'},
		{label: 'Vietnam', value: 'Vietnam'},
		{label: 'Vanuatu', value: 'Vanuatu'},
		{label: 'Samoa', value: 'Samoa'},
		{label: 'Yemen', value: 'Yemen'},
		{label: 'South Africa, Republic Of', value: 'South Africa, Republic Of'},
		{label: 'Zambia', value: 'Zambia'},
		{label: 'Zimbabwe', value: 'Zimbabwe'},
	],
	availableNumbers: [
		{ label: '1-3', value: '1-3'},
		{ label: '4-6', value: '4-6'},
		{ label: '7+', value: '7+'},
	],
	integers: [
		{label: '1', value: 1},
		{label: '2', value: 2},
		{label: '3', value: 3},
		{label: '4', value: 4},
		{label: '5', value: 5},
		{label: '6', value: 6},
		{label: '7', value: 7},
		{label: '8', value: 8},
		{label: '9', value: 9},
		{label: '10', value: 10}
	],
	flightFilterStatuses: [
		{label: 'Open', value: 'Open'},
		{label: 'Full', value: 'Full'},
		{label: 'Closed', value: 'Closed'},
		{label: 'Completed', value: 'Completed'},
	],
	filterPlaneTypes: [
		{label: 'Single Prop', value: 'Single Prop'},
		{label: 'Twin Prop', value: 'Twin Prop'},
		{label: 'Seaplane', value: 'Seaplane'},
		{label: 'Helicopter', value: 'Helicopter'}
	],
	filterAvailableNumbers: [
		{ label: '1-3', value: '1-3'},
		{ label: '4-6', value: '4-6'},
		{ label: '7-9', value: '7-9'},
		{ label: '10-12', value: '10-12'},
		{ label: '13-15', value: '13-15'},
		{ label: '16-19', value: '16-19'},
	],
	repeatOn: [
		{label: 'S', value: 'Sunday'},
		{label: 'M', value: 'Monday'},
		{label: 'T', value: 'Tuesday'},
		{label: 'W', value: 'Wednesday'},
		{label: 'T', value: 'Thursday'},
		{label: 'F', value: 'Friday'},
		{label: 'S', value: 'Saturday'},
	],
	repeatOnConvert: {
		'Sunday': 'S',
		'Monday': 'M',
		'Tuesday': 'T',
		'Wednesday': 'W',
		'Thursday': 'T',
		'Friday': 'F',
		'Saturday': 'S'
	},
	frequencies: [
		{label: 'Weekly', value: 'Weekly'},
		// {label: 'Daily', value: 'Daily'},
		// {label: 'Monthly', value: 'Monthly'},
	],
	yesOrNo: [
		{label: 'Yes', value: 'Yes'},
		{label: 'No', value: 'No'},
	],
	yesOrNoBoolean: [
		{label: 'Yes', value: true},
		{label: 'No', value: false},
	],
	planeTypes: [
		{label: 'Single Prop', value: 'single-prop-plane'},
		{label: 'Twin Prop', value: 'twin-prop-plane'},
		{label: 'Seaplane', value: 'seaplane'},
		{label: 'Helicopter', value: 'helicopter'}
	],
	planePhotos: [
		{label: 'front side', value: 'front'},
		{label: 'left side', value: 'left'},
		{label: 'right side', value: 'right'},
		{label: 'inside', value: 'inside'},
	],
	privacyTypes: [
		{label: 'Private', value: false},
		{label: 'Public', value: true},
	],
	statusTypes: [
		{label: 'Await', value: 'await'},
		{label: 'Pending', value: 'pending'},
		{label: 'Declined', value: 'declined'},
		{label: 'Accepted', value: 'accepted'}
	],
	addressLabel: {
		'Bahamas': 'Island',
		'United States': 'State',
		'Turks & Caicos': 'Island',
		'Cuba': 'Province',
		'Jamaica': 'Parish',
		'Cayman Islands': 'Island'
	},
	address: {
		'Bahamas': [
			{label: "The Abacos", value: "The Abacos"},
			{label: "Acklins", value: "Acklins"},
			{label: "Andros", value: "Andros"},
			{label: "Berry Islands", value: "Berry Islands"},
			{label: "Bimini", value: "Bimini"},
			{label: "Cat Island", value: "Cat Island"},
			{label: "Cay Sal", value: "Cay Sal"},
			{label: "Crooked Island", value: "Crooked Island"},
			{label: "Eleuthera", value: "Eleuthera"},
			{label: "Exuma", value: "Exuma"},
			{label: "Grand Bahama", value: "Grand Bahama"},
			{label: "Inagua", value: "Inagua"},
			{label: "Long Island", value: "Long Island"},
			{label: "Mayaguana", value: "Mayaguana"},
			{label: "New Providence", value: "New Providence"},
			{label: "Ragged Island", value: "Ragged Island"},
			{label: "Rum Cay", value: "Rum Cay"},
			{label: "San Salvador", value: "San Salvador"},
		],
		'United States': [
			{label: "Alabama", value: "Alabama"},
			{label: "Alaska", value: "Alaska"},
			{label: "American Samoa", value: "American Samoa"},
			{label: "Arizona", value: "Arizona"},
			{label: "Arkansas", value: "Arkansas"},
			{label: "California", value: "California"},
			{label: "Colorado", value: "Colorado"},
			{label: "Connecticut", value: "Connecticut"},
			{label: "Delaware", value: "Delaware"},
			{label: "District Of Columbia", value: "District Of Columbia"},
			{label: "Federated States Of Micronesia", value: "Federated States Of Micronesia"},
			{label: "Florida", value: "Florida"},
			{label: "Georgia", value: "Georgia"},
			{label: "Guam", value: "Guam"},
			{label: "Hawaii", value: "Hawaii"},
			{label: "Idaho", value: "Idaho"},
			{label: "Illinois", value: "Illinois"},
			{label: "Indiana", value: "Indiana"},
			{label: "Iowa", value: "Iowa"},
			{label: "Kansas", value: "Kansas"},
			{label: "Kentucky", value: "Kentucky"},
			{label: "Louisiana", value: "Louisiana"},
			{label: "Maine", value: "Maine"},
			{label: "Marshall Islands", value: "Marshall Islands"},
			{label: "Maryland", value: "Maryland"},
			{label: "Massachusetts", value: "Massachusetts"},
			{label: "Michigan", value: "Michigan"},
			{label: "Minnesota", value: "Minnesota"},
			{label: "Mississippi", value: "Mississippi"},
			{label: "Missouri", value: "Missouri"},
			{label: "Montana", value: "Montana"},
			{label: "Nebraska", value: "Nebraska"},
			{label: "Nevada", value: "Nevada"},
			{label: "New Hampshire", value: "New Hampshire"},
			{label: "New Jersey", value: "New Jersey"},
			{label: "New Mexico", value: "New Mexico"},
			{label: "New York", value: "New York"},
			{label: "North Carolina", value: "North Carolina"},
			{label: "North Dakota", value: "North Dakota"},
			{label: "Northern Mariana Islands", value: "Northern Mariana Islands"},
			{label: "Ohio", value: "Ohio"},
			{label: "Oklahoma", value: "Oklahoma"},
			{label: "Oregon", value: "Oregon"},
			{label: "Palau", value: "Palau"},
			{label: "Pennsylvania", value: "Pennsylvania"},
			{label: "Puerto Rico", value: "Puerto Rico"},
			{label: "Rhode Island", value: "Rhode Island"},
			{label: "South Carolina", value: "South Carolina"},
			{label: "South Dakota", value: "South Dakota"},
			{label: "Tennessee", value: "Tennessee"},
			{label: "Texas", value: "Texas"},
			{label: "Utah", value: "Utah"},
			{label: "Vermont", value: "Vermont"},
			{label: "Virgin Islands", value: "Virgin Islands"},
			{label: "Virginia", value: "Virginia"},
			{label: "Washington", value: "Washington"},
			{label: "West Virginia", value: "West Virginia"},
			{label: "Wisconsin", value: "Wisconsin"},
			{label: "Wyoming", value: "Wyoming"},
		],
		'Turks & Caicos': [
			{label: "Grand Turk", value: "Grand Turk"},
			{label: "Salt Cay", value: "Salt Cay"},
			{label: "South Caicos", value: "South Caicos"},
			{label: "Middle Caicos", value: "Middle Caicos"},
			{label: "North Caicos", value: "North Caicos"},
			{label: "Providenciales", value: "Providenciales"},
			{label: "Pine Cay", value: "Pine Cay"},
			{label: "Parrot Cay", value: "Parrot Cay"},
			{label: "Ambergris Cay", value: "Ambergris Cay"},
			{label: "East Caicos", value: "East Caicos"},
			{label: "West Caicos", value: "West Caicos"},
			{label: "French Cay", value: "French Cay"},
			{label: "Water Cay", value: "Water Cay"},
			{label: "Little Water Cay", value: "Little Water Cay"},
			{label: "Fort George Cay", value: "Fort George Cay"},
			{label: "Pine Cay", value: "Pine Cay"},
			{label: "Parrot Cay", value: "Parrot Cay"},
			{label: "Ambergris Cay", value: "Ambergris Cay"},
			{label: "East Caicos", value: "East Caicos"},
			{label: "West Caicos", value: "West Caicos"},
			{label: "French Cay", value: "French Cay"},
			{label: "Water Cay", value: "Water Cay"},
			{label: "Little Water Cay", value: "Little Water Cay"},
			{label: "Fort George Cay", value: "Fort George Cay"},
		],
		'Cuba': [
			{label: "Pinar del Rio", value: "Pinar del Rio"},
			{label: "La Habana", value: "La Habana"},
			{label: "Ciudad de La Habana", value: "Ciudad de La Habana"},
			{label: "Matanzas", value: "Matanzas"},
			{label: "Villa Clara", value: "Villa Clara"},
			{label: "Cienfuegos", value: "Cienfuegos"},
			{label: "Sancti Spiritus", value: "Sancti Spiritus"},
			{label: "Ciego de Avila", value: "Ciego de Avila"},
			{label: "Camaguey", value: "Camaguey"},
			{label: "Las Tunas", value: "Las Tunas"},
			{label: "Holguin", value: "Holguin"},
			{label: "Granma", value: "Granma"},
			{label: "Santiago de Cuba", value: "Santiago de Cuba"},
			{label: "Guantanamo", value: "Guantanamo"},
			{label: "Isla de la Juventud", value: "Isla de la Juventud"},
		],
		'Jamaica': [
			{label: "Kingston", value: "Kingston"},
			{label: "St. Andrew", value: "St. Andrew"},
			{label: "St. Thomas", value: "St. Thomas"},
			{label: "Portland", value: "Portland"},
			{label: "St. Mary", value: "St. Mary"},
			{label: "St. Ann", value: "St. Ann"},
			{label: "Trelawny", value: "Trelawny"},
			{label: "St. James", value: "St. James"},
			{label: "Hanover", value: "Hanover"},
			{label: "Westmoreland", value: "Westmoreland"},
			{label: "St. Elizabeth", value: "St. Elizabeth"},
			{label: "Manchester", value: "Manchester"},
			{label: "Clarendon", value: "Clarendon"},
			{label: "St. Catherine", value: "St. Catherine"},
		],
		'Cayman Islands': [
			{label: "Bodden Town", value: "Bodden Town"},
			{label: "East End", value: "East End"},
			{label: "George Town", value: "George Town"},
			{label: "North Side", value: "North Side"},
			{label: "West Bay", value: "West Bay"},
		]
	},
	airports: {
		bahamas: [
			{label: "MYAM - Marsh Harbour", value: "MYAM - Marsh Harbour"},
			{label: "MYAO - Moores Island", value: "MYAO - Moores Island"},
			{label: "MYAS - Sandy Point", value: "MYAS - Sandy Point"},
			{label: "MYXI - Scotland Cay", value: "MYXI - Scotland Cay"},
			{label: "MYAX - Spanish Cay", value: "MYAX - Spanish Cay"},
			{label: "MYAT - Treasure Cay", value: "MYAT - Treasure Cay"},
			{label: "MYAW - Walker Cay", value: "MYAW - Walker Cay"},
			{label: "MYAP - Spring Point", value: "MYAP - Spring Point"},
			{label: "MYAF - Andros Town", value: "MYAF - Andros Town"},
			{label: "MYAK - Congo Town", value: "MYAK - Congo Town"},
			{label: "MYAB - Clarence Bain", value: "MYAB - Clarence Bain"},
			{label: "MYAN - San Andros", value: "MYAN - San Andros"},
			{label: "MYBC - Chub Cay", value: "MYBC - Chub Cay"},
			{label: "MYBG - Great Harbour Cay", value: "MYBG - Great Harbour Cay"},
			{label: "MYBX - Lt. Whale Cay", value: "MYBX - Lt. Whale Cay"},
			{label: "MYBW - Big Whale Cay", value: "MYBW - Big Whale Cay"},
			{label: "MYBS - South Bimini", value: "MYBS - South Bimini"},
			{label: "MYBO - Ocean Cay", value: "MYBO - Ocean Cay"},
			{label: "MYCC - North Cat Cay", value: "MYCC - North Cat Cay"},
			{label: "MYCA - Arthurs Town", value: "MYCA - Arthurs Town"},
			{label: "MYCX - Cutlass Bay", value: "MYCX - Cutlass Bay"},
			{label: "MYCH - Hawks Nest", value: "MYCH - Hawks Nest"},
			{label: "MYCB - New Bight", value: "MYCB - New Bight"},
			{label: "MYCS - Cay Sal", value: "MYCS - Cay Sal"},
			{label: "MYCI - Colonial Hill", value: "MYCI - Colonial Hill"},
			{label: "MYCP - Pitts Town", value: "MYCP - Pitts Town"},
			{label: "MYEM - Governor's Harbour", value: "MYEM - Governor's Harbour"},
			{label: "MYEH - North Eleuthera", value: "MYEH - North Eleuthera"},
			{label: "MYER - Rock Sound", value: "MYER - Rock Sound"},
			{label: "MYEF - Exuma Int'l", value: "MYEF - Exuma Int'l"},
			{label: "MYEB - Black Point", value: "MYEB - Black Point"},
			{label: "MYEY - Hog Cay", value: "MYEY - Hog Cay"},
			{label: "MYEL - Lee Stocking", value: "MYEL - Lee Stocking"},
			{label: "MYEN - Norman's Cay", value: "MYEN - Norman's Cay"},
			{label: "RUDDER_CUT_CAY - Rudder Cut Cay", value: "RUDDER_CUT_CAY - Rudder Cut Cay"},
			{label: "SAMPSON_CAY - Sampson Cay", value: "SAMPSON_CAY - Sampson Cay"},
			{label: "MYES - Staniel Cay", value: "MYES - Staniel Cay"},
			{label: "FARMERS_CAY - Farmers Cay", value: "FARMERS_CAY - Farmers Cay"},
			{label: "MYGF - Freeport", value: "MYGF - Freeport"},
			{label: "MYGW - West End", value: "MYGW - West End"},
			{label: "MYIG - Matthew Town", value: "MYIG - Matthew Town"},
			{label: "MYLD - Deadmans Cay", value: "MYLD - Deadmans Cay"},
			{label: "MYLS - Stella Maris", value: "MYLS - Stella Maris"},
			{label: "MYLM - Cape Santa Maria", value: "MYLM - Cape Santa Maria"},
			{label: "MYMM - Mayaguana", value: "MYMM - Mayaguana"},
			{label: "MYNN - Nassau", value: "MYNN - Nassau"},
			{label: "MYNN - Nassau (Odyssey Aviation)", value: "MYNN - Nassau (Odyssey Aviation)"},
			{label: "MYNN - Jet Nassau", value: "MYNN - Jet Nassau"},
			{label: "MYRD - Duncan Town", value: "MYRD - Duncan Town"},
			{label: "MYRP - Port Nelson", value: "MYRP - Port Nelson"},
			{label: "MYSM - Cockburn Town", value: "MYSM - Cockburn Town"},
		],
		us: [
			{label: "FXE - Ft. Lauderdale Executive Airport", value: "FXE - Ft. Lauderdale Executive Airport"},
			{label: "FLL - Ft. Lauderdale Sheltair Terminal", value: "FLL - Ft. Lauderdale Sheltair Terminal"},
			{label: "MPD - Miami Seaplane Base", value: "MPD - Miami Seaplane Base"},
			{label: "TMB - Miami Executive Airport", value: "TMB - Miami Executive Airport"},
			{label: "OPF - Miami Opa Locka Airport", value: "OPF - Miami Opa Locka Airport"},
			{label: "PBI - Palm Beach International Airport", value: "PBI - Palm Beach International Airport"},
			{label: "BCT - Boca Raton Airport", value: "BCT - Boca Raton Airport"},
			{label: "PIE - St Petersburg-Clearwater International Airport", value: "PIE - St Petersburg-Clearwater International Airport"},
			{label: "SRQ - Sarasota-Bradenton International Airport", value: "SRQ - Sarasota-Bradenton International Airport"},
			{label: "ORL - Orlando Executive Airport", value: "ORL - Orlando Executive Airport"},
			{label: "CRG - Jacksonville Executive at Craig Airport", value: "CRG - Jacksonville Executive at Craig Airport"},
			{label: "EYW - Key West International Airport", value: "EYW - Key West International Airport"},
			{label: "APF - Naples Municipal Airport", value: "APF - Naples Municipal Airport"},
		],
		tc: [
			{label: "PLS - Providenciales International Airport", value: "PLS - Providenciales International Airport"},
			{label: "GDT - JAGS McCartney International Airport", value: "GDT - JAGS McCartney International Airport"},
		],
		cu: [
			{label: "HAV - José Martí International Airport", value: "HAV - José Martí International Airport"},
			{label: "VRA - Juan Gualberto Gómez International Airport", value: "VRA - Juan Gualberto Gómez International Airport"},
			{label: "SCU - Antonio Maceo International Airport", value: "SCU - Antonio Maceo International Airport"},
			{label: "HOG - Frank País International Airport", value: "HOG - Frank País International Airport"},
			{label: "CCC - Jardines del Rey Airport", value: "CCC - Jardines del Rey Airport"},
		],
		jm: [
			{label: "MBJ - Sangster International Airport", value: "MBJ - Sangster International Airport"},
			{label: "KIN - Norman Manley International Airport", value: "KIN - Norman Manley International Airport"},
			{label: "OCJ - Ian Fleming International Airport", value: "OCJ - Ian Fleming International Airport"},
		],
		ky: [
			{label: "GCM - Owen Roberts International Airport", value: "GCM - Owen Roberts International Airport"},
			{label: "CYB - Charles Kirkconnell International Airport", value: "CYB - Charles Kirkconnell International Airport"},
			{label: "LYB - Edward Bodden Airfield", value: "LYB - Edward Bodden Airfield"},
		],
		index: {
			"MYAM - Marsh Harbour": {
				place: "The Abacos",
				code: "MYAM",
				name: "Marsh Harbour",
				address: "Marsh Harbour, Eleuthera, Bahamas",
				title: "Leonard Thompson Airport",
				latitude: 26.513572909308785,
				longitude: -77.07815536441754,
				geocode: "26.513572909308785,-77.07815536441754",
				country: "Bahamas",
			},
			"MYAO - Moores Island": {
				place: "The Abacos",
				code: "MYAO",
				name: "Moores Island",
				address: "Hard Bargain Moore's Island, Hard Bargain, Bahamas ",
				title: "Moores Island Airport",
				latitude: 26.320691132430003,
				longitude: -77.56157440152063,
				geocode: "26.320691132430003,-77.56157440152063",
				country: "Bahamas",
			},
			"MYAS - Sandy Point": {
				place: "The Abacos",
				code: "MYAS",
				name: "Sandy Point",
				address: "Sandy Point, Bahamas",
				title: "Sandy Point Airport",
				latitude: 26.005285116897436,
				longitude: -77.3956504153419,
				geocode: "26.005285116897436,-77.3956504153419",
				country: "Bahamas",
			},
			"MYXI - Scotland Cay": {
				place: "The Abacos",
				code: "MYXI",
				name: "Scotland Cay",
				address: "Scotland Cay, Bahamas",
				title: "Scotland Cay Airport",
				latitude: 26.639261221936486,
				longitude: -77.06385800165906,
				geocode: "26.639261221936486,-77.06385800165906",
				country: "Bahamas",
			},
			"MYAX - Spanish Cay": {
				place: "The Abacos",
				code: "MYAX",
				name: "Spanish Cay",
				address: "Spanish Cay, Bahamas",
				title: "Spanish Cay Airport",
				latitude: 26.97577358570719,
				longitude: -77.54580455108999,
				geocode: "26.97577358570719,-77.54580455108999",
				country: "Bahamas",
			},
			"MYAT - Treasure Cay": {
				place: "The Abacos",
				code: "MYAT",
				name: "Treasure Cay",
				address: "Norman Castle, Bahamas",
				title: "Treasure Cay International Airport",
				latitude: 26.74362597665285,
				longitude: -77.38808837071669,
				geocode: "26.74362597665285,-77.38808837071669",
				country: "Bahamas",
			},
			"MYAW - Walker Cay": {
				place: "The Abacos",
				code: "MYAW",
				name: "Walker Cay",
				address: "Walker Cay, Bahamas",
				title: "Walker's Cays Airport",
				latitude: 27.261064464395787,
				longitude: -78.40217182025565,
				geocode: "27.261064464395787,-78.40217182025565",
				country: "Bahamas",
			},
			"MYAP - Spring Point": {
				place: "Acklins",
				code: "MYAP",
				name: "Spring Point",
				address: "Spring Point, Bahamas",
				title: "Spring Point Airport ",
				latitude: 22.443039390503753,
				longitude: -73.97074587183018,
				geocode: "22.443039390503753,-73.97074587183018",
				country: "Bahamas",
			},
			"MYAF - Andros Town": {
				place: "Andros",
				code: "MYAF",
				name: "Andros Town",
				address: "Andros Town, Bahamas",
				title: "Andros Town Airport",
				latitude: 24.69927433196698,
				longitude: -77.7908772405904,
				geocode: "24.69927433196698,-77.7908772405904",
				country: "Bahamas",
			},
			"MYAK - Congo Town": {
				place: "Andros",
				code: "MYAK",
				name: "Congo Town",
				address: "Congo Town, Bahamas",
				title: "South Andros-Congo Town-Queen Airport",
				latitude: 24.15751462136052,
				longitude: -77.58380665607156,
				geocode: "24.15751462136052,-77.58380665607156",
				country: "Bahamas",
			},
			"MYAB - Clarence Bain": {
				place: "Andros",
				code: "MYAB",
				name: "Clarence Bain",
				address: "Moxey Town, Bahamas",
				title: "Clarence A Bain Airport",
				latitude: 24.290709079223276,
				longitude: -77.67807917009208,
				geocode: "24.290709079223276,-77.67807917009208",
				country: "Bahamas",
			},
			"MYAN - San Andros": {
				place: "Andros",
				code: "MYAN",
				name: "San Andros",
				address: "Queen's Hwy, Sa Andros Bahama Village, Bahamas",
				title: "San Andros Airport",
				latitude: 25.054656594876427,
				longitude: -78.0421033232904,
				geocode: "25.054656594876427,-78.0421033232904",
				country: "Bahamas",
			},
			"MYBC - Chub Cay": {
				place: "Berry Islands",
				code: "MYBC",
				name: "Chub Cay",
				address: "Chub Cay, Bahamas",
				title: "Chub Cay Airport",
				latitude: 25.419081141811674,
				longitude: -77.88507204879697,
				geocode: "25.419081141811674,-77.88507204879697",
				country: "Bahamas",
			},
			"MYBG - Great Harbour Cay": {
				place: "Berry Islands",
				code: "MYBG",
				name: "Great Harbour Cay",
				address: "Bullock Harbour, Bahamas",
				title: "Great Harbour Cay-Bullock Harbour Airport",
				latitude: 25.742083567934852,
				longitude: -77.84450535565426,
				geocode: "25.742083567934852,-77.84450535565426",
				country: "Bahamas",
			},
			"MYBX - Lt. Whale Cay": {
				place: "Berry Islands",
				code: "MYBX",
				name: "Little Whale Cay",
				address: "Little Whale Cay, Bahamas",
				title: "Little Whale Cay Airport",
				latitude: 25.45041040871012,
				longitude: -77.75973071710109,
				geocode: "25.45041040871012,-77.75973071710109",
				country: "Bahamas",
			},
			"MYBW - Big Whale Cay": {
				place: "Berry Islands",
				code: "MYBW",
				name: "Big Whale Cay",
				address: "Big Whale Cay, Bahamas",
				title: "Big Whale Cay Airport",
				latitude: 25.402190367739273,
				longitude: -77.78953825574641,
				geocode: "25.402190367739273,-77.78953825574641",
				country: "Bahamas",
			},
			"MYBS - South Bimini": {
				place: "Bimini",
				code: "MYBS",
				name: "South Bimini",
				address: "Port Royal, Bahamas",
				title: "South Bimini Airport",
				latitude: 25.702212366597372,
				longitude: -79.27272808634902,
				geocode: "25.702212366597372,-79.27272808634902",
				country: "Bahamas",
			},
			"MYBO - Ocean Cay": {
				place: "Bimini",
				code: "MYBO",
				name: "Ocean Cay",
				address: "MSC Marine Reserve, Ocean Cay, Bahamas",
				title: "Ocean Cay Heliport",
				latitude: 25.425300217930065,
				longitude: -79.21008427592903,
				geocode: "25.425300217930065,-79.21008427592903",
				country: "Bahamas",
			},
			"MYCC - North Cat Cay": {
				place: "Bimini",
				code: "MYCC",
				name: "North Cat Cay",
				address: "North Cat Cay, Bahamas",
				title: "North Cat Cay Airport ",
				latitude: 25.55440847081162,
				longitude: -79.27627532185126,
				geocode: "25.55440847081162,-79.27627532185126",
				country: "Bahamas",
			},
			"MYCA - Arthurs Town": {
				place: "Cat Island",
				code: "MYCA",
				name: "Arthurs Town",
				address: "Arthur's Town, Bahamas",
				title: "Arthur's Town",
				latitude: 24.629666741902415,
				longitude: -75.67496757159891,
				geocode: "24.629666741902415,-75.67496757159891",
				country: "Bahamas",
			},
			"MYCX - Cutlass Bay": {
				place: "Cat Island",
				code: "MYCX",
				name: "Cutlass Bay",
				address: "Cutlass Bay, Bahamas",
				title: "Cutlass Bay Airport",
				latitude: 24.144614229979762,
				longitude: -75.3833666474845,
				geocode: "24.144614229979762,-75.3833666474845",
				country: "Bahamas",
			},
			"MYCH - Hawks Nest": {
				place: "Cat Island",
				code: "MYCH",
				name: "Hawks Nest",
				address: "McQueen's Settlement, Bahamas",
				title: "Hawks Nest Creek Airport",
				latitude: 24.154975961227745,
				longitude: -75.5240966746111,
				geocode: "24.154975961227745,-75.5240966746111",
				country: "Bahamas",
			},
			"MYCB - New Bight": {
				place: "Cat Island",
				code: "MYCB",
				name: "New Bight",
				address: "Freetown Settlement, Bahamas",
				title: "New Bight-Freetown Settlement Airport",
				latitude: 24.316917440775757,
				longitude: -75.45429160588039,
				geocode: "24.316917440775757,-75.45429160588039",
				country: "Bahamas",
			},
			"MYCS - Cay Sal": {
				place: "Cay Sal",
				code: "MYCS",
				name: "Cay Sal",
				address: "Cay Sal, Bahamas",
				title: "Cay Sal",
				latitude: 23.694219952906593,
				longitude: -80.38686645087792,
				geocode: "23.694219952906593,-80.38686645087792",
				country: "Bahamas",
			},
			"MYCI - Colonial Hill": {
				place: "Crooked Island",
				code: "MYCI",
				name: "Colonial Hill",
				address: "Colonel Hill Settlement, Bahamas",
				title: "Colonel Hill Airport",
				latitude: 22.7466233370982,
				longitude: -74.17673525641706,
				geocode: "22.7466233370982,-74.17673525641706",
				country: "Bahamas",
			},
			"MYCP - Pitts Town": {
				place: "Crooked Island",
				code: "MYCP",
				name: "Pitts Town",
				address: "Marine Farm, Bahamas",
				title: "Pitts Town Airport",
				latitude: 22.829907477348772,
				longitude: -74.34602882571352,
				geocode: "22.829907477348772,-74.34602882571352",
				country: "Bahamas",
			},
			"MYEM - Governor's Harbour": {
				place: "Eleuthera",
				code: "MYEM",
				name: "Governor's Harbour",
				address: "Governors Harbour, Bahamas",
				title: "Governors Harbour Airport",
				latitude: 25.278163087871697,
				longitude: -76.32574534679368,
				geocode: "25.278163087871697,-76.32574534679368",
				country: "Bahamas",
			},
			"MYEH - North Eleuthera": {
				place: "Eleuthera",
				code: "MYEH",
				name: "North Eleuthera",
				address: "Airport Rd, Lower Bogue, Bahamas",
				title: "North Eleuthera Airport",
				latitude: 25.477106982593515,
				longitude: -76.684205701752,
				geocode: "25.477106982593515,-76.684205701752",
				country: "Bahamas",
			},
			"MYER - Rock Sound": {
				place: "Eleuthera",
				code: "MYER",
				name: "Rock Sound",
				address: "Rock Sound South Eleuthera, Rock Sound, Bahamas",
				title: "Rock Sound International Airport",
				latitude: 24.88945933576668,
				longitude: -76.16928619451492,
				geocode: "24.88945933576668,-76.16928619451492",
				country: "Bahamas",
			},
			"MYEF - Exuma Int'l": {
				place: "Exuma",
				code: "MYEF",
				name: "Exuma Int'l",
				address: "Unnamed Road, Moss Town, Bahamas",
				title: "Exuma International Airport",
				latitude: 23.564486864430144,
				longitude: -75.87395418690363,
				geocode: "23.564486864430144,-75.87395418690363",
				country: "Bahamas",
			},
			"MYEB - Black Point": {
				place: "Exuma",
				code: "MYEB",
				name: "Black Point",
				address: "Great Guana Cay, Bahamas",
				title: "Black Point-Great Guana Cay Airport",
				latitude: 24.091844989471085,
				longitude: -76.40066866324557,
				geocode: "24.091844989471085,-76.40066866324557",
				country: "Bahamas",
			},
			"MYEY - Hog Cay": {
				place: "Exuma",
				code: "MYEY",
				name: "Hog Cay",
				address: "Hog Cay, Bahamas",
				title: "Hog Cay Airport",
				latitude: 23.394688393026215,
				longitude: -75.49084123789714,
				geocode: "23.394688393026215,-75.49084123789714",
				country: "Bahamas",
			},
			"MYEL - Lee Stocking": {
				place: "Exuma",
				code: "MYEL",
				name: "Lee Stocking",
				address: "Lee Stocking Island, Rolleville, Bahamas",
				title: "Lee Stocking Airport",
				latitude: 23.775549668129294,
				longitude: -76.10338699212511,
				geocode: "23.775549668129294,-76.10338699212511",
				country: "Bahamas",
			},
			"MYEN - Norman's Cay": {
				place: "Exuma",
				code: "MYEN",
				name: "Norman's Cay",
				address: "Norman's Cay, Bahamas",
				title: "Norman's Cay Airport",
				latitude: 24.59801207768726,
				longitude: -76.81844483324186,
				geocode: "24.59801207768726,-76.81844483324186",
				country: "Bahamas",
			},
			"RUDDER_CUT_CAY - Rudder Cut Cay": {
				place: "Exuma",
				code: "RUDDER_CUT_CAY",
				name: "Rudder Cut Cay",
				address: "Rudder Cut Cay, Bahamas",
				title: "Rudder Cut Cay Airport",
				latitude: 23.88697839684192,
				longitude: -76.25256854814137,
				geocode: "23.88697839684192,-76.25256854814137",
				country: "Bahamas",
			},
			"SAMPSON_CAY - Sampson Cay": {
				place: "Exuma",
				code: "SAMPSON_CAY",
				name: "Sampson Cay",
				address: "Sampson Cay, Bahamas",
				title: "Sampson Cay Airport",
				latitude: 24.217685769969684,
				longitude: -76.47780453804485,
				geocode: "24.217685769969684,-76.47780453804485",
				country: "Bahamas",
			},
			"MYES - Staniel Cay": {
				place: "Exuma",
				code: "MYES",
				name: "Staniel Cay",
				address: "Black Point, Bahamas",
				title: "Staniel Cay Airport",
				latitude: 24.169472715990338,
				longitude: -76.43991997962054,
				geocode: "24.169472715990338,-76.43991997962054",
				country: "Bahamas",
			},
			"FARMERS_CAY - Farmers Cay": {
				place: "Exuma",
				code: "FARMERS_CAY",
				name: "Farmers Cay",
				address: "Little Farmer's Cay, Bahamas",
				title: "Little Farmers Cay Airport",
				latitude: 23.959882600888978,
				longitude: -76.32631730707212,
				geocode: "23.959882600888978,-76.32631730707212",
				country: "Bahamas",
			},
			"MYGF - Freeport": {
				place: "Grand Bahama",
				code: "MYGF",
				name: "Freeport",
				address: "Freeport, Bahamas",
				title: "Grand Bahama Airport",
				latitude: 26.54809699402166,
				longitude: -78.70263606302142,
				geocode: "26.54809699402166,-78.70263606302142",
				country: "Bahamas",
			},
			"MYGW - West End": {
				place: "Grand Bahama",
				code: "MYGW",
				name: "West End",
				address: "Courant Ave, Bahamas",
				title: "West End Airport",
				latitude: 26.690173285885496,
				longitude: -78.9859407098893,
				geocode: "26.690173285885496,-78.9859407098893",
				country: "Bahamas",
			},
			"MYIG - Matthew Town": {
				place: "Inagua",
				code: "MYIG",
				name: "Matthew Town",
				address: "Matthew Town, Bahamas",
				title: "Inagua International Airport ",
				latitude: 20.97586712792431,
				longitude: -73.67579162038078,
				geocode: "20.97586712792431,-73.67579162038078",
				country: "Bahamas",
			},
			"MYLD - Deadmans Cay": {
				place: "Long Island",
				code: "MYLD",
				name: "Deadmans Cay",
				address: "Deadman's Cay Settlement, Bahamas",
				title: "Deadman's Cay Settlement Airport",
				latitude: 23.179112643891383,
				longitude: -75.09356917165577,
				geocode: "23.179112643891383,-75.09356917165577",
				country: "Bahamas",
			},
			"MYLS - Stella Maris": {
				place: "Long Island",
				code: "MYLS",
				name: "Stella Maris",
				address: "Stella Maris, Bahamas",
				title: "Stella Maris Airport",
				latitude: 23.58193164002859,
				longitude: -75.2644527742376,
				geocode: "23.58193164002859,-75.2644527742376",
				country: "Bahamas",
			},
			"MYLM - Cape Santa Maria": {
				place: "Long Island",
				code: "MYLM",
				name: "Cape Santa Maria",
				address: "Galliot Cay, Glinton's Settlement, Bahamas",
				title: "Cape Santa Maria Airstrip",
				latitude: 23.649911753628484,
				longitude: -75.32409915558854,
				geocode: "23.649911753628484,-75.32409915558854",
				country: "Bahamas",
			},
			"MYMM - Mayaguana": {
				place: "Mayaguana",
				code: "MYMM",
				name: "Mayaguana",
				address: "Abraham Bay Settlement, Bahamas",
				title: "Mayaguana Airport",
				latitude: 22.38146110395998,
				longitude: -73.01278850183827,
				geocode: "22.38146110395998,-73.01278850183827",
				country: "Bahamas",
			},
			"MYNN - Nassau": {
				place: "New Providence",
				code: "MYNN",
				name: "Nassau",
				address: "Coral Harbour Rd, Nassau, Bahamas",
				title: "General Aviation Centre - Nassau",
				latitude: 25.043369022922345,
				longitude: -77.45892184088167,
				geocode: "25.043369022922345,-77.45892184088167",
				country: "Bahamas",
			},
			"MYNN - Nassau (Odyssey Aviation)": {
				place: "New Providence",
				code: "MYNN (Odyssey Aviation)",
				name: "Nassau",
				address: "Coral Harbour Rd, Nassau, Bahamas",
				title: "Odyssey Aviation - Nassau",
				latitude: 25.030335066451403,
				longitude: -77.45521443515506,
				geocode: "25.030335066451403,-77.45521443515506",
				country: "Bahamas",
			},
			"MYNN - Jet Nassau": {
				place: "New Providence",
				code: "MYNN",
				name: "Jet Nassau",
				address: "Coral Harbour Rd Suite #1 Lynden Pindling International Airport Nassau, Bahamas",
				title: "Jet Nassau",
				latitude: 25.046386766973793,
				longitude: -77.4604336613769,
				geocode: "25.046386766973793,-77.4604336613769",
				country: "Bahamas",
			},
			"MYRD - Duncan Town": {
				place: "Ragged Island",
				code: "MYRD",
				name: "Duncan Town",
				address: "Duncan Town, Bahamas",
				title: "Duncan Town Airport",
				latitude: 22.179844227928715,
				longitude: -75.72540207614304,
				geocode: "22.179844227928715,-75.72540207614304",
				country: "Bahamas",
			},
			"MYRP - Port Nelson": {
				place: "Rum Cay",
				code: "MYRP",
				name: "Port Nelson",
				address: "Port Nelson, Bahamas",
				title: "Port Nelson Airport",
				latitude: 23.683202473273692,
				longitude: -74.83045147153233,
				geocode: "23.683202473273692,-74.83045147153233",
				country: "Bahamas",
			},
			"MYSM - Cockburn Town": {
				place: "San Salvador",
				code: "MYSM",
				name: "Cockburn Town",
				address: "Cockburn Town, Bahamas",
				title: "San Salvador Airport",
				latitude: 24.066177707598765,
				longitude: -74.53162017143659,
				geocode: "24.066177707598765,-74.53162017143659",
				country: "Bahamas",
			},
			"FXE - Ft. Lauderdale Executive Airport": {
				place: "Florida",
				code: "FXE",
				name: "Ft. Lauderdale",
				address: "5925 NW 24th Way, Fort Lauderdale, FL 33309",
				title: "Ft. Lauderdale Executive Airport",
				latitude: 26.2014580,
				longitude: -80.1788989,
				geocode: "26.2014580,-80.1788989",
				country: "United States",
			},
			"FLL - Ft. Lauderdale Sheltair Terminal": {
				place: "Florida",
				code: "FLL",
				name: "Ft. Lauderdale",
				address: "Hollywood Int'l Airport, 1100 Lee Wagener Blvd, Fort Lauderdale, FL 33315",
				title: "Ft. Lauderdale Sheltair Terminal",
				latitude: 26.0693396,
				longitude: -80.1566390,
				geocode: "26.0693396,-80.1566390",
				country: "United States",
			},
			"MPD - Miami Seaplane Base": {
				place: "Florida",
				code: "MPD",
				name: "Miami",
				address: "1000 MacArthur Cswy, Miami, FL 33132",
				title: "Miami Seaplane Base",
				latitude: 25.7836433,
				longitude: -80.1775673,
				geocode: "25.7836433,-80.1775673",
				country: "United States",
			},
			"TMB - Miami Executive Airport": {
				place: "Florida",
				code: "TMB",
				name: "Miami",
				address: "12800 SW 145th Ave, Miami, FL 33186",
				title: "Miami Executive Airport",
				latitude: 25.6492604,
				longitude: -80.4290845,
				geocode: "25.6492604,-80.4290845",
				country: "United States",
			},
			"OPF - Miami Opa Locka Airport": {
				place: "Florida",
				code: "OPF",
				name: "Miami",
				address: "14201 NW 42nd Ave, Opa-locka, FL 33054",
				title: "Miami Opa Locka Airport",
				latitude: 25.9126998,
				longitude: -80.2770866,
				geocode: "25.9126998,-80.2770866",
				country: "United States",
			},
			"PBI - Palm Beach International Airport": {
				place: "Florida",
				code: "PBI",
				name: "Palm Beach",
				address: "1000 James L Turnage Blvd, West Palm Beach, FL 33415",
				title: "Palm Beach International Airport",
				latitude: 26.6886621,
				longitude: -80.0926443,
				geocode: "26.6886621,-80.0926443",
				country: "United States",
			},
			"BCT - Boca Raton Airport": {
				place: "Florida",
				code: "BCT",
				name: "Boca Raton",
				address: "903 NW 35th St, Boca Raton, FL 33431",
				title: "Boca Raton Airport",
				latitude: 26.3813125,
				longitude: -80.1076091,
				geocode: "26.3813125,-80.1076091",
				country: "United States",
			},
			"PIE - St Petersburg-Clearwater International Airport": {
				place: "Florida",
				code: "PIE",
				name: "Clearwater",
				address: "14700 Terminal Blvd #221, Clearwater, FL 33762",
				title: "St Petersburg-Clearwater International Airport",
				latitude: 27.9074052,
				longitude: -82.6907279,
				geocode: "27.9074052,-82.6907279",
				country: "United States",
			},
			"SRQ - Sarasota-Bradenton International Airport": {
				place: "Florida",
				code: "SRQ",
				name: "Sarasota",
				address: "6000 Airport Cir, Sarasota, FL 34243",
				title: "Sarasota-Bradenton International Airport",
				latitude: 27.4006056,
				longitude: -82.5527900,
				geocode: "27.4006056,-82.5527900",
				country: "United States",
			},
			"ORL - Orlando Executive Airport": {
				place: "Florida",
				code: "ORL",
				name: "Orlando",
				address: "365 Rickenbacker Dr, Orlando, FL 32803",
				title: "Orlando Executive Airport",
				latitude: 28.5516579,
				longitude: -81.3320443,
				geocode: "28.5516579,-81.3320443",
				country: "United States",
			},
			"CRG - Jacksonville Executive at Craig Airport": {
				place: "Florida",
				code: "CRG",
				name: "Jacksonville",
				address: "855-1 St Johns Bluff Rd N, Jacksonville, FL 32225",
				title: "Jacksonville Executive at Craig Airport",
				latitude: 30.3413585,
				longitude: -81.5165920,
				geocode: "30.3413585,-81.5165920",
				country: "United States",
			},
			"EYW - Key West International Airport": {
				place: "Florida",
				code: "EYW",
				name: "Key West",
				address: "3491 S Roosevelt Blvd, Key West, FL 33040",
				title: "Key West International Airport",
				latitude: 24.5848948,
				longitude: -81.7577205,
				geocode: "24.5848948,-81.7577205",
				country: "United States",
			},
			"APF - Naples Municipal Airport": {
				place: "Florida",
				code: "APF",
				name: "Naples",
				address: "160 Aviation Drive N, Naples, FL 34104",
				title: "Naples Municipal Airport",
				latitude: 26.152198,
				longitude: -81.7749422,
				geocode: "26.152198,-81.7749422",
				country: "United States",
			},
			"PLS - Providenciales International Airport": {
				place: "Turks & Caicos",
				code: "PLS",
				name: "Providenciales",
				address: "Airport Rd, Providenciales, Turks and Caicos Islands",
				title: "Providenciales International Airport",
				latitude: 21.7736,
				longitude: -72.2659,
				geocode: "21.7736,-72.2659",
				country: 'Turks & Caicos'
			},
			"GDT - JAGS McCartney International Airport": {
				place: "Turks & Caicos",
				code: "GDT",
				name: "Grand Turk",
				address: "Grand Turk Island, Turks and Caicos Islands",
				title: "JAGS McCartney International Airport",
				latitude: 21.4445,
				longitude: -71.1423,
				geocode: "21.4445,-71.1423",
				country: 'Turks & Caicos'
			},
			"HAV - José Martí International Airport": {
				place: "Cuba",
				code: "HAV",
				name: "Havana",
				address: "Avenida Van Troy y Final, Boyeros, Havana, Cuba",
				title: "José Martí International Airport",
				latitude: 22.9892,
				longitude: -82.4091,
				geocode: "22.9892,-82.4091",
				country: 'Cuba'
			},
			"VRA - Juan Gualberto Gómez International Airport": {
				place: "Cuba",
				code: "VRA",
				name: "Varadero",
				address: "Autopista Sur, Varadero, Cuba",
				title: "Juan Gualberto Gómez International Airport",
				latitude: 23.0344,
				longitude: -81.4353,
				geocode: "23.0344,-81.4353",
				country: 'Cuba'
			},
			"SCU - Antonio Maceo International Airport": {
				place: "Cuba",
				code: "SCU",
				name: "Santiago de Cuba",
				address: "Carretera del Aeropuerto, Santiago de Cuba",
				title: "Antonio Maceo International Airport",
				latitude: 19.9698,
				longitude: -75.8355,
				geocode: "19.9698,-75.8355",
				country: 'Cuba'
			},
			"HOG - Frank País International Airport": {
				place: "Cuba",
				code: "HOG",
				name: "Holguín",
				address: "Holguín, Cuba",
				title: "Frank País International Airport",
				latitude: 20.7856,
				longitude: -76.3158,
				geocode: "20.7856,-76.3158",
				country: 'Cuba'
			},
			"CCC - Jardines del Rey Airport": {
				place: "Cuba",
				code: "CCC",
				name: "Cayo Coco",
				address: "Cayo Coco, Cuba",
				title: "Jardines del Rey Airport",
				latitude: 22.4610,
				longitude: -78.3287,
				geocode: "22.4610,-78.3287",
				country: 'Cuba'
			},
			"MBJ - Sangster International Airport": {
				place: "Jamaica",
				code: "MBJ",
				name: "Montego Bay",
				address: "Queens Dr, Montego Bay, Jamaica",
				title: "Sangster International Airport",
				latitude: 18.5037,
				longitude: -77.9130,
				geocode: "18.5037,-77.9130",
				country: 'Jamaica'
			},
			"KIN - Norman Manley International Airport": {
				place: "Jamaica",
				code: "KIN",
				name: "Kingston",
				address: "Palisadoes, Kingston, Jamaica",
				title: "Norman Manley International Airport",
				latitude: 17.9357,
				longitude: -76.7874,
				geocode: "17.9357,-76.7874",
				country: 'Jamaica'
			},
			"OCJ - Ian Fleming International Airport": {
				place: "Jamaica",
				code: "OCJ",
				name: "Ocho Rios",
				address: "Boscobel, St. Mary, Jamaica",
				title: "Ian Fleming International Airport",
				latitude: 18.4043,
				longitude: -76.9688,
				geocode: "18.4043,-76.9688",
				country: 'Jamaica'
			},
			"GCM - Owen Roberts International Airport": {
				place: "Cayman Islands",
				code: "GCM",
				name: "Grand Cayman",
				address: "Roberts Dr, George Town, Grand Cayman, Cayman Islands",
				title: "Owen Roberts International Airport",
				latitude: 19.2928,
				longitude: -81.3578,
				geocode: "19.2928,-81.3578",
				country: 'Cayman Islands'
			},
			"CYB - Charles Kirkconnell International Airport": {
				place: "Cayman Islands",
				code: "CYB",
				name: "Cayman Brac",
				address: "Cayman Brac, Cayman Islands",
				title: "Charles Kirkconnell International Airport",
				latitude: 19.6869,
				longitude: -79.8822,
				geocode: "19.6869,-79.8822",
				country: 'Cayman Islands'
			},
			"LYB - Edward Bodden Airfield": {
				place: "Cayman Islands",
				code: "LYB",
				name: "Little Cayman",
				address: "Little Cayman, Cayman Islands",
				title: "Edward Bodden Airfield",
				latitude: 19.6571,
				longitude: -80.0892,
				geocode: "19.6571,-80.0892",
				country: 'Cayman Islands'
			}
		},
	},
	planeManufacturers: [
		{label: "Aeroprakt", value: "Aeroprakt"},
		{label: "Aerospatiale", value: "Aerospatiale"},
		{label: "Airbus", value: "Airbus"},
		{label: "Antonov", value: "Antonov"},
		{label: "Aquila", value: "Aquila"},
		{label: "Avions Robin", value: "Avions Robin"},
		{label: "Avro International Aerospace", value: "Avro International Aerospace"},
		{label: "Beechcraft", value: "Beechcraft"},
		{label: "Bell", value: "Bell"},
		{label: "Beriev Aircraft Company", value: "Beriev Aircraft Company"},
		{label: "Boeing", value: "Boeing"},
		{label: "Bombardier", value: "Bombardier"},
		{label: "British Aerospace", value: "British Aerospace"},
		{label: "British Aircraft Corporation", value: "British Aircraft Corporation"},
		{label: "Britten-Norman", value: "Britten-Norman"},
		{label: "Cessna", value: "Cessna"},
		{label: "Champion Aircraft Corporation", value: "Champion Aircraft Corporation"},
		{label: "China Commercial Aircraft", value: "China Commercial Aircraft"},
		{label: "Cirrus", value: "Cirrus"},
		{label: "Convair", value: "Convair"},
		{label: "Curtiss-Wright", value: "Curtiss-Wright"},
		{label: "Dassault Falcon", value: "Dassault Falcon"},
		{label: "De Havilland Aircraft of Canada", value: "De Havilland Aircraft of Canada"},
		{label: "Diamond Aircraft Industries", value: "Diamond Aircraft Industries"},
		{label: "Dornier Luftfahrt", value: "Dornier Luftfahrt"},
		{label: "Embraer", value: "Embraer"},
		{label: "Eurocopter", value: "Eurocopter"},
		{label: "Evektor-Aerotechnik", value: "Evektor-Aerotechnik"},
		{label: "Fokker", value: "Fokker"},
		{label: "GippsAero", value: "GippsAero"},
		{label: "Government Aircraft Factories", value: "Government Aircraft Factories"},
		{label: "Grumman", value: "Grumman"},
		{label: "Gulfstream", value: "Gulfstream"},
		{label: "Harbin Aircraft Industry Group", value: "Harbin Aircraft Industry Group"},
		{label: "Hawker Beechcraft Corporation", value: "Hawker Beechcraft Corporation"},
		{label: "Honda", value: "Honda"},
		{label: "ICON", value: "ICON"},
		{label: "Ilyushin", value: "Ilyushin"},
		{label: "Indonesian Aerospace", value: "Indonesian Aerospace"},
		{label: "Israel Aircraft Industries", value: "Israel Aircraft Industries"},
		{label: "Junkers", value: "Junkers"},
		{label: "Learjet", value: "Learjet"},
		{label: "Let Kunovice", value: "Let Kunovice"},
		{label: "Lockheed Corporation", value: "Lockheed Corporation"},
		{label: "Maule", value: "Maule"},
		{label: "McDonnell Douglas", value: "McDonnell Douglas"},
		{label: "MD Helicopters", value: "MD Helicopters"},
		{label: "Mil Moscow Helicopter Plant", value: "Mil Moscow Helicopter Plant"},
		{label: "Mitsubishi", value: "Mitsubishi"},
		{label: "Nihon Aircraft Manufacturing Corporation", value: "Nihon Aircraft Manufacturing Corporation"},
		{label: "Piaggio Aero", value: "Piaggio Aero"},
		{label: "Pilatus Aircraft", value: "Pilatus Aircraft"},
		{label: "Piper Aircraft", value: "Piper Aircraft"},
		{label: "Pipistrel", value: "Pipistrel"},
		{label: "Russian Aircraft Corporation MiG", value: "Russian Aircraft Corporation MiG"},
		{label: "Saab", value: "Saab"},
		{label: "Short Brothers", value: "Short Brothers"},
		{label: "Sikorsky", value: "Sikorsky"},
		{label: "Socata", value: "Socata"},
		{label: "Sukhoi", value: "Sukhoi"},
		{label: "Team Rocket", value: "Team Rocket"},
		{label: "Tecnam", value: "Tecnam"},
		{label: "TL Ultralight", value: "TL Ultralight"},
		{label: "Tupolev", value: "Tupolev"},
		{label: "Vulcanair", value: "Vulcanair"},
		{label: "Yakovlev", value: "Yakovlev"}
	],
	payoutMethods: [
		{label: 'Kanoo Pay', value: 'Kanoo Pay'},
	],
	payoutsMethodsUS: [
		{label: 'Stripe', value: 'Stripe'},
	],
	personWeightRange: [
		{label: '100lbs', value: '100'},
		{label: '150lbs', value: '150'},
		{label: '200lbs', value: '200'},
		{label: '250lbs', value: '250'},
	],
	personWeightRange2: [
		{label: '50 lbs', value: '50'},
		{label: '60 lbs', value: '60'},
		{label: '70 lbs', value: '70'},
		{label: '80 lbs', value: '80'},
		{label: '90 lbs', value: '90'},
		{label: '100 lbs', value: '100'},
		{label: '110 lbs', value: '110'},
		{label: '120 lbs', value: '120'},
		{label: '130 lbs', value: '130'},
		{label: '140 lbs', value: '140'},
		{label: '150 lbs', value: '150'},
		{label: '160 lbs', value: '160'},
		{label: '170 lbs', value: '170'},
		{label: '180 lbs', value: '180'},
		{label: '190 lbs', value: '190'},
		{label: '200 lbs', value: '200'},
		{label: '200+ lbs', value: '200+'},
	],
	bagWeightRange: [
		{label: '20-40 lbs', value: '20-40'},
		{label: '40-50 lbs', value: '40-50'},
		{label: '50-60 lbs', value: '50-60'},
		{label: '60+ lbs', value: '60-60'}
	],
	additionalWeightOptions: {
		'20-40': '20-40',
		'40-50': '40-50',
		'50-60': '50-60',
		'60-60': '60+',
		'60+': '60+'
	},
	paymentType: [
		{label: 'Credit Card', 'value': 'credit-card'},
		{label: 'Apple Pay', 'value': 'apple-pay'},
		{label: 'Google Pay', 'value': 'google-pay'},
		{label: 'Kanoo pay', 'value': 'kanoo-pay'}
	],
	payoutType: [
		{label: 'Kanoo Pay', 'value': 'kanoo-pay'},
		{label: 'Wire Transfer', 'value': 'wire-transfer'},
	],
	cancelReason: [
		{label: 'Weather', value: 'weather'},
		{label: 'Issue with plane', value: 'issue with plane'},
		{label: 'Issue with pilot', value: 'issue with pilot'},
		{label: 'Other', value: 'other'}
	],
	requestPayoutReason: [
		{label: 'I need money now', value: 'I need money now'},
		{label: 'The schedule payout is too long of a timeframe', value: 'The schedule payout is too long of a timeframe'},
		{label: 'Other', value: 'other'}
	],
	cancelFlightReason: [
		{label: 'I found another pilot', value: 'I found another pilot'},
		{label: 'Trip Cancelled', value: 'Trip Cancelled'},
		{label: 'Other', value: 'Other'}
	],
	weekdays: [
	    "", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
	],
	onboardingTitles: [
		{header: 'Welcome to Flymingo!', content: 'Hop on already scheduled flights, request flights and get notified when there are available seats from your favorite islands.'},
		{header: 'Vetted Pilots & Planes', content: 'We vet all of our pilots and planes ensuring that all certificates and licesnes are valid and up to date.'},
		{header: 'Flexible Departures', content: 'Don’t have a fixed time? That’s OK, our pilots will bid on your flexible request with a departure time. Select the flight that has the best price and departure time. '},
		{header: 'Flight alerts!', content: 'Recieve push notifications when there are empty seats to your favorite islands.'},
		{header: 'Split the flight with friends!', content: 'Invite your friends on your requested flight and split the total cost with your friends.'}
	],
	requestStatus: {
		'expired': 'Expired',
		'available': 'Bids Available',
		'pending': 'Pending Bids',
		'info-needed': 'Info Needed',
		'on-time': 'On-Time',
		'pending-pilot-status': 'Pending pilot',
		'approved': 'Approved by pilot',
	},
	flightStatus: {
		'expired': 'Expired',
		'completed': 'Completed',
		'cancelled': 'Cancelled',
		'verified': 'Complete Payment',
		'awaiting': 'Awaiting Check-In',
		'checkin': 'Check-In Open',
		'on-time': 'Approved',
		'pending': 'Pending',
		'denied': 'Declined',
		'smart-check-in': 'Smart Check-In',
		'manual-checkin-required': 'Manual Check-In Required',
		'approved': 'Pending Accept & Pay'
	},
	flightStatusBadge: {
		'expired': 'Expired',
		'completed': 'Completed',
		'cancelled': 'Cancelled',
		'verified': 'Cancelled',
		'awaiting': 'Check-In open',
		'checkin': 'Closed',
		'on-time': 'Departure',
		'pending': 'Pending',
		'denied': 'Declined',
		'smart-check-in': 'Smart Check-In',
		'manual-checkin-required': 'Manual Check-In Required',
	},
	checkinStatus: {
		'awaiting': 'Waiting',
		'verified': 'Approved',
		'pending': 'Pending Review',
		'denied': 'Declined'
	},
	countryContraction: {
		'Bahamas': 'bahamas',
		'United States': 'us',
		'Turks & Caicos': 'tc',
		'Cuba': 'cu',
		'Jamaica': 'jm',
		'Cayman Islands': 'ky',
	}
}

export default resources
