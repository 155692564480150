const calculateZoomLevel = (markers) => {
  const lat1 = markers[0].lat;
  const lon1 = markers[0].lng;
  const lat2 = markers[1].lat;
  const lon2 = markers[1].lng;

  const R = 6371; // Radius of the Earth in kilometers
  const dLat = (lat2 - lat1) * (Math.PI / 180);
  const dLon = (lon2 - lon1) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return 14 - Math.log2(distance);
};

export default calculateZoomLevel;
