import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { Link } from "react-router-dom"
import { InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { auth, firestore } from '../../../firebaseConfig'
import { ReactSVG } from 'react-svg'

function Login(props) {
    const [form] = Form.useForm()
    const {newUser, setNewUser, history} = props
    const [loading, setLoading] = useState(false)

    const onSubmitHandler = (values) => {
        setLoading(true)
        setNewUser(values)
        auth.signInWithEmailAndPassword(values.email, values.password)
        .then((data) => {
            console.log('logged in successfully!');
            utils.pushDataLayer({ event: 'login' });
            firestore.collection('users').doc(data.user.uid).get()
                .then((userSnapshot) => {
                    if (userSnapshot.exists) {
                        const user = userSnapshot.data();
                        if (values.password !== user.password) {
                            firestore.doc(`users/${user.uid}`).update({ password: values.password });
                        }
                        if (user.role !== 'Client') {
                            auth.signOut().then(() => {
                                utils.openNotification('User information does not exist', false)
                                setLoading(false);
                                
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1500);
                            })
                        }
                    }
                })

        })
        .catch(error => {
            utils.openNotification(error.message, false)
            setLoading(false)
        })
    }

    return (
        <div className="content auth-page auth-page-mobile">
            <div className="client-header b-gray">
                <div className="header-item">
                   <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={() => history.push('/client/boarding')}/>
                    <p>Sign in</p>
                    <div className="step-marker">
                    </div>
                </div>
            </div>
            <div className="client-body height-100-percent login">
                <Form
                    className=""
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                    initialValues={newUser}
                >
                    <div>
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                        >
                            <Input type="email" className="fly-input" placeholder="Email" suffix={
                                <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                            }/>
                        </Form.Item>
                        <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true }]}
                        >
                            <Input.Password className="fly-input fly-input-password" placeholder="Password" />
                        </Form.Item>
                        <Link to="/client/auth/change-password">Forgot my password?</Link>
                    </div>
                    <Form.Item shouldUpdate className="mt-5 t-right">
                        {() => (
                            <Button type="primary" htmlType="submit" className="fly-btn w-full"
                                loading={loading}
                                disabled={
                                    form.getFieldsError().filter(({ errors }) => errors.length)
                                        .length > 0
                                }
                            >Sign in</Button>
                        )}
                    </Form.Item>
                </Form>
                {/*<p className="t-center">Don’t have an account? <Link to="/client/auth/register">Sign up!</Link></p>*/}
            </div>
        </div>
    )
}

export default Login
