import React from 'react'
import { Row, Col, Button } from 'antd'
import { ReactSVG } from 'react-svg'

function CheckInFailed(props) {
    const {history, step, setStep, setActiveRecord} = props

    return (
        <div className="bid-done-board">
            <Row>
                <Col span={24} className="t-center">
                    <ReactSVG src="/images/error.svg" />
                    <h3 className="mt-3">Manual Check-In <br />Required</h3>
                    <p className="fly-notes mt-2 t-center">Your flight is confirmed, but we noticed a changes to your passenger detail that requires a review from the pilot.</p>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col span={24}>
                    <Button type="primary" className="fly-btn w-full" onClick={() => {
                            setActiveRecord()
                            history.push('/client/flight-requests?ft=upcoming')
                        }}>
                        Go to My Flights
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default CheckInFailed
