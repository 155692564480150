export const CLIENT_DOMAIN = 'app.flymingo.io';
export const PILOT_DOMAIN = 'pilot.flymingo.io';
export const DEV_CLIENT_DOMAIN = 'dev.app.flymingo.io';
export const DEV_PILOT_DOMAIN = 'dev.pilot.flymingo.io';
export const CLIENT_ROLE = 'Client';
export const FLYMINGO_APP_URL = 'https://www.flymingo.app/';

export const FIXED_STRIPE_COMMISSION = 0.3;
export const MAX_STRIPE_COMMISSION = 5.9;
export const MIN_STRIPE_COMMISSION = 2.9;
export const KANOO_COMMISSION = 0;
export const FLYMINGO_COMMISSION = 0.1;
export const FLYMINGO_SERVICES = 0.75;

export const KANOO_PAYMENT_COUNTRY = 'Bahamas';
export const STRIPE_PAYMENT_COUNTRY = 'United States';
export const KANOO_PAYMENT_METHOD = 'kanoo';
export const STRIPE_PAYMENT_METHOD = 'stripe';
