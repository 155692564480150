import React from 'react';
import { ReactSVG } from 'react-svg';
import './index.scss';

const NotFound = () => {
    return (
        <div className='not-found'>
            <div className='cloud'><ReactSVG src={'/images/cloud.svg'} /></div>
            <div className='container'>
                <p className='title'>
                    Pardon the sticks, we are building our nest
                </p>
                <div className='flamingo'><ReactSVG src={'/images/flamingo.svg'} /></div>
            </div>
        </div>
    )
}

export default NotFound;
