import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Switch, Dropdown, Menu, Modal, Select } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { ReactSVG } from 'react-svg'
import './index.scss'
import * as utils from '../../../utils'
import { resources } from '../../../utils';


function PassengerItem(props) {
    const {passenger, removePassengerHandler, editPassengerHandler, idx} = props
    const menu = (
        <Menu className="passenger-menu">
            <Menu.Item key="edit" onClick={() => editPassengerHandler(passenger, idx)}>
                <p>Edit</p>
            </Menu.Item>
            <Menu.Item key="remove" onClick={() => removePassengerHandler(passenger, idx)}>
                <p>Remove</p>
            </Menu.Item>
        </Menu>
    )

    return (
        <Row className="passenger-item row-wrapper mb-2">
            <Col span={22}>
                <p>{passenger.firstname} {passenger.lastname}, <span className="fly-notes">{passenger.weight}lbs</span></p>
                {passenger.numberOfBags &&
                    <p>
                        {passenger.numberOfBags} Bag{passenger.numberOfBags > 1 && "s"}, <span className="fly-notes">
                            {resources.bagWeightRange.find(range => range.value === passenger.weightPerBag)?.label} (S)
                        </span>
                    </p>}
            </Col>
            <Col span={2} className="t-right">
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    <EllipsisOutlined />
                </Dropdown>
            </Col>
        </Row>
    )
}

function Step3(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, setFlight, setFlightValues, setActivePassenger, currentUser} = props
    const [hasPet, setHasPet] = useState(false)
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        setHasPet(flight.hasPet)
    }, [step])
    
    const onSubmit = (values) => {
        setFlightValues(values)
        // if (flight.passengerCnt)
        //     setStep("TermsAndConditions")
        // else
        //     setStep(4)
        setStep("TermsAndConditions")
    }

    const onSubmitHandler = (values) => {
        if (flight.passengerCnt && flight.passengers && flight.passengerCnt !== flight.passengers.length) {
            utils.openNotification(`Please add ${flight.passengerCnt} passengers`, false);
            return
        }
        if ((!flight.passengerCnt && ((flight.passengers && flight.passengers.length === 0) || !flight.passengers)) || (flight.passengerCnt && !flight.passengers)) {
            utils.openNotification(`Please add 1 passenger at least`, false);
            return
        }
        if (flight.passengers.length < 2) {
            setModalOpen(true);
            return
        }

        onSubmit(values);
    }


    const AddPassengerHandler = () => {
        setActivePassenger()
        setStep("AddPassenger")
    }

    const removePassengerHandler = (passenger, idx) => {
        Modal.confirm({
            title: '',
            maskClosable: true,
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div>
                <h3 className="mt-4">Remove the passenger</h3>
                <p>Are you sure you want to remove the passenger <b>{passenger.firstname} {passenger.lastname}</b>?</p>
            </div>,
            okText: 'REMOVE',
            onOk() {
                let passengers = flight.passengers.filter((item, i) => i !== idx)
                setFlight({...flight, passengers})
            },
        })
    }

    const editPassengerHandler = (passenger, idx) => {
        setActivePassenger({
            passenger: passenger,
            idx: idx
        })
        setStep("AddPassenger")
    }

    const onChangeHander = (checked) => {
        setHasPet(checked)
        setFlightValues({ ...flight, hasPet: checked })
    }

    const myInfo = {
        firstname: currentUser.profile.firstname,
        lastname: currentUser.profile.lastname,
        weight: currentUser.profile.approximateWeight,
        owner: true,
        you: true
    }
    const autoId = flight.passengers? flight.passengers.length : 0
    const myInfoIncluded = flight.passengers && flight.passengers.filter(passenger => passenger.firstname === myInfo.firstname && passenger.lastname === myInfo.lastname).length > 0

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label">Passengers</p>
                        { flight.passengerCnt &&
                            <p className="mb-2">Please add {flight.passengerCnt} passengers</p>
                        }
                        {flight.passengers && flight.passengers.map((passenger, idx) =>
                            <PassengerItem key={idx}
                                passenger={passenger}
                                idx={idx}
                                editPassengerHandler={editPassengerHandler}
                                removePassengerHandler={removePassengerHandler}
                            />
                        )}
                        <Row className="mt-4">
                            <Col span={24}>
                                {!myInfoIncluded &&
                                    <Button type="primary" className="fly-btn w-full add-btn mb-2" onClick={() => editPassengerHandler(myInfo, autoId)}>
                                        Add me
                                    </Button>
                                }
                                <Button type="primary" className="fly-btn w-full add-btn" onClick={AddPassengerHandler}>
                                    Add a Passenger
                                </Button>
                            </Col>
                        </Row>
                        <hr className="mt-3 mb-3" />
                        <p className="section-label">Pets</p>
                        <Row className="d-flex ">
                            <Col span={20}>
                                <p>Traveling with pet</p>
                            </Col>
                            <Col span={4} className="t-right">
                                <Form.Item label="" name='hasPet' valuePropName="checked">
                                    <Switch onChange={onChangeHander} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {hasPet &&
                            <Row className="row-wrapper">
                                <Col span={24}>
                                    <Form.Item
                                        label="Number of Pets"
                                        name="numberOfPets"
                                        rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                        <Select
                                            placeholder="Type to search"
                                            className="fly-select"
                                            options={utils.resources.integers}
                                            showSearch={true}
                                            allowClear
                                            onChange={(value) => setFlightValues({ ...flight, numberOfPets: value })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                <hr />
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Approximate Weight per Pet"
                                        name="weightPerPet"
                                        rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                        <Select
                                            placeholder="Type to search"
                                            className="fly-select"
                                            options={utils.resources.bagWeightRange}
                                            showSearch={true}
                                            allowClear
                                            onChange={(value) => setFlightValues({ ...flight, weightPerPet: value })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                Review
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
            <Modal
                title="Fostering Sustainable Travel"
                centered
                open={modalOpen}
                okType='danger'
                onCancel={() => setModalOpen(false)}
                closable={false}
                footer={[
                    <Button key="submit" type="primary" onClick={() => {
                        form.validateFields().then(values => {
                            setModalOpen(false);
                            onSubmit(values);
                        })
                    }}>
                        This is an emergency
                    </Button>,
                    <Button key="back" onClick={() => setModalOpen(false)}>
                        Cancel My Request
                    </Button>,
                ]}
            >
                <p>We are committed to our mission of sustainable travel, for leisure travel we will only send requests for a minimum or 2-3 passengers.</p>
            </Modal>
        </div>
    )
}

export default Step3
