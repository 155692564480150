import React from 'react'
import { Carousel, Row, Col, Button} from 'antd'
import _ from "lodash"
import * as utils from '../../../utils'

function Boarding(props) {
    const {history} = props

    return (
        <div className="content boarding-page height-100-percent">
            <Carousel autoplay waitForAnimate>
                {_.times(5, (idx) =>
                    <Row className="t-center" key={idx}>
                        <Col span={24}>
                            <img alt='' src={`/images/onboarding-${idx+1}.png`} className="boarding-img" />
                            <h3 className="mt-3">{utils.resources.onboardingTitles[idx].header}</h3>
                            <p className="fly-notes mt-2 t-center">{utils.resources.onboardingTitles[idx].content}</p>
                        </Col>
                    </Row>
                )}
            </Carousel>
            <Row className="mt-5">
                <Col span={24}>
                    {/*<Button type="primary fly-btn" className="fly-btn w-full" onClick={() => history.push('/client/auth/register')}>*/}
                    {/*    Get Started*/}
                    {/*</Button>*/}
                    <Button type="primary" className="fly-btn w-full mt-2 " onClick={() => history.push('/client/auth/login')}>
                        Sign in
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default Boarding
