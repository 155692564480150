import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Input, Select } from 'antd'
import './index.scss'
import * as utils from '../../../utils'
import { firestorage } from "../../../firebaseConfig"
import { InfoOutlined } from '@ant-design/icons'

function AddPassenger(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, setFlight, setFlightValues, activePassenger, setActivePassenger, external, checkin, edit} = props
    const [bagList, setBagList] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (activePassenger)
            setBagList(activePassenger.passenger.bagList||[])
    }, [activePassenger])

    const customUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }

        setLoading(true)
        const storageRef = await firestorage.ref()
        const imgFile = storageRef.child(`passengers/bag-${utils.generateRandomKeys()}.png`)
        try {
            const image = await imgFile.put(file, {contentType: 'image/jpeg'})
            onSuccess(null, image)
            bagList.push(await image.ref.getDownloadURL())
        } catch(e) {
        }
        setLoading(false)
    }

    const onSubmitHandler = (values) => {
        let data = {}
        Object.keys(values).forEach(key => {
            if (values[key] !== undefined)
                data[key] = values[key]
        })
        if (activePassenger && activePassenger.passenger.owner) {
            data['owner'] = true
            data['you'] = true
        }
        // data['bagList'] = bagList
        if (checkin) {
            data['checkin'] = true
        }
        if (!flight.passengers) {
            flight['passengers'] = []
        }
        if (!activePassenger) {
            flight['passengers'].push(data)
        } else {
            flight['passengers'][activePassenger.idx] = data
        }
        if (checkin) {
            setStep(1)
        } else if (edit) {
            setStep('AddPassenger')
        } else {
            setStep(external? 'Details': 3)
        }
    }

    let initialValues = activePassenger? activePassenger.passenger: {}

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={initialValues}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label mb-1">Personal Information</p>
                        { checkin
                            ? <p className="fly-banner warning mb-2 mt-2"><span className="svg-wrapper"><InfoOutlined /></span> Name must match their government-provided ID.</p>
                            : <p className="fly-notes mb-2">Please note: the name of the person must match their government provided ID</p>
                        }
                        <Row className="row-wrapper">
                            <Col span={24}>
                                <Form.Item
                                    label="First name"
                                    name="firstname"
                                    dependencies={['lastname']}
                                    rules={[{ validator: (rule, value, callback) =>
                                        utils.validations.validateUsers(rule, value, form, flight['passengers'], callback, 'First name', true, checkin, activePassenger) }]}
                                >
                                    <Input type="text" className="fly-input" placeholder="Type your first name" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <hr />
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Last name"
                                    name="lastname"
                                    dependencies={['firstname']}
                                    rules={[{ validator: (rule, value, callback) =>
                                        utils.validations.validateUsers(rule, value, form, flight['passengers'], callback, 'Last name', true, checkin, activePassenger) }]}
                                >
                                    <Input type="text" className="fly-input" placeholder="Type your last name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-wrapper mt-2">
                            <Col span={24}>
                                <Form.Item label="Approximate Weight" name="weight"
                                    rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        placeholder="Type to search"
                                        className="fly-select"
                                        options={utils.resources.personWeightRange2}
                                        showSearch={true}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <p className="section-label mb-1 mt-4">Bags</p>
                        <p className="fly-notes mb-2">Your first bag is free, any additional bag will incur a cost by the pilot.</p>
                        <Row className="row-wrapper">
                            <Col span={24}>
                                <Form.Item
                                    label="Number of Bags"
                                    name="numberOfBags"
                                    dependencies={['weightPerBag']}
                                    rules={[{ validator: (rule, value, callback) =>
                                        utils.validations.validateNumberOfBags(rule, value, form, callback, 'Number of Bags') }]}
                                >
                                    <Select
                                        placeholder="Type to search"
                                        className="fly-select"
                                        options={utils.resources.integers}
                                        showSearch={true}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                            <hr />
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label="Approximate Weight per Bag"
                                    name="weightPerBag"
                                    dependencies={['numberOfBags']}
                                    rules={[{ validator: (rule, value, callback) =>
                                        utils.validations.validateWeightPerBag(rule, value, form, callback, 'Approximate Weight per Bag') }]}
                                >
                                    <Select
                                        placeholder="Type to search"
                                        className="fly-select"
                                        options={utils.resources.bagWeightRange}
                                        showSearch={true}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row className="mt-3">
                            {bagList.map(file => {
                                return (
                                    <Col span={8} key={file}>
                                        <img src={file} className="bag-img" />
                                    </Col>
                                )
                            })}
                            <Col span={8}>
                                {loading?
                                    <div className="bag-img"><Spin style={{margin: "auto"}} /></div>
                                    :
                                    <Upload
                                        className="ant-upload bag-img"
                                        listType="picture-card"
                                        customRequest={customUploadHandler}
                                    >
                                        <img src="/images/upload.svg" className="m-auto" />
                                    </Upload>
                                }
                            </Col>
                        </Row>*/}
                        {/*<p className="fly-banner info mb-2 mt-2">The information does not match as in the original request. The pilot will have to approve the changes before the flight is accepted. </p>*/}
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                {checkin && !edit? 'Done': 'Continue'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default AddPassenger
