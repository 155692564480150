export const MAP_STYLES = [
    // {
    //     "elementType": "labels",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "administrative",
    //     "elementType": "geometry",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "administrative.land_parcel",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "administrative.neighborhood",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "landscape.natural",
    //     "stylers": [
    //         {
    //             "color": "#e2e5e9"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "landscape.natural",
    //     "elementType": "geometry.fill",
    //     "stylers": [
    //         {
    //             "color": "#e2e5e9"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "poi",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "road",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "road",
    //     "elementType": "labels.icon",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "transit",
    //     "stylers": [
    //         {
    //             "visibility": "off"
    //         }
    //     ]
    // },
    // {
    //     "featureType": "water",
    //     "stylers": [
    //         {
    //             "color": "#91d2fd"
    //         }
    //     ]
    // }
];
