import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Redirect } from "react-router-dom"
import { useSelector } from 'react-redux'
import Welcome from "./Welcome"
import Login from "./Login"
import Register from "./Register"
import PasswordRecovery from "./PasswordRecovery"
import Complete from "./Complete"
import AccountType from "./AccountType"
import AccountCompany from "./AccountCompany"
import Action from "./Action"
import './index.scss'
import { Layout } from 'antd'
import { FlyHeader } from '../../components/'
import { firestore } from '../../firebaseConfig';

const { Header, Content, Footer } = Layout
const authPages = ['login', 'welcome', 'password-recovery']

function Auth (props) {
    const history = useHistory()
    const { currentUser } = useSelector((state) => state.common)
    const [ user, setUser ] = useState({})
    const { path } = useParams()
    const [config, setConfig] = useState({});
    
    useEffect(() => {
        firestore.collection('main').doc('config').get().then(doc => {
            if (doc.exists) setConfig(doc.data());
        });
    }, []);

    let renderPage = ''

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [path])

    let params = {
        user,
        setUser,
        history,
        currentUser,
        config
    }

    switch (path) {
        case 'welcome':
            renderPage = <Welcome {...params} />
            break
        case 'login':
            renderPage = <Login {...params} />
            break
        case 'register':
            renderPage = <Register {...params} />
            break
        case 'password-recovery':
            renderPage = <PasswordRecovery {...params} />
            break
        case 'complete':
            renderPage = <Complete {...params} />
            break
        case 'account-type':
            renderPage = <AccountType {...params} />
            break
        case 'account-company':
            renderPage = <AccountCompany {...params} />
            break
        case 'action':
            renderPage = <Action {...params} />
            break
        default:
            renderPage = <Welcome {...params} />
            break
    }

    let clsName = "auth-layout " + path

    if (currentUser && authPages.indexOf(path) > -1) {
        if (!currentUser?.profile?.accountCompanyInfoFilled) {
            if (currentUser?.profile?.role === 'Client') {
                return <Redirect to="/client/auth" />
            }
            
            return <Redirect to="/auth/account-company" />
        }
        return <Redirect to="/dashboard/home" />
    }

    return (
        <Layout className={clsName}>
            { path === 'account-company' && <Header><FlyHeader {...params} /></Header>}
            <Content>{renderPage}</Content>
        </Layout>
    )
}

export default Auth
