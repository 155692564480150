import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { useDispatch } from 'react-redux'
import { Form, Row, Col,  Input, Button, DatePicker, Checkbox, Tooltip, Select } from 'antd'
import { FlySideBar } from '../../components/'
import { ArrowRightOutlined, QuestionCircleOutlined, InfoCircleOutlined  } from '@ant-design/icons'
import GetNotifiedModal from "./GetNotifiedModal"
import * as utils from '../../utils'
import FlyActions from '../../redux/common'
import { auth, generateUserDocument, firestore } from "../../firebaseConfig"
import { ApiManager } from "../../service"

const requiredFields = ['password', 'firstname', 'lastname', 'birthdate', 'country']

function Register(props) {
    const [form] = Form.useForm()
    const {user, setUser, history} = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search);
        if (!urlParams.get('email')) {
            history.push('/auth/welcome');
        } else {
            form.setFieldValue('email', urlParams.get('email'))
        }
    }, []);

    const onSubmitHandler = async (values) => {
        setLoading(true)
        try {
            const {user} = await auth.createUserWithEmailAndPassword(values.email, values.password)
            values.birthdate = values.birthdate.format('YYYY-MM-DD')
            generateUserDocument(user, { ...values, role: values.role || utils.resources.roles[utils.resources.roles.length - 1].value })
            ApiManager.verifyEmailRequest({user: values})
            // const emailVerification = await user.sendEmailVerification({
            //     url: `${utils.domain}/auth/login`,
            // })

            const requests = [];

            const rSnapshot = await firestore.collection('requests').get();

            if (!rSnapshot.empty) {
                rSnapshot.forEach(doc => {
                    let rItem = doc.data();
                    rItem.id = doc.id;

                    const userRegistered = rItem.uid.length !== 20;

                    if (!rItem.status && userRegistered) requests.push(rItem);
                })
            }
            
            await Promise.all(requests.map(async (request) => {
                await firestore.collection('users').doc(user.uid).collection('requests').doc(request.id).set({
                    requestId: request.id,
                    status: 'pending',
                });
                
                if (request.users) {
                    await firestore.collection('requests').doc(request.id).update({
                        users: [...request.users, user.uid],
                    });
                }
            }));

            dispatch(FlyActions.onLoadUser(user))
            history.push('/auth/complete')
            utils.pushDataLayer({ event: 'sign_up' });

            ApiManager.sendSlackAlert({
                text: `🦩 new user created \nType: pilot \nCountry: ${values.country}`,
            })
        }
        catch (error) {
            utils.openNotification(error.message, false)
        }
        setLoading(false)
    }

    const toolTipContent = <p>
        Don’t see your country? Get <GetNotifiedModal {...props} /> when we expand.
    </p>

    return (
        <div className="auth-page auth-page-auth">
            <FlySideBar />
            <div className="content">
                <div className="fly-form auth-form auth-register-page">
                    <h2>Create Your Flymingo Account</h2>
                    <Form
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        initialValues={user}
                    >
                        <Form.Item>
                            <Form.Item label="Email" name="email"
                                className="form-item-children"
                                rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                            >
                                <Input type="email" className="fly-input" placeholder="Email" suffix={
                                    <InfoCircleOutlined style={{ color: '#E24D59' }} />
                                } readOnly/>
                            </Form.Item>
                            <p className="fly-notes mt-1">This email will be used to receive requests to book flights. You can change this later.</p>
                        </Form.Item>
                        <Form.Item>
                            <Row>
                                <Col className="ant-form-item-label d-flex">
                                    <label>Password</label>
                                    <Tooltip className="fly-tooltip" title={"Password must be at least 8 characters long, contain one upper and lower case letter, one number and one special character."} color="#90C4C2" placement="right">
                                      <QuestionCircleOutlined className="fly-icon ml-1" />
                                    </Tooltip>
                                </Col>
                            </Row>
                            <Form.Item label="" name="password"
                                className="form-item-children"
                                rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword(rule, value, callback, 'Password', true) }]}
                            >
                                <Input.Password className="fly-input fly-input-password" placeholder="Password" />
                            </Form.Item>
                            <Checkbox className="fly-checkbox mt-1">I do NOT want to get emails from <b>Flymingo</b> about product updates, news, and events. You can unsubscribe at any time.</Checkbox>
                        </Form.Item>
                        <Form.Item label="First name" name="firstname"
                            rules={[{ validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'First name', true) }]}
                        >
                            <Input type="text" className="fly-input" placeholder="First name" suffix={
                                <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                            }/>
                        </Form.Item>
                        <Form.Item label="Last name" name="lastname"
                            rules={[{ validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Last name', true) }]}
                        >
                            <Input type="text" className="fly-input" placeholder="Last name" suffix={
                                <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                            }/>
                        </Form.Item>
                        <Row>
                            <Col xl={12} xs={24} className="pr-1">
                                <Form.Item label="Birth Date" name="birthdate"
                                    rules={[{ validator: (rule, value, callback) => utils.validations.validateBirthDay(rule, value, callback, 'Birthday', true) }]}>
                                    <DatePicker format="YYYY-MM-DD" placeholder="YYYY-MM-DD" className="fly-date-picker" defaultPickerValue={utils.defaultDate} showToday={false}/>
                                </Form.Item>
                            </Col>
                            <Col xl={12} xs={24}>
                                <Form.Item shouldUpdate>
                                    { () => (
                                        form.getFieldsError().filter(({ errors }) => errors && errors[0] === '').length > 0
                                            ? <div className="fly-error">
                                                <InfoCircleOutlined style={{ marginTop: 7, fontSize: '1.2rem'}}/>
                                                <p className="fly-notes" style={{marginTop: 3}}>To sign up, you need to be at least 18. Your birthdate wont be shared with others using Flymingo.</p>
                                            </div>
                                            : <p className="fly-notes mt-4 additionalLabel">To sign up, you need to be at least 18. Your birthdate wont be shared with others using Flymingo.</p>
                                    )}
                               </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} xs={24} className="pr-1">
                                <Row>
                                    <Col className="ant-form-item-label d-flex">
                                        <label>Country</label>
                                        <Tooltip className="fly-tooltip" title={toolTipContent} color="#90C4C2" placement="right">
                                          <QuestionCircleOutlined className="fly-icon ml-1" />
                                        </Tooltip>
                                    </Col>
                                </Row>
                                <Form.Item label="" name="country" rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        placeholder="Pick fom the list"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.defaultCountries}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                            <Col xl={12} xs={24} className="pl-1">
                                <Row>
                                    <Col span={24} className="ant-form-item-label d-flex d-space">
                                        <label>Role</label>
                                        <p className="fly-notes">*optional</p>
                                    </Col>
                                </Row>
                                <Form.Item label="" name="role">
                                    <Select
                                        placeholder="Pick fom the list"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.roles}
                                        allowClear
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <p className="fly-notes">By clicking Next, you agree to our <a href="/pilot-terms" target="_blank">T & C</a> and that you have read our <a href="/pilot-privacy" target="_blank">Privacy Policy</a></p>
                        <Form.Item shouldUpdate className="mt-3 t-right">
                            {() => (
                                <Button type="primary" htmlType="submit" className="fly-btn"
                                    loading={loading}
                                    disabled={
                                        form.getFieldsError().filter(({ errors }) => errors.length)
                                          .length > 0 || !form.isFieldsTouched(requiredFields, true)
                                    }
                                >NEXT<ArrowRightOutlined /></Button>
                            )}
                        </Form.Item>
                    </Form>
                </div>
                <h4>Already have an account? <Link to="/auth/login" className="ml-1">Sign in</Link></h4>
            </div>
        </div>
    )
}

export default Register
