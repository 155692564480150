import React, { useState } from 'react'
import { Form, Button, Row, Col, Space } from 'antd'
import * as utils from '../../../../utils'
import { firestore, serverTimestamp } from "../../../../firebaseConfig"
import { ApiManager } from "../../../../service"
import { sendExpoNotificationToUser } from '../../../../utils';
import { useMediaQuery } from '../../../../hooks/useMediaQuery.'

function RequestReviewForm (props) {
    const {setStep, activeRecord, bidInfo, pilotsDict, fleetsDict, currentUser, loadData, activeBid} = props
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const isMobile = useMediaQuery('(max-width: 768px)');

    const onSubmitHandler = async (values) => {
        setLoading(true)
        let departureTime = ''
        if (bidInfo.departureTime && bidInfo.departureTime !== '')
            departureTime = bidInfo.departureTime.format('hh:mm a')
        
        if (activeBid) {
            await firestore.collection('bids').doc(activeBid.id).update({
                pilot: bidInfo.pilot,
                plane: bidInfo.plane,
                cost: bidInfo.cost,
                costPerAdditionalBag: bidInfo.costPerAdditionalBag,
                departureTime: departureTime,
            });
            
            const title = 'Your Bid was changed';
            const content = `Your Bid was changed by ${pilotsDict[bidInfo.pilot].firstname} ${pilotsDict[bidInfo.pilot].lastname} for your flight to ${utils.resources.airports.index[activeRecord?.arrivalAirport]?.place || ''} - Review Now.`;
            
            ApiManager.addNotification({
                uid: activeRecord.uid,
                type: 'receivedBid',
                title,
                content,
                icon: 'cash',
                rid: activeRecord.id,
                showInApp: true
            });
            sendExpoNotificationToUser({
                uid: activeRecord.uid, title, content, data: { type: 'receivedBid', rid: activeRecord.id }
            });
            
            ApiManager.sendEmail({type: 'bidRequestEdit', uid: currentUser.uid,
                bidRequest: {
                    flightNumber: activeRecord.number,
                    planeNumber: fleetsDict[bidInfo.plane].tailNumber,
                    pilotName: `${pilotsDict[bidInfo.pilot].firstname} ${pilotsDict[bidInfo.pilot].lastname}`,
                    cost: bidInfo.cost,
                    costPerAdditionalBag: bidInfo.costPerAdditionalBag,
                    departureTime: departureTime,
                    flightType: activeRecord.public ? 'Public' : 'Private',
                }});

            ApiManager.sendEmail({type: 'receivedEditedBid', uid: activeRecord.uid});
            
            ApiManager.sendSlackAlert({
                text: `💸 bid for flight was changed by pilot \nDeparture time: ${departureTime} \nCost: ${bidInfo.cost}$ \nCost per additional bag: ${bidInfo.costPerAdditionalBag || 0}$`,
                thread_ts: activeRecord.thread_ts
            });
        } else {
            await firestore.collection('users').doc(currentUser.uid).collection('requests').doc(activeRecord.rid).update({
                status: 'awaiting'
            })
            await firestore.collection('bids').add({
                pilot: bidInfo.pilot,
                plane: bidInfo.plane,
                cost: bidInfo.cost,
                costPerAdditionalBag: bidInfo.costPerAdditionalBag,
                departureTime: departureTime,
                status: 'pending',
                createdAt: serverTimestamp,
                uid: currentUser.uid,
                rid: activeRecord.id,
                ruid: activeRecord.rid
            })
                .then(() => utils.pushDataLayer({ event: 'flight_offer' }))
            
            const title = 'You Received A Bid';
            const content = `You Received A Bid from ${pilotsDict[bidInfo.pilot].firstname} ${pilotsDict[bidInfo.pilot].lastname} for your flight to ${utils.resources.airports.index[activeRecord?.arrivalAirport]?.place || ''} - Review Now.`;
            
            ApiManager.addNotification({
                uid: activeRecord.uid,
                type: 'receivedBid',
                title,
                content,
                icon: 'cash',
                rid: activeRecord.id,
                showInApp: true
            });
            sendExpoNotificationToUser({
                uid: activeRecord.uid, title, content, data: { type: 'receivedBid', rid: activeRecord.id }
            });
            ApiManager.sendEmail({type: 'bidRequest', uid: currentUser.uid,
                bidRequest: {
                    flightNumber: activeRecord.number,
                    planeNumber: fleetsDict[bidInfo.plane].tailNumber,
                    pilotName: `${pilotsDict[bidInfo.pilot].firstname} ${pilotsDict[bidInfo.pilot].lastname}`,
                    cost: bidInfo.cost,
                    costPerAdditionalBag: bidInfo.costPerAdditionalBag,
                    departureTime: departureTime,
                    flightType: activeRecord.public ? 'Public' : 'Private',
                }});
            ApiManager.sendEmail({type: 'receivedBid', uid: activeRecord.uid});
            ApiManager.sendSlackAlert({
                text: `💸 bid for flight sent \nDeparture time: ${departureTime} \nCost: ${bidInfo.cost}$ \nCost per additional bag: ${bidInfo.costPerAdditionalBag || 0}$`,
                thread_ts: activeRecord.thread_ts
            });
        }
        loadData()
        setLoading(false)
        setStep('done')
    }

    return (
        <Form
            name="form"
            id="form"
            layout='vertical'
            form={form}
            onFinish={onSubmitHandler}
            requiredMark={false}
        >
            <h2 className="t-center">Review all information</h2>
            <div className="card-bordered mt-4">
                <Row>
                    <Col span={24}>
                        <p className="label">Registerd Plane</p>
                        <p>{fleetsDict[bidInfo.plane].tailNumber}</p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <p className="label">Registerd Pilot</p>
                        <Space>
                            <img alt='' src={pilotsDict[bidInfo.pilot].photo||'/images/user-placeholder.png'} className="avatar"/>
                            <p>{pilotsDict[bidInfo.pilot].firstname} {pilotsDict[bidInfo.pilot].lastname}</p>
                        </Space>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col span={24}>
                        <p className="label">{'Flight Cost'}</p>
                        <p>${bidInfo.cost}</p>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col span={24}>
                        <p className="label">Cost per Additional bag</p>
                        <p>${bidInfo.costPerAdditionalBag}</p>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col span={24}>
                        <p className="label">Departure Time</p>
                        <p>{utils.formatTime(bidInfo.departureTime)}</p>
                    </Col>
                </Row>
            </div>
            <div className="card-bordered mt-2">
                <Row>
                    <Col span={24}>
                        <p className="label">Flight Type</p>
                        <p>{activeRecord.public? 'Public': 'Private'}</p>
                    </Col>
                </Row>
            </div>
            <p className="label mt-4">
                {activeRecord.public
                    ? 'This flight if accepted will be added to your list of upcoming flights for others to book. '
                    : 'This flight if accepted will be added to your list of upcoming flights.'
                }
            </p>
            <Row style={{
                flexDirection:isMobile ? 'column-reverse': ''
            }} className={`mt-5 ${ isMobile ? 'd-flex' :'d-space'}`}>
                <Col className={`${isMobile ? 'mt-2' : ''}`}><Button type="primary" className="fly-btn green-btn bordered" onClick={() => setStep(activeBid ? 'bid' : 'tac')}>BACK</Button></Col>
                <Col><Button type="primary" htmlType="submit" className="fly-btn green-btn" loading={loading}>{activeBid ? 'EDIT BID' : 'SUBMIT BID'}</Button></Col>
            </Row>
        </Form>
    )
}

export default RequestReviewForm
