import React from 'react'
import { Form, Input, Button } from 'antd'
import { FlySideBar } from '../../../components/'
import { ArrowRightOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'

function PasswordRecoveryRequest(props) {
    const [form] = Form.useForm()
    const {user, setUser, setStep} = props

    const onSubmitHandler = (values) => {
        setUser(values)
        console.log(values)
        setStep('reset')
    }

    return (
        <div className="auth-page auth-page-auth">
            <FlySideBar />
            <div className="content">
                <Form className="auth-form v1-form"
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                    initialValues={user}
                >
                    <h2>Password recovery</h2>
                    <p className="fly-info mb-4">We sent a code to {user.email} to make sure you were really you. Enter it in the box below.</p>
                    <Form.Item label="Secret code" name="code"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validateSecretCode(rule, value, callback, 'Secret code', true) }]}
                    >
                        <Input type="text" placeholder="6 digits code" className="fly-input" />
                    </Form.Item>
                    <Form.Item shouldUpdate className="mt-5 t-right">
                        {() => (
                            <Button type="primary" htmlType="submit" className="fly-btn"
                            disabled={
                                form.getFieldsError().filter(({ errors }) => errors.length)
                                  .length > 0 || !form.isFieldsTouched(true)
                              }
                            >NEXT<ArrowRightOutlined /></Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default PasswordRecoveryRequest
