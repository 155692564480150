import React from 'react';
import { Col, Form, Row, Select } from 'antd';
import * as utils from '../../../utils';
import { STRIPE_PAYMENT_COUNTRY } from '../../../constants';
import { ReactSVG } from 'react-svg';

const RenderCountryState = ({ idx, fbo, onDeleteFBO, additionalFBOs, usedIslands, setUsedIslands, form }) => {
	return (
		<Row className="d-flex" style={{ position: 'relative' }}>
			<Col xl={12} xs={24} className="pr-2">
				<Form.Item label="Fixed Based Operations Country" name={['fixedBasedOperations', `${idx}`, 'country']}
						   rules={[{ required: true, message: 'This field is required' }]}
				>
					<Select
						placeholder="Pick from the list"
						className="fly-select"
						showSearch={true}
						options={utils.resources.defaultCountries}
						onChange={(value) => {
							setUsedIslands({ ...usedIslands, [idx]: { ...usedIslands[idx], country: value, state: undefined } });
							form.setFieldValue(['fixedBasedOperations', `${idx}`, 'state'], undefined)
						}}
						allowClear
					/>
				</Form.Item>
			</Col>
			<Col xl={12} xs={24} className="pl-2">
				<Form.Item
					label={(usedIslands && utils.resources.addressLabel[usedIslands[idx]?.country]) || 'Island'}
					name={['fixedBasedOperations', `${idx}`, 'state']}
					rules={[{ required: true, message: 'This field is required' }]}
				>
					<Select
						placeholder="Pick one"
						className="fly-select"
						options={(usedIslands && utils.resources.address[usedIslands[idx]?.country]?.filter(island => !Object.values(usedIslands).map(value => value.state).includes(island.value))) || []}
						onChange={(value) => setUsedIslands({ ...usedIslands, [idx]: { ...usedIslands[idx], state: value } })}
						showSearch={true}
						allowClear
						disabled={!(usedIslands && usedIslands[idx]?.country)}
					/>
				</Form.Item>
			</Col>
			{additionalFBOs > 1 && <ReactSVG
				src="/images/close.svg"
				style={{ position: 'absolute', top: '5px', right: '2px', cursor: 'pointer' }}
				onClick={() => onDeleteFBO(idx)}
			/>}
		</Row>
	)
}

export default RenderCountryState;
