import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import NumberFormat from 'react-number-format'
import { Row, Col, Form, Input, Button, DatePicker, Radio } from 'antd'
import Upload from 'rc-upload'
import { ArrowRightOutlined, InfoCircleOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { firestore, serverTimestamp, firestorage } from "../../../firebaseConfig"
import Loader from "react-loader-spinner"
import { ApiManager } from "../../../service"
import prepareNumericalValue from '../../../utils/prepareNumericalValue';

const requiredFields = ['firstname', 'lastname', 'email', 'phone', 'ifrRating', 'vfrRating']

function AccountCompanyPilots(props) {
    const [form] = Form.useForm()
    const {user, setUser, history, setStep, currentUser} = props
    const [uploadedFilesLicense, setUploadedFilesLicense] = useState({files: [], uploading: false})
    const [fileToUploadLicense, setFileToUploadLicense] = useState({});
    const [uploadedFilePhoto, setUploadedFilePhoto] = useState({})
    const [loading, setLoading] = useState(false)

    const onSubmitHandler = (values) => {
        addPilotInfo(values, true)
    }

    const onNextHandler = () => {
        form.validateFields().then(values => {
            if (values.errorFields) return;
            addPilotInfo(values, false);
        })
    }

    const addPilotInfo = async (values, addAnother) => {
        setLoading(true)
        // get user info from users collection and check if pilots exist.
        const uSnapshot = await firestore.collection('users').doc(currentUser.uid).get()
        if (uSnapshot.exists) { // user exists
            const userData = uSnapshot.data()
            let pilots = userData.pilots || []
            let cData = Object.assign({}, values)
            cData.uid = currentUser.uid
            cData.createdAt = serverTimestamp
            // replace undefinded values with '' to save data into firestore
            Object.keys(values).forEach(key => {
                if (cData[key] === undefined) cData[key] = ''
            })
            if (cData.licenseExpirationDate && cData.licenseExpirationDate !== '')
                cData.licenseExpirationDate = cData.licenseExpirationDate.format('MM/DD/YYYY')
            cData.license = uploadedFilesLicense.files || []
            cData.photo = uploadedFilePhoto.file || ''
            if (!cData.license) cData.licenseExpirationDate = ''
            // company info not exists, add new company document to pilots collection and add company doc id to user doc.
            const cSnapshot = await firestore.collection('pilots').add(cData)
            if (cData.license && cData.licenseExpirationDate) {
                ApiManager.scheduleLicenseExpiration({ uid: currentUser.uid, pid: cSnapshot.id, type: 'pilotLicense', date: cData.licenseExpirationDate });
            }
            currentUser.profile.pilots.push(cSnapshot.id)
            await firestore.collection('users').doc(currentUser.uid).update({pilots: currentUser.profile.pilots})
                .then(() => {
                    utils.pushDataLayer({ event: 'pilot_added' });
                    ApiManager.sendSlackAlert({
                        text: `🔔 pilot added \nEmail: ${cData?.email || ''} \nHours flown: ${cData?.hoursFlown || ''}`
                    });
                })
            ApiManager.sendEmail({type: "pilotRequest", id: cSnapshot.id, uid: currentUser.uid, collection: "pilots",
                verified: "pending", reason: "", pilotName: `${cData.firstname} ${cData.lastname}`})
            utils.openNotification('Added a pilot successfully!', true)
        }
        else {
            utils.openNotification('User information does not exist', false)
        }
        setUser({...user, pilot: undefined })
        setLoading(false)
        if (addAnother) {
            form.resetFields();
            setUploadedFilesLicense({ files: [], uploading: false });
            setUploadedFilePhoto({});
        }
        else
            history.push('/dashboard')
    }

    useEffect(() => {
        if (Object.keys(fileToUploadLicense).length !== 0) {
            setUploadedFilesLicense({
                ...uploadedFilesLicense,
                files: [...uploadedFilesLicense.files, fileToUploadLicense],
                uploading: false
            })
        }
    }, [fileToUploadLicense]);

    const customFileLicenseUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'application/pdf') {
            setUploadedFilesLicense({...uploadedFilesLicense, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`pilots/${currentUser.uid}/license-${utils.generateRandomKeys()}.${file.type === 'application/pdf' ? 'pdf' : 'png'}`)
            try {
                const image = await imgFile.put(file, {contentType: file.type})
                onSuccess(null, image)
                const filePath = await image.ref.getDownloadURL()
                setFileToUploadLicense({ path: filePath, name: file.name })
            } catch(e) {
                setUploadedFilesLicense({...uploadedFilesLicense, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }

    const customFilePhotoUploadHandler = async ({ onError, onSuccess, file }) => {
        const maxFileSize = 5 * 1024 * 1024;
        
        if (file.size > maxFileSize) {
            return utils.openNotification('File size exceeds the 5MB limit', false);
        }
        
        if (file.type === 'image/jpeg' || file.type === 'image/png') {
            setUploadedFilePhoto({...uploadedFilePhoto, uploading: true})
            const storageRef = await firestorage.ref()
            const imgFile = storageRef.child(`pilots/${currentUser.uid}/photo-${utils.generateRandomKeys()}.png`)
            try {
                const image = await imgFile.put(file, {contentType: 'image/jpeg'})
                onSuccess(null, image)
                setUploadedFilePhoto({...uploadedFilePhoto, file: await image.ref.getDownloadURL()})
            } catch(e) {
                setUploadedFilePhoto({...uploadedFilePhoto, uploading: false})
            }
        } else {
            return utils.openNotification('File format not accepted', false)
        }
    }
    
    const onChangeHoursFlown = (e) => {
        const newVal = prepareNumericalValue(e.target.value);
        form.setFieldValue('hoursFlown', newVal);
    }

    return (
        <div className="content">
            <div className="fly-form fly-form-mob">
                <Form
                    className=""
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={true}
                    initialValues={user.pilot}
                >
                    <div className="form-content">
                        <div className="form-header">
                            <h2>Pilots</h2>
                            <p className="fly-info mb-4">To verify each flight, an active certified pilot must be associated to every flight.</p>
                            <div className="form-body">
                                <Row className="d-flex">
                                    <Col xl={12} xs={24} className="pr-2">
                                        <Form.Item label="First Name" name='firstname'
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'First name', true) }]
                                            }>
                                            <Input type="text" className="fly-input" placeholder="First Name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} xs={24} className="pl-2">
                                        <Form.Item label="Last Name" name='lastname'
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Last name', true) }]
                                            }>
                                            <Input type="text" className="fly-input" placeholder="Last Name" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/> }/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="d-flex">
                                    <Col xl={12} xs={24} className="pr-2">
                                       <Form.Item label="Email" name="email"
                                            rules={[{
                                                required: true,
                                                validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                                        >
                                            <Input type="email" className="fly-input" placeholder="Email" suffix={
                                                <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                            }/>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} xs={24} className="pl-2">
                                        <Form.Item label="Phone" name='phone'
                                            rules={[{ required: true, message: "This field is required"}]}>
                                            <NumberFormat className="fly-input" format="(###) ###-####" mask="_" placeholder="(000) 000-0000" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} xs={24} className="pr-2">
                                        <Form.Item label="Pilot's License">
                                            {uploadedFilesLicense.files.length !== 0 && (
                                                <div className="ant-upload-select uploaded">
                                                    <CheckOutlined style={{ color: '#ffffff'}}/>
                                                    <p>Uploaded!</p>
                                                    <EditOutlined
                                                        className="p-right"
                                                        onClick={() => setUploadedFilesLicense({
                                                            ...uploadedFilesLicense, files: []
                                                        })}
                                                    />
                                                </div>
                                            )}
                                            {uploadedFilesLicense.uploading && (
                                                <div className="ant-upload-select uploading">
                                                    <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                                </div>
                                            )}
                                            <div style={{
                                                position: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 'initial' : 'absolute',
                                                zIndex: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 1 : -1,
                                                opacity: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 1 : 0,
                                                pointerEvents: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 'initial' : 'none',
                                                touchAction: uploadedFilesLicense.files.length === 0 && !uploadedFilesLicense.uploading ? 'initial' : 'none'
                                            }}>
                                                <Upload
                                                    className="ant-upload ant-upload-select"
                                                    customRequest={customFileLicenseUploadHandler}
                                                    showUploadList={false}
                                                    multiple={true}
                                                    accept=".png,.jpg,.jpeg,.pdf"
                                                >
                                                    <p>Upload</p>
                                                </Upload>
                                            </div>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} xs={24} className="pl-2">
                                        <Form.Item
                                            label="License expiration date"
                                            name='licenseExpirationDate'
                                            rules={[{ required: uploadedFilesLicense.files.length !== 0, message: 'This field is required' }]}
                                        >
                                            <DatePicker format="MM/DD/YYYY" placeholder="MM/DD/YYYY" className="fly-date-picker" defaultPickerValue={utils.getDefaultExpirationDate} disabledDate={utils.disabledDate} showToday={false}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="d-flex">
                                    <Col xl={6} xs={12} className="pr-2">
                                        <Form.Item label="IFR Rating" name='ifrRating'
                                            rules={[{ required: true, message: 'This field is required' }]}>
                                            <Radio.Group options={utils.resources.yesOrNo} size='large' className="rating-radio-grp"></Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col xl={6} xs={12} className="pl-2">
                                        <Form.Item label="VFR Rating" name='vfrRating'
                                            rules={[{ required: true, message: 'This field is required' }]}>
                                            <Radio.Group options={utils.resources.yesOrNo} size='large' className="rating-radio-grp"></Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} xs={24} className="pr-2">
                                        <Form.Item label="Hours Flown" name='hoursFlown'>
                                            <Input type="number" className="fly-input has-custom-suffix-icon" placeholder="Enter amount" suffix={<p>hours</p>} onChange={onChangeHoursFlown}/>
                                            {/*<Select
                                                placeholder="Enter amount"
                                                className="fly-select has-custom-suffix-icon"
                                                options={utils.resources.integers}
                                                showSearch={true}
                                                suffixIcon={<p>hours</p>}
                                                allowClear
                                            />*/}
                                        </Form.Item>
                                    </Col>
                                    <Col xl={12} xs={24} className="pl-2">
                                        <Form.Item label="Pilot's Photo">
                                            {
                                                uploadedFilePhoto.file?
                                                <div className="ant-upload-select uploaded">
                                                    <CheckOutlined style={{ color: '#ffffff'}}/>
                                                    <p>Uploaded!</p>
                                                    <EditOutlined className="p-right" onClick={() => setUploadedFilePhoto({...uploadedFilePhoto, file: null})}/>
                                                </div>
                                                :
                                                uploadedFilePhoto.uploading?
                                                    <div className="ant-upload-select uploading">
                                                        <Loader type="Oval" color="#90C4C2" height={32} width={32} className="m-auto" />
                                                    </div>
                                                    :
                                                    <Upload
                                                        className="ant-upload ant-upload-select"
                                                        customRequest={customFilePhotoUploadHandler}
                                                        showUploadList={false}
                                                        multiple={false}
                                                        accept=".png,.jpg,.jpeg"
                                                    >
                                                        <p>Upload</p>
                                                    </Upload>
                                            }
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Form.Item shouldUpdate>
                                {() => {
                                    let invalid = form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || (user.pilot === undefined && !form.isFieldsTouched(requiredFields, true))
                                    return (
                                        <div className={invalid? 'w-full': 'w-right'}>
                                            {
                                                invalid?
                                                <Link to="#" className="extra-txt" onClick={() => history.push('/dashboard')}>Add later</Link>
                                                :
                                                <Button type="primary" htmlType="submit" className="fly-btn bordered" loading={loading}>SAVE & ADD ANOTHER</Button>
                                            }
                                            <Button
                                                type="primary"
                                                className="fly-btn ml-2"
                                                onClick={onNextHandler}
                                                disabled={currentUser.profile.pilots? false: invalid}
                                                loading={loading}
                                            >
                                                NEXT<ArrowRightOutlined/>
                                            </Button>
                                        </div>
                                    )
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    )
}

export default AccountCompanyPilots
