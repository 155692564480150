import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { useDispatch } from 'react-redux'
import * as utils from '../../../../utils'
import { auth, generateUserDocument } from "../../../../firebaseConfig"
import FlyActions from '../../../../redux/common'
import { firestore } from '../../../../firebaseConfig'

function Step2(props) {
    const [form] = Form.useForm()
    const {newUser, setNewUser, history, setStep, currentUser} = props
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')
    const dispatch = useDispatch()

    const onSubmitHandler = async (values) => {
        setLoading(true)
        try {
            const { user } = await auth.createUserWithEmailAndPassword(newUser.email, values.password)
            await firestore.collection('users').doc(newUser.userId).delete();
            values.role = 'Client'
            values.email = newUser.email
            values.firstname = newUser.firstname
            values.lastname = newUser.lastname
            values.approximateWeight = newUser.approximateWeight
            delete values.confirmPassword
            await generateUserDocument(user, values)
            dispatch(FlyActions.onLoadUser(user))
            utils.pushDataLayer({ event: 'sign_up' })

            const pSnapshot = await firestore.collection('requests').doc(newUser.requestId).get()
            if (pSnapshot.exists) {
                let requestInfo = pSnapshot.data()
                requestInfo.uid = user.uid
                requestInfo.requestId = newUser.requestId
                await firestore.collection('requests').doc(newUser.requestId).update({
                    uid: user.uid
                })
            }
            const pSnapshot2 = await firestore.collection('requests').where('uid', '==', newUser.userId).get()
            if (!pSnapshot2.empty) {
                await pSnapshot2.forEach(doc => {
                    firestore.collection('requests').doc(doc.id).update({
                        uid: user.uid
                    })
                })
            }
            setStep('SetPassword')
        }
        catch (error) {
            // utils.openNotification(error.message, false)
            utils.openNotification((<>It looks like you already have an account as a pilot, please log in <a href='/auth/login'>here</a> or create an account/request with another email address.</>), false)
        }
        setLoading(false)
    }

    if (!loading && currentUser) {
        setStep(3);
    }

    return (
        <div className="client-body height-100-percent step2">
            <Form
                className=""
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={newUser}
            >
                <div>
                    <Form.Item
                        label="Create password"
                        name="password"
                        className="form-item-children"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword2(rule, value, callback, 'Password', true) }]}
                        onPaste={(e) => e.preventDefault()}
                    >
                        <Input.Password className="fly-input fly-input-password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Item>
                    <p className="fly-notes mh-2">Password must be at least 8 characters long, contain one upper and lower case letter, one number and one special character.</p>
                    <Form.Item
                        label="Repeat password"
                        name="confirmPassword"
                        className="form-item-children"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validateConfirmPassword(rule, value, callback, 'Password', true, password) }]}
                        onPaste={(e) => e.preventDefault()}
                    >
                        <Input.Password className="fly-input fly-input-password" placeholder="Password" />
                    </Form.Item>
                </div>
                <Form.Item shouldUpdate className="mt-5 t-right">
                    {() => (
                        <Button type="primary" htmlType="submit" className="fly-btn w-full"
                            loading={loading}
                            disabled={
                                form.getFieldsError().filter(({ errors }) => errors.length)
                                  .length > 0 || !form.isFieldsTouched(true)
                              }
                        >Continue</Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default Step2
