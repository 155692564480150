import React from 'react'
import { Form, Button, Row, Col } from 'antd'
import { ReactSVG } from 'react-svg'

function RequestDoneForm (props) {
    const {handleOk, activeBid} = props
    const [form] = Form.useForm()

    const onSubmitHandler = (values) => {
        handleOk()
    }

    return (
        <Form
            name="form"
            id="form"
            layout='vertical'
            form={form}
            onFinish={onSubmitHandler}
            requiredMark={false}
        >
            <ReactSVG src="/images/success.svg" className="success-icon" />
            <h2 className="t-center mt-4">{activeBid ? 'Your Bid has been edited!' : 'Your Bid has been sent!'}</h2>
            <Row className="mt-5 d-center">
                <Col><Button type="primary" htmlType="submit" className="fly-btn green-btn">GOT IT</Button></Col>
            </Row>
        </Form>
    )
}

export default RequestDoneForm
