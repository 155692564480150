import React from 'react'
import { Row, Col, Button } from 'antd'
import { ReactSVG } from 'react-svg'

function PaymentDone(props) {
    const {history, step, setStep, setActiveRecord} = props

    return (
        <div className="bid-done-board">
            <Row>
                <Col span={24} className="t-center">
                    <ReactSVG src="/images/done.svg" />
                    <h3 className="mt-3">Payment Successful</h3>
                    <p className="fly-notes mt-2 t-center">You can view details in Payment History on your profile.</p>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col span={24}>
                    <Button
                        type="primary"
                        className="fly-btn w-full"
                        onClick={() => {
                            setActiveRecord()
                            history.push('/client/flight-requests?ft=upcoming')
                        }}
                    >
                        Done
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default PaymentDone
