import React, { useState } from 'react'
import { Row, Col, Form, Select, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../utils'
import { STRIPE_PAYMENT_COUNTRY } from '../../../constants';

function ContactInformationPanel ({ form }) {
    const [choosedCountry, setChoosedCountry] = useState(form.getFieldValue('country'));

    return (
        <>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Country" name="country" rules={[{ required: true, message: 'This field is required' }]}>
                        <Select
                            placeholder="Pick fom the list"
                            className="fly-select"
                            showSearch={true}
                            options={utils.resources.defaultCountries}
                            allowClear
                            onChange={(value) => {
                                setChoosedCountry(value);
                                form.setFieldValue('state', undefined);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24} className="pl-2">
                    <Form.Item
                        label={utils.resources.addressLabel[choosedCountry] || 'Island'}
                        name="state" rules={[{ required: true, message: 'This field is required' }]}
                    >
                        <Select
                            placeholder="Pick fom the list"
                            className="fly-select"
                            showSearch={true}
                            options={utils.resources.address[choosedCountry] || []}
                            allowClear
                            disabled={!choosedCountry}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={12} xs={24} className="pr-2">
                    <Form.Item label="Street address" name="address"
                        rules={[{
                            required: true,
                            validator: (rule, value, callback) => utils.validations.validateAddress(rule, value, callback, 'Street address', true)
                        }]}>
                        <Input type="text" className="fly-input" placeholder="Add street" suffix={<InfoCircleOutlined style={{ color: '#E24D59' }}/>}/>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default ContactInformationPanel
