import React from 'react';
import { TimePicker } from 'antd';

const FlyTimePicker = ({ onChange, ...prop }) => {
    return (
        <TimePicker onChange={onChange} onSelect={onChange} {...prop} />
    )
}

export default FlyTimePicker;
