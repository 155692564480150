import React from 'react'
import { Select } from 'antd'
import './index.scss';

const { Option } = Select

function FlySelect(props) {
    const {
        values,
        placeholder,
        onChange,
        disabled,
        className,
        show,
        showSearch
    } = props

    return (
        <Select
            showSearch={showSearch}
            placeholder={placeholder} onChange={onChange}
            optionFilterProp="children" disabled={disabled}
            className={className}
            dropdownStyle={{
                fontSize: "20px"
            }}
            allowClear
        >
            { values.map((value, index) =>
              <Option value={value} key={index}>{value}</Option>
            )}
        </Select>
    )
}

export default FlySelect
