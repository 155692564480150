import React, { useState } from 'react'
import { Form, Input, Button, Progress } from 'antd'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg';
import { auth, firestore } from '../../../firebaseConfig';

function ChangePassword(props) {
    const [form] = Form.useForm();
    const { history, setStep, currentUser, step } = props;
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');

    const onSubmitHandler = async (values) => {
        setLoading(true);
        if (auth.currentUser.profile.password === values.password || auth.currentUser.profile.password !== values.prevPassword) {
            setStep('change-password-status_error');
        } else {
            await auth.currentUser.updatePassword(values.password)
                .then(() => {
                    firestore.doc(`users/${currentUser.uid}`).update({ password: values.password })
                        .then(() => {
                            setStep('change-password-status_success');
                        })
                        .catch(() => {
                            setStep('change-password-status_error');
                        });
                })
                .catch(() => {
                    auth.signInWithEmailAndPassword(auth.currentUser.profile.email, auth.currentUser.profile.password)
                        .then(() => {
                            Promise.all([
                                auth.currentUser.updatePassword(values.password),
                                firestore.doc(`users/${currentUser.uid}`).update({ password: values.password })
                            ])
                                .then(() => {
                                    setStep('change-password-status_success');
                                })
                                .catch(() => {
                                    setStep('change-password-status_error');
                                });
                        })
                        .catch(() => {
                            setStep('change-password-status_error');
                        });
                })
        }
        setLoading(false);
    };

    const prevStepHandler = () => {
        setStep('');
    };

    return (
        <div className="content auth-page auth-page-mobile">
            <div className="client-header b-gray">
                <div className="header-item">
                    <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>
                    <p>Password change</p>
                    <div className="step-marker">
                        <Progress
                            type="circle"
                            trailColor="#ffffff1a"
                            strokeColor="#33CDCD"
                            percent={1/2*100} width={36}
                            format={percent => `1/2`}
                        />
                    </div>
                </div>
            </div>
            <Form
                className="client-body height-100-percent"
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
            >
                <div>
                    <Form.Item
                        label="Current password"
                        name="prevPassword"
                        className="form-item-children mb-2"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword2(rule, value, callback, 'Password', true) }]}
                        onPaste={(e) => e.preventDefault()}
                    >
                        <Input.Password className="fly-input fly-input-password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Item>
                    <Form.Item
                        label="Create new password"
                        name="password"
                        className="form-item-children"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword2(rule, value, callback, 'Password', true) }]}
                        onPaste={(e) => e.preventDefault()}
                    >
                        <Input.Password className="fly-input fly-input-password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Item>
                    <p className="fly-notes mh-2" style={{ fontSize: '0.875rem' }}>
                        Password must be at least 8 characters long, contain one upper and lower case letter, one number and one special character.
                    </p>
                    <Form.Item
                        label="Repeat password"
                        name="confirmPassword"
                        className="form-item-children"
                        rules={[{ validator: (rule, value, callback) => utils.validations.validateConfirmPassword(rule, value, callback, 'Password', true, password) }]}
                        onPaste={(e) => e.preventDefault()}
                    >
                        <Input.Password className="fly-input fly-input-password" placeholder="Password" />
                    </Form.Item>
                </div>
                <Form.Item shouldUpdate className="t-right mb-0 mt-3">
                    {() => (
                        <Button type="primary" htmlType="submit" className="fly-btn w-full"
                            loading={loading}
                            disabled={
                                form.getFieldsError().filter(({ errors }) => errors.length)
                                    .length > 0 || !form.isFieldsTouched(true)
                            }
                        >
                            Continue
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default ChangePassword;
