import React, { useState } from 'react'
import { Form, Button, Modal, Select } from 'antd'
import * as utils from '../../../utils'
import { ReactSVG } from 'react-svg'
import { firestore } from '../../../firebaseConfig'

function RequestPayoutModal (props) {
    const {isModalVisible, setIsModalVisible, currentUser} = props;
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const onSubmitHandler = async (values) => {
        setLoading(true)
        await firestore.collection('users').doc(currentUser.uid).update({
            requestPayout: {
                reason: values.requestPayoutReason,
                date: utils.getToday().format('MM/DD/YYYY'),
                status: 'pending'
            }
        })
        utils.openNotification('Submitted successfully!.')
        handleOk()
        setLoading(false)
    }

    return (
        <Modal title=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width='500px'
            className="fly-modal content request-payout-modal"
            footer={[]}
            closable={false}
            destroyOnClose={true}
        >
            <div className="fly-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    preserve={false}
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                >
                    <ReactSVG src="/images/warning.svg" />
                    <h3 className="mt-3">Request A Payout</h3>
                    <p className="mb-2" style={{ fontSize: '1rem'}}>Are you sure you want to request a payout before your scheduled payout?</p>
                    <Form.Item label="Reason for Request?" name="requestPayoutReason" className="t-left"
                        rules={[{ required: true, message: 'Required' }]}
                    >
                        <Select
                            placeholder="Pick one"
                            className="fly-select"
                            showSearch={true}
                            options={utils.resources.requestPayoutReason}
                            allowClear
                        />
                    </Form.Item>
                    <Form.Item shouldUpdate className="mt-5">
                        {() => (
                            <Button type="primary" htmlType="submit" className="fly-btn" loading={loading}
                            disabled={form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                                || !form.isFieldsTouched(['requestPayoutReason'], true) }
                            >Request Payout</Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    )
}

export default RequestPayoutModal
