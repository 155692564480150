import React, { useState } from 'react'
import { Row, Col, Form, Button, Statistic, Input} from 'antd'
import { ReactSVG } from 'react-svg'
import NumberFormat from 'react-number-format'
import { firestore, serverTimestamp } from '../../../../firebaseConfig'

function PaymentCard(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, currentUser} = props
    const [cardInfo, setCardInfo] = useState({
        cvc: '',
        expiry: '',
        focus: '',
        name: '',
        number: ''
    })

    const onSubmitHandler = async (values) => {
        const flightRoute = `${flight.departureAirport ? flight.departureAirport.split('-')[0] : ''} ${flight.arrivalAirport ? flight.arrivalAirport.split('-')[0] : ''}`;

        const tSnapshot = await firestore.collection('transactions').add({
            oid: flight.uid,
            uid: currentUser.uid,
            fid: flight.id,
            rid: flight.request.id,
            flightNumber: flight.number,
            document: `${flightRoute}.pdf`,
            flightRoute: flightRoute.replace('-', '>'),
            receipt: 'receipt.pdf',
            status: 'paid',
            amount: flight.totalPrice + 5,
            method: 'credit-card',
            createdAt: serverTimestamp,
            updatedAt: serverTimestamp
        })
        await firestore.collection('flights').doc(flight.id).collection('requests').doc(flight.request.id).update({
            payment: tSnapshot.id
        })
        setStep('Payment Done')
    }

    return (
        <div className="payment-board">
            <Row className="flight-item" >
                <Col span={24}>
                    <div className="flight-item-body">
                        <div className="d-flex d-space">
                            <div>
                                <h3>{flight.departureAirport ? flight.departureAirport.split('-')[0] : ''}</h3>
                                <p className="gray">{flight.departureIsland}</p>
                            </div>
                            <ReactSVG src="/images/bird1.svg" className="b-icon" />
                            <div className="t-right">
                                <h3>{flight.arrivalAirport ? flight.arrivalAirport.split('-')[0] : ''}</h3>
                                <p className="gray">{flight.arrivalIsland}</p>
                            </div>
                        </div>
                        <hr />
                        <div className="d-flex d-space mt-1">
                            <p className="section-desc">Flight</p>
                            <Statistic prefix="$" value={flight.totalPrice} precision={2} />
                        </div>
                        <div className="d-flex d-space mt-1">
                            <p className="section-desc">Flymingo Service</p>
                            <Statistic prefix="$" value={2} precision={2} />
                        </div>
                        <div className="d-flex d-space mt-1">
                            <p className="section-desc">Taxes</p>
                            <Statistic prefix="$" value={3} precision={2} />
                        </div>
                        <hr />
                        <div className="d-flex d-space mt-1">
                            <p className="section-desc">Total Amount Paid</p>
                            <Statistic prefix="$" value={flight.totalPrice + 5} precision={2} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col span={24}>
                    {/*<Cards
                        cvc={cardInfo.cvc}
                        expiry={cardInfo.expiry}
                        focused={cardInfo.focus}
                        name={cardInfo.name}
                        number={cardInfo.number}
                    />*/}
                    <Form
                        className="content"
                        name="form"
                        id="form"
                        layout='vertical'
                        form={form}
                        onFinish={onSubmitHandler}
                        requiredMark={false}
                        preserve={false}
                    >
                        <Row className="row-wrapper">
                            <Col md={24} xs={24}>
                                <Form.Item label="Credit Card Number" name='number'
                                    rules={[{required: true}]}>
                                    <NumberFormat className="fly-input" placeholder="Card Number" format="#### #### #### ####" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col md={12} xs={12} className="pr-1">
                                <Row className="row-wrapper">
                                    <Col span={24}>
                                        <Form.Item label="Expiry Date" name='expiry'
                                            rules={[{required: true}]}>
                                            <NumberFormat className="fly-input" placeholder="00/00" format="##/##" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12} xs={12} className="pl-1">
                                <Row className="row-wrapper">
                                    <Col span={24}>
                                        <Form.Item label="CVV Code" name='cvv'
                                            rules={[{required: true}]}>
                                            <NumberFormat className="fly-input" placeholder="CVV Code" format="###" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="row-wrapper mt-2">
                            <Col md={24} xs={24}>
                                <Form.Item label="Card Holder" name='name'
                                    rules={[{required: true}]}>
                                    <Input type="text" className="fly-input" placeholder="Card Holder" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="mt-5">
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                    Pay
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </div>
    )
}

export default PaymentCard
