import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import * as utils from '../../../../utils'
import { auth } from "../../../../firebaseConfig"

function Step1(props) {
    const [form] = Form.useForm()
    const {newUser, setNewUser, history, setStep} = props
    const [loading, setLoading] = useState(false)

    const onSubmitHandler = async (values) => {
        auth.sendPasswordResetEmail(values.email, {
            url: `${process.env.REACT_APP_SECRET_CODE === 'Prod' ? utils.prodClientDomain : utils.devClientDomain}/client/auth/login`,
            handleCodeInApp: false
        }).then(function(res) {
            setNewUser(values)
            setStep(2)
        }).catch(function(error) {
            utils.openNotification(error.message, false)
            setLoading(false)
        })
    }

    return (
            <Form
                className="client-body height-100-percent"
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={newUser}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ validator: (rule, value, callback) => utils.validations.validateEmail(rule, value, callback, 'Email', true) }]}
                >
                    <Input type="email" className="fly-input" placeholder="Email" suffix={
                        <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                    }/>
                </Form.Item>
                <Form.Item shouldUpdate className="t-right mb-0">
                    {() => (
                        <Button type="primary" htmlType="submit" className="fly-btn w-full"
                            loading={loading}
                            disabled={
                                form.getFieldsError().filter(({ errors }) => errors.length)
                                  .length > 0 || !form.isFieldsTouched(true)
                              }
                        >Continue</Button>
                    )}
                </Form.Item>
            </Form>

    )
}

export default Step1
