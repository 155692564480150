import React, { useState } from 'react'
import { Form, Input, Button } from 'antd'
import * as utils from '../../../../utils'
import { auth } from "../../../../firebaseConfig"

function Step3(props) {
    const [form] = Form.useForm()
    const {history, setStep, currentUser} = props
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState('')

    const onSubmitHandler = async (values) => {
        let params = new URLSearchParams(history.location.search)
        let actionCode = params.get('actionCode')
        setLoading(true)
        auth
        .confirmPasswordReset(actionCode, values.password)
        .then(() => {
            setStep('SetPassword')
        }, error => {
            utils.openNotification(error.message, false)
        })
        setLoading(false)
    }

    return (
        <Form
            className="client-body height-100-percent"
            name="form"
            id="form"
            layout='vertical'
            form={form}
            onFinish={onSubmitHandler}
            requiredMark={false}
        >
            <div>
                <Form.Item
                    label="Create password"
                    name="password"
                    className="form-item-children"
                    rules={[{ validator: (rule, value, callback) => utils.validations.validatePassword2(rule, value, callback, 'Password', true) }]}
                    onPaste={(e) => e.preventDefault()}
                >
                    <Input.Password className="fly-input fly-input-password" placeholder="Password" onChange={(e) => setPassword(e.target.value)}/>
                </Form.Item>
                <p className="fly-notes mh-2">Password must be at least 8 characters long, contain one upper and lower case letter, one number and one special character.</p>
                <Form.Item
                    label="Repeat password"
                    name="confirmPassword"
                    className="form-item-children"
                    rules={[{ validator: (rule, value, callback) => utils.validations.validateConfirmPassword(rule, value, callback, 'Password', true, password) }]}
                    onPaste={(e) => e.preventDefault()}
                >
                    <Input.Password className="fly-input fly-input-password" placeholder="Password" />
                </Form.Item>
            </div>
            <Form.Item shouldUpdate className="t-right mb-0 mt-3">
                {() => (
                    <Button type="primary" htmlType="submit" className="fly-btn w-full"
                        loading={loading}
                        disabled={
                            form.getFieldsError().filter(({ errors }) => errors.length)
                              .length > 0 || !form.isFieldsTouched(true)
                          }
                    >Continue</Button>
                )}
            </Form.Item>
        </Form>
    )
}

export default Step3
