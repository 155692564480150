import React from 'react'
import { useHistory } from "react-router-dom"
import { Row, Col} from 'antd'

const navbars = ['upcoming', 'requested', 'past']

function FlyFlightsNavBar(props) {
    const {active} = props
    const history = useHistory()

    return (
        <div className="client-navbar">
            <Row>
                {navbars.map(nav => {
                    return (
                        <Col span={8} className={`client-navbar-item ${active === nav? 'active': ''}`} key={nav}>
                            <p onClick={() => history.push(`/client/flight-requests?ft=${nav}`)}>{nav}</p>
                        </Col>
                    )
                })}
            </Row>
        </div>
    )

}

export default FlyFlightsNavBar
