import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Switch, Dropdown, Menu, Modal, Select } from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import { ReactSVG } from 'react-svg'
import * as utils from '../../../../utils'

function PassengerItem(props) {
    const {passenger, removePassengerHandler, editPassengerHandler, idx} = props
    const menu = (
        <Menu className="passenger-menu">
            <Menu.Item key="edit" onClick={() => editPassengerHandler(passenger, idx)}>
                <p>Edit</p>
            </Menu.Item>
            <Menu.Item key="remove" onClick={() => removePassengerHandler(passenger, idx)}>
                <p>Remove</p>
            </Menu.Item>
        </Menu>
    )

    return (
        <div className="passenger-item row-wrapper mb-2">
            <Row>
                {passenger.checkin && <Col span={3} className="d-flex d-center">
                    <ReactSVG src="/images/verified-star.svg" className="mr-1 planetype-icon" />
                </Col>}
                <Col span={passenger.checkin? 19: 22}>
                    <p>{passenger.firstname} {passenger.lastname}, <span className="fly-notes">{passenger.weight}lbs</span></p>
                    {passenger.numberOfBags && <p>{passenger.numberOfBags} Bag{passenger.numberOfBags>1 && "s"}, <span className="fly-notes">{passenger.weightPerBag}lbs (S)</span></p>}
                </Col>
                <Col span={2} className="t-right">
                    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                        <EllipsisOutlined />
                    </Dropdown>
                </Col>
            </Row>
            {!passenger.checkin &&
                <Row className="mt-2">
                    <Col span={24}>
                        <Button type="primary" className="fly-btn w-full check-in" onClick={() => editPassengerHandler(passenger, idx)}>
                            Check-In
                        </Button>
                    </Col>
                </Row>
            }
        </div>
    )
}

function Pets(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, setFlight, setActivePassenger, setFlightValues, postRequest} = props
    const [hasPet, setHasPet] = useState(false)
    const [loading, setLoading] = useState()

    useEffect(() => {
        setHasPet(flight.hasPet)
    }, [step])

    const onSubmitHandler = async (values) => {
        setLoading(true)
        setFlightValues(values)
        const nextStep = await postRequest()
        setLoading(false)
        setStep(nextStep)
    }

    const AddPassengerHandler = () => {
        setActivePassenger()
        setStep("AddPassenger")
    }

    const removePassengerHandler = (passenger, idx) => {
        Modal.confirm({
            title: '',
            maskClosable: true,
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div>
                <h3 className="mt-4">Remove the passenger</h3>
                <p>Are you sure you want to remove the passenger <b>{passenger.firstname} {passenger.lastname}</b>?</p>
            </div>,
            okText: 'REMOVE',
            onOk() {
                let passengers = flight.passengers.filter((item, i) => i !== idx)
                setFlight({...flight, passengers: passengers})
            },
        })
    }

    const editPassengerHandler = (passenger, idx) => {
        setActivePassenger({
            passenger: passenger,
            idx: idx
        })
        setStep("Passenger")
    }

    const onChangeHander = (checked) => {
        setHasPet(checked)
    }

    let checkedInList = flight.passengers?.map(passenger => passenger.checkin === true)

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label">Pets</p>
                        <Row className="d-flex ">
                            <Col span={20}>
                                <p>Traveling with pet</p>
                            </Col>
                            <Col span={4} className="t-right">
                                <Form.Item label="" name='hasPet' valuePropName="checked">
                                    <Switch onChange={onChangeHander} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {hasPet &&
                            <Row className="row-wrapper">
                                <Col span={24}>
                                    <Form.Item label="Number of Pets" name="numberOfPets"
                                        rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Type to search"
                                            className="fly-select"
                                            options={utils.resources.integers}
                                            showSearch={true}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                <hr />
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Approximate Weight per Pet" name="weightPerPet"
                                        rules={[{ required: true, message: 'This field is required' }]}>
                                        <Select
                                            placeholder="Type to search"
                                            className="fly-select"
                                            options={utils.resources.bagWeightRange}
                                            showSearch={true}
                                            allowClear
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full" loading={loading}>
                                Confirm
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default Pets
