import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format';
import { Form, Input, Button, Row, Col, DatePicker, Select, Modal, Tooltip } from 'antd'
import { InfoCircleOutlined, QuestionOutlined } from '@ant-design/icons'
import * as utils from '../../../../utils'
import ApiManager from '../../../../service';
import { firestore } from '../../../../firebaseConfig'

function Step3(props) {
    const [form] = Form.useForm()
    const {newUser, setNewUser, history, setStep, currentUser} = props
    const [loading, setLoading] = useState(false)
    const [touchableFields, setTouchableFields] = useState(
        ['country', 'phone', 'birthdate', 'firstname', 'lastname', 'approximateWeight']
    );

    const onSubmitHandler = async (values) => {
        setLoading(true)
        try {
            const phoneNumber = values.phone.replace(/[\s()-]/g, '');
            
            await ApiManager.changePhone({ uid: currentUser.uid, phoneNumber });
            
            values.birthdate = values.birthdate.format('YYYY-MM-DD')
            await firestore.doc(`users/${currentUser.uid}`).update({
                ...values,
                phoneNumber: phoneNumber.includes('+') ? phoneNumber : `+1${phoneNumber}`,
                isClientCompleteRegistration: true,
            })
            await ApiManager.completeRequests({ uid: currentUser.uid });

            await ApiManager.sendSlackAlert({
                text: `🦩 new user created \nType: passenger \nCountry: ${values.country}`,
            })

            // history.push(`/client/request-a-flight?rid=${newUser.requestId}`)
            // window.location.href = newUser.requestId ? `/client/request-a-flight?rid=${newUser.requestId}` : 'client/flight-requests?ft=requested'
            setTimeout(() => {
                window.location.href = 'client/flight-requests?ft=requested&popup=show'
                setLoading(false);
            }, 1000);
        } catch (error) {
            utils.openNotification(error.message, false)
            setLoading(false)
        }
    }

    useEffect(() => {
        if (currentUser.profile) {
            let fieldsTouched = []
            if (currentUser.profile.firstname) {
                form.setFieldValue('firstname', currentUser.profile.firstname);
                fieldsTouched.push('firstname');
            }
            if (currentUser.profile.lastname) {
                form.setFieldValue('lastname', currentUser.profile.lastname);
                fieldsTouched.push('lastname');
            }
            if (currentUser.profile.approximateWeight) {
                form.setFieldValue('approximateWeight', currentUser.profile.approximateWeight);
                fieldsTouched.push('approximateWeight');
            }
            setTouchableFields(touchableFields.filter(field => !fieldsTouched.includes(field)));
        }
    }, [currentUser]);

    const showReason = (record) => {
        Modal.info({
            title: 'Why do we want this?',
            icon: null,
            maskClosable: true,
            content:  <p className="p-0 mb-0">Our goals is to provide our pilot partners with as much information as possible to ensure a safe flight. It is important for pilots to know weight for balance purposes - we request this for flyers, pets & cargo.</p>
        })
    }

    return (
        <div className="client-body height-100-percent step3">
            <Form
                className=""
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={newUser}
            >
                <div>
                    <p className="section-label">Personal Information</p>
                    <p className="fly-notes mh-1">Please note: the name of the person must match their government provided ID</p>
                    <Row className="row-wrapper">
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="First name"
                                name="firstname"
                                rules={[{ validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'First name', true) }]}
                            >
                                <Input type="text" className="fly-input" placeholder="First name" suffix={
                                    <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                }/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <hr />
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Last name"
                                name="lastname"
                                rules={[{ validator: (rule, value, callback) => utils.validations.validateText(rule, value, callback, 'Last name', true) }]}
                            >
                                <Input type="text" className="fly-input" placeholder="Last name" suffix={
                                    <InfoCircleOutlined style={{ color: '#E24D59' }}/>
                                }/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Birth Date"
                                name="birthdate"
                                rules={[{ validator: (rule, value, callback) => utils.validations.validateBirthDay(rule, value, callback, 'Birthday', true) }]}
                            >
                                <DatePicker format="YYYY-MM-DD" placeholder="YYYY-MM-DD" className="fly-date-picker" defaultPickerValue={utils.defaultDate} showToday={false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Phone Number" name="phone">
                                <NumberFormat className="fly-input" format="(###) ###-####" mask="_" placeholder="(000) 000-0000" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Nationality" name="country" rules={[{ required: true, message: 'This field is required' }]}>
                                <Select
                                    placeholder="Pick fom the list"
                                    className="fly-select"
                                    showSearch={true}
                                    options={utils.resources.allCountries}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-wrapper mt-2">
                        <Col md={24} xs={24}>
                            <Form.Item label="Approximate Weight" name="approximateWeight" rules={[{ required: true, message: 'This field is required' }]}>
                                <Select
                                    placeholder="Pick fom the list"
                                    className="fly-select"
                                    showSearch={true}
                                    options={utils.resources.personWeightRange2}
                                    allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <p className="fly-notes mh-1" onClick={() => showReason()} >Why do we want this?
                        <QuestionOutlined className="ml-1" style={{ background: "#FF9E97", color: '#ffffff', borderRadius: '50%', fontSize: '10px', padding: '2.5px'}}/>
                    </p>
                </div>
                <Form.Item shouldUpdate className="mt-5 t-right">
                    {() => (
                        <Button type="primary" htmlType="submit" className="fly-btn w-full"
                            loading={loading}
                            disabled={
                                form.getFieldsError().filter(({ errors }) => errors.length)
                                  .length > 0 || !form.isFieldsTouched(touchableFields ,true)
                              }
                        >Continue</Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default Step3
