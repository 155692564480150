import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Select, DatePicker } from 'antd'
import { ReactSVG } from 'react-svg'
import './index.scss'
import * as utils from '../../../utils'
import TimePopup from './TimePopup'

function FlightItem(props) {
    const {item, idx, flight, removeFlightHandler, form} = props
    const [selectedDate, setSelectedDate] = useState()
    const [departureAirport, setDepartureAirport] = useState('')
    const [arrivalAirport, setArrivalAirport] = useState('')
    const [departureCountry, setDepartureCountry] = useState('')
    const [arrivalCountry, setArrivalCountry] = useState('')

    useEffect(() => {
        if (flight.list && flight.list[item]) {
            setSelectedDate(flight.list[item].departureDate)
            setDepartureCountry(flight.list[item].departureCountry || '')
            setArrivalCountry(flight.list[item].arrivalCountry || '')
        }
    }, [])

    let departureAirportList =
        utils.resources.airports[utils.resources.countryContraction[departureCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== arrivalAirport)
    let arrivalAirportList =
        utils.resources.airports[utils.resources.countryContraction[arrivalCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== departureAirport)

    return (
        <div className="flight-item">
            {idx !== 0 && <hr className="mh-4" />}
            <div className="d-flex d-space mb-2">
                <h3 className="mb-0">Flight {idx+1}</h3>
                {idx !== 0 && <ReactSVG src="/images/remove.svg" onClick={() => removeFlightHandler(item)}/>}
            </div>
            <p className="section-label">Departure</p>
            <Row className="row-wrapper">
                <Col md={24} xs={24}>
                    <Form.Item label="Country" name={['list', `${item}`, 'departureCountry']} rules={[{ required: true, message: 'This field is required' }]}>
                        <Select
                            placeholder="Type to search"
                            className="fly-select"
                            showSearch={true}
                            options={utils.resources.defaultCountries}
                            allowClear
                            onChange={(value) => {
                                setDepartureCountry(value);
                                form.setFieldValue(['list', item, 'departureAirport'], undefined);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <hr />
                </Col>
                {departureCountry && (
                    <>
                        <Col md={24} xs={24}>
                            <Form.Item label="Airport" name={['list', `${item}`, 'departureAirport']} rules={[{ required: true, message: 'This field is required' }]}>
                                <Select
                                  placeholder="Type to search"
                                  className="fly-select"
                                  showSearch={true}
                                  options={departureAirportList}
                                  allowClear
                                  onChange={(value) => setDepartureAirport(value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <hr />
                        </Col>
                    </>
                )}
                <Col md={12} xs={12} className="pr-1">
                    <Form.Item label="Date" name={['list', `${item}`, 'departureDate']}
                        rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateDate(rule, value, callback, 'Departure date', true)}]}>
                        <DatePicker
                            format="MM/DD/YYYY"
                            placeholder="MM/DD/YYYY"
                            className="fly-date-picker"
                            onChange={(date) => {
                                setSelectedDate(date);
                                form.setFieldsValue({
                                    'list': {
                                        [item]: {['departureTime']: ''}
                                    }
                                });
                            }}
                            defaultPickerValue={utils.getSpecificDate(2)}
                            disabledDate={utils.disabledDateWithoutToday}
                            showToday={false}
                        />
                    </Form.Item>
                </Col>
                <Col md={12} xs={12} className="pl-1">
                    <Row className="ant-form-item">
                        <Col className="ant-form-item-label">
                            <label>Time</label>
                        </Col>
                    </Row>
                    <TimePopup form={form} idx={`${item}`} field="departureTime" selectedDate={selectedDate}/>
                </Col>
            </Row>
            <p className="section-label mt-2">Arrival</p>
            <Row className="row-wrapper">
                <Col md={24} xs={24}>
                    <Form.Item label="Country" name={['list', `${item}`, 'arrivalCountry']} rules={[{ required: true, message: 'This field is required' }]}>
                        <Select
                            placeholder="Type to search"
                            className="fly-select"
                            showSearch={true}
                            options={utils.resources.defaultCountries}
                            allowClear
                            onChange={(value) => {
                                setArrivalCountry(value);
                                form.setFieldValue(['list', item, 'arrivalAirport'], undefined);
                            }}
                        />
                    </Form.Item>
                </Col>
                {arrivalCountry && (
                    <>
                        <Col span={24}>
                            <hr />
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item label="Airport" name={['list', `${item}`, 'arrivalAirport']} rules={[{ required: true, message: 'This field is required' }]}>
                                <Select
                                  placeholder="Type to search"
                                  className="fly-select"
                                  showSearch={true}
                                  options={arrivalAirportList}
                                  allowClear
                                  onChange={(value) => setArrivalAirport(value)}
                                />
                            </Form.Item>
                        </Col>
                    </>
                )}
            </Row>
        </div>
    )
}

function MultipleFlight(props) {
    const [form] = Form.useForm()
    const [flightCount, setFlightCount] = useState([0])
    const [count, setCount] = useState(1)

    const {step, setStep, flight, setFlightValues} = props

    useEffect(() => {
        if (flight.list) {
            let fc = []
            Object.keys(flight.list).map(key => fc.push(key))
            setFlightCount(fc)
            setCount(utils.getMaxValue(fc))
        }
    }, [step])

    const onSubmitHandler = (values) => {
        setFlightValues(values)
        setStep(2)
    }

    const removeFlightHandler = (idx) => {
        let fc = flightCount.filter(item => item !== idx)
        setFlightCount(fc)
    }

    const addFlightHandler = () => {
        flightCount.push(count)
        setCount(count+1)
    }

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        {flightCount.map((item, idx) =>
                            <FlightItem item={item} idx={idx} key={idx} flight={flight}
                                removeFlightHandler={removeFlightHandler} form={form}
                            />
                        )}
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" className="fly-btn w-full add-flight-btn mb-2"
                                onClick={addFlightHandler}
                            >
                                Add Flight
                            </Button>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default MultipleFlight
