import React, { useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import * as utils from '../../../../utils'
import PaymentTypes from './PaymentTypes'
import PaymentDone from './PaymentDone'
import PaymentCard from './PaymentCard'
import FlightInfo from './FlightInfo'
import AddPassenger from '../../RequestFlight/AddPassenger';
import Passengers from './Passengers';
import Pets from './Pets';
import { ApiManager } from "../../../../service"

function FlightDetails(props) {
    const {history, currentUser, setActiveRecord, activeNav, updateData, config} = props
    const [loading, setLoading] = useState()
    const [step, setStep] = useState('Details')
    const [flight, setFlight] = useState()
    const [activePassenger, setActivePassenger] = useState()
    const [flightRequest, setFlightRequest] = useState()

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search)
        const stype = urlParams.get('status')
        if (stype)
            setStep(stype.toLowerCase())
    }, [history.location.search])

    const loadData = async () => {
        setFlight(props.flight)
        setFlightRequest(props.flight.request)
    }

    const postRequest = async (values) => {
        setLoading(true)
        utils.openNotification('Add one passenger at least!', false)
        setLoading(false)
    }

    const prevStepHandler = () => {
        if (step === "Details")
            setActiveRecord()
        if (step === "Payment Types")
            setStep('Details')
        if (step === "Payment Card")
            setStep('Payment Types')
        if (step === "done")
            setStep('Payment Card')
        if (step === "AddPassenger")
            setStep("Details")
        if (step === "Passenger")
            setStep("AddPassenger")
        if (step === 2)
            setStep("AddPassenger")
    }

    const setFlightValues = (values) => {
        let temp = flightRequest;
        Object.keys(values).forEach(key => {temp[key] = values[key]})
        setFlightRequest(temp)
    }

    const params = {
        history, step, setStep,
        flight, setFlight,
        setActiveRecord, activeNav,
        postRequest, loading, currentUser,
        updateData,
        activePassenger,
        setActivePassenger,
        config
    }

    const editPostRequest = async () => {
        setLoading(true);
        await ApiManager.flyerEditFlight({
            uid: currentUser.uid,
            flightProps: props.flight,
            flightRequest
        })
          .then((res) => {
              res.data.body.emailsToSend.map((content) => {
                  ApiManager.sendEmail(content);
              })
          })

        setActiveRecord();
        setLoading(false);
    }

    let renderPage = ''
    let pageTitle = 'Flight Details'
    let headerStyle = "client-header"
    let bodyStyle = 'client-body white'

    switch (step) {
        case 'Payment Types':
            renderPage = <PaymentTypes {...params} />
            pageTitle = 'Payment Method'
            headerStyle ="client-header gray"
            bodyStyle = "client-body height-100-percent"
            break
        case 'Payment Card':
            renderPage = <PaymentCard {...params} />
            pageTitle = 'Pay with a Credit Card'
            headerStyle ="client-header gray"
            bodyStyle = "client-body height-100-percent"
            break
        case 'done':
            renderPage = <PaymentDone {...params} />
            pageTitle = ''
            headerStyle ="client-header gray"
            bodyStyle = "client-body height-100-percent"
            break
        case 'AddPassenger':
            renderPage = <Passengers {...params} flight={flightRequest} setFlight={setFlightRequest} setFlightValues={setFlightValues} edit seatCapacity={props.flight.planeDetails.seatCapacity}/>
            pageTitle = 'Edit Flight'
            break
        case 'Passenger':
            renderPage = <AddPassenger {...params} flight={flightRequest} setFlight={setFlightRequest} edit/>
            pageTitle = 'Passenger'
            bodyStyle = 'client-body white addPassenger height-100-percent'
            break
        case 2:
            renderPage = <Pets {...params} flight={flightRequest} setFlight={setFlightRequest} setFlightValues={setFlightValues} edit postRequest={editPostRequest}/>
            pageTitle = 'Pets'
            bodyStyle = 'client-body white addPets'
            break
        default:
            renderPage = <FlightInfo {...params} />
            break
    }

    if (!flight)
        return ''

    return (
        <div className="content flight-details flexWrapper">
            <div className={headerStyle}>
                <div className="header-item">
                    {pageTitle && <ReactSVG src="/images/back-white.svg" className="label-icon" onClick={prevStepHandler}/>}
                    <p>{pageTitle}</p>
                    <div className="step-marker">
                    </div>
                </div>
                {pageTitle === 'Flight Details' && <>
                    <div className="header-item mt-3">
                        <div>
                            {flight?.departureAirport
                                ? (
                                    <>
                                        <p className="gray">{utils.resources.airports.index[flight?.departureAirport]?.place || ''}</p>
                                        <h1 className="mb-0">{flight?.departureAirport ? flight.departureAirport.split('-')[0] : ''}</h1>
                                        <p className="gray mb-3">{utils.resources.airports.index[flight?.departureAirport]?.title || ''}</p>
                                    </>
                                )
                                : (
                                    flight?.pickupInstruction
                                        ? (
                                            <p>
                                                {flight.pickupInstruction}
                                            </p>
                                        )
                                        : (
                                            <div className="mb-3">
                                                <p style={{fontSize: '12px'}}>
                                                    {flight?.departureLocation?.latitude}
                                                    <p style={{
                                                        opacity: 0.4,
                                                        marginRight: '3px',
                                                        fontSize: '12px',
                                                        whiteSpace: 'nowrap'
                                                    }}>Latitude</p>
                                                </p>
                                                <p style={{fontSize: '12px'}}>
                                                    {flight?.departureLocation?.longitude}
                                                    <p style={{
                                                        opacity: 0.4,
                                                        marginRight: '3px',
                                                        fontSize: '12px',
                                                        whiteSpace: 'nowrap'
                                                    }}>Longitude</p>
                                                </p>
                                            </div>
                                        )
                                )
                            }
                        </div>
                        <div className="t-right">
                            {flight?.arrivalAirport
                                ? (
                                    <>
                                        <p className="gray">{utils.resources.airports.index[flight?.arrivalAirport]?.place || ''}</p>
                                        <h1 className="mb-0">{flight?.arrivalAirport ? flight.arrivalAirport.split('-')[0] : ''}</h1>
                                        <p className="gray mb-3">{utils.resources.airports.index[flight?.arrivalAirport]?.title || ''}</p>
                                    </>
                                )
                                : (
                                    <div className="mb-3">
                                        <p style={{ fontSize: '12px' }}>
                                            {flight?.arrivalLocation?.latitude}
                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Latitude</p>
                                        </p>
                                        <p style={{ fontSize: '12px' }}>
                                            {flight?.arrivalLocation?.longitude}
                                            <p style={{ opacity: 0.4, marginRight: '3px', fontSize: '12px', whiteSpace: 'nowrap' }}>Longitude</p>
                                        </p>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="header-item">
                        <ReactSVG src="/images/arrow-circle.svg" className="b-icon" />
                        <div className="dashed"></div>
                        <ReactSVG src="/images/bird1.svg" className="b-icon" />
                        <div className="dashed"></div>
                        <ReactSVG src="/images/dot-circle.svg" className="b-icon" />
                    </div>
                    <div className="header-item mt-3">
                        <div className="d-flex">
                            <ReactSVG src="/images/flights1.svg" />
                            <p className="ml-1">{flight.departureTime} • {flight.departureDate}</p>
                        </div>
                        <p className="gray">{flight.number}</p>
                    </div>
                    <div className="header-item mt-3 mb-2">
                        <p className="price">
                            ${(flight.public && flight.createdBy !== currentUser.uid) ? flight.pricePerPerson : (flight.pricePerPerson * flight.planeDetails.seatCapacity / flight.request.passengers.length).toFixed(2)}/person
                        </p>
                    </div>
                </>}
            </div>
            <div className={bodyStyle}>
                { renderPage }
            </div>
        </div>
    )
}

export default FlightDetails
