import React, { useEffect } from 'react';
import { Spin } from 'antd';
import './index.scss';
import { useHistory } from 'react-router-dom';
import ApiManager from '../../service';

const KanooPayment = () => {
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const uid = urlParams.get('uid');
  const fid = urlParams.get('fid');
  const rid = urlParams.get('rid');
  const pid = urlParams.get('pid');
  const coupon = urlParams.get('coupon');
  const creditMoney = urlParams.get('creditMoney');

  useEffect(() => {
    ApiManager.kanooPaymentIntent({ uid, fid, rid, pid, coupon, creditMoney })
      .then((res) => {
        if (res?.data?.price) {
          window.kanooExternalPayment.createPayWithKanooContainer(
            "#pwk_container",
            process.env.REACT_APP_SECRET_CODE === "Prod" ? "859C3C1D43124E2A8D2DBD4EED9661AA" : "B302ED6833CD485BA39FD7469C4AB2F9",
            process.env.REACT_APP_SECRET_CODE === "Prod" ? 31688 : 1971
          );
          document.getElementById('amountInputTag').value = (res.data.price).toFixed(2);
          document.getElementById('callbackUrl').value = `https://us-central1-flymingo---${process.env.REACT_APP_SECRET_CODE === "Prod" ? "prod" : "dev"}.cloudfunctions.net/api/payment?uid=${uid}&fid=${fid}&rid=${rid}&pid=${pid}&coupon=${coupon}&creditMoney=${creditMoney}`;
          document.getElementById('btnPayWithKanoo').click();
        }
      })
  }, []);

  return (
    <div className='kanooPayBtnWrapper'>
      <Spin/>
      <div id="pwk_container" />
      <input type="hidden" id="redirectUrl" />
      <input type="hidden" id="callbackUrl" />
    </div>
  )
}

export default KanooPayment;
