import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom"
import { Row, Col, Dropdown, Menu } from 'antd'
import { ReactSVG } from 'react-svg'
import './index.scss'
import NotificationDrawer from './NotificationDrawer'
import { auth } from '../../firebaseConfig'

function FlyHeader(props) {
    const dispatch = useDispatch();
    const {history, currentUser} = props
    const [notificationVisible, setNotificationVisible] = useState(false)

    const openNotificationHandler = () => {
        setNotificationVisible(true)
    }

    const closeNotificationHandler = () => {
        setNotificationVisible(false)
    }

    const handleSignOut = () => {
        const user = null
        dispatch({ type: 'USER_LOADED', user })
        auth.signOut()
        history.push('/auth/login')
    }

    const menu = (
      <Menu>
        {currentUser &&
            <Menu.Item key="0" style={{ userSelect: 'none', pointerEvents: 'none' }}>
                {currentUser.profile.firstname} {currentUser.profile.lastname}
            </Menu.Item>
        }
        <Menu.Divider />
        <Menu.Item key="1" onClick={() => history.push('/dashboard/settings')}>
            Settings
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleSignOut()}>
            Log out
        </Menu.Item>
      </Menu>
    )

    const params = {
        history,
        notificationVisible,
        openNotificationHandler,
        closeNotificationHandler,
        currentUser
    }

    return (
        <Row className="fly-header">
            <Col><Link to="/"><ReactSVG src="/images/logo_black.svg" className="logo-icon" /></Link></Col>
            {currentUser && currentUser?.profile?.accountCompanyInfoFilled &&
                <Col className="d-flex">
                    {/*<Input type="text" className="fly-input mr-2" placeholder="Search" prefix={<SearchOutlined />}/>*/}
                    <NotificationDrawer {...params} />
                    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                        <img alt='' src={currentUser.photoURL? currentUser.photoURL : "/images/user-placeholder.png"} className="avatar"/>
                    </Dropdown>
                </Col>
            }
        </Row>
    )

}

export default FlyHeader
