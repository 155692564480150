import React from 'react'
import { Form, Modal, Row, Col } from 'antd'

function PassengerInfoModal(props) {
    const [form] = Form.useForm()
    const {isModalVisible, handleOk, handleCancel, currentUser,
            activeRecord } = props

    const onSubmitHandler = (values) => {
        console.log(values)
    }

    let renderForm = null
    let width = '56.25rem'
    let closable = true


    let passengerList = []
    if (activeRecord?.passengers) {
        Object.keys(activeRecord.passengers).map(key => passengerList.push(activeRecord.passengers[key]))
    }
    else {
        activeRecord?.requests && Object.keys(activeRecord?.requests).map(key => {
            Object.keys(activeRecord.requests[key].passengers).map(key2 => passengerList.push(activeRecord.requests[key].passengers[key2]))
        })
    }

    let passengerCnt = passengerList?.length

    return (
        <Modal title=""
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={width}
            className="fly-modal content request-details-modal"
            footer={[]}
            destroyOnClose={true}
            maskClosable={true}
            closable={closable}
        >
            <div className="fly-form auth-form">
                <Form
                    name="form"
                    id="form"
                    layout='vertical'
                    form={form}
                    onFinish={onSubmitHandler}
                    requiredMark={false}
                >
                    <h2 className="t-center">{passengerCnt} Passenger{passengerCnt > 1 && 's'}</h2>
                    { passengerList.map((passenger, key) => {
                        return (
                            <div className="card-bordered mb-4" key={key}>
                                <Row>
                                    <Col span={7}>
                                        <p className="label">Passenger {parseInt(key)+1}</p>
                                        <p>{passenger.firstname} {passenger.lastname}</p>
                                    </Col>
                                    <Col span={7}>
                                        <p className="label">Approximate Weight</p>
                                        <p>{passenger.weight}lbs</p>
                                    </Col>
                                </Row>
                                {!!passenger.numberOfBags && <>
                                    <hr />
                                    <Row>
                                        <Col span={7}>
                                            <p className="label">Number of Bags</p>
                                            <p>{passenger.numberOfBags}</p>
                                        </Col>
                                        <Col span={7}>
                                            <p className="label">Approximate Weight</p>
                                            <p>{passenger.weightPerBag}lbs (S)</p>
                                        </Col>
                                    </Row>
                                </>}
                            </div>
                        )
                    })}
                    {!!activeRecord.numberOfPets &&
                        <div className="card-bordered mb-4">
                            <Row>
                                <Col span={7}>
                                    <p className="label">Number of Pets</p>
                                    <p>{activeRecord.numberOfPets}</p>
                                </Col>
                                <Col span={7}>
                                    <p className="label">Weight per Pet</p>
                                    <p>{activeRecord.weightPerPet}lbs (S)</p>
                                </Col>
                            </Row>
                        </div>
                    }
                </Form>
            </div>
        </Modal>
    )
}

export default PassengerInfoModal
