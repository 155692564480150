import React, { useEffect, useState } from 'react';
import './index.scss';
import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

const CheckoutForm = ({ price, redirect, fid, rid }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    (async () => {
      if (!stripe) return;

      const urlParams = new URLSearchParams(window.location.search);
      const clientSecret = urlParams.get("payment_intent_client_secret");
      const redirect = urlParams.get("redirect");
      const fid = urlParams.get("fid");
      const rid = urlParams.get("rid");

      if (!clientSecret) return;
      setClientSecret(clientSecret);

      stripe.retrievePaymentIntent(clientSecret).then(async ({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            if (redirect) window.location.href = `${redirect}?fd=${fid}&rd=${rid}`;
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    })();
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + window.location.pathname + (redirect ? `?redirect=${redirect}&fid=${fid}&rid=${rid}` : ''),
      },
    });

    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    !clientSecret
      ? (
        <form id="payment-form" className='payment-form' onSubmit={handleSubmit}>
          <LinkAuthenticationElement
            id="link-authentication-element"
            onChange={(e) => setEmail(e.target.value)}
          />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <button className='payment-button' disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">
              {isLoading
                ? <div className="spinner" id="spinner"/>
                : `Pay ${price}$`}
            </span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </form>
      )
      : <div className='messageWrapper'>{message && <div id="payment-message">{message}</div>}</div>
  )
}

export default CheckoutForm;
