import React from 'react'
import { Row, Col, Button} from 'antd'

function ChangePasswordStatus(props) {
    const { history, newUser, setStep, error } = props;

    return (
        <div className="content auth-page auth-page-mobile profile-change-password">
            <div className="client-header b-gray">
                <div className="header-item">
                    <div className="step-marker">
                    </div>
                </div>
            </div>
            <div className="client-body b-gray height-100-percent">
                <Row className="t-center mt-5">
                    <Col span={24}>
                        <img src={`/images/${error ? 'error' : 'success'}.png`} alt=''/>
                        {error
                            ? <h3 className="mt-3">Whoops! Error!</h3>
                            : <h3 className="mt-3">New password was <br /> successfully set!</h3>}
                        {error
                            ? <p className="fly-notes mt-2 t-center">The ability to simplify means to eliminate the unnecessary so that the necessary may speak.</p>
                            : <p className="fly-notes mt-2 t-center">Now you can use it to sing in!</p>}
                    </Col>
                </Row>

                <Row className="mt-5">
                    {error
                        ? <>
                            <Col span={24}>
                                <Button type="primary fly-btn" className="fly-btn w-full" onClick={() => setStep('change-password')}>
                                    Try Again
                                </Button>
                                <Button type='text' className="mt-3 simple-btn" onClick={() => {}}>
                                    Help
                                </Button>
                            </Col>
                        </>
                        : <Col span={24}>
                            <Button type="primary fly-btn" className="fly-btn w-full" onClick={() => setStep('')}>
                                Continue
                            </Button>
                        </Col>}
                </Row>
            </div>
        </div>
    )
}

export default ChangePasswordStatus;
