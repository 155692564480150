import React, { useEffect, useState } from 'react'
import { Breadcrumb, Form, Row, Col, Button } from 'antd'
import { RightOutlined, ArrowRightOutlined } from '@ant-design/icons'
import AccountCompanyInfo from "./AccountCompanyInfo"
import AccountCompanyFlights from "./AccountCompanyFlights"
import AccountCompanyFleet from "./AccountCompanyFleet"
import AccountCompanyPilots from "./AccountCompanyPilots"

const routes = [
  {
    index: 0,
    path: 'company',
    breadcrumbName: 'Company Information',
  },
  {
    index: 1,
    path: 'flights',
    breadcrumbName: 'Add Your Flights',
  },
  {
    index: 2,
    path: 'fleet',
    breadcrumbName: 'Add Your Fleet',
  },
  {
    index: 3,
    path: 'pilots',
    breadcrumbName: 'Pilots',
  }
]

function AccountCompany(props) {
    const {user, setUser, history, currentUser, config} = props
    const [step, setStep] = useState('company')

    useEffect(() => {
        const urlParams = new URLSearchParams(history.location.search)
        setStep(urlParams.get('pt') || 'company')
    }, [history.location.search])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [step])

    let params = {
        user,
        setUser,
        setStep,
        history,
        currentUser,
        config
    }

    const itemRender = (route, params, routes, paths) => {
        const last = routes.indexOf(route) === routes.length - 1
        if (route.index > activeIndex())
            return <span>{route.breadcrumbName}</span>
        else
            return <span
                className="active-item"
                onClick={() => {
                    const urlParams = new URLSearchParams(history.location.search)
                    urlParams.set('pt', route.path)
                    history.push(`${history.location.pathname}?${urlParams.toString()}`)
                }}
            >{route.breadcrumbName}</span>
    }

    const activeIndex = () => {
        let activeStep = routes.filter(route => route.path === step)
        return activeStep[0].index
    }

    let renderPage = ''

    switch (step) {
        case 'flights':
            renderPage = <AccountCompanyFlights {...params} />
            break
        case 'fleet':
            renderPage = <AccountCompanyFleet {...params} />
            break
        case 'pilots':
            renderPage = <AccountCompanyPilots {...params} />
            break
        default:
            renderPage = <AccountCompanyInfo {...params} />
            break
    }

    if (!currentUser)
        return (
            <div className="content">
                <div className="fly-form">
                    <Form
                        className=""
                        name="form"
                        id="form"
                        layout='vertical'
                    >
                        <div className="form-content">
                            <div className="form-header">
                                <h2>403 Error</h2>
                                <p className="fly-info mb-4">You don't have a permission for this page. Please sign in.</p>
                            </div>
                        </div>
                        <Row className="form-footer mt-4">
                            <Col span={24} className="d-flex">
                                <Button type="primary" className="fly-btn ml-auto" onClick={() => history.push('/auth/login')}>
                                    CONTINUE<ArrowRightOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        )

    return (
        <>
            <Breadcrumb itemRender={itemRender} routes={routes} separator={<RightOutlined />} />
            {renderPage}
        </>
    )
}

export default AccountCompany
