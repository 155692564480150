import React, { useEffect, useState } from 'react'
import { Redirect } from "react-router-dom"
import './index.scss'
import Login from './Login'
import Register from './Register'
import PasswordRecovery from './PasswordRecovery'

function Auth(props) {
    const {history, currentUser} = props
    const [step, setStep] = useState('');
    const [newUser, setNewUser] = useState({});

    useEffect(() => {
        let pathname = history.location.pathname.split('/')[3]
        setStep(pathname)
    }, [history.location])

    const params = {
        history, currentUser,
        newUser, setNewUser
    }

    switch (step) {
        case 'register':
            return <Register {...params} />
        case 'change-password':
            return <PasswordRecovery {...params} />
        default:
            if (currentUser) {
                if (!currentUser?.profile?.isClientCompleteRegistration) {
                    let urlParams = new URLSearchParams(history.location.search);
                    let userId = urlParams.get('uid')
                    let requestId = urlParams.get('rid')

                    window.history.replaceState(null, '', `/client/auth/register${userId && requestId ? `?uid=${userId}&rid=${requestId}` : ''}`);
                    return <Register {...params} />
                }
                return <Redirect to="/client/flight-requests?ft=requested" />
            }
            return <Login {...params} />
    }
}

export default Auth
