import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Switch, Dropdown, Menu, Modal, Statistic, Tooltip, Select } from 'antd'
import { EllipsisOutlined, InfoCircleFilled } from '@ant-design/icons'
import { ReactSVG } from 'react-svg'
import './index.scss'
import * as utils from '../../../utils'
import {
    KANOO_COMMISSION,
    MIN_STRIPE_COMMISSION,
    FIXED_STRIPE_COMMISSION,
    FLYMINGO_COMMISSION,
    KANOO_PAYMENT_COUNTRY
} from '../../../constants';

function PassengerItem(props) {
    const {passenger, removePassengerHandler, editPassengerHandler, idx} = props
    const menu = (
        <Menu className="passenger-menu">
            <Menu.Item key="edit" onClick={() => editPassengerHandler(passenger, idx)}>
                <p>Edit</p>
            </Menu.Item>
            <Menu.Item key="remove" onClick={() => removePassengerHandler(passenger, idx)}>
                <p>Remove</p>
            </Menu.Item>
        </Menu>
    )

    return (
        <Row className="passenger-item row-wrapper mb-2">
            <Col span={22}>
                <p>{passenger.firstname} {passenger.lastname}, <span className="fly-notes">{passenger.weight}lbs</span></p>
                {passenger.numberOfBags && <p>{passenger.numberOfBags} Bag{passenger.numberOfBags>1 && "s"}, <span className="fly-notes">{passenger.weightPerBag}lbs (S)</span></p>}
            </Col>
            <Col span={2} className="t-right">
                <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
                    <EllipsisOutlined />
                </Dropdown>
            </Col>
        </Row>
    )
}

function Flight(props) {
    const [form] = Form.useForm()
    const {step, setStep, flight, setFlight, setFlightValues, setActivePassenger, postRequest, loading, currentUser, config} = props

    const [hasPet, setHasPet] = useState(false)

    useEffect(() => {
        setHasPet(flight.hasPet)
    }, [step])

    const onSubmitHandler = (values) => {
        postRequest(values)
    }

    const AddPassengerHandler = () => {
        setActivePassenger()
        setStep("AddPassenger")
    }

    const removePassengerHandler = (passenger, idx) => {
        Modal.confirm({
            title: '',
            maskClosable: true,
            icon: <ReactSVG src="/images/warning.svg" />,
            content: <div>
                <h3 className="mt-4">Remove the passenger</h3>
                <p>Are you sure you want to remove the passenger <b>{passenger.firstname} {passenger.lastname}</b>?</p>
            </div>,
            okText: 'REMOVE',
            onOk() {
                let passengers = flight.passengers.filter((item, i) => i !== idx)
                setFlight({...flight, passengers})
            },
        })
    }

    const editPassengerHandler = (passenger, idx) => {
        setActivePassenger({
            passenger: passenger,
            idx: idx
        })
        setStep("AddPassenger")
    }

    const onChangeHander = (checked) => {
        setHasPet(checked)
        setFlightValues({ ...flight, hasPet: checked })
    }

    const personCnt = flight.passengers ? Object.keys(flight.passengers).length : 0
    let bagCnt = 0
    flight.passengers && Object.keys(flight.passengers).map(pkey => {
        let passenger = flight.passengers[pkey]
        const passengerBagCnt = passenger.numberOfBags || 0
        bagCnt += passengerBagCnt > 1 ? passengerBagCnt - 1 : 0
    })
    let subTotalPrice = ((flight.pricePerPerson * personCnt) + (bagCnt * flight.pricePerBag)).toFixed(2);
    let serviceFee = (+subTotalPrice * (config.FLYMINGO_COMMISSION || FLYMINGO_COMMISSION)).toFixed(2);

    // const IS_KANOO_PAY = flight.companyCountry === KANOO_PAYMENT_COUNTRY;
    const IS_KANOO_PAY = false;

    const commissionPercentage = IS_KANOO_PAY ? KANOO_COMMISSION : MIN_STRIPE_COMMISSION;

    const totalTaxes = personCnt ? (
      ((+subTotalPrice + +serviceFee) *
        (commissionPercentage)) / (100 - commissionPercentage)
      + (IS_KANOO_PAY ? 0 : FIXED_STRIPE_COMMISSION)
    ).toFixed(2) : 0;

    const totalPrice = (+subTotalPrice + +serviceFee + +totalTaxes).toFixed(2);

    const myInfo = {
        firstname: currentUser.profile.firstname,
        lastname: currentUser.profile.lastname,
        weight: currentUser.profile.approximateWeight
    }
    const autoId = flight.passengers ? flight.passengers.length : 0
    const myInfoIncluded = flight.passengers && flight.passengers.filter(passenger => passenger.firstname === myInfo.firstname && passenger.lastname === myInfo.lastname).length > 0;


    return (
        <div className="flight-board departure-details">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label">Plane Details</p>
                        <Row className="row-wrapper">
                            <Col span={24}>
                                <div className="d-flex d-space">
                                    <p className="section-desc">Status</p>
                                    {flight.planeDetails.verified === "done"?
                                        <div className="d-flex">
                                            <ReactSVG src="/images/verified-star.svg" className="mr-1" />
                                            <p>Verified</p>
                                        </div>
                                        :
                                        <p>unverified</p>
                                    }
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Aircraft Make</p>
                                    <p>{flight.planeDetails.planeMake}</p>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Year</p>
                                    <p>{flight.planeDetails.year}</p>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Plane Type</p>
                                    <div className="d-flex ">
                                        <ReactSVG src={`images/${utils.getPlaneType(flight.planeDetails.planeType)}.svg`} className="planetype-icon" />
                                        <p className="ml-1">{flight.planeDetails.planeType}</p>
                                    </div>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Pilot</p>
                                    <p>{flight.pilotName}</p>
                                </div>
                            </Col>
                        </Row>

                        <div className="flex-container">
                            <p className="section-label mt-3">Passengers</p>
                            <p className="section-label-small mt-3">{flight.availableSeats} Seats Available</p>
                        </div>
                        {flight.passengers && flight.passengers.map((passenger, idx) =>
                            <PassengerItem key={idx}
                                passenger={passenger}
                                idx={idx}
                                editPassengerHandler={editPassengerHandler}
                                removePassengerHandler={removePassengerHandler}
                            />
                        )}
                        <Row className="">
                            <Col span={24}>
                                { !myInfoIncluded &&
                                    <Button
                                        type="primary"
                                        disabled={!flight.availableSeats || flight?.passengers?.length === flight.availableSeats}
                                        className="fly-btn w-full add-btn mb-2"
                                        onClick={() => editPassengerHandler(myInfo, autoId)}
                                    >
                                        Add me
                                    </Button>
                                }
                                <Button
                                    type="primary"
                                    disabled={!flight.availableSeats || flight?.passengers?.length === flight.availableSeats}
                                    className="fly-btn w-full add-btn"
                                    onClick={AddPassengerHandler}
                                >
                                    Add a Passenger
                                </Button>
                            </Col>
                        </Row>

                        <p className="section-label mt-3">Pets</p>
                        <Row className="d-flex">
                            <Col span={20}>
                                <p>Traveling with pet</p>
                            </Col>
                            <Col span={4} className="t-right">
                                <Form.Item label="" name='hasPet' valuePropName="checked">
                                    <Switch onChange={onChangeHander} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {hasPet &&
                            <Row className="row-wrapper">
                                <Col span={24}>
                                    <Form.Item
                                        label="Number of Pets"
                                        name="numberOfPets"
                                        rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                        <Select
                                            placeholder="Type to search"
                                            className="fly-select"
                                            options={utils.resources.integers}
                                            showSearch={true}
                                            allowClear
                                            onChange={(value) => setFlightValues({ ...flight, numberOfPets: value })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                <hr />
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label="Approximate Weight per Pet"
                                        name="weightPerPet"
                                        rules={[{ required: true, message: 'This field is required' }]}
                                    >
                                        <Select
                                            placeholder="Type to search"
                                            className="fly-select"
                                            options={utils.resources.bagWeightRange}
                                            showSearch={true}
                                            allowClear
                                            onChange={(value) => setFlightValues({ ...flight, weightPerPet: value })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }

                        <p className="section-label mt-4">Total Fees</p>
                        <Row className="row-wrapper mt-1 bg-gray">
                            <Col span={24}>
                                <div className="d-flex d-space">
                                    <p className="section-desc">Total</p>
                                    <Statistic prefix="$" value={subTotalPrice} precision={2}/>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Service Fee</p>
                                    <div className="d-flex">
                                        <Tooltip
                                            className="fly-tooltip"
                                            title={<p>This is to help run our platform & cover processing fees.</p>}
                                            color="#90C4C2"
                                            placement="top"
                                        >
                                            <InfoCircleFilled
                                                className="mr-1"
                                                style={{color: "#95999A", fontSize: '12px'}}
                                            />
                                        </Tooltip>
                                        <Statistic prefix="$" value={serviceFee} precision={2}/>
                                    </div>
                                </div>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Taxes</p>
                                    <Statistic prefix="$" value={totalTaxes} precision={2}/>
                                </div>
                                <hr/>
                                <div className="d-flex d-space mt-1">
                                    <p className="section-desc">Total Amount Paid (BSD)</p>
                                    <Statistic prefix="$" value={totalPrice} precision={2}/>
                                </div>
                            </Col>
                        </Row>
                        
                        <Row className="mt-4">
                            <Col span={24}>
                                <Button type="primary" htmlType="submit" className="fly-btn w-full" loading={loading}>
                                    Request to Book
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Form>
        </div>
    )
}

export default Flight
