import React from 'react'
import { Row, Col, Button } from 'antd'
import { ReactSVG } from 'react-svg'

function RequestDone(props) {
    const {history, step, setStep, setActiveRecord, activeBid, setActiveNav} = props

    return (
        <div className="bid-done-board">
            <Row>
                <Col span={24} className="t-center">
                    <ReactSVG src="/images/done.svg" />
                    <h3 className="mt-3">You accepted<br />the flight bid!</h3>
                    <p className="fly-notes mt-2 t-center">
                        You have 15 minutes to pay for your flight before it is canceled, please go to your flight below and scroll to the bottom to pay and confirm your flight.
                    </p>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col span={24}>
                    <Button type="primary" className="fly-btn w-full" onClick={() => {
                        setActiveRecord();
                        history.push('/client/flight-requests?ft=upcoming')
                    }}>
                        Pay Now
                    </Button>
                    {/*<Button type="primary" className="fly-btn w-full mt-2 cancel-btn" onClick={() => {
                        setActiveRecord();
                        history.push('/client/flight-requests?ft=requested')
                    }}>
                        Got it
                    </Button>*/}
                </Col>
            </Row>
        </div>
    )
}

export default RequestDone
