import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from 'history'
import FlyActions from './redux/common'
import './pages/index.scss'
import FlyHelmet from './components/FlyHelmet';
import Auth from "./pages/Auth/"
import Client from "./pages/Client/"
import Terms from "./pages/TermsAndPrivacy/Terms"
import Privacy from "./pages/TermsAndPrivacy/Privacy"
import Dashboard from "./pages/Dashboard/"
import NotFound from "./pages/404"
import StripePayment from './pages/StripePayment'
import KanooPayment from './pages/KanooPayment';
import Receipt from './pages/Receipt';
import "antd/dist/antd.css"
import { auth } from './firebaseConfig'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { CLIENT_DOMAIN, CLIENT_ROLE, DEV_CLIENT_DOMAIN, DEV_PILOT_DOMAIN, PILOT_DOMAIN } from './constants';

const basename = '/'

const history = createBrowserHistory({
    basename
})

function App (props) {
    const dispatch = useDispatch()
    const { currentUser } = useSelector((state) => state.common)
    const { appLoaded } = useSelector((state) => state.common)

    useEffect(() => {
        auth.onAuthStateChanged(user => {
            dispatch(FlyActions.onLoadUser(user))
        })
    }, [])

    const domain = window.location.host;

    useEffect(() => {
        if (domain === PILOT_DOMAIN || domain === DEV_PILOT_DOMAIN || (process.env.NODE_ENV !== 'production' && !history?.location?.pathname?.includes('/client'))) {
            const rootElement = document.getElementById('root');
            if (rootElement) {
                rootElement.style.height = 'fit-content';
                rootElement.style.minHeight = '100vh';
            }
        }
    }, [history]);

    if (!appLoaded)
        return ''

    const productionEnv = domain === PILOT_DOMAIN || domain === CLIENT_DOMAIN;

    const renderComponent = (component, user, chatra = false, staticBarColor = false) => {
        const userRole = user?.profile?.role;

        if (user && userRole && userRole === CLIENT_ROLE && (domain === PILOT_DOMAIN || domain === DEV_PILOT_DOMAIN)) {
            return <NotFound/>;
        }

        if (user && userRole && userRole !== CLIENT_ROLE && (domain === CLIENT_DOMAIN || domain === DEV_CLIENT_DOMAIN)) {
            return <NotFound/>;
        }

        return <>
            <FlyHelmet chatra={chatra} analytics={productionEnv} staticBarColor={staticBarColor} />
            {component}
        </>
    }

    const ClientRotes = [
        <Route exact path="/">
            <Redirect to="/client/auth" />
        </Route>,
        <Route
            path="/client/:path(request-a-flight|post-a-book|flight-requests|departures|profile)"
            render={(props) => renderComponent(<Client props={props}/>, currentUser, true, true)}
        />,
        <Route
            path="/client/:path(boarding|auth)"
            render={(props) => renderComponent(<Client props={props}/>, currentUser, false, true)}
        />,
        <Route
          path="/stripe-payment"
          render={(props) => <StripePayment />}
        />,
        <Route
          path="/kanoo-payment"
          render={(props) => <KanooPayment />}
        />,
        <Route
          path="/receipt"
          render={(props) => <Receipt />}
        />,
        <Route
            path="*"
            render={(props) => <NotFound />}
        />
    ];

    const PilotRotes = [
        <Route exact path="/">
            <Redirect to="/auth/login" />
        </Route>,
        <Route exact path="/auth/">
            <Redirect to="/auth/welcome" />
        </Route>,
        <Route
            path="/auth/:path(welcome|login|register|password-recovery|complete|account-type|account-company|action)"
            render={(props) => renderComponent(<Auth props={props}/>, currentUser, false, false)}
        />,
        <Route exact path="/dashboard/">
            <Redirect to="/dashboard/home" />
        </Route>,
        <Route
            path="/dashboard/:path(home|requests|flights|fleets|pilots|wallet|settings)"
            render={(props) => renderComponent(<Dashboard props={props}/>, currentUser, true, false)}
        />,
        <Route
            path="/pilot-terms"
            render={(props) => <Terms />}
        />,
        <Route
            path="/pilot-privacy"
            render={(props) => <Privacy />}
        />,
        <Route
            path="*"
            render={(props) => <NotFound />}
        />
    ]

    const DevelopmentRoutes = [
        <Route exact path="/">
            <Redirect to="/auth/login" />
        </Route>,
        <Route exact path="/auth/">
            <Redirect to="/auth/welcome" />
        </Route>,
        <Route
            path="/auth/:path(welcome|login|register|password-recovery|complete|account-type|account-company|action)"
            render={(props) => renderComponent(<Auth props={props}/>, currentUser, false, false)}
        />,
        <Route exact path="/dashboard/">
            <Redirect to="/dashboard/home" />
        </Route>,
        <Route
            path="/dashboard/:path(home|requests|flights|fleets|pilots|wallet|settings)"
            render={(props) => renderComponent(<Dashboard props={props}/>, currentUser, false, false)}
        />,
        <Route
            path="/client/:path(request-a-flight|post-a-book|flight-requests|departures|auth|boarding|profile)"
            render={(props) => renderComponent(<Client props={props}/>, currentUser, false, true)}
        />,
        <Route
            path="/pilot-terms"
            render={(props) => <Terms />}
        />,
        <Route
            path="/pilot-privacy"
            render={(props) => <Privacy />}
        />,
        <Route
          path="/stripe-payment"
          render={(props) => <StripePayment />}
        />,
        <Route
          path="/kanoo-payment"
          render={(props) => <KanooPayment />}
        />,
        <Route
          path="/receipt"
          render={(props) => <Receipt />}
        />,
        <Route
            path="*"
            render={(props) => <NotFound />}
        />
    ]

    return (
        <BrowserRouter basename={basename} history={history}>
            <Switch>
                {(domain === CLIENT_DOMAIN || domain === DEV_CLIENT_DOMAIN) && ClientRotes}
                {(domain === PILOT_DOMAIN || domain === DEV_PILOT_DOMAIN) && PilotRotes}
                {process.env.NODE_ENV !== 'production' && DevelopmentRoutes}
            </Switch>
        </BrowserRouter>
    )
}

export default App
