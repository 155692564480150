import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button, Select, DatePicker } from 'antd'
import { ReactSVG } from 'react-svg'
import './index.scss'
import * as utils from '../../../utils'
import TimePopup from './TimePopup'

function RoundFlight(props) {
    const [form] = Form.useForm()
    const [selectedDate, setSelectedDate] = useState()
    const [selectedArrivalDate, setSelectedArrivalDate] = useState()
    const [departureAirport, setDepartureAirport] = useState('')
    const [arrivalAirport, setArrivalAirport] = useState('')
    const [departureCountry, setDepartureCountry] = useState('')
    const [arrivalCountry, setArrivalCountry] = useState('')
    const {step, setStep, flight, setFlightValues} = props

    useEffect(() => {
        if (flight.list) {
            setSelectedDate(flight.list['0'].departureDate)
            setSelectedArrivalDate(flight.list['0'].arrivalDate)
            setDepartureCountry(flight.list['0'].departureCountry || '')
            setArrivalCountry(flight.list['0'].arrivalCountry || '')
        }
    }, [])

    const onSubmitHandler = (values) => {
        setFlightValues(values)
        setStep(2)
    }

    let departureAirportList =
        utils.resources.airports[utils.resources.countryContraction[departureCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== arrivalAirport)
    let arrivalAirportList =
        utils.resources.airports[utils.resources.countryContraction[arrivalCountry] || utils.resources.countryContraction.Bahamas]
            .filter(item => item.value !== departureAirport)

    return (
        <div className="flight-board">
            <Form
                name="form"
                id="form"
                layout='vertical'
                form={form}
                onFinish={onSubmitHandler}
                requiredMark={false}
                initialValues={flight}
            >
                <div className="form-content">
                    <div className="form-body">
                        <p className="section-label">Departure</p>
                        <Row className="row-wrapper">
                            <Col md={24} xs={24}>
                                <Form.Item label="Country" name={['list', '0', 'departureCountry']} rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        placeholder="Type to search"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.defaultCountries}
                                        allowClear
                                        onChange={(value) => {
                                            setDepartureCountry(value);
                                            form.setFieldValue(['list', '0', 'departureAirport'], undefined);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <hr />
                            </Col>
                            {departureCountry && (
                                <>
                                    <Col md={24} xs={24}>
                                        <Form.Item label="Airport" name={['list', '0', 'departureAirport']} rules={[{ required: true, message: 'This field is required' }]}>
                                            <Select
                                                placeholder="Type to search"
                                                className="fly-select"
                                                showSearch={true}
                                                options={departureAirportList}
                                                allowClear
                                                onChange={(value) => setDepartureAirport(value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <hr />
                                    </Col>
                                </>
                            )}
                            <Col md={12} xs={12} className="pr-1">
                                <Form.Item label="Date" name={['list', '0', 'departureDate']}
                                    rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateDate(rule, value, callback, 'Departure date', true)}]}>
                                    <DatePicker
                                        format="MM/DD/YYYY"
                                        placeholder="MM/DD/YYYY"
                                        className="fly-date-picker"
                                        onChange={(date) => {
                                            setSelectedDate(date);
                                            form.setFieldsValue({
                                                'list': {
                                                    [0]: {['departureTime']: ''}
                                                }
                                            });
                                        }}
                                        defaultPickerValue={utils.getSpecificDate(2)}
                                        disabledDate={utils.disabledDateWithoutToday}
                                        showToday={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={12} className="pl-1">
                                <Row className="ant-form-item">
                                    <Col className="ant-form-item-label">
                                        <label>Time</label>
                                    </Col>
                                </Row>
                                <TimePopup form={form} idx="0" field="departureTime" selectedDate={selectedDate}/>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-3">
                            <Col span={24}>
                                <ReactSVG src="/images/switch.svg" className="label-icon m-auto" />
                            </Col>
                        </Row>
                        <p className="section-label">Return</p>
                        <Row className="row-wrapper">
                            <Col md={24} xs={24}>
                                <Form.Item label="Country" name={['list', '0', 'arrivalCountry']} rules={[{ required: true, message: 'This field is required' }]}>
                                    <Select
                                        placeholder="Type to search"
                                        className="fly-select"
                                        showSearch={true}
                                        options={utils.resources.defaultCountries}
                                        allowClear
                                        onChange={(value) => {
                                            setArrivalCountry(value);
                                            form.setFieldValue(['list', '0', 'arrivalAirport'], undefined);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <hr />
                            </Col>
                            {arrivalCountry && (
                                <>
                                    <Col md={24} xs={24}>
                                        <Form.Item label="Airport" name={['list', '0', 'arrivalAirport']} rules={[{ required: true, message: 'This field is required' }]}>
                                            <Select
                                                placeholder="Type to search"
                                                className="fly-select"
                                                showSearch={true}
                                                options={arrivalAirportList}
                                                allowClear
                                                onChange={(value) => setArrivalAirport(value)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <hr />
                                    </Col>
                                </>
                            )}
                            <Col md={12} xs={12} className="pr-1">
                                <Form.Item label="Date" name={['list', '0', 'arrivalDate']}
                                    rules={[{ required: true, validator: (rule, value, callback) => utils.validations.validateDateCompare(rule, value, callback, 'Arrival date', true, selectedDate)}]}>
                                    <DatePicker
                                        format="MM/DD/YYYY"
                                        placeholder="MM/DD/YYYY"
                                        className="fly-date-picker"
                                        onChange={(date) => {
                                            setSelectedArrivalDate(date);
                                            form.setFieldsValue({
                                                'list': {
                                                    [0]: {['arrivalTime']: ''}
                                                }
                                            });
                                        }}
                                        defaultPickerValue={utils.getSpecificDate(2)}
                                        disabledDate={utils.disabledDateWithoutToday}
                                        showToday={false}
                                    />
                                </Form.Item>
                            </Col>
                            <Col md={12} xs={12} className="pl-1">
                                <Row className="ant-form-item">
                                    <Col className="ant-form-item-label">
                                        <label>Time</label>
                                    </Col>
                                </Row>
                                <TimePopup form={form} idx="0" field="arrivalTime" selectedDate={selectedArrivalDate}/>
                            </Col>
                        </Row>
                    </div>
                    <Row className="form-footer mt-5">
                        <Col span={24}>
                            <Button type="primary" htmlType="submit" className="fly-btn w-full">
                                Continue
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Form>
        </div>
    )
}

export default RoundFlight
